/**
 *
 * StoreList
 *
 */

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
// import useStore from 'containers/StoreProvider/hooks/useStore';
import { ListItemAvatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/StoreProvider/messages';
import StoreItem from './StoreItem';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      height: 1,
      backgroundColor: theme.palette.background.peach,
      margin: theme.spacing(0, 3),
    },
  },
}));

function StoreList({ list, onSelect }) {
  const classes = useStyles();
  // const { storeSelected } = useStore();

  return (
    <Box>
      {list.map(item => (
        <Fragment key={item.source_code}>
          <StoreItem
            // selectedItem={storeSelected}
            item={item}
            onClick={() => onSelect?.(item)}
            storeNotice={ListItemAvatar.storeNotice}
          />
          <Divider className={classes.divider} />
        </Fragment>
      ))}
      {list.length < 3 && (
        <Box p={3} align="center">
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage {...messages.noMoreStore} />
          </Typography>
        </Box>
      )}
    </Box>
  );
}

StoreList.propTypes = {
  list: PropTypes.array,
  onSelect: PropTypes.func,
};

export default StoreList;
