import get from 'lodash/get';
import messages from 'containers/MembershipProvider/messages';
import appMessages from 'containers/App/messages';
import { WE_CHAT_PAY, ALIPAY } from './constants';

export const definePaymentMethod = (order = {}) => {
  const payment = get(order, 'payment.method', '');
  const isPointsPayment = get(order, 'extension_attributes.use_points', false);
  const methodLabel = get(order, 'payment.extension_attributes.method_label', '');

  if (methodLabel) {
    return methodLabel;
  }

  if (payment === WE_CHAT_PAY && isPointsPayment) {
    return messages.creditPointsAndWeChatPay;
  }

  if (payment === WE_CHAT_PAY) {
    return appMessages.weChatPay;
  }

  if (payment === ALIPAY) {
    return appMessages.alipay;
  }

  if (isPointsPayment) {
    return appMessages.creditPoints;
  }

  return get(order, 'payment.extension_attributes.method_label', '') || payment;
};

export const getTracks = (shipments = []) => {
  if (!shipments) {
    return [];
  }

  return shipments.reverse().map((shipment, index) => {
    const qtyMaps = {};
    const childrenMaps = {};
    const notChildProducts = (shipment.items || []).filter(item => {
      const itemParentId = item.extension_attributes.item_parent_id;
      if (itemParentId !== 0) {
        const product = {
          image: item.extension_attributes.image,
          ...item,
        };
        if (childrenMaps[itemParentId]) {
          childrenMaps[itemParentId].push(product);
        } else {
          childrenMaps[itemParentId] = [product];
        }
      }

      return itemParentId === 0;
    });

    const bundleKeys = Object.keys(childrenMaps);
    const products = notChildProducts.map(({ qty, ...product }) => {
      const isBundle = bundleKeys.includes(`${product.order_item_id}`);
      qtyMaps[product.order_item_id] = qty;

      return {
        ...product,
        qty: isBundle ? null : qty,
        image: product.extension_attributes.image,
        children: childrenMaps[product.order_item_id],
      };
    });

    const [tracks = {}] = shipment.tracks || [];

    return {
      ...tracks,
      products,
      qtyMaps,
      mobile: shipment.extension_attributes.mobile,
      index,
      shipment_id: shipment.entity_id,
    };
  });
};
