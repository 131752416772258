/* eslint-disable no-undef */
/**
 * Process WeChat Pay payment parameters before redirecting to native payment page
 * @param parameters
 * @param orderID
 */
import determineEnvironment from 'utils/WeChat/determineEnvironment';

class CustomError extends Error {
  constructor(message, extra) {
    super(message);
    this.name = this.constructor.name;
    this.extra = extra;
  }
}

export default function processWeChatPayParameters(parameters, orderID) {
  return new Promise((resolve, reject) => {
    try {
      if (parameters[0] === 'Ok' && parameters[2]) {
        const params = parameters[2];
        const prepayID = params.package.split('=');
        const isMin = determineEnvironment();

        if (isMin) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateTo({
            url: `/pages/payment/payment?orderID=${orderID}&nonceStr=${params.nonceStr}&prepayID=${prepayID[1]}&paySign=${params.paySign}&signType=${params.signType}&timeStamp=${params.timeStamp}`,
          });

          resolve(true);
        } else {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {
              appId: params.appId, // 公众号ID，由商户传入
              timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
              nonceStr: params.nonceStr, // 随机串
              package: params.package,
              signType: params.signType, // 微信签名方式：
              paySign: params.paySign, // 微信签名
            },
            function (res) {
              if (res.err_msg == 'get_brand_wcpay_request:ok') {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                resolve(true);
                return;
              }
              // throw new CustomError(res.errMsg, { parameters, orderID });
              reject(new CustomError(res.err_msg || res.errMsg, { parameters, orderID }));
            },
          );
        }

        return;
      }
    } catch (err) {
      console.log(err);
    }

    reject(new CustomError('支付参数获取错误', { parameters, orderID }));
  });
}
