/* eslint-disable */
/* eslint-disable */
function Icon12AngleDown(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M5.655 8.071L2.38 4.951a.5.5 0 0 1 0-.725l.312-.297a.5.5 0 0 1 .69 0l2.273 2.166a.5.5 0 0 0 .69 0l2.273-2.166a.5.5 0 0 1 .69 0l.312.297a.5.5 0 0 1 0 .724L6.345 8.071a.5.5 0 0 1-.69 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12AngleDown;
