import store from 'store';
import currency from 'currency.js';
import dayjs from 'dayjs';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import qs from 'qs';
import union from 'lodash/union';
import isPlainObject from 'lodash/isPlainObject';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isObject from 'lodash/isObject';
import placeholder from '../images/placeholder.png';
import { URL_TO_SHOPNOW_TRACKING_APPID_MAP } from './constants';

/**
 * Toggle (add or remove) item in array
 *
 * @param {array} collection         The array in which the item should be toggled
 * @param {string} item         The input to toggle inside the collection
 *
 * @returns {*[]}               Returns the new array in which the item as been toggled
 */
export const toggleItemInArray = (collection, item) => {
  const index = indexOf(collection, item);
  if (index !== -1) {
    return without(collection, item);
  }
  return [...collection, item];
};

/**
 * Reverse an array
 *
 * @param {array} array         The array to be reversed
 *
 * @returns {*[]}               Returns the new reversed array
 */
/* eslint-disable no-unused-vars, prefer-const, no-plusplus */
export const reverseArray = array => {
  let ar = array;
  let len = ar.length;
  let forwardI = 0;
  while (forwardI < len) {
    let end = len - 1 - forwardI;
    ar.push(ar[end]);
    ar.splice(end, 1);
    forwardI++;
  }
  return ar;
};
/* eslint-enable */

/**
 * Return a formatted string with swapped parameters
 *
 * @param {string} input      The string that needs to be formatted
 * @param {array} [parameters]  The array of parameters that will used to swap variables in the input
 *
 * @return {string}           Returns the formatted string
 */
export const sprintf = (input, parameters) => {
  if (Array.isArray(parameters)) {
    /* eslint-disable-next-line no-plusplus, no-param-reassign */
    return input.replace(/%(\d+)/g, (_, m) => parameters[--m]);
  }

  if (parameters && (parameters?.fieldName || parameters?.fieldValue)) {
    return input
      .replace(/%\s?fieldName/g, parameters.fieldName)
      .replace(/%\s?fieldValue/g, parameters.fieldValue);
  }

  if (isObject(parameters)) {
    /* eslint-disable-next-line no-plusplus, no-param-reassign */
    return input.replace(/%(\w+)/g, (_, m) => parameters[m]);
  }

  return input;
};

/**
 * Parse date and return in the user's local timezone
 *
 * @param {(string|number)} input    The input date, should be UTC
 *
 * @return {string}                  Returns the localised date
 */
export const getLocalTime = input => dayjs.utc(input).local();

/**
 * 获取过期时间
 * */
export const getExpireTime = (time, space = 7) => {
  // eslint-disable-next-line no-throw-literal
  if (!time) throw "The required parameter 'time' is required";

  const now = dayjs.utc();
  const expire = now.add(space, 'day');

  return {
    about: expire.isAfter(time) && now.isBefore(time),
    expire: now.isAfter(time),
  };
};

/**
 * Format price
 *
 * @param {(string|number)} input    The string or number that needs to be formatted
 * @param {string} symbol            The currency symbol to be used for formatting
 * @param {number} decimals          The amount of decimals in the formatted price
 * @param {string} precision
 * @return {string}                  Returns the formatted price
 */
export const formatPrice = (input, symbol = '¥', precision = '') => {
  const decimal = /^1(.+)1$/.exec((1.1).toLocaleString('zh'))[1];
  const decimals = input % 1 === 0 ? 0 : 2;
  const output = currency(input, {
    symbol,
    separator: decimal === '.' ? ',' : '.',
    decimal,
    precision: precision || decimals,
    formatWithSymbol: true,
  });
  return output.format();
};

export const formatFullPrice = (input, symbol = '¥') => {
  const decimals = 2;
  const output = currency(input, {
    symbol,
    separator: '',
    precision: decimals,
    formatWithSymbol: true,
  });
  return output.format();
};

/**
 * Format product weight
 *
 * @param {(string|number)} weight      The string or number that needs to be formatted
 * @param {boolean} small               Whether to use the smaller version of the unit: ounce
 *
 * @return {string}                     Returns the formatted price
 */
export const formatWeight = (weight, small = false) => {
  const configs = store.get('store_configs');
  const unit = get(configs, 'weight_unit', 'kgs');

  if (small && unit === 'kgs') return `${weight * 1000}g`;
  if (small && unit === 'lb') return `${weight * 16}oz`;

  return `${weight}${unit}`;
};

/**
 * Format product volume
 *
 * @param {(string|number)} volume      The string or number that needs to be formatted
 *
 * @return {string}                     Returns the formatted volume
 */
export const formatVolume = volume => {
  const unit = 'mL';

  return `${volume}${unit}`;
};

/**
 * Parse the media_gallery_entries array from GQL and return a standardised object with a single image.
 * Includes a fallback to a product placeholder image.
 *
 * @param {Array} entries                       Array of product images
 * @param {string} name                         Optional string to override the image label
 *
 * @returns {{file: string, label: string}}
 */
export const parseMediaGalleryEntries = (entries = [], name = '') => {
  let entry = { file: placeholder, label: name };
  if (!isEmpty(entries) && Array.isArray(entries)) {
    entry = {
      file: process.env.PRODUCT_MEDIA + entries[0].file,
      label: entries[0].label ? entries[0].label : name,
    };
  }
  if (typeof entries === 'string') {
    entry.file = entries;
  }
  return entry;
};

/**
 * Extend product image file path with a base URL pointing to the proper pub/media folder
 *
 * @param {string} file         File path of the image, starting from catalog/product/
 * @param {bool} leadingSlash   Include a slash before the relative path
 *
 * @returns {string}            Full public file URL
 */
export const parseProductImage = (file, leadingSlash = true) =>
  isUndefined(file) || isNull(file)
    ? placeholder
    : `${process.env.PRODUCT_MEDIA}${leadingSlash ? '/' : ''}${file}`;

/**
 * Extend category image file path with a base URL pointing to the proper pub/media folder
 *
 * @param {string} file        File path of the image, starting from catalog/category/
 * @param {bool} leadingSlash   Include a slash before the relative path
 *
 * @returns {string}           Full public file URL
 */
export const parseCategoryImage = file => (isUndefined(file) || isNull(file) ? placeholder : file);

/**
 * Parse a product object for bundle options and return a spreadable object that can be used
 * to add the product to the cart.
 *
 * @param {object} product      Product fetched from GQL
 *
 * @returns {object}
 */
export const parseBundleOptions = product => {
  if (product.type_id === 'bundle' && product.items) {
    return {
      product_option: {
        extension_attributes: {
          bundle_options: product.items.map(i => ({
            option_id: i.option_id,
            option_qty: i.options?.[0]?.qty || 0,
            option_selections: [i.options?.[0]?.id],
          })),
        },
      },
    };
  }
  return {};
};

/**
 * Parse an HTML string to strip out unwanted tags. Removes all attributes while ignoring the exceptions.
 *
 * @param {string} html             HTML to strip
 * @param {string} exceptions       HTML attributes to ignore while parsing
 *
 * @returns {string}                Parsed HTML
 */
/* eslint-disable no-param-reassign, func-names */
export const parseHTML = (html, exceptions = ['src', 'alt', 'href']) => {
  const walk = function walk(node, func) {
    func(node);
    node = node.firstChild;
    while (node) {
      walk(node, func);
      node = node.nextSibling;
    }
  };
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  walk(wrapper, element => {
    if (element.hasAttributes && element.hasAttributes() && element.removeAttribute) {
      const attrs = element.attributes;
      for (let i = attrs.length - 1; i >= 0; i -= 1) {
        if (exceptions.indexOf(attrs[i].name) === -1) {
          element.removeAttribute(attrs[i].name);
        }
      }
    }
  });
  return wrapper.innerHTML;
};
/* eslint-enable */

/**
 * Luhn algorithm in JavaScript: validate credit card number supplied as string of numbers
 *
 * @param {string} luhn     Credit card number
 *
 * @returns {boolean}       Return result of validity check
 */
export const luhnCheck = luhn => {
  let ca;
  let sum = 0;
  let mul = 0;
  let len = luhn.length;
  // eslint-disable-next-line no-plusplus
  while (len--) {
    // eslint-disable-next-line no-bitwise
    ca = parseInt(luhn.charAt(len), 10) << mul;
    sum += ca - (ca > 9) * 9; // sum += ca - (-(ca>9))|9
    // 1 <--> 0 toggle.
    // eslint-disable-next-line no-bitwise
    mul ^= 1; // mul = 1 - mul;
  }
  return sum % 10 === 0 && sum > 0;
};

/**
 * Convert base64 data to a blob object
 *
 * @param {string} base64
 * @param {string} mimetype
 * @param {number} slicesize    specified range of bytes of the blob
 *
 * @returns {object}            Blob
 */
export const base64ToBlob = (base64, mimetype, slicesize) => {
  const mimeType = mimetype || '';
  const sliceSize = slicesize || 512;
  if (!window.atob || !window.Uint8Array) {
    return null;
  }
  const bytechars = atob(base64);
  const bytearrays = [];
  for (let offset = 0; offset < bytechars.length; offset += sliceSize) {
    const slice = bytechars.slice(offset, offset + sliceSize);
    const bytenums = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      bytenums[i] = slice.charCodeAt(i);
    }
    const bytearray = new Uint8Array(bytenums);
    bytearrays[bytearrays.length] = bytearray;
  }
  return new Blob(bytearrays, { type: mimeType });
};

/**
 * Function to test for a specific browser. Add more cases to allow testing for other browsers.

 * @param {'ie' | 'safari-desktop' | 'wechat'} browserToTest

 * @returns {boolean}
 */
export const detectBrowser = browserToTest => {
  const browser = browserToTest.toLowerCase().split(' ').join('');
  const uA = navigator.userAgent;
  const { vendor } = navigator;
  switch (browser) {
    case 'ie':
      return uA.indexOf('rv') > -1 && uA.indexOf('like Gecko') > -1;
    case 'safari-desktop':
      return /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);
    case 'wechat':
      return /micromessenger/i.test(uA);
    default:
      return false;
  }
};

/**
 * Function to resize image . magento only support int
 *
 * @param {string} image
 * @param {array} size
 *
 * @returns {string}
 */
export const resizeImage = (image, size) => {
  let imageSrc = image;
  if (size && !isEmpty(size) && !imageSrc.startsWith('data:image')) {
    const width = parseInt(Math.ceil(size[0]) * window.devicePixelRatio, 10);
    const height = parseInt(Math.ceil(size[1]) * window.devicePixelRatio, 10);
    imageSrc = `${image}?w=${width}&h=${height}`;
  }
  return imageSrc;
};
/**
 * Function to check China phone number
 * @param phone
 * @returns {boolean}
 */
export const checkPhone = phone => !/^1[3456789]\d{9}$/.test(phone);

/**
 * Function to format js string date
 * @param dateString
 * @returns {string}
 */
export const formatDate = dateString => {
  const fsp = getLocalTime(dateString);
  return fsp.format('YYYY年M月D日');
};

export const calTimeDifference = dateString => {
  const now = dayjs.utc();
  const fsp = getLocalTime(dateString);
  let diffInMilliSeconds = now.diff(fsp) / 1000;
  let difference = '';
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  if (days > 0) {
    difference += days === 1 ? `${days} 天前 ` : `${days} 天前`;
    return difference;
  }

  difference += hours === 0 || hours === 1 ? `${hours} 小时前 ` : `${hours} 小时前`;

  return difference;
};
/**
 * Function to format FlashSale string date
 * @param dateString
 * @returns {string}
 */
export const formatFlashSaleDate = dateString => {
  const now = dayjs.utc();
  const fsp = getLocalTime(dateString);
  const difference = '';
  if (!isNull(fsp) && now.isBefore(fsp)) {
    return fsp.format('M月D日HH:mm');
  }

  return difference;
};

// eslint-disable-next-line consistent-return
export const convertGroupIdToName = groupId => {
  // eslint-disable-next-line no-param-reassign
  groupId = parseInt(groupId, 10);
  if (groupId === 1) {
    return 'Guest';
  }
  if (groupId === 2) {
    return 'Customer';
  }
  if (groupId === 3) {
    return 'DC';
  }
  if (groupId === 4) {
    return 'Franchisee';
  }
};

export const filterFlashSale = product => {
  const from = product.special_from_date;
  const to = product.special_to_date;
  const now = dayjs.utc();
  let fsp = getLocalTime(from);
  if (!isNull(fsp) && !fsp.isValid()) fsp = null;
  let tsp = getLocalTime(to);
  if (!isNull(tsp) && !tsp.isValid()) tsp = null;
  if (!isNull(fsp) && !isNull(tsp) && !now.isAfter(tsp)) {
    return true;
  }
  return false;
};

export const isInSpecialPrice = product => {
  const from = product.special_from_date;
  const to = product.special_to_date;
  const now = dayjs.utc();

  let fsp = getLocalTime(from);
  if (!isNull(fsp) && !fsp.isValid()) fsp = null;
  let tsp = getLocalTime(to);
  if (!isNull(tsp) && !tsp.isValid()) tsp = null;

  if (!isNull(fsp) && !isNull(tsp) && now.isAfter(fsp) && now.isBefore(tsp)) {
    return true;
  }
  return false;
};
export const convertCartItemId = items => {
  const itemIds = [];
  items.forEach(i => {
    itemIds.push(i.item_id);
  });
  return itemIds;
};

/**
 * check if this product belong to this categories
 */
export const checkBelongCategories = (urlId, product) => {
  const result = product.categories.filter(item => item.id === urlId);

  return !!result.length;
};

/**
 * 对象序列化
 * @param {Object} data 要序列化的对象
 * @returns {String}
 */
export const serialize = data => {
  let s = '';
  Object.keys(data).forEach(key => {
    s += `&${key}=${encodeURIComponent(data[key]).replace(/\+/gm, '%2B')}`;
  });
  s = s.length > 0 ? s.substring(1) : s;

  return s;
};

/**
 * @module getUrlParams
 * @returns {*}
 */
export const getUrlParams = url => {
  const URL = url || window.location.href;
  const params = URL.match(/([?&#])(.+?=[^&]+)/gim);
  return params
    ? params.reduce((a, b) => {
        const value = b.slice(1).split('=');
        // eslint-disable-next-line no-param-reassign,prefer-destructuring
        a[value[0]] = value[1];
        return a;
      }, {})
    : {};
};

/**
 * @module arrayNoRepeat 数组对象去重
 * @param {array} arr Source array
 * @param {string} key Uniquely identified field
 * @param {string} count Count field
 * @return {array}
 * */
export const arrayNoRepeat = (arr = [], key = 'sku', count = 'qty') => {
  if (isNull(arr) || isString(arr)) return [];

  const countMap = {};
  const repeatMap = {};

  // 1: Count the number of duplicates
  // eslint-disable-next-line no-return-assign,no-unused-expressions
  arr.length &&
    arr.forEach(v => {
      countMap[v[key]] = countMap[v[key]] === undefined ? v[count] : countMap[v[key]] + v[count];
    });

  // 2: The array is de duplicated and the number of duplicates is added
  return arr
    .reduce((cur, next) => {
      if (!repeatMap[next[key]]) {
        repeatMap[next[key]] = true;
        cur.push(next);
      }

      return cur;
    }, [])
    .map(item => ({ ...item, [count]: countMap[item[key]] }));
};

export const wrapText = (canvas, context, text, x, y, initMaxWidth, initLineHeight) => {
  let maxWidth = initMaxWidth;
  let lineHeight = initLineHeight;
  const offsetX = x;
  let offsetY = y;
  if (typeof text !== 'string' || typeof x !== 'number' || typeof y !== 'number') {
    return null;
  }

  if (typeof maxWidth === 'undefined') {
    maxWidth = (canvas && canvas.width) || 300;
  }
  if (typeof lineHeight === 'undefined') {
    lineHeight =
      (canvas && parseInt(window.getComputedStyle(canvas).lineHeight, 10)) ||
      parseInt(window.getComputedStyle(document.body).lineHeight, 10);
  }

  // 字符分隔为数组
  const arrText = text.split('');
  let line = '';

  for (let n = 0; n < arrText.length; n += 1) {
    const testLine = line + arrText[n];
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, offsetX, offsetY);
      line = arrText[n];
      offsetY += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, offsetX, offsetY);
  return { x: offsetX, y: offsetY };
};

// 划线转换驼峰
export const toHump = name =>
  /_|-(\w)/g.test(name) ? name.replace(/_|-(\w)/g, (all, letter) => letter.toUpperCase()) : name;

export const selectedOthersBuyItems = (a = [], b = '') =>
  a.filter(i => b.some(j => j === i.item_id));

// 生成一个不重复的id
export const genNonDuplicateID = randomLength =>
  Number(Math.random().toString().substr(3, randomLength) + Date.now()).toString(36);

export const calcSubTotal = (selectedItems, cartItems) => {
  let total = 0;
  if (selectedItems?.length > 0 && cartItems) {
    const selectedCartItems = cartItems.filter(i => selectedItems.indexOf(i.item_id) !== -1);
    total = selectedCartItems.reduce(
      (acc, curr) =>
        acc + curr.qty * get(curr, 'extension_attributes.calculated_price', curr.price),
      total,
    );
  }
  return total;
};

export const calcPvTotal = (selectedItems, cartItems) => {
  let total = 0;
  if (selectedItems?.length > 0 && cartItems) {
    const selectedCartItems = cartItems.filter(i => selectedItems.indexOf(i.item_id) !== -1);
    total = selectedCartItems.reduce(
      (acc, curr) => acc + get(curr, 'extension_attributes.point_value', 0),
      total,
    );
  }
  return total;
};

export function formatDistance(value, pointLength, units = ['m', 'km']) {
  if (value === null || value === undefined || value === '') {
    return '';
  }
  let size = parseFloat(value);
  let unit = units.shift();
  // eslint-disable-next-line no-cond-assign
  while (size > 1000 && (unit = units.shift())) {
    size /= 1000;
  }
  unit = unit || 'km';
  return (unit === 'm' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit;
}

export function getReceiver(user) {
  if (user) {
    return `${user?.lastname}${user?.firstname} `;
  }
  return null;
}

export const formatHashParams = (hash, callback) => {
  const result = qs.parse(hash || window.location.hash)['#'];
  let json = {};

  try {
    json = JSON.parse(result);
  } catch (error) {
    json = {};
  }

  if (callback) callback(json);

  return json;
};

export const parseMediaSourcesImage = (name, type) => {
  const status = {
    source: '/tmo_vendor/source/',
    user: '/tmo_vendor/user/',
    product: '/catalog/product',
  };

  return process.env.BASE_MEDIA + (status[type] ?? '/') + name;
};

/**
 * @description timeSleep
 * @param {number} timeout
 * */
export const timeSleep = (timeout = 500) =>
  new Promise(resolve => {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      resolve(true);
    }, timeout);
  });

export function formatBankCard(no, str) {
  if (!no) return '';

  const noStr = no.toString();
  const yushu = noStr.length % 4;
  if (yushu > 0) {
    return `${noStr.match(/\d{4}/g).join(str)}${str}${noStr.substr(-yushu, yushu)}`;
  }

  return noStr.match(/\d{4}/g).join(str);
}

/**
 * @description createWebDownload
 * @param {string} url download url
 * @param {string} filename filename
 * @param {function} complete
 * */
export const createWebDownload = ({ url, filename, complete }, target = '_self') => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.target = target;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);

  return complete && complete();
};

/**
 * @description webDownloadArrayStream
 * @param {Blob} stream api response (is file data stream)
 * @param {'pdf' | 'xls' | 'word' | 'zip' | 'csv'} type download type
 * @param {string} filename download filename
 * @param [{function} complete]
 * @param [{function} failed]
 * */
export const webDownloadArrayStream = ({ stream, type, filename, complete, failed }) => {
  const defineType = {
    pdf: 'application/pdf;chartset=UTF-8',
    xls: 'application/xls',
    word: 'application/msword',
    zip: 'application/zip',
    csv: 'data:text/csv;charset=utf-8',
  };

  try {
    // 创建blob对象，解析流数据
    const blob = new Blob([stream], {
      type: defineType[type],
    });

    const URL = window.URL || window.webkitURL;
    const url = URL.createObjectURL(blob);

    createWebDownload({ url, filename, complete });
  } catch (err) {
    failed?.(err);
  }
};

/**
 * @description includes
 * @param {array} a
 * @param {array | string} b
 * @returns {boolean}
 * */
export const includes = (a, b) => {
  if (isArray(b)) {
    return a.some(i => b.includes(i));
  }

  return indexOf(a, b) >= 0;
};

/**
 * @description toggle
 * @param {string[] | number[] | object[] | any[]} a
 * @param {string | object | string[] | number[] | object[] | any[]} b
 * @param {string} key
 * @returns {array}
 * */
export const toggle = (a, b, key = undefined) => {
  function filterObject(i = [], j = []) {
    const mergeParams = [...i, ...j];
    const iKeys = i.map(item => item[key]);
    const jKeys = j.map(item => item[key]);
    const includesKeys = includes(iKeys, jKeys) ? without(iKeys, ...jKeys) : union(iKeys, jKeys);

    return mergeParams.filter(item => includesKeys.includes(item.id));
  }

  if (isPlainObject(b)) {
    return key ? filterObject(a, [b]) : a;
  }

  if (isArray(b)) {
    if (key) {
      return filterObject(a, b);
    }

    return includes(a, b) ? without(a, ...b) : union(a, b);
  }

  return includes(a, b) ? without(a, b) : union(a, [b]);
};

/**
 * @description Determine if it is within a certain time period 判断是否在某个时间段
 * @param day Date
 * @param firstDay Date
 * @param step number
 * */
export const isSameOrBeforeDay = (day, firstDay, step = 0) => {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const currentDate = dayjs();
  const firstMonthDay = firstDay ? dayjs(firstDay) : currentDate.startOf('month');
  const targetMonthDay = dayjs(firstMonthDay).add(step, 'day');

  return dayjs(day).isSameOrBefore(targetMonthDay) && dayjs(day).isSameOrAfter(firstMonthDay);
};

export const insertCssAndJs = (cssConfigs, scriptConfigs) => {
  cssConfigs.forEach(config => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = config.src;
    document.head.appendChild(link);
  });

  scriptConfigs.forEach(config => {
    const script = document.createElement('script');
    script.src = config.src;
    script.setAttribute('app', config.appId);
    script.defer = true;
    window.addEventListener('load', () => {
      document.body.appendChild(script);
    });
  });
};

export const parseIngredientHtml = (value, isHtml = true) => {
  if (!value) {
    return isHtml ? { __html: '' } : '';
  }

  if (!isHtml) {
    return value;
  }

  const ingredient =
    value.includes('https://') || value.includes('http://')
      ? value
      : value.split('src="').join(`src="${process.env.BASE_URL_API}`);

  return { __html: ingredient };
};

/**
 * @description validatePhoneNumber
 * @param {string} phoneNumber
 * @return {boolean}
 * */
export const validatePhoneNumber = phoneNumber => {
  if (!phoneNumber) return false;

  return /^1[3456789]\d{9}$/.test(phoneNumber);
};

/**
 * @description hasDuplicatesInArray 检查数组是否有重复项
 * @param {array} array
 * @return {boolean}
 */
export const hasDuplicatesInArray = (array, property = 'id') => {
  if (!Array.isArray(array)) return false;

  if (isObject(array[0])) {
    const ids = array.map(item => item[property]);
    return ids.length !== new Set(ids).size; // Set 去重后长度和原数组长度不一致，说明有重复项
  }

  return array.length !== new Set(array).size; // Set 去重后长度和原数组长度不一致，说明有重复项
};

/**
 * @description filterNoSubBundle 筛选没有子产品的套装
 * @param {array} array
 * @return {array}
 */
export const filterNoSubBundle = array => {
  if (!isArray(array)) return [];
  return array.filter(item => item.product_type === 'bundle' && !item.product_option);
};

export const getShopNowTrackingAppIdFromUrl = url => {
  const urlKey =
    Object.keys(URL_TO_SHOPNOW_TRACKING_APPID_MAP).find(key => url.includes(key)) || null;
  return URL_TO_SHOPNOW_TRACKING_APPID_MAP[urlKey];
};

export const isMobileBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
};
