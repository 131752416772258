/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * SharingShoppingCard
 *
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import placeholder from 'images/placeholder.png';
import Picture from 'components/Picture';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 0 16px rgba(0,0,0,.08)',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
      width: 343,
      flexShrink: 0,
    },
  },
  wave: {
    width: '100%',
    background: `linear-gradient(0deg, ${theme.palette.background.peach} 1px, transparent 0%)`,
  },
  singleImage: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 220,
    position: 'absolute',
    zIndex: 5,
    margin: '0 auto',
  },
}));

const SharingShoppingCard = forwardRef(({ qrCode, subtitle, fullName }, ref) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} ref={ref}>
      <Box
        position="relative"
        width={1}
        pt={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!qrCode ? (
          <Picture
            src={`${process.env.BASE_URL_APP}${placeholder}`}
            title=""
            alt="placeholder"
            op="fit"
            id="qrcode"
            width={200}
            height={200}
            params={{
              width: 200,
              height: 200,
            }}
          />
        ) : (
          <img
            id="qrcode"
            crossOrigin="anonymous"
            src={qrCode}
            width={200}
            height={200}
            alt="placeholder"
          />
        )}
        <img
          alt="wave"
          className={classes.wave}
          src="data:image/svg+xml,%3Csvg width='375' height='43' viewBox='0 0 375 43' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fillRule='nonzero' fill='none'%3E%3Cpath d='M375 3.515S332 0 300.67 0C143.262 0 0 35.132 0 35.132V43c7.585-1.652 130.402-25.989 253.042-25.989C318.376 16.647 375 23.2 375 23.2V3.515' fill='%23CD0039'/%3E%3Cpath d='M375 23.203v19.8H0c0-5.975 0-1.144 0 0 7.585-1.651 130.402-25.988 253.042-25.988C318.376 16.65 375 23.203 375 23.203' fill='%23FFF3E0'/%3E%3C/g%3E%3C/svg%3E"
        />
      </Box>
      <Box p={2} pt={1} bgcolor="background.peach" textAlign="left">
        <Typography variant="body1" component="p">
          <b>
            <FormattedMessage {...messages.somebodySharedAList} values={{ name: fullName }} />
          </b>
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
});

SharingShoppingCard.propTypes = {
  fullName: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  qrCode: PropTypes.string,
};

export default SharingShoppingCard;
