/* eslint-disable global-require */
/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

require('@formatjs/intl-getcanonicallocales/polyfill');
require('@formatjs/intl-locale/polyfill');

// require('@formatjs/intl-displaynames/polyfill');
// require('@formatjs/intl-displaynames/locale-data/en');
// require('@formatjs/intl-displaynames/locale-data/zh'); // locale-data for en

const store = require('store');

const getStoreLanguage = () => {
  const storeCode = store.get('store_code');
  if (storeCode) {
    return storeCode.split('_')[1];
  }
  return undefined;
};

const getLanguage = () => {
  const lang = navigator.language || navigator.userLanguage;
  const storeLanguage = getStoreLanguage();
  let language = storeLanguage || lang.substr(0, 2) || 'zh';
  language = ['zh', 'en'].includes(language) ? language : 'zh';
  if (storeLanguage !== language) {
    store.set('store_code', `chn_${language}`, new Date().getTime() + 86400000);
  }
  return language;
};

if (!Intl.NumberFormat) {
  require('@formatjs/intl-numberformat/polyfill');
  require('@formatjs/intl-numberformat/locale-data/en'); // locale-data for en
  require('@formatjs/intl-numberformat/locale-data/zh');
}

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en'); // locale-data for en
  require('@formatjs/intl-pluralrules/locale-data/zh');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for en
  require('@formatjs/intl-relativetimeformat/locale-data/zh'); // Add locale data for zh
}

const enTranslationMessages = require('./translations/en.json');
const zhTranslationMessages = require('./translations/zh.json');

const DEFAULT_LOCALE = getLanguage();

const appLocales = ['en', 'zh'];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  zh: formatTranslationMessages('zh', zhTranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
