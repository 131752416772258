import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import Picture from 'components/Picture';
import { Price } from 'components/Price';
import cartMessages from 'containers/CartProvider/messages';
import FormatMessage from 'components/FormatMessage';
import useSpecialProduct from 'components/Product/hooks/useSpecialProduct';
import { CheckboxCheckedIcon, CheckboxIcon } from 'components/Icons';
import StepperButtons from 'components/Buttons/StepperButtons';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2.75),
    maxWidth: 252,
    borderRadius: 8,
    border: `2px solid ${theme.palette.primary.peach}`,
    cursor: 'pointer',
    transition: 'all 0.3s',
  },
  active: {
    borderColor: theme.palette.primary.darkPeach,
  },
  image: {
    width: 208,
    height: 208,
  },
  info: {
    marginTop: theme.spacing(1.25),
    '& .name': {
      minHeight: 56,
      fontWeight: 700,
    },
  },
  price: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& .price': {
      color: theme.palette.primary.main,
    },
  },
  checkBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    margin: theme.spacing(1.75),
  },
  checkDisabled: {
    '& svg #Rectangle': {
      fill: theme.palette.primary.disabled,
    },
  },
  stepper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

function SpecialProduct(props) {
  const { onSelected, checked, product, onChange, ...other } = props;
  const classes = useStyles(props);
  const { productImage, price, stock, originalPrice } = useSpecialProduct(product);

  const [value, setValue] = useState(() => ({ ...product, qty: 1 }));

  const handleChange = val => {
    setValue(state => ({ ...state, qty: val }));
    if (checked) {
      onChange({ ...value, qty: val });
    }
  };

  const handleSelected = () => {
    onSelected(value);
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.active]: checked,
      })}
      {...other}
    >
      <div className={classes.image}>
        <Picture
          src={productImage}
          alt={product.name}
          title=""
          op="fit"
          params={{
            width: 208,
            height: 208,
          }}
        />
      </div>
      <div className={classes.info}>
        <Typography className="name">{product.name}</Typography>
        <div className={classes.price}>
          <Price
            originalPrice={originalPrice}
            price={price}
            color="primary"
            size="1.5rem"
            secondarySize="0.625rem"
          />
          <FormatMessage
            variant="h6"
            color="textSecondary"
            className="stock"
            message={{ ...cartMessages.stock, values: { total: stock } }}
          />
        </div>
      </div>
      <Checkbox
        checked={checked}
        onChange={handleSelected}
        classes={{
          root: classes.checkBox,
          disabled: classes.checkDisabled,
        }}
        icon={<CheckboxIcon />}
        checkedIcon={<CheckboxCheckedIcon />}
      />
      <div className={classes.stepper}>
        <StepperButtons onChange={handleChange} minusDisabled={value.qty <= 1} />
      </div>
    </Box>
  );
}

SpecialProduct.propTypes = {
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  product: PropTypes.object,
  checked: PropTypes.bool,
};

export default SpecialProduct;
