import { useMutation, useQuery } from 'react-query';
import get from 'lodash/get';
import request from 'utils/request';
import queryGetStoreConfigs from 'containers/App/gql/queryGetStoreConfigs';
import queryGetStoreCurrencies from 'containers/App/gql/queryGetStoreCurrencies';
import queryGetStoreCountries from 'containers/App/gql/queryGetStoreCountries';
import requestGql, { querySetting } from 'utils/requestGql';

export const getStoreViews = () => request('GET', '/store/storeViews');

export const getStoreConfigsQuery = () => requestGql(queryGetStoreConfigs);
export const getStoreCurrenciesQuery = () => requestGql(queryGetStoreCurrencies);
export const getStoreCountriesQuery = () => requestGql(queryGetStoreCountries);
export const getWechatJSSDKSign = (url, vendorCode) =>
  request('GET', `/wechat_share/jsapi?url=${url}&vendorCode=${vendorCode}`);

export const useGetStoreConfig = () =>
  useQuery(
    ['general.getStoreConfig'],
    () => getStoreConfigsQuery().then(response => get(response, 'storeConfig', [])),
    querySetting,
  );

export const useGetStoreCurrencies = () =>
  useQuery(['general.getStoreCurrencies'], () => getStoreCurrenciesQuery(), querySetting);

export const useGetStoreCountries = () =>
  useQuery(
    ['general.getStoreCountries'],
    () =>
      getStoreCountriesQuery().then(response =>
        get(response, 'countries.[0].available_regions', []),
      ),
    { ...querySetting, staleTime: 15 * 60 * 1000, cacheTime: 30 * 60 * 1000 },
  );

export const useGetStoreViewsQuery = storeViews =>
  useQuery(
    ['general.getStoreViews', storeViews],
    () => getStoreViews().then(response => response),
    querySetting,
  );

export const useGetStoreViewsMutation = () => useMutation(() => getStoreViews());
export const useGetStoreConfigMutation = () => useMutation(() => getStoreConfigsQuery());
export const useGetWechatJSSDKSign = () =>
  useMutation(({ url, vendorCode }) => getWechatJSSDKSign(url, vendorCode));
