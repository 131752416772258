import { useState } from 'react';
import { useIntl } from 'react-intl';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRemoveFromCart, useUpdateCartItem } from 'containers/CartProvider/queries';
import useDeepCompareEffect from 'utils/Hooks/updateEffect/useDeepCompareEffect';
import { useGetAllWishlist, useToggleFav } from 'containers/WishlistPage/queries';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import {
  changeRemoveItem,
  selectRemoveItem,
  selectDeleteDialogShow,
  toggleDeleteDialogShow,
  selectCartItemsSelected,
  toggleCartItem as storeToggleCartItem,
} from 'containers/CartProvider/slices';
import { getAuthorization } from 'utils/Auth';
import routes from 'utils/routes';
import usePurchasePermissions from 'utils/Hooks/usePurchasePermissions';
import cartMessages from 'containers/CartProvider/messages';

const useOperateCartItem = (cartItem = {}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notify = useNotificationSnackbar();
  const intl = useIntl();
  const selectedItems = useSelector(selectCartItemsSelected);
  const deleteDialogShow = useSelector(selectDeleteDialogShow, shallowEqual);
  const removeItem = useSelector(selectRemoveItem, shallowEqual);
  const { specialCartList } = useShoppingCart();
  const { mutateAsync: remove, isLoading: removeLoading } = useRemoveFromCart();
  const { mutateAsync: update, isLoading: updateLoading } = useUpdateCartItem();
  const { mutateAsync: toggleFavorite, isLoading: favLoading } = useToggleFav();
  const { data: wishlistItems, refetch: refetchWishList } = useGetAllWishlist();
  const { cartCheckout } = usePurchasePermissions();

  const [quantity, setQuantity] = useState(cartItem.qty);

  useDeepCompareEffect(() => {
    setQuantity(cartItem.qty);
  }, [cartItem]);

  const toggleConfirm = flag => {
    dispatch(toggleDeleteDialogShow(flag));
  };

  const removeCartItem = async ({ complete, error } = {}) => {
    try {
      const result = await remove({
        itemId: removeItem.item_id,
      });

      toggleConfirm(false);
      complete?.(result);
      notify(intl.formatMessage(cartMessages.successfullyDeleted), 'success');
    } catch (err) {
      error?.(err);
      notify(err.message, 'default');
    }
  };

  const toggleItem = payload => {
    dispatch(storeToggleCartItem(payload));
  };

  const updateCart = async qty => {
    try {
      const isChecked = selectedItems.includes(cartItem.item_id);
      const { divider, ...other } = cartItem;
      await update({
        data: { cartItem: { ...other, qty } },
      });

      if (!isChecked && cartCheckout(cartItem, 'extension_attributes')) {
        toggleItem({ itemId: cartItem.item_id });
      }
    } catch (err) {
      notify(err.message, 'default');
    }
  };

  const quantityAdjustment = async qty => {
    if (!isNumber(qty) || isNaN(qty) || qty === cartItem.qty) {
      return;
    }

    setQuantity(qty);
    await updateCart(qty);
  };

  const joinFavorites = async option => {
    const { type } = getAuthorization();
    if (type === 'guest') {
      toggleConfirm(false);
      history.push(routes.logIn);

      return;
    }

    try {
      const id = option?.extension_attributes?.product_id;

      await toggleFavorite({
        toggle: 'no',
        id,
        productId: id,
      });

      await refetchWishList();

      notify(intl.formatMessage(cartMessages.joinFavSuccess), 'success');
    } catch (err) {
      notify(err.message, 'default');
    }

    await removeCartItem();
  };

  const changeRemove = options => {
    dispatch(changeRemoveItem(options));
    toggleConfirm(true);
  };

  const toggleCartItem = (item = cartItem) => {
    const { item_id: itemId, extension_attributes: { is_standard_sets: isStandardSets } = {} } =
      item;

    if (isStandardSets && selectedItems.includes(itemId)) {
      const specialIds = specialCartList.map(i => i.item_id);
      const findItems = selectedItems.filter(i => specialIds.some(j => j === i));
      toggleItem({ itemId: [itemId, ...findItems] });
      return;
    }

    toggleItem({ itemId });
  };

  return {
    quantity,
    deleteDialogShow,
    wishlistItems,
    selectedItems,
    loading: removeLoading || updateLoading || favLoading,
    toggleCartItem,
    updateCart,
    removeItem,
    toggleConfirm,
    changeRemove,
    joinFavorites,
    removeCartItem,
    quantityAdjustment,
  };
};

export default useOperateCartItem;
