/* eslint-disable */
function Icon12Shield(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M10.375 2.876c-1.094 0-2.84-.88-4-1.75a.623.623 0 0 0-.75 0c-1.16.87-2.906 1.75-4 1.75A.625.625 0 0 0 1 3.5c0 3.49 1.688 6.136 4.754 7.45a.625.625 0 0 0 .492 0C9.312 9.635 11 6.99 11 3.5a.625.625 0 0 0-.625-.624zM6.207 10V5.954H2.415A8.05 8.05 0 0 1 2 3.834C3.358 3.613 4.965 2.834 6.207 2v3.954H10C9.382 7.76 8.105 9.136 6.207 10z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12Shield;
