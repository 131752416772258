import * as Sentry from '@sentry/browser/esm';
import { sprintf } from 'utils/helpers';

export const sentrySetUser = (customer = {}) => {
  try {
    Sentry.configureScope(scope => {
      const {
        group_id: groupId,
        email,
        firstname,
        lastname,
        custom_attributes: customAttributes,
      } = customer;

      const openid = localStorage.getItem('openid');
      scope.clear();
      scope.setTag('openid', openid);

      if (customer.id) {
        const username = `${lastname}${firstname}`;
        const phone = customAttributes?.find(a => a.attribute_code === 'mobile')?.value;
        const accountId = customAttributes?.find(a => a.attribute_code === 'account_id')?.value;

        scope.setTag('group_id', groupId);

        scope.setUser({
          id: accountId,
          email,
          phone,
          username,
          ...(openid ? { openid } : null),
        });
      } else {
        scope.setUser({
          ...(openid ? { openid } : null),
        });
      }
    });
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const sentryCaptureRequest = err => {
  try {
    Sentry.withScope(scope => {
      scope.setExtra('request params', err.response?.config?.params);
      scope.setExtra('request data', err.response?.config?.data);
      scope.setExtra('info', err.response?.data);
      const msg = sprintf(err.response?.data?.message, err.response?.data?.parameters);
      console.log(msg, err.response);
      Sentry.captureException(err);
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default Sentry;
