/**
 *
 * Asynchronously loads the component for OrderDetail
 *
 */

import loadable from 'utils/loadable';

export const OrderSuccessPage = loadable(() => import('./components/OrderSuccessPage'));
export const OfflineOrderSuccessPage = loadable(() =>
  import('./components/OfflineOrderSuccessPage'),
);

export default loadable(() => import('./index'));
