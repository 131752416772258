/* eslint-disable */
function ContactIcon(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="2.Icons/16/16-clock-Copy" fill={fill} transform="translate(-2 -2)">
				<path id="Path" d="M13.314 9.8l-2.353-1.044a1.156 1.156 0 0 0-1.368.332l-.71.888a9.32 9.32 0 0 1-2.86-2.859l.888-.709c.41-.328.546-.89.333-1.37L6.2 2.687a1.154 1.154 0 0 0-1.345-.649l-1.989.517a1.159 1.159 0 0 0-.855 1.28 11.982 11.982 0 0 0 10.156 10.155 1.158 1.158 0 0 0 1.279-.854l.516-1.99a1.15 1.15 0 0 0-.648-1.345z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default ContactIcon;