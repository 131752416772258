import isEqual from 'lodash/isEqual';
import { useRef, useEffect } from 'react';

const depsEqual = (aDeps, bDeps) => isEqual(aDeps, bDeps);

const useDeepCompareEffect = (callback, deps) => {
  const ref = useRef();
  const signalRef = useRef(0);

  if (!depsEqual(deps, ref.current)) {
    ref.current = deps;
    signalRef.current += 1;
  }

  useEffect(() => {
    const uninstall = callback();
    return () => uninstall?.();
  }, [signalRef.current]);
};

export default useDeepCompareEffect;
