/* eslint-disable */
function Icon16Icons(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M11.5 9.2L15 15H8l3.5-5.8zM5 9v2h2v2H5v2H3v-2H1v-2h2V9h2zM4 1a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm11 0v6H9V1h6z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Icons;
