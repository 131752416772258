/* eslint-disable */
function Icon16Eye(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M8 3c3.16 0 5.618 2.583 6.67 4a1.56 1.56 0 0 1 0 1.917C13.619 10.417 11.16 13 8 13s-5.618-2.583-6.67-4.083c-.44-.5-.44-1.25 0-1.834C2.381 5.583 4.84 3 8 3zm0 2C6.3 5 5 6.3 5 8s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Eye;
