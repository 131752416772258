/**
 *
 * SelectPicker
 *
 *
 */

import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from 'components/Dropdown';
import { AngleDownIcon16 } from 'components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    '&$select': {
      paddingRight: theme.spacing(3),
    },
  },
  select: {
    paddingRight: 0,
  },
  icon: {
    right: 0,
  },
  listItemText: {
    minWidth: 311,
  },
}));

const ArrowIcon = props => (
  <SvgIcon component={AngleDownIcon16} viewBox="0 0 16 16" color="primary" {...props} />
);

const SelectPicker = forwardRef(({ options, value, onChange, placeholder, ...restProps }, ref) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = e => {
    setSelectedValue(e.target.value);

    onChange?.(e.target.value);
  };

  return (
    <Dropdown
      value={selectedValue}
      onChange={handleChange}
      disableUnderline
      renderValue={selected => (
        <Typography variant="body2">
          <b>{selected || placeholder}</b>
        </Typography>
      )}
      options={options}
      IconComponent={ArrowIcon}
      inputProps={{ placeholder }}
      {...restProps}
      ref={ref}
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
        listItemText: classes.listItemText,
      }}
    />
  );
});

SelectPicker.defaultProps = {};

SelectPicker.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.any,
};

export default SelectPicker;
