import { useQuery, useMutation } from 'react-query';
import get from 'lodash/get';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import { isSignedIn } from 'utils/Auth';

export const getWishlist = () => request('GET', '/wishlist/mine');
export const addWishlist = productId => request('POST', `/wishlist/add/${productId}`);
export const removeWishlist = ids => request('POST', '/wishlist/remove/items', ids);

export const removeSingleWishlist = wishlistItemId =>
  request('GET', `/wishlist/remove/items/${wishlistItemId}`);

export const addWishlistToCart = payload => request('POST', '/wishlist/to-cart', payload);
export const checkWishlist = productId => request('GET', `/wishlist/check/${productId}`);

export const useToggleFav = () =>
  useMutation(({ id, toggle }) => (toggle ? addWishlist(id) : removeWishlist({ itemIds: [id] })), {
    enabled: isSignedIn(),
  });

export const useGetAllWishlist = () =>
  useQuery(
    ['wishlistPage.allWishlist'],
    () => getWishlist().then(response => get(response, '[1]', [])),
    {
      ...querySetting,
      enabled: isSignedIn(),
    },
  );

export const useWishlistCheck = id =>
  useQuery(['wishlistPage.checkWishlist', id], () => checkWishlist(id), {
    ...querySetting,
    enabled: isSignedIn() && id !== undefined,
  });

export const useWishlistAdd = () => useMutation(id => addWishlist(id));
export const useWishlistRemove = () => useMutation(ids => removeWishlist(ids));
export const useSingleWishlistRemove = () => useMutation(id => removeSingleWishlist(id));
export const useWishlistAddToCart = () => useMutation(payload => addWishlistToCart(payload));
