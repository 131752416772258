/* eslint-disable */
function Icon24Benefits(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-benefits" fill={fill}>
            <path id="Shape" d="M17.076 3.282A.737.737 0 0 0 16.5 3h-9a.737.737 0 0 0-.576.282l-3.75 4.695a.81.81 0 0 0-.03.964l8.25 11.739c.142.201.367.32.606.32a.742.742 0 0 0 .605-.32l8.25-11.74a.809.809 0 0 0-.03-.963l-3.75-4.695zM17.5 9h-11V7h11v2z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Benefits;
