import { useMemo } from 'react';
import get from 'lodash/get';
import { parseMediaGalleryEntries } from 'utils/helpers';

const useSpecialProduct = product =>
  useMemo(
    () => ({
      stock: get(product, 'extension_attributes.only_xleft_in_stock', 0),
      price: get(product, 'extension_attributes.calculated_price', 0),
      originalPrice: product.price,
      productImage: parseMediaGalleryEntries(product.media_gallery_entries, product.name).file,
      pv:
        get(product, 'custom_attributes', []).find(i => i.attribute_code === 'point_value')
          ?.value ?? 0,
    }),
    [product],
  );

export default useSpecialProduct;
