/* eslint-disable */
function Icon12AngleLeft(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M7.05 9.62L3.929 6.345a.5.5 0 0 1 0-.69l3.12-3.275a.5.5 0 0 1 .725 0l.297.312a.5.5 0 0 1 0 .69L5.905 5.655a.5.5 0 0 0 0 .69l2.166 2.273a.5.5 0 0 1 0 .69l-.297.312a.5.5 0 0 1-.724 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12AngleLeft;
