import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { TimesIcon } from 'components/Icons';
import { actions } from './actions';
import messages from './messages';

function CloseNotification({ notificationKey, closeSnackbar }) {
  const handleClick = () => closeSnackbar(notificationKey);

  return (
    <IconButton
      onClick={handleClick}
      aria-label={<FormattedMessage {...messages.dismiss} />}
      component="span"
      style={{ marginRight: -16 }}
    >
      <SvgIcon component={TimesIcon} viewBox="0 0 16 16" />
    </IconButton>
  );
}

CloseNotification.propTypes = {
  notificationKey: PropTypes.number,
  closeSnackbar: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    closeSnackbar: key => dispatch(actions.closeSnackbar(key)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default withConnect(CloseNotification);
