/*
 *
 * Order Detail Page constants
 *
 */

export const ORDER_STATUS_ACTION_PAY = 'actionPay';
export const ORDER_STATUS_ACTION_CANCEL = 'actionCancel';
export const ORDER_STATUS_ACTION_REVIEW = 'actionReview';
export const ORDER_STATUS_ACTION_LOGISTICS = 'actionLogistics';
export const ORDER_STATUS_ACTION_REFUND = 'actionRefund';
export const ORDER_STATUS_ACTION_REORDER = 'actionReorder';
export const ORDER_STATUS_ACTION_INVOICE = 'actionInvoice';
export const ORDER_STATUS_ACTION_INVOICE_APPLY = 'actionInvoiceApply';

export const WE_CHAT_PAY = 'wechatpay';
export const ALIPAY = 'alipay';

export const ORDER_STATUS = {
  PENDING_PAYMENT: 'pending_payment',
  PENDING_PACKING: 'pending_packing',
  PENDING_SHIPMENT: 'pending_shipment',
  PENDING_AUTHORIZATION: 'pending_authorization',
  PARTIAL_SHIPMENT: 'partial_shipment',
  HOLDED: 'holded',
  CANCELED: 'canceled',
  CLOSED: 'closed',
  IN_TRANSIT: 'in_transit',
  REVIEWED: 'reviewed',
  COMPLETE: 'complete',
  FRAUD: 'fraud',
};

export const Coupon1 = {
  discount_amount: 12,
  simple_action: 'by_percent',
  extension_attributes: {
    tag: '',
    link: '',
    total_coupon: 50,
    available_coupon: 50,
  },
  from_date: '2021-6-1',
  name: '优惠券',
  to_date: '2021-6-30',
};

export const Coupon2 = {
  discount_amount: 18,
  simple_action: 'by_percent',
  extension_attributes: {
    tag: '',
    link: '',
    total_coupon: 50,
    available_coupon: 50,
  },
  from_date: '2021-6-1',
  name: '优惠券',
  to_date: '2021-6-30',
};
