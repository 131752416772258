/**
 * Various endpoints used for managing a user
 */

import request from 'utils/request';
/**
 * @api {GET} /customers/me Get customer
 * @apiGroup User
 */
export const getProfile = () => request('GET', '/customers/me');

/**
 * @api {PUT} /customers/me Update customer
 * @apiGroup User
 */
export const updateProfile = payload => request('PUT', '/customers/me', payload);

/**
 * @api {POST} /customer/changepassword Change password
 * @apiGroup User
 */
export const changePassword = payload => request('POST', '/customer/changepassword', payload);

/**
 * @api {GET} /customer/address Get address list
 * @apiGroup User
 */
export const getAddressList = () => request('GET', '/customer/address');

/**
 * @api {POST} /customer/address Add address
 * @apiGroup User
 */
export const addAddress = payload => request('POST', '/customer/address', payload);

/**
 * @api {PUT} /customer/address Edit address
 * @apiGroup User
 */
export const editAddress = payload => request('PUT', '/customer/address', payload);

/**
 * @api {DELETE} /customer/address/:id Remove address
 * @apiGroup User
 */
export const removeAddress = addressId => request('DELETE', `/customer/address/${addressId}`);

/**
 * @api {PUT} /customers/sm Update SM Information
 * @apiGroup User
 */
export const updateSM = payload => request('PUT', '/customers/sm', payload);

/**
 * @api {PUT} /customers/update-group/dc User upgrade to DC
 * @apiGroup User
 * */
export const userUpgradeDC = () => request('PUT', '/customers/update-group/dc');
