/**
 * @api {QUERY} products Fetch single product
 * @apiGroup Catalog
 */
export default function queryGetProduct(id, sourceCode) {
  return `
    {
      products(filter: {url_key: {eq: "${id}"}}, sourceCode: "${sourceCode}" ) {
                items {
                    id
                    type_id
                    sku
                    url_key
                    name
                    meta_title
                    meta_keyword
                    meta_description
                    stock_status
                    only_x_left_in_stock
                    eligibility
                    special_vendors {
                      id
                      product_id
                      vendor_code
                      related_products
                      start_time
                      end_time
                    }
                    categories {
                      id
                      url_key
                      name
                      include_in_menu
                      is_anchor
                      position
                      product_count
                    }
                    media_gallery_entries {
                      label
                      file
                    }
                    description {
                      html
                    }
                    special_price_qty
                    ingredient_benefit
                    faq
                    # taking_method
                    lable_info
                    short_description {
                      html
                    }
                    # Set Product as New From
                    new_from_date
                    new_to_date
                    calculated_price
                    special_price
                    special_from_date
                    special_to_date
                    tier_price
                    tier_prices {
                      customer_group_id
                      percentage_value
                      qty
                      value
                      website_id
                    }
                    price {
                      minimalPrice {
                        amount {
                          value
                          currency
                        }
                        adjustments {
                          amount {
                            value
                            currency
                          }
                          code
                          description
                        }
                      }
                      maximalPrice {
                        amount {
                          value
                          currency
                        }
                        adjustments {
                          amount {
                            value
                            currency
                          }
                          code
                          description
                        }
                      }
                      regularPrice {
                        amount {
                          value
                          currency
                        }
                        adjustments {
                          amount {
                            value
                            currency
                          }
                          code
                          description
                        }
                      }
                    }
                    product_line
                    point_value
                    product_links {
                      link_type
                      linked_product_sku
                      linked_product_type
                      position
                      sku
                    }
                    like {
                      liked_entity_id
                      entity_type
                      like_count
                      customers
                    }
                    ... on SimpleProduct {
                      weight
                    }
                    ... on BundleProduct {
                      weight
                      items {
                        option_id
                        options {
                          id
                          qty
                        }
                      }
            }
      }
      }
    }
  `;
}
