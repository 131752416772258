/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * BlogShareCard
 *
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import placeholder from 'images/placeholder.png';
import Picture from 'components/Picture';
import bottomSwooshPng from 'images/share/bottom-swoosh.png';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 0 16px rgba(0,0,0,.08)',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
      width: 343,
      flexShrink: 0,
    },
  },
  bottomSwooshPng: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
  },
}));

const BlogShareCard = forwardRef(({ title, subtitle, picture, qrCode }, ref) => {
  const classes = useStyles();

  return (
    <Box className={classes.card} ref={ref}>
      <Box height={236} width={1} borderRadius="8px 8px 0 0" overflow="hidden">
        <Box position="relative">
          <img id="image1" style={{ height: 204, width: '100%' }} src={picture} alt="blog" />
          <img src={bottomSwooshPng} alt="bottomSwooshPng" className={classes.bottomSwooshPng} />
        </Box>
      </Box>
      <Box p={2} pt={0}>
        <Box mb={1}>
          <Typography variant="body1" component="p">
            <b>{title}</b>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Typography variant="body1" color="textSecondary" component="p">
              {subtitle}
            </Typography>
          </Box>
          <Box ml="auto">
            {!qrCode ? (
              <Picture
                src={`${process.env.BASE_URL_APP}${placeholder}`}
                title=""
                alt="placeholder"
                op="fit"
                id="qrcode"
                width={68}
                height={68}
                params={{ width: 136, height: 136 }}
              />
            ) : (
              <img id="qrcode" src={qrCode} width={68} height={68} alt="placeholder" />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

BlogShareCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  picture: PropTypes.string,
  qrCode: PropTypes.string,
};

export default BlogShareCard;
