import { useEffect } from 'react';
import useLatest from './useLatest';

const useUnMount = callback => {
  const fnRef = useLatest(callback);

  useEffect(
    () => () => {
      fnRef.current();
    },
    [],
  );
};

export default useUnMount;
