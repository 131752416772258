/* eslint-disable */
function Icon24LLC(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
	const viewBox = props.viewBox || '0 0 18 18'

  return (
    <svg height={height} width={width} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
      <g fill={fill}>
        <g id="---11.-Apply-for-FC" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="Apply-for-FC---Lobby---All-Empty" fill={fill} transform="translate(-35 -401)">
            <path id="Combined-Shape" d="M17.857 3v2.25H5.286v13.5h11.428v-1.125H19v2.25C19 20.55 18.543 21 17.857 21H4.143C3.457 21 3 20.55 3 19.875V4.125C3 3.45 3.457 3 4.143 3h13.714zM17.5 6C19.483 6 21 7.444 21 9.333c0 1-.467 1.89-1.167 2.445V16L17.5 13.778 15.167 16v-4.222A3.123 3.123 0 0 1 14 9.333C14 7.444 15.517 6 17.5 6zM13 13v2H7v-2h6zm-1-4v2H7V9h5z" transform="translate(32 398)" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24LLC;
