import PropTypes from 'prop-types';

function PendingCommentIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g
          id="Order-Detail-Page---Completed"
          transform="translate(-48.000000, -83.000000)"
          fillRule="nonzero"
        >
          <g id="Group-28" transform="translate(0.000000, 64.000000)">
            <g id="f-chat" transform="translate(48.000000, 19.000000)">
              <path
                d="M44,0 L2,0 C0.89543,0 0,0.89543 0,2 L0,30 C0,31.10457 0.89543,32 2,32 L14.51953,32 L22.21875,41.625 C22.40918,41.8623 22.69629,42 23,42 C23.30371,42 23.59082,41.8623 23.78125,41.625 L31.48047,32 L44,32 C45.10457,32 46,31.10457 46,30 L46,2 C46,0.89543 45.10457,0 44,0 Z"
                id="Path"
                fill="#BADEFC"
              />
              <path
                d="M33,13 L13,13 C12.44727,13 12,12.55273 12,12 C12,11.44727 12.44727,11 13,11 L33,11 C33.55273,11 34,11.44727 34,12 C34,12.55273 33.55273,13 33,13 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M25,21 L13,21 C12.44727,21 12,20.55273 12,20 C12,19.44727 12.44727,19 13,19 L25,19 C25.55273,19 26,19.44727 26,20 C26,20.55273 25.55273,21 25,21 Z"
                id="Path"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PendingCommentIcon.defaultProps = {
  width: 46,
  height: 42,
  fill: 'currentColor',
};

PendingCommentIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PendingCommentIcon;
