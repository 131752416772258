/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import { useMemo } from 'react';
import { useGetCartQuote } from 'containers/CartProvider/queries';

const useGetVendorCode = () => {
  const { data: quote } = useGetCartQuote();

  return useMemo(
    () => quote?.extension_attributes?.vendor_code,
    [quote?.extension_attributes?.vendor_code],
  );
};

export default useGetVendorCode;
