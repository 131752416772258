/**
 * @author zhengji.su
 * @description SideSwiper
 */

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Buttons from 'components/Buttons/Buttons';
import useSideSwiper from 'components/Swiper/hooks/useSideSwiper';
import { AngleLeftIcon12, AngleRightIcon } from 'components/Icons';
import VariantList from 'components/Variant/VariantList';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  banner: {
    overflowX: 'clip',
  },
  container: {
    display: 'flex',
    width: 'max-content',
  },
  action: {
    position: 'absolute',
    top: '50%',
    padding: 0,
    transform: 'translateY(-50%)',
    width: 48,
    height: 48,
    borderRadius: '50%',
    boxShadow: '0 0 16px rgba(0,0,0,0.08) !important',
    backgroundColor: theme.palette.primary.white,
    '& svg': {
      width: 40,
      height: 40,
    },
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      cursor: 'no-drop',
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
        '& svg': {
          color: theme.palette.primary.disabled,
        },
      },
    },
  },
  prevBtn: {
    left: 0,
  },
  nextBtn: {
    right: 0,
  },
}));

function SideSwiper(props) {
  const { data, triggerScroll, children, classes: propClasses, ...other } = props;
  const classes = useStyles(props);

  const {
    containerRef,
    showTrigger,
    sideRef,
    swiperRef,
    prevDisabled,
    nextDisabled,
    onNext,
    onPrev,
  } = useSideSwiper({
    sideCount: data.length,
  });

  return (
    <Box className={classes.root} {...other}>
      <Box ref={containerRef} className={classes.banner}>
        <VariantList ref={swiperRef} list={data} className={classes.container}>
          {option => (
            <Box ref={sideRef} height="100%">
              {children(option)}
            </Box>
          )}
        </VariantList>
      </Box>
      {triggerScroll && showTrigger && (
        <>
          <Buttons
            variant="text"
            onClick={onPrev}
            disabled={prevDisabled}
            className={clsx(classes.action, classes.prevBtn)}
          >
            <AngleLeftIcon12 />
          </Buttons>
          <Buttons
            variant="text"
            onClick={onNext}
            disabled={nextDisabled}
            className={clsx(classes.action, classes.nextBtn)}
          >
            <AngleRightIcon />
          </Buttons>
        </>
      )}
    </Box>
  );
}

SideSwiper.propTypes = {
  data: PropTypes.array,
  triggerScroll: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default SideSwiper;
