import PropTypes from 'prop-types';

function ResearchIcon({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 86 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---2.-Search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Search---Empty-Result"
          transform="translate(-145.000000, -255.000000)"
          fillRule="nonzero"
        >
          <g id="research" transform="translate(145.000000, 255.000000)">
            <path
              d="M74.8421053,96 L4.15789474,96 C1.86155288,96 0,94.1312755 0,91.826087 L0,4.17391304 C0,1.86872452 1.86155288,0 4.15789474,0 L54.0526316,0 L79,25.0434783 L79,91.826087 C79,94.1312755 77.1384471,96 74.8421053,96 Z"
              id="Path"
              fill="#E6E6E6"
            />
            <path
              d="M54,0 L54,20.8333333 C54,23.1345198 55.8654802,25 58.1666667,25 L79,25 L54,0 Z"
              id="Path"
              fill="#B3B3B3"
            />
            <path
              d="M60.9090909,71 L19.0909091,71 C17.9361319,71 17,71.8954305 17,73 C17,74.1045695 17.9361319,75 19.0909091,75 L60.9090909,75 C62.0638681,75 63,74.1045695 63,73 C63,71.8954305 62.0638681,71 60.9090909,71 Z"
              id="Path"
              fill="#B3B3B3"
            />
            <path
              d="M60.9090909,50 L19.0909091,50 C17.9361319,50 17,50.8954305 17,52 C17,53.1045695 17.9361319,54 19.0909091,54 L60.9090909,54 C62.0638681,54 63,53.1045695 63,52 C63,50.8954305 62.0638681,50 60.9090909,50 Z"
              id="Path"
              fill="#B3B3B3"
            />
            <path
              d="M35.9,29 L19.1,29 C17.940202,29 17,29.8954305 17,31 C17,32.1045695 17.940202,33 19.1,33 L35.9,33 C37.059798,33 38,32.1045695 38,31 C38,29.8954305 37.059798,29 35.9,29 Z"
              id="Path"
              fill="#B3B3B3"
            />
            <path
              d="M81.7641901,96 C80.6402034,96.002088 79.5619234,95.5562705 78.7689203,94.7615911 L65,81.0250558 L70.9418187,75 L84.7594598,88.7851416 C85.9707347,89.9939324 86.3330224,91.8115608 85.6774357,93.3907042 C85.021849,94.9698476 83.47745,95.9996277 81.7641901,96 Z"
              id="Path"
              fill="#D0AB86"
            />
            <path
              d="M54,46 C43.5065898,46 35,54.5065898 35,65 C35,75.4934102 43.5065898,84 54,84 C64.4934102,84 73,75.4934102 73,65 C72.9872046,54.5118943 64.4881057,46.0127954 54,46 Z"
              id="Path"
              fill="#C2EFF4"
              opacity="0.7"
            />
            <path
              d="M54,86 C42.4020203,86 33,76.5979797 33,65 C33,53.4020203 42.4020203,44 54,44 C65.5979797,44 75,53.4020203 75,65 C74.987269,76.5927023 65.5927023,85.987269 54,86 L54,86 Z M54,48.2 C44.7216162,48.2 37.2,55.7216162 37.2,65 C37.2,74.2783838 44.7216162,81.8 54,81.8 C63.2783838,81.8 70.8,74.2783838 70.8,65 C70.7895842,55.725934 63.274066,48.2104158 54,48.2 L54,48.2 Z"
              id="Shape"
              fill="#2A4B55"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

ResearchIcon.defaultProps = {
  width: '86px',
  height: '96px',
};

ResearchIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ResearchIcon;
