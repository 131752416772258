import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import FormatMessage from 'components/FormatMessage';
import appMessages from 'containers/App/messages';

function DuplicateDialog({ open, onClose }) {
  const intl = useIntl();

  return (
    <ConfirmDialog
      open={open}
      width={343}
      title={intl.formatMessage(appMessages.duplicateItems)}
      onConfirm={onClose}
      onAssistant={onClose}
      onClose={onClose}
    >
      <FormatMessage color="textSecondary" message={appMessages.duplicateCheckoutNotice} />
    </ConfirmDialog>
  );
}

DuplicateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DuplicateDialog;
