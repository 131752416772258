import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FormatMessage from 'components/FormatMessage';
import Buttons from 'components/Buttons/Buttons';
import { AngleRightIcon16 } from 'components/Icons';
import useSpecial from 'components/Product/hooks/useSpecial';
import productMessages from 'containers/ProductPage/messages';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    fontSize: 12,
  },
  specialBtn: {
    padding: 0,
    marginLeft: theme.spacing(1),
    minHeight: 'auto',
    height: 'auto',
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
}));

export function SpecialController(props) {
  const { onOpenSpecial, specialVendors = [] } = props;
  const classes = useStyles(props);
  const { toggleSpecial, changeSpecialVendors } = useSpecial();

  const handleOpenSpecial = () => {
    toggleSpecial(true);
    changeSpecialVendors(specialVendors);
    onOpenSpecial?.();
  };

  return (
    <div className={clsx(classes.root, 'special-controller')}>
      <FormatMessage color="textSecondary" message={productMessages.specialControllerNotice} />
      <Buttons
        variant="text"
        color="primary"
        className={classes.specialBtn}
        onClick={handleOpenSpecial}
      >
        <FormatMessage message={productMessages.specialControllerBtn} />
        <SvgIcon component={AngleRightIcon16} viewBox="0 -1 16 16" />
      </Buttons>
    </div>
  );
}

SpecialController.propTypes = {
  specialVendors: PropTypes.array,
  onOpenSpecial: PropTypes.func,
};

export default SpecialController;
