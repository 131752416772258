/* eslint-disable */
function Icon24Alipay(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="2.Icons/Brand/24-alipay" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<path id="alipay" d="M19.5 22.5c1.512 0 2.977-1.457 3-3-2.154-1.463-5.184-3.091-8-4-1.547 1.653-3.988 3.389-7 3-3.32.389-4.404-1.735-5-3 .255-2.02 1.138-4.011 5-4 1.764-.011 3.825.469 6 1 .84-1.252 1.305-2.67 1-3h-8 4v-2h-5v-1h5v-2h3v2h5v1h-5v2h4c-.184-.453-.897 1.733-2 4 2.312.592 4.706 1.499 7 2v-11c-.008-1.52-1.48-3-3-3h-15c-1.528 0-3 1.472-3 3v15c0 1.528 1.472 3 3 3h15zm-13-5c2.848 0 4.788-1.902 6-3-2.138-1.39-4.047-2-5-2-3.475 0-4.045 1.585-4 2 .05 1.391.494 3 3 3z" fill={fill}/>
		</g>
	</g>
</svg>
	);
}

export default Icon24Alipay;