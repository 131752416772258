/**
 *
 * Asynchronously loads the component for Authorization
 *
 */

import loadable from 'utils/loadable';
import LogoPageLoader from 'components/PageLoader/LogoPageLoader';

export const AccountLogInPage = loadable(() => import('./components/AccountLogInPage'), {
  fallback: <LogoPageLoader />,
});
export const RegistrationPage = loadable(() => import('./components/RegistrationPage'));
export const ResetPasswordPage = loadable(() => import('./components/ResetPasswordPage'));
export const PartnerRegister = loadable(() => import('./components/PartnerRegister'));
