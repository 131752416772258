/**
 *
 * Asynchronously loads the component for OrderDetail
 *
 */

import loadable from 'utils/loadable';

export const OrderRefundApplicationPage = loadable(() =>
  import('./components/OrderRefundApplicationPage'),
);
export const OrderRefundCourierPage = loadable(() => import('./components/OrderRefundCourierPage'));

export default loadable(() => import('./index'));
