/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import { Children, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import { selectLanguage } from 'containers/App/slices';

const appDateLocales = {
  en: 'en',
  zh: 'zh-cn',
};

export function LanguageProvider(props) {
  const locale = useSelector(selectLanguage);
  useEffect(() => {
    dayjs.locale(appDateLocales[locale]);
  }, [locale]);

  return (
    <IntlProvider locale={locale} key={locale} messages={props.messages[locale]}>
      {Children.only(props.children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
