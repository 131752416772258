/*
 *
 * Order List Page constants
 *
 */

// eslint-disable-next-line import/prefer-default-export
export const ORDER_STATUS_LIST = [
  'pending_payment',
  'pending_shipment',
  'customerPickUp',
  'in_transit',
  'complete',
  'canceled',
  'reviewed',
];
