/* eslint-disable */
function Icon24Account(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
	const viewBox = props.viewBox || '0 0 24 24'

  return (
    <svg height={height} width={width} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-account" fill={fill}>
            <path id="Shape" d="M20.25 3H3.75a.75.75 0 0 0-.75.75v16.5c0 .414.336.75.75.75h16.5a.75.75 0 0 0 .75-.75V3.75a.75.75 0 0 0-.75-.75zM8.5 9.111C8.5 7.393 10.067 6 12 6s3.5 1.393 3.5 3.111v.778C15.5 11.607 13.933 13 12 13s-3.5-1.393-3.5-3.111V9.11zm8.857 9.639H6.643c-.493 0-.893-.336-.893-.75 0-2.07 2-3.75 4.464-3.75h3.572c2.465 0 4.464 1.68 4.464 3.75 0 .414-.4.75-.893.75z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Account;
