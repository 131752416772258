import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAddress: {},
  selectedShippingMethod: {},
  selectedCommissionMonth: {},
  selectedPickupMethod: {},
  selectedPaymentMethod: {},
  selectedCard: {},
  selectedFapiao: {},
  lock: false,
};
export const checkoutState = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetCheckout: () => initialState,
    selectShippingAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    selectShippingMethod: (state, action) => {
      state.selectedShippingMethod = action.payload.method;
    },
    selectCommissionMonth: (state, action) => {
      state.selectedCommissionMonth = action.payload.method;
    },
    selectPickupMethod: (state, action) => {
      state.selectedPickupMethod = action.payload.method;
    },
    selectPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = action.payload.paymentMethod;
    },
    lockCart: (state, action) => {
      state.lock = action.payload;
    },
    selectFapiao: (state, action) => {
      state.selectedFapiao = action.payload.fapiao;
    },
  },
});

export const {
  resetCheckout,
  selectShippingAddress,
  selectShippingMethod,
  selectCommissionMonth,
  selectPickupMethod,
  selectPaymentMethod,
  lockCart,
  selectFapiao,
} = checkoutState.actions;

export const selectShippingAddressSelected = state => state.checkout.selectedAddress;
export const selectShippingMethodSelected = state => state.checkout.selectedShippingMethod;
export const selectCommissionMonthSelected = state => state.checkout.selectedCommissionMonth;
export const selectPickupMethodSelected = state => state.checkout.selectedPickupMethod;
export const selectPaymentMethodSelected = state => state.checkout.selectedPaymentMethod;
export const selectFapiaoSelected = state => state.checkout.selectedFapiao;
export const selectLockCart = state => state.checkout.lock;

export default checkoutState.reducer;
