import { PropTypes } from 'prop-types';

function ListLineIcon({ color = 'currentColor' }) {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>2.Icons/12/12-list</title>
      <g id="2.Icons/12/12-list" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="list-(1)" transform="translate(0.9, 1.7)" fill={color} fillRule="nonzero">
          <path
            d="M10.6,5.13333333 L3.02857143,5.13333333 L3.02857143,3.66666667 L10.6,3.66666667 L10.6,5.13333333 Z M0,5.13333333 L1.51428571,5.13333333 L1.51428571,3.66666667 L0,3.66666667 L0,5.13333333 Z M10.6,0 L3.02857143,0 L3.02857143,1.46666666 L10.6,1.46666666 L10.6,0 Z M1.51428571,1.46666666 L1.51428571,0 L0,0 L0,1.46666666 L1.51428571,1.46666666 Z M3.02857143,7.33333334 L3.02857143,8.8 L10.6,8.8 L10.6,7.33333334 L3.02857143,7.33333334 Z M0,8.8 L1.51428571,8.8 L1.51428571,7.33333334 L0,7.33333334 L0,8.8 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}

ListLineIcon.propTypes = { color: PropTypes.string };

export default ListLineIcon;
