import { useQuery } from 'react-query';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import QUERY_ALL_PRODUCTS from 'containers/CatalogPage/gql/queryGetAllProducts';
import QUERY_ALL_CATEGORIES from 'containers/CatalogPage/gql/queryGetCategories';
import QUERY_PRODUCT_LINES from 'containers/CatalogPage/gql/queryGetProductLines';
import QUERY_SINGLE_CATEGORY from 'containers/CatalogPage/gql/queryGetSingleCategory';
import requestGql, { querySetting } from 'utils/requestGql';
import useGetSourceCode from 'utils/Hooks/useGetSourceCode';
import useGetVendorCode from 'utils/Hooks/useGetVendorCode';
import useDeepCompareMemo from 'utils/Hooks/updateEffect/useDeepCompareMemo';
import { changeSpecialProductVendors } from 'containers/CatalogPage/slices';
import { getAuthorization } from 'utils/Auth';

const getAllProductsQuery = sourceCode => requestGql(QUERY_ALL_PRODUCTS(sourceCode));

const getAllCategoriesQuery = () => requestGql(QUERY_ALL_CATEGORIES);
const getProductLinesQuery = () => requestGql(QUERY_PRODUCT_LINES);
const getSingleCategoryQuery = (id, sourceCode) =>
  requestGql(QUERY_SINGLE_CATEGORY(id, sourceCode));

export const CATALOG_QUERY_KEY = {
  GET_PRODUCTS: 'catalogPage.allProducts',
  GET_PRODUCTS_LINES: 'catalogPage.allProductLines',
  GET_CATEGORIES: 'catalogPage.allCategories',
  GET_SINGLE_CATEGORY: 'catalogPage.singleCategory',
};

export const useGetAllProducts = () => {
  const dispatch = useDispatch();
  const sourceCode = useGetSourceCode();
  const vendorCode = useGetVendorCode();
  const { token } = getAuthorization();
  const {
    data: products = [],
    isLoading,
    ...other
  } = useQuery(
    [CATALOG_QUERY_KEY.GET_PRODUCTS, sourceCode, token],
    () =>
      getAllProductsQuery(sourceCode).then(response =>
        get(response, 'category.products.items', []),
      ),
    { ...querySetting, refetchOnMount: true, enabled: sourceCode !== undefined },
  );

  const { specialVendors, specialProducts, filterProducts } = useDeepCompareMemo(() => {
    if (!vendorCode && isEmpty(products)) {
      return {
        specialVendors: [],
        specialProducts: [],
        filterProducts: [],
      };
    }

    const specialGoods = products.filter(product => product.eligibility);

    const goodsVendors = specialGoods.map(p => {
      const vendors = get(p, 'special_vendors', []);
      const { related_products: relatedProducts } =
        vendors.find(vendor => vendor.vendor_code === vendorCode) ?? {};

      return relatedProducts ? JSON.parse(relatedProducts) : {};
    });

    const specialGoodsVendors = [...new Set(goodsVendors.flat(1))];

    dispatch(changeSpecialProductVendors(specialGoodsVendors));

    return {
      specialVendors: specialGoodsVendors,
      specialProducts: specialGoods,
      filterProducts: products.filter(product => product.display_front !== 1),
    };
  }, [products, vendorCode]);

  return {
    data: filterProducts,
    products,
    loading: isLoading,
    isLoading,
    specialProducts,
    specialVendors,
    ...other,
  };
};

export const useGetAllCategories = () =>
  useQuery(
    CATALOG_QUERY_KEY.GET_CATEGORIES,
    () =>
      getAllCategoriesQuery().then(response =>
        get(response, 'category.children', []).filter(c => c.include_in_menu === 1),
      ),
    querySetting,
  );

export const useGetAllProductLines = () =>
  useQuery(
    CATALOG_QUERY_KEY.GET_PRODUCTS_LINES,
    () =>
      getProductLinesQuery().then(response =>
        get(response, 'customAttributeMetadata.items[0].attribute_options', []),
      ),
    querySetting,
  );

export const useGetSingleCategory = id => {
  const sourceCode = useGetSourceCode();
  return useQuery(
    [CATALOG_QUERY_KEY.GET_SINGLE_CATEGORY, id, sourceCode],
    () => getSingleCategoryQuery(id, sourceCode),
    {
      ...querySetting,
      enabled: !!id && !!sourceCode,
    },
  );
};
