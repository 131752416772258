/* eslint-disable */
function TwoWayArrow(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="2.Icons/12/12-sort" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<path id="down" d="M8.57 8H3.43a.433.433 0 0 0-.397.231.342.342 0 0 0 .093.41l2.57 2.249c.081.07.19.11.304.11.114 0 .223-.04.303-.11l2.571-2.25a.342.342 0 0 0 .093-.409A.433.433 0 0 0 8.571 8z" fill={fill}/>
			<path id="up" d="M6.303 1.11A.461.461 0 0 0 6 1a.461.461 0 0 0-.303.11L3.126 3.36a.342.342 0 0 0-.093.409c.066.14.223.231.396.231h5.142c.173 0 .33-.091.396-.231a.342.342 0 0 0-.093-.41L6.304 1.11z" fill={fill}/>
		</g>
	</g>
</svg>
	);
}

export default TwoWayArrow;