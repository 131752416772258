/**
 *
 * Asynchronously loads the component for CatalogPage
 *
 */

import loadable from 'utils/loadable';
import LogoPageLoader from 'components/PageLoader/LogoPageLoader';

export const FlashSale = loadable(() => import('./components/FlashSalePage'));
export const SearchResultPage = loadable(() => import('./components/SearchResultPage'));

export default loadable(() => import('./index'), { fallback: <LogoPageLoader /> });
