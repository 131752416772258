import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
// import { isSignedIn } from 'utils/Auth';
import isEmpty from 'lodash/isEmpty';
import {
  setStoreList,
  selectStoreList,
  setFilterValues,
  setPopoverProps,
  toggleStoreShow,
  selectStoreShow,
  selectFilterValues,
  selectPopoverProps,
} from 'containers/StoreProvider/slices';
import usePosition from 'utils/Hooks/usePosition';
import { CART_QUERY_KEY, useSetSourceToCart } from 'containers/CartProvider/queries';
// import useToLogin from 'utils/Hooks/useToLogin';
import useGetSourceCode from 'utils/Hooks/useGetSourceCode';
// import { useClearCheckoutQuote } from 'containers/CheckoutPage/queries';

import {
  useGetStoreList,
  useGetStores,
  // useSetCustomerSource,
  useGetSourceCartQuantity,
  useGetSourceCityListQuery,
} from 'containers/StoreProvider/queries';

const initialParams = {
  'searchCriteria[filterGroups][1][filters][0][conditionType]': 'eq',
  'searchCriteria[filterGroups][1][filters][0][field]': 'enabled',
  'searchCriteria[filterGroups][1][filters][0][value]': '1',
};

const useStore = () => {
  const queryClient = useQueryClient();
  const { data: cityList } = useGetSourceCityListQuery();
  const cartSourceCode = useGetSourceCode();
  const { mutateAsync: getStore, isLoading: isLoadingStore } = useGetStores();
  const { mutateAsync: getSourceCartQuantity, isLoading: isLoadingSourceCartQuantity } =
    useGetSourceCartQuantity();
  const { mutateAsync: setSourceToCart } = useSetSourceToCart();
  // const { mutateAsync: clearCheckoutQuote } = useClearCheckoutQuote();
  // const { mutateAsync: setSource } = useSetCustomerSource();
  const { position } = usePosition();
  // const toLogin = useToLogin();

  const dispatch = useDispatch();

  const storeList = useSelector(selectStoreList);
  const storeVisible = useSelector(selectStoreShow);
  const filterValues = useSelector(selectFilterValues);
  const popoverProps = useSelector(selectPopoverProps);

  const { data: allStoreList } = useGetStoreList(cartSourceCode);
  const storeSelected = useMemo(
    () => allStoreList?.find(s => s.source_code === cartSourceCode) ?? {},
    [allStoreList, cartSourceCode],
  );

  const [isLoadingPosition, setIsLoadingPosition] = useState(false);

  const updateSource = async payload => {
    try {
      const { source_code: sourceCode } = payload;
      if (cartSourceCode !== sourceCode) {
        // if (isSignedIn()) {
        //   // await setSource({ sourceCode });
        //   // await clearCheckoutQuote();
        //   await setSourceToCart({ sourceCode });
        // } else {
        //   toLogin();
        // }
        await setSourceToCart({ sourceCode });
        queryClient.refetchQueries([CART_QUERY_KEY.GET]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const redirectStore = payload => {
    try {
      const { source_code: sourceCode } = payload;
      if (cartSourceCode !== sourceCode) {
        const subDomain = payload?.extension_attributes?.subdomain;
        if (subDomain) {
          const url = subDomain;
          if (url !== window.location.origin) {
            window.location.href = url;
            return;
          }
        }
        updateSource(payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSuccess = stores => {
    dispatch(setStoreList(stores));
  };

  const getParams = data => {
    const params = { ...initialParams };
    if (data.latitude && data.longitude) {
      Object.assign(params, {
        'searchCriteria[filterGroups][0][filters][1][conditionType]': 'eq',
        'searchCriteria[filterGroups][0][filters][1][field]': 'latitude',
        'searchCriteria[filterGroups][0][filters][1][value]': `${data.latitude}`,
        'searchCriteria[filterGroups][0][filters][2][conditionType]': 'eq',
        'searchCriteria[filterGroups][0][filters][2][field]': 'longitude',
        'searchCriteria[filterGroups][0][filters][2][value]': `${data.longitude}`,
      });
    }

    if (data.city && data.city !== '全国') {
      Object.assign(params, {
        'searchCriteria[filterGroups][0][filters][0][conditionType]': 'like',
        'searchCriteria[filterGroups][0][filters][0][field]': 'city',
        'searchCriteria[filterGroups][0][filters][0][value]': `%${data.city}%`,
      });
    }

    return params;
  };

  const fetchStore = (values = {}) => {
    const data = {
      ...filterValues,
      ...values,
    };

    if (isEmpty(data.skus)) {
      const nextParams = getParams(data);
      return getStore(nextParams, {
        onSuccess: res => {
          handleSuccess(res);
        },
      });
    }
    const { skus, city, ...rest } = data;
    const sku = skus.map(item => item.sku);
    return getSourceCartQuantity(
      { ...rest, sku, city: city !== '全国' ? city : undefined },
      {
        onSuccess: res => {
          handleSuccess(res);
        },
      },
    );
  };

  const toggleStoreVisible = async (
    visible = false,
    payload = { skus: [], city: undefined },
    popover = undefined,
  ) => {
    if (visible) {
      fetchStore(payload);
    }
    dispatch(setFilterValues(payload));
    dispatch(setPopoverProps(popover));
    dispatch(toggleStoreShow(visible));
  };

  const showPosition = async result => {
    const params = { latitude: result.latitude, longitude: result.longitude, city: undefined };
    dispatch(setFilterValues(params));
    await fetchStore(params);
    setIsLoadingPosition(false);
  };

  const onPositionError = async err => {
    // const params = { city: undefined, skus: [] };
    // dispatch(setFilterValues(params));
    await fetchStore();
    setIsLoadingPosition(false);
    console.log(err);
  };

  const getNearbyStores = () => {
    setIsLoadingPosition(true);
    position({ onSuccess: res => showPosition(res), onError: err => onPositionError(err) });
  };

  const changeCity = async (data = {}) => {
    const params = { ...data };
    setIsLoadingPosition(true);
    dispatch(setFilterValues(params));
    await fetchStore(params);
    setIsLoadingPosition(false);
  };

  const getStockStatus = (stocks = []) => {
    const { skus } = filterValues;

    const outOfStockItems = stocks.filter(
      stock =>
        stock.quantity <= 0 || stock.quantity < skus?.find(item => item.sku === stock.sku)?.qty,
    );
    if (outOfStockItems.length <= 0) {
      return 'inStock';
    }

    if (outOfStockItems.length === stocks.length) {
      return 'outOfStock';
    }

    return 'someItemsOutOfStock';
  };

  const selectFlagshipStore = () => {
    const flagshipStore = storeList.find(item => item?.extension_attributes?.flagship);
    updateSource(flagshipStore);
  };

  const hasLoading = isLoadingPosition || isLoadingStore || isLoadingSourceCartQuantity;
  const isLoading = useMemo(() => hasLoading, [hasLoading]);
  return {
    isLoading,
    popoverProps,
    storeVisible,
    fetchStore,
    storeList,
    cityList,
    updateSource,
    redirectStore,
    selectFlagshipStore,
    storeSelected,
    getNearbyStores,
    toggleStoreVisible,
    changeCity,
    filterValues,
    getStockStatus,
  };
};

export default useStore;
