/* eslint-disable */
function Icon16Return(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/16/16-return-refund" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Combined-Shape-Copy" d="M5.725 2l-.628 4.027L3.62 4.676c-1.505 1.684-1.814 4.012-.796 5.978 1.019 1.966 3.183 3.22 5.56 3.22.34 0 .616.252.616.563 0 .31-.276.563-.615.563-2.876.002-5.49-1.525-6.7-3.913C.475 8.7.891 5.887 2.75 3.88L1.326 2.576 5.726 2zm1.89-1c4.077.004 7.38 3.028 7.385 6.76.003 1.598-.617 3.145-1.749 4.361l1.423 1.303-4.399.576.628-4.027 1.477 1.351c1.505-1.684 1.815-4.012.796-5.978-1.018-1.966-3.183-3.22-5.56-3.22-.34 0-.616-.252-.616-.563 0-.31.276-.563.615-.563zM6.588 5l1.414 3.184L9.422 5H10.5L8.736 8.639H9.74v.723H8.497v.737H9.74v.733H8.497V12h-.994v-1.168H6.252v-.733h1.25v-.737h-1.25v-.723h.99L5.5 5h1.088z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon16Return;
