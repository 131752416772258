/* eslint-disable */
function QRCodeIcon(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---Icons-(Dont't-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="2.Icons/12/12-qr-code" fill={fill} transform="translate(-1 -1)">
				<path id="Combined-Shape-Copy" d="M4.429 7C4.77 7 5 7.229 5 7.571v2.858c0 .342-.229.571-.571.571H1.57C1.23 11 1 10.771 1 10.429V7.57C1 7.23 1.229 7 1.571 7H4.43zM11 10v1H7v-1h4zM3 8.142h-.857v1.715h1.714V9H3v-.858zM10.4 6c.36 0 .6.3.6.75V9H9.8V7.5H8V6h2.4zM9 8v1H8V8h1zM4.429 1C4.77 1 5 1.229 5 1.571V4.43C5 4.77 4.771 5 4.429 5H1.57C1.23 5 1 4.771 1 4.429V1.57C1 1.23 1.229 1 1.571 1H4.43zm6 0c.342 0 .571.229.571.571V4.43c0 .342-.229.571-.571.571H7.57C7.23 5 7 4.771 7 4.429V1.57C7 1.23 7.229 1 7.571 1h2.858zM3.857 2.143H2.143v1.714H3V3h.857v-.857zm6 0H8.143L8.142 3H9v.857h.857V2.143z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default QRCodeIcon;