/* eslint-disable */
/* eslint-disable no-underscore-dangle */
// import ReactGA from 'react-ga';
import loadable from 'utils/loadable';
// import get from 'lodash/get';

export const addImpressionAction = (lists) => {
  // let _paq = window._paq || [];

  // lists.forEach((i, index) => {
  //   ReactGA.ga('ec:addImpression', {
  //     id: i.id, // Product details are provided in an impressionFieldObject.
  //     name: i.name,
  //     category: i?.categories?.[0]?.name,
  //     brand: i.product_line,
  //     variant: i.type_id,
  //     list: 'Filter Results',
  //     position: index + 1, // 'position' indicates the product position in the list.
  //   });
  //   // Push Category View Data to Matomo - Fill category dynamically
  //   _paq.push(['setEcommerceView',
  //     false, // Product name is not applicable for a category view.
  //     false, // Product SKU is not applicable for a category view.
  //     i?.categories?.[0]?.name, // (Optional) Product category, or array of up to 5 categories
  //   ]);
  // });
  // ReactGA.ga('send', 'pageview'); // Send product impressions with initial pageview.

  // _paq.push(['setCustomUrl', `${window.location.pathname}`]);
  // _paq.push(['setDocumentTitle', document.title]);
  // _paq.push(['trackPageView']); // You must also call trackPageView when tracking a category view
};
export const onProductClick = (item) => {
  // ReactGA.ga('ec:addProduct', {
  //   id: item.id,
  //   name: item.name,
  //   category: item?.categories?.[0]?.name,
  //   brand: item.product_line,
  //   variant: item.type_id,
  //   position: 1,
  // });
  // ReactGA.ga('ec:setAction', 'click', { list: 'Search Results' });

  // // Send click with an event, then send user to product page.
  // ReactGA.ga('send', 'event', 'UX', 'click', 'Results', {
  //   hitCallback() {
  //     document.location = '/product_details?id=P12345';
  //   },
  // });
};

export const productPageView = (item) => {
  // let _paq = window._paq || [];

  // ReactGA.ga('ec:addProduct', {
  //   id: item.id,
  //   name: item.name,
  //   category: item?.categories?.[0]?.name,
  //   brand: item.product_line,
  //   variant: item.type_id,
  // });
  // ReactGA.ga('ec:setAction', 'detail');
  // ReactGA.ga('send', 'event', 'UX', 'view', 'product view');

  // const originalPrice = get(item, 'price.regularPrice.amount.value', 0);
  // const price = get(item, 'calculated_price', originalPrice);
  // // Push Product View Data to Matomo - Populate parameters dynamically
  // _paq.push(['setEcommerceView',
  //   item.sku, // (Required) productSKU
  //   item.name, // (Optional) productName
  //   item?.categories?.[0]?.name, // (Optional) categoryName
  //   price, // (Optional) price
  // ]);

  // // You must also call trackPageView when tracking a product view
  // _paq.push(['setCustomUrl', `${window.location.pathname}`]);
  // _paq.push(['setDocumentTitle', document.title]);
  // _paq.push(['trackPageView']);
};

/**
 *
 * @param item
 * @param type remove or add
 * @param qty
 */
export const addToCartAction = (item, type, qty) => {
  // // let _paq = window._paq || [];
  // const originalPrice = get(item, 'price.regularPrice.amount.value', 0);
  // const price = get(item, 'calculated_price', originalPrice);

  // ReactGA.ga('ec:addProduct', {
  //   id: item.id,
  //   name: item.name,
  //   category: item.product_line,
  //   brand: item.product_line,
  //   variant: item.type_id,
  //   price,
  //   quantity: qty,
  // });

  // ReactGA.ga('ec:setAction', type);
  // ReactGA.ga('send', 'event', 'UX', 'click', 'add to cart');

  // // An addEcommerceItem push should be generated for each cart item, even the products not updated by the current "Add to cart" click.
  // // _paq.push(['addEcommerceItem',
  // //   item.sku, // (Required) productSKU
  // //   item.name, // (Optional) productName
  // //   item.product_line, // (Optional) categoryName
  // //   price, // (Optional) price
  // //   qty, // (Optional, defaults to 1) quantity
  // // ]);

  // // _paq.push(['setCustomUrl', `${window.location.pathname}`]);
  // // _paq.push(['setDocumentTitle', document.title]);
};

export const updateEcommerceCart = (items, cartAmount) => {
  // let _paq = window._paq || [];
  // // eslint-disable-next-line no-plusplus
  // for (let i = 0; i < items.length; i++) {
  //   let item = items[i];
  //   let price = get(item, 'extension_attributes.calculated_price', 0);
  //   _paq.push(['addEcommerceItem',
  //     item.sku, // (Required) productSKU
  //     item.name, // (Optional) productName
  //     item.product_type, // (Optional) categoryName
  //     price, // (Optional) price
  //     item.qty, // (Optional, defaults to 1) quantity
  //   ]);
  // }
  // _paq.push(['trackEcommerceCartUpdate', cartAmount]); // (required) Cart amount
};

export const handleDeleteCartItem = (id, total) => {
//   let _paq = window._paq || [];
//   _paq.push(['removeEcommerceItem', id]); // (required) Remove item
//   updateEcommerceCart([], total);
};

export const handleClearEcommerceCart = () => {
//   let _paq = window._paq || [];
//   _paq.push(['clearEcommerceCart']); // (required) Remove item
//   _paq.push(['setCustomUrl', `${window.location.pathname}`]);
//   _paq.push(['setDocumentTitle', document.title]);
//   _paq.push(['trackPageView']);
};

export const checkoutAction = (cart) => {
  // cart.forEach((i) => {
  //   ReactGA.ga('ec:addProduct', {
  //     id: i.item_id, // Product details are provided in an impressionFieldObject.
  //     name: i.name,
  //     category: i.product_type,
  //     brand: i.product_type,
  //     price: i.price,
  //     quantity: i.qty,
  //   });
  // });

  // // In the case of checkout actions, an additional actionFieldObject can
  // // specify a checkout step and option.
  // ReactGA.ga('ec:setAction', 'checkout', {
  //   step: 1, // A value of 1 indicates this action is first checkout step.
  //   option: 'Shipping method', // Used to specify additional info about a checkout stage, e.g. payment method.
  // });
  // ReactGA.ga('send', 'pageview'); // Pageview for payment.html
};

export const checkoutPayAction = (cart) => {
  // cart.forEach((i) => {
  //   ReactGA.ga('ec:addProduct', {
  //     id: i.item_id, // Product details are provided in an impressionFieldObject.
  //     name: i.name,
  //     category: i.product_type,
  //     brand: i.product_type,
  //     price: i.price,
  //     quantity: i.qty,
  //   });
  // });
  // // In the case of checkout actions, an additional actionFieldObject can
  // // specify a checkout step and option.
  // ReactGA.ga('ec:setAction', 'checkout', {
  //   step: 2, // A value of 1 indicates this action is first checkout step.
  //   option: 'Pay', // Used to specify additional info about a checkout stage, e.g. payment method.
  // });
  // ReactGA.ga('send', 'pageview'); // Pageview for payment.html
};

export const purchaseAction = (order) => {
  // let _paq = window._paq || [];

  // order.items.forEach((i) => {
  //   ReactGA.ga('ec:addProduct', {
  //     id: i.item_id, // Product details are provided in an impressionFieldObject.
  //     name: i.name,
  //     category: i.product_type,
  //     brand: i.product_type,
  //     price: i.price_incl_tax,
  //     quantity: i.qty_ordered,
  //   });
  // });

  // // Transaction level information is provided via an actionFieldObject.
  // ReactGA.ga('ec:setAction', 'purchase', {
  //   id: order.increment_id,
  //   affiliation: order.store_name,
  //   revenue: order.subtotal_incl_tax,
  //   tax: order.tax_amount,
  //   shipping: order.shipping_amount,
  //   // coupon: order.discount_amount, // User added a coupon at checkout.
  // });

  // ReactGA.ga('send', 'pageview'); // Send transaction data with initial pageview.

  // // Order Array - Parameters should be generated dynamically
  // // eslint-disable-next-line no-plusplus
  // for (let i = 0; i < order.items.length; i++) {
  //   let item = order.items[i];
  //   _paq.push(['addEcommerceItem',
  //     item.sku, // (Required) productSKU
  //     item.name, // (Optional) productName
  //     item.extension_attributes ? item.extension_attributes.product_line : '', // (Optional) categoryName
  //     (item.base_row_total_incl_tax / item.qty_ordered).toFixed(2), // (Optional) price
  //     item.qty_ordered, // (Optional, defaults to 1) quantity
  //   ]);
  // }
  // _paq.push(['setCustomUrl', `${window.location.pathname}`]);
  // _paq.push(['setDocumentTitle', document.title]);
  // _paq.push(['trackEcommerceOrder',
  //   order.increment_id, // (Required) orderId
  //   order.base_grand_total, // (Required) revenue
  //   order.subtotal, // (Optional) subTotal
  //   order.tax_amount, // (optional) tax
  //   order.shipping_amount, // (optional) shipping
  //   order.discount_amount, // (optional) discount
  // ]);
};

/*
 * login action
 * @param id
 */

export const loginAction = (id) => {
  // let _paq = window._paq || [];
  // // Login Action
  // _paq.push(['setUserId', id]);
  // // You must also call trackPageView when tracking a product view
  // _paq.push(['setCustomUrl', `${window.location.pathname}`]);
  // _paq.push(['setDocumentTitle', document.title]);
  // _paq.push(['trackPageView']);
};

/*
 * refund order
 * @param order
 */
export const refundsAction = (order) => {
// // Refund an entire transaction.
//   ReactGA.ga('ec:setAction', 'refund', {
//     // Transaction ID is only required field for full refund.
//     id: order.increment_id,
//   });
};
export default loadable;
