/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * ProductShareCard
 *
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import placeholder from 'images/placeholder.png';
import Price from 'components/Price';
import ProductPv from 'containers/ProductPage/components/ProductPv';
import logo from 'images/logo.png';
import Picture from 'components/Picture';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 0 16px rgba(0,0,0,.08)',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
      width: 343,
      flexShrink: 0,
    },
  },
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  singleImage: {
    display: 'block',
    maxWidth: 188,
    maxHeight: 188,
    position: 'absolute',
    zIndex: 5,
    margin: '0 auto',
    borderRadius: 4,
  },
  wave: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    paddingBottom: theme.spacing(3),
    background: `linear-gradient(0deg, ${theme.palette.background.peach} 25px, transparent 0%)`,
  },
  logo: {
    width: 42,
    height: 32,
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 10,
  },
  qrCode: {
    borderRadius: 2,
    overflow: 'hidden',
    flexShrink: 0,
  },
}));

const ProductShareCard = forwardRef(({ title, pv, picture, price, qrCode }, ref) => {
  const classes = useStyles();

  return (
    <Box className={classes.card} ref={ref}>
      <img
        crossOrigin="anonymous"
        width={42}
        height={32}
        src={logo}
        alt="FitLine"
        className={classes.logo}
      />
      <Box height={236} pt={2} width={1} borderRadius="8px 8px 0 0" overflow="hidden">
        <Box className={classes.imageWrapper}>
          <img
            id="image0"
            crossOrigin="anonymous"
            className={classes.singleImage}
            src={picture}
            alt="item"
          />
          <img
            alt="wave"
            className={classes.wave}
            src="data:image/svg+xml,%3Csvg width='375' height='43' viewBox='0 0 375 43' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fillRule='nonzero' fill='none'%3E%3Cpath d='M375 3.515S332 0 300.67 0C143.262 0 0 35.132 0 35.132V43c7.585-1.652 130.402-25.989 253.042-25.989C318.376 16.647 375 23.2 375 23.2V3.515' fill='%23CD0039'/%3E%3Cpath d='M375 23.203v19.8H0c0-5.975 0-1.144 0 0 7.585-1.651 130.402-25.988 253.042-25.988C318.376 16.65 375 23.203 375 23.203' fill='%23FFF3E0'/%3E%3C/g%3E%3C/svg%3E"
          />
        </Box>
      </Box>

      <Box p={2} pt={0} bgcolor="background.peach" display="flex" alignItems="flex-start">
        <Box mr={1}>
          <Typography variant="body1" component="p">
            <b>{title}</b>
          </Typography>
          <Price price={price} size="16px" color="primary" display="block" />
          <ProductPv point={pv} />
        </Box>
        <Box ml="auto" width={68} height={68} className={classes.qrCode}>
          {!qrCode ? (
            <Picture
              src={`${process.env.BASE_URL_APP}${placeholder}`}
              title=""
              alt="placeholder"
              op="fit"
              id="qrcode"
              width={68}
              height={68}
              params={{ width: 136, height: 136 }}
            />
          ) : (
            <img id="qrcode" src={qrCode} width={68} height={68} alt="placeholder" />
          )}
        </Box>
      </Box>
    </Box>
  );
});

ProductShareCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pv: PropTypes.number,
  qrCode: PropTypes.string,
  picture: PropTypes.string,
};

export default ProductShareCard;
