import { useEffect } from 'react';
import useFirstMount from 'utils/Hooks/updateEffect/useFirstMount';

const useUpdateEffect = (callback, deps = []) => {
  const isFirstMount = useFirstMount();

  useEffect(() => {
    if (isFirstMount) {
      return;
    }

    const unMountCallback = callback();
    // eslint-disable-next-line consistent-return
    return () => unMountCallback?.();
  }, deps);
};

export default useUpdateEffect;
