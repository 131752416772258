/**
 * @api {QUERY} customAttributeMetadata Fetch list of products from default category
 * @apiGroup Catalog
 */
export default `
    {
        customAttributeMetadata(
            attributes: {
                attribute_code: "product_line"
                entity_type: "4"
            }
        ) {
            items {
                attribute_code
                attribute_label
                attribute_options {
                    label
                    value
                }
                attribute_type
                entity_type
            }
        }
    }
`;
