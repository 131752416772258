/* eslint-disable */
function Icon24PendingReview(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-pending-review" fill={fill}>
            <path id="Combined-Shape" d="M20.333 3C21.253 3 22 3.733 22 4.636v10.637c0 .903-.746 1.636-1.667 1.636H15.75L12 21l-3.75-4.09H3.667c-.92 0-1.667-.734-1.667-1.637V4.636C2 3.733 2.746 3 3.667 3zM12 6l-1 3.049H8l2.405 1.858L9.417 14 12 12.093 14.583 14l-.988-3.093L16 9.05h-3L12 6z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24PendingReview;
