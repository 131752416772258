/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * PageLoader
 *
 */

import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import loadingGif from 'images/logo.svg';

const LoadingBox = styled(Box)(({ theme, opacity, display }) => ({
  height: '100vh',
  width: '100vw',
  background: theme.palette.primary.white,
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%, 0)',
  zIndex: '99999',
  margin: 'auto',
  transition: '1s ease',
  textAlign: 'center',
  overflow: 'hidden',
  top: 0,
  opacity,
  display,
}));

const useStyles = makeStyles(() => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  fadeInOut: {
    animation: '$fadeIn 1.5s ease-in-out infinite alternate',
  },
}));

function FirstLoading({ loading }) {
  const alpha = loading ? 1 : 0;
  const [disp, setDisplay] = useState('block');
  const classes = useStyles();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (alpha === 0) {
      const timer1 = setTimeout(() => setDisplay('none'), 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [alpha]);

  return (
    <LoadingBox opacity={alpha} display={disp}>
      <img
        style={{ paddingTop: '48vh' }}
        src={loadingGif}
        alt="loader"
        className={classes.fadeInOut}
      />
    </LoadingBox>
  );
}

FirstLoading.defaultProps = {
  loading: false,
};

FirstLoading.propTypes = {
  loading: PropTypes.bool,
};

export default memo(FirstLoading);
