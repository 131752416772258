/* eslint-disable */
function Icon24Coupon(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/24/24-coupon" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Shape" d="M21.625 10.222a.882.882 0 0 0 .875-.889V4.89A.882.882 0 0 0 21.625 4H2.375a.882.882 0 0 0-.875.889v4.444c0 .491.392.89.875.89.998 0 1.75.764 1.75 1.777s-.752 1.778-1.75 1.778a.882.882 0 0 0-.875.889v4.444c0 .49.392.889.875.889h19.25a.882.882 0 0 0 .875-.889v-4.444a.882.882 0 0 0-.875-.89c-.997 0-1.75-.764-1.75-1.777s.753-1.778 1.75-1.778zM9 7a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 9 7zm6 10a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 15 17zm-6.194 0L7.5 15.694 16.194 7 17.5 8.306 8.806 17z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon24Coupon;
