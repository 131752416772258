/**
 * @api {QUERY} storeConfig Request store configurations
 * @apiGroup General
 */
export default `
  query storeConfigs {
    storeConfig {
      id
      code
      website_id
      locale
      timezone
      weight_unit
      base_url
      base_link_url
      base_static_url
      secure_base_url
      secure_base_link_url
      secure_base_static_url
      secure_base_media_url
      store_name
      phone
      customer_service_email
      localized_attributes {
        key
        value
      }
      info_banners {
        content
        image
        text_color
        title
      }
      customer_groups {
        id
        code
      }
      tmo_shopnow_tracking {
        enabled
        css
        js
      }
    }
  }
`;
