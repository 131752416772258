import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import cx from 'clsx';

const useStyles = makeStyles(theme => ({
  detection: {
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '3rem',
    padding: '0.5rem',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.white, // Note: SCSS variables like $white need to be replaced with actual values or theme variables in JSX
  },
  normal: {
    background: theme.palette.success.main, // Note: Replace SCSS variables with actual values or theme variables
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 5s 5s, opacity 5s linear',
  },
  warning: {
    background: theme.palette.warning.main, // Note: Replace SCSS variables with actual values or theme variables
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 1s linear',
    // Note: The following sibling and child selectors cannot be directly translated into inline styles.
    // You might need to apply these styles directly to the elements or use a CSS-in-JS library that supports pseudo selectors.
  },
}));

const OfflineDetection = () => {
  const classes = useStyles();
  const [isOnline, setIsOnline] = useState(true);
  const setOffline = () => setIsOnline(false);
  const setOnline = () => setIsOnline(true);

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  return (
    <Box
      className={cx(classes.detection, {
        [classes.normal]: isOnline,
        [classes.warning]: !isOnline,
      })}
    >
      {!isOnline && (
        <Helmet>
          <style type="text/css">{`body { padding-top: 3rem; transition: padding .3s ease; }`}</style>
        </Helmet>
      )}
      <Box>
        <FormattedMessage id={isOnline ? 'generic.online' : 'generic.offline'} />
      </Box>
    </Box>
  );
};

export default OfflineDetection;
