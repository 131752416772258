/**
 *
 * Asynchronously loads the component for PrivacyCenter
 *
 */

import loadable from 'utils/loadable';

export const Privacy = loadable(() => import('./index'));
export const PrivacyPolicy = loadable(() => import('./components/PrivacyPolicy'));
export const ExportAccount = loadable(() => import('./components/ExportAccount'));
export const DestroyAccount = loadable(() => import('./components/DestroyAccount'));
