/* eslint-disable */
function Icon16StepOn(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="2.Icons/16/16-step-on" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" fill={fill} cx="8" cy="8" r="4"></circle>
        </g>
    </svg>
  );
}

export default Icon16StepOn;
