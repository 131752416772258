/**
 *
 * CartListItem
 *
 */

import { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import appMessages from 'containers/App/messages';
import Picture from 'components/Picture';
import StepperButtons from 'components/Buttons/StepperButtons';
import { CheckboxCheckedIcon, CheckboxIcon, MedalIcon } from 'components/Icons';
import Price from 'components/Price';
import FormatMessage from 'components/FormatMessage';
import Access, { useAccess } from 'components/Access';
import useMount from 'utils/Hooks/updateEffect/useMount';
import useOperateCartItem from 'containers/CartProvider/hooks/useOperateCartItem';
import SpecialController from 'components/Product/components/SpecialController';
import usePurchasePermissions from 'utils/Hooks/usePurchasePermissions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.primary.peach}`,
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0, 3.25),
    },
  },
  pcContainer: {
    alignItems: 'center',
  },
  cartItemName: {
    marginTop: '-4px',
    lineHeight: 1.5,
    [theme.breakpoints.up('md')]: {
      marginTop: '-4px',
      fontSize: 16,
      fontWeight: 700,
    },
  },
  comImg: {
    overflow: 'hidden',
    margin: '0 8px 0 0',
    border: `1px solid ${theme.palette.primary.darkPeach}`,
    borderRadius: 4,
  },
  PCDetailsComImg: {
    margin: '0 8px',
  },
  pcNotice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '9px',
    '& .notice-content': {
      width: '65%',
    },
  },
  notice: {
    '& .notice-content': {
      width: '100%',
    },
  },
  checkRoot: {
    marginLeft: -8,
    padding: 8,
  },
  checkDisabled: {
    '& svg #Rectangle': {
      fill: theme.palette.primary.disabled,
    },
  },
  smallSize: {
    fontSize: 10,
  },
  PCButtons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 184,
  },
  checkStore: {
    padding: 0,
    minHeight: 'auto',
  },
  special: {
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(1.375),
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4.5),
    },
  },
}));

function MiniCartListItem(props) {
  const { cartItem, isPCDetails, disabled, imgSize = 62 } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const matchLg = useMediaQuery(th => th.breakpoints.up('md'));
  const { authorization } = useAccess();
  const { quantity, showConfirm, selectedItems, toggleCartItem, quantityAdjustment, changeRemove } =
    useOperateCartItem(cartItem);
  const { isBuy, isCartCheckout } = usePurchasePermissions(cartItem, 'extension_attributes');

  const [PV, setPV] = useState(0);

  const calculatePrice = get(cartItem, 'extension_attributes.calculated_price', 0);
  const isChecked = selectedItems.includes(cartItem.item_id);
  const { primary } = get(theme, 'palette', {});
  const isCheckoutDisabled = isCartCheckout ? false : disabled;

  useMount(() => {
    setPV(get(cartItem, 'extension_attributes.point_value', 0) / cartItem.qty);
  });

  const handleToggleItem = () => toggleCartItem();

  const handleQuantityAdjustment = qty => {
    if (qty === 0) {
      return changeRemove(cartItem);
    }

    return quantityAdjustment(qty);
  };

  const cartQuantityButtonRenderer = () => (
    <StepperButtons
      initialValue={quantity}
      key={quantity}
      onChange={debounce(handleQuantityAdjustment, 500)}
      showConfirm={showConfirm}
    />
  );

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.container, {
          [classes.pcContainer]: isPCDetails,
        })}
      >
        <Box display="flex" justifyContent="flex-start">
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isChecked}
              onChange={handleToggleItem}
              classes={{
                root: classes.checkRoot,
                disabled: classes.checkDisabled,
              }}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              disabled={isCheckoutDisabled}
            />
            <Box
              width={imgSize}
              height={imgSize}
              minHeight={imgSize}
              minWidth={imgSize}
              bgcolor="background.default"
              mx={1}
              className={clsx(classes.comImg, {
                [classes.PCDetailsComImg]: isPCDetails,
              })}
            >
              <Picture
                src={cartItem.extension_attributes.main_image_url}
                alt={cartItem.name}
                title=""
                op="fit"
                params={{
                  width: imgSize,
                  height: imgSize,
                }}
              />
            </Box>
          </Box>
          <Box width="100%" className={isPCDetails ? classes.pcNotice : classes.notice}>
            <div className="notice-content">
              <FormatMessage
                message={cartItem.name}
                variant="h5"
                className={classes.cartItemName}
              />
              <Box display="flex" alignItems="center">
                <Access accessible={false && authorization([3, 4])}>
                  {PV > 0 && (
                    <Box fontSize="10px" mr={{ xm: 0.5, md: 1 }}>
                      <Box display="flex" alignItems="center" color={primary.main}>
                        <MedalIcon
                          fill={primary.silver}
                          secondaryFill={primary.main}
                          width={13}
                          height={13}
                        />
                        <FormatMessage
                          className={classes.smallSize}
                          variant="caption"
                          color="primary"
                          message={{ ...appMessages.pvc, values: { total: PV } }}
                        />
                      </Box>
                    </Box>
                  )}
                </Access>
                {/* {isPCDetails ? null : stockNodeRenderer()} */}
              </Box>
              <div>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                  <Box display="flex" alignItems="flex-end">
                    <Price
                      originalPrice={calculatePrice}
                      price={cartItem.price}
                      color="primary"
                      size={matchLg ? 16 : 12}
                      secondarySize="0.625rem"
                      style={{ lineHeight: 1 }}
                    />
                  </Box>
                  <div>{isPCDetails ? null : cartQuantityButtonRenderer()}</div>
                </Box>
              </div>
            </div>
            {isPCDetails && (
              <div className={classes.PCButtons}>
                <Box display="flex" justifyContent="center">
                  {cartQuantityButtonRenderer()}
                </Box>
                {/* <Box mt={0.5}>{stockNodeRenderer()}</Box> */}
              </div>
            )}
          </Box>
        </Box>
        {!isBuy && (
          <SpecialController
            classes={{ root: classes.special }}
            specialVendors={get(cartItem, 'extension_attributes.special_vendors', [])}
          />
        )}
      </div>
    </div>
  );
}

MiniCartListItem.propTypes = {
  cartItem: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isPCDetails: PropTypes.bool,
  imgSize: PropTypes.number,
};

export default MiniCartListItem;
