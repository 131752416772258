import PropTypes from 'prop-types';

function CheckoutCompleteIcon1({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group</title>
      <g id="---8.-Checkout" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g
          id="Checkout---Completed"
          transform="translate(-133.000000, -124.000000)"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(16.000000, 124.000000)">
            <g id="Group" transform="translate(117.000000, 0.000000)">
              <g id="gift-exchange" transform="translate(15.000000, 22.000000)">
                <path
                  d="M72.4166667,57.9005693 C70.6220509,55.828463 65.2039844,53.3419355 65.2039844,53.3419355 L61.7820513,55.6212524 L62.1385141,60.1798861 L71.0497926,60.3870968 L72.4166667,57.9005693 Z"
                  id="Path"
                  fill="#EAD8C5"
                />
                <path
                  d="M56.7569034,0 C49.2800068,0 45.1027392,6.07359329 43.0448718,10.4931427 C40.9870044,6.07359329 36.8097368,0 29.3328402,0 C24.6076056,0 20.7628205,3.8379744 20.7628205,8.55483871 C20.7628205,13.271703 24.6076056,17.1096774 29.3328402,17.1096774 L43.0448718,17.1096774 L56.7569034,17.1096774 C61.482138,17.1096774 65.3269231,13.271703 65.3269231,8.55483871 C65.3269231,3.8379744 61.482138,0 56.7569034,0 Z M29.3328402,13.6877419 C26.4973635,13.6877419 24.1908284,11.3852926 24.1908284,8.55483871 C24.1908284,5.72438477 26.4973635,3.42193548 29.3328402,3.42193548 C35.8540939,3.42193548 39.3055322,10.1446699 40.6713706,13.6877419 L29.3328402,13.6877419 Z M56.7569034,13.6877419 L45.418373,13.6877419 C46.7842114,10.1446699 50.2356497,3.42193548 56.7569034,3.42193548 C59.5923801,3.42193548 61.8989152,5.72438477 61.8989152,8.55483871 C61.8989152,11.3852926 59.5923801,13.6877419 56.7569034,13.6877419 Z"
                  id="Shape"
                  fill="#D6BA45"
                />
                <path
                  d="M68.48454,57.8709677 L15.3859351,57.8709677 C14.4399407,57.8709677 13.6730769,57.1049333 13.6730769,56.16 L13.6730769,25.3625806 C13.6730769,24.4176303 14.4399407,23.6516129 15.3859351,23.6516129 L70.1973982,23.6516129 C71.1433755,23.6516129 71.9102564,24.4176303 71.9102564,25.3625806 L71.9102564,54.4490323 C71.9102564,56.3389159 70.3765118,57.8709677 68.48454,57.8709677 Z"
                  id="Path"
                  fill="#C64B4B"
                />
                <path
                  d="M75.5757021,27.1741935 L10.5140415,27.1741935 C8.62285313,27.1741935 7.08974359,25.6534068 7.08974359,23.7774194 L7.08974359,16.983871 C7.08974359,15.1078835 8.62285313,13.5870968 10.5140415,13.5870968 L75.5757021,13.5870968 C77.4668905,13.5870968 79,15.1078835 79,16.983871 L79,23.7774194 C79,25.6534068 77.4668905,27.1741935 75.5757021,27.1741935 Z"
                  id="Path"
                  fill="#E86C60"
                />
                <rect
                  id="Rectangle"
                  fill="#EFD358"
                  x="34.4358974"
                  y="13.5870968"
                  width="17.2179487"
                  height="13.5870968"
                />
                <rect
                  id="Rectangle"
                  fill="#D6BA45"
                  x="34.4358974"
                  y="27.1741935"
                  width="17.2179487"
                  height="30.6967742"
                />
                <path
                  d="M72.429562,57.7858592 L27.4784468,57.7858592 C25.581455,57.7858592 24.0436409,56.2614282 24.0436409,54.3809454 L24.0436409,54.3809454 C24.0436409,52.5004626 25.581455,50.9760316 27.4784468,50.9760316 L42.6437844,50.9760316 C45.4333619,50.9760316 47.9337632,48.9054865 48.0799657,46.1439823 C48.2359059,43.1984765 45.8732575,40.7612903 42.9350731,40.7612903 L0,40.7612903 L0,65.4111636 L28.2499385,77.0856255 C31.3769858,78.2476544 34.8366424,78.2313279 38.0193507,77.4347653 L75.9615385,63.9506088 C75.1631007,61.3108983 73.9440366,59.2901671 72.429562,57.7858592 Z"
                  id="Path"
                  fill="#EAD8C5"
                />
              </g>
              <path
                d="M13.6722187,59 C13.4393226,59 13.2099478,58.940445 13.0038348,58.8269083 L8.99640654,56.6220555 L4.98897824,58.8269083 C4.50483926,59.0926256 3.91865837,59.0478196 3.47650833,58.7112993 C3.03435829,58.374779 2.81277255,57.804794 2.90477056,57.2406183 L3.66945917,52.5750595 L0.426719486,49.2640177 C0.0398293886,48.8634126 -0.097853534,48.2684052 0.0710147589,47.7268122 C0.239883052,47.1852192 0.686351129,46.7898934 1.22446795,46.7054855 L5.70335839,46.0222068 L7.71569685,41.7720332 C7.98386393,41.2937623 8.47391475,41 9.00359346,41 C9.53327217,41 10.023323,41.2937623 10.2914901,41.7720332 L12.2966416,46.0222068 L16.7755321,46.7054855 C17.3136489,46.7898934 17.7601169,47.1852192 17.9289852,47.7268122 C18.0978535,48.2684052 17.9601706,48.8634126 17.5732805,49.2640177 L14.3305408,52.5750595 L15.0952294,57.2406183 C15.1670247,57.6790078 15.0499433,58.1281968 14.7754106,58.4676207 C14.5008779,58.8070446 14.0970142,59.0019362 13.6722187,59 Z"
                id="Path"
                fill="#ED7064"
              />
              <path
                d="M73.6820519,18 C73.4489883,18 73.2194486,17.9404992 73.0131873,17.8270384 L69.0028768,15.6236576 L64.9925664,17.8270384 C64.5080792,18.0925783 63.9214767,18.0478022 63.4790087,17.7115065 C63.0365406,17.3752109 62.8147955,16.8056065 62.9068597,16.2418074 L63.6720983,11.5793636 L60.4270264,8.2705324 C60.039858,7.87019472 59.9020761,7.27558455 60.0710658,6.73435315 C60.2400556,6.19312175 60.6868448,5.79805992 61.2253486,5.71370833 L65.7074603,5.0308859 L67.7083003,0.771517804 C67.9766602,0.293566214 68.4670635,0 68.9971232,0 C69.5271828,0 70.0175861,0.293566214 70.285946,0.771517804 L72.2925397,5.01885379 L76.7746514,5.70167622 C77.3131552,5.7860278 77.7599444,6.18108964 77.9289342,6.72232104 C78.0979239,7.26355244 77.960142,7.85816261 77.5729736,8.25850029 L74.3279017,11.5673314 L75.0931403,16.2297753 C75.1684094,16.6673831 75.0546384,17.1173873 74.7823813,17.4589365 C74.5101242,17.8004858 74.1072862,17.9985728 73.6820519,18 Z"
                id="Path"
                fill="#ED7064"
              />
              <path
                d="M44.8,13.8 C42.8117749,13.8 41.2,12.1882251 41.2,10.2 C41.2,9.5372583 40.6627417,9 40,9 C39.3372583,9 38.8,9.5372583 38.8,10.2 C38.8,12.1882251 37.1882251,13.8 35.2,13.8 C34.5372583,13.8 34,14.3372583 34,15 C34,15.6627417 34.5372583,16.2 35.2,16.2 C37.1882251,16.2 38.8,17.8117749 38.8,19.8 C38.8,20.4627417 39.3372583,21 40,21 C40.6627417,21 41.2,20.4627417 41.2,19.8 C41.2,17.8117749 42.8117749,16.2 44.8,16.2 C45.4627417,16.2 46,15.6627417 46,15 C46,14.3372583 45.4627417,13.8 44.8,13.8 Z"
                id="Path"
                fill="#EA9860"
              />
              <path
                d="M108.8,55.8 C106.811775,55.8 105.2,54.1882251 105.2,52.2 C105.2,51.5372583 104.662742,51 104,51 C103.337258,51 102.8,51.5372583 102.8,52.2 C102.8,54.1882251 101.188225,55.8 99.2,55.8 C98.5372583,55.8 98,56.3372583 98,57 C98,57.6627417 98.5372583,58.2 99.2,58.2 C101.188225,58.2 102.8,59.8117749 102.8,61.8 C102.8,62.4627417 103.337258,63 104,63 C104.662742,63 105.2,62.4627417 105.2,61.8 C105.2,59.8117749 106.811775,58.2 108.8,58.2 C109.462742,58.2 110,57.6627417 110,57 C110,56.3372583 109.462742,55.8 108.8,55.8 Z"
                id="Path-Copy"
                fill="#EA9860"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

CheckoutCompleteIcon1.defaultProps = {
  width: 110,
  height: 100,
  fill: 'currentColor',
};

CheckoutCompleteIcon1.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CheckoutCompleteIcon1;
