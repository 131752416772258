import store from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { changeLanguage, selectLanguage } from 'containers/App/slices';

export const useChangeStore = () => {
  const locale = useSelector(selectLanguage);
  const queryClient = useQueryClient();
  const changeStore = async (language = locale) => {
    const storeConfigs = store.get('store_configs');
    const storeViews = store.get('store_views');
    const nextCode = storeConfigs?.code?.replace(/_(\w+$)/, `_${language}`);
    const nextStore = storeViews?.find(s => s.code === nextCode)?.code ?? 'chn_zh';
    store.set('store_code', nextStore, new Date().getTime() + 86400000);
    // store.remove('store_configs');
    queryClient.clear();
  };
  return changeStore;
};

const useChangeLanguage = () => {
  const dispatch = useDispatch();
  const changeStore = useChangeStore();
  const locale = useSelector(selectLanguage);

  // eslint-disable-next-line consistent-return
  const changeLocalLanguage = async language => {
    try {
      if (locale !== language) {
        await changeStore(language);
        await dispatch(changeLanguage(language));
      }
    } catch (err) {
      return console.error(err.message);
    }
  };
  return changeLocalLanguage;
};

export default useChangeLanguage;
