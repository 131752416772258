export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const DEFAULT_STORE_NAME = 'FitLine';

export const URL_TO_SHOPNOW_TRACKING_APPID_MAP = {
  'mobile.feidekai.cn': '12312367',
  'www.feidekai.cn': '12312367',
  'www.xiamen-centre.com': '12312368',
  'www.southwestsvc.com': '12312366',
  'www.leicairyp.com': '12312365',
  'cd.feidekai.upgrade.alpha.tmogroup.asia': '12312372',
  'mobile.feidekai.upgrade.alpha.tmogroup.asia': '12312371',
  localhost: '12312371',
};
