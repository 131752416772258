/* eslint-disable */
function Icon24FavouriteIdle(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-favourite-idle" fill={fill}>
            <path id="Path" d="M8.712 8.352l-5.354.77-.115.023a1 1 0 0 0-.437 1.686l3.867 3.732-.911 5.266-.014.11a1 1 0 0 0 1.46.948L12 18.393l4.791 2.494.101.046a1 1 0 0 0 1.346-1.104l-.912-5.266 3.868-3.732.08-.085a1 1 0 0 0-.632-1.624l-5.355-.77-2.392-4.798a1 1 0 0 0-1.79 0L8.712 8.352zM12 6.24l1.732 3.473.06.106a1 1 0 0 0 .692.438l3.85.553-2.778 2.682-.084.09a1 1 0 0 0-.207.8l.657 3.798-3.46-1.8-.111-.05a1 1 0 0 0-.813.05l-3.461 1.8.658-3.798.014-.123a1 1 0 0 0-.305-.767L5.665 10.81l3.85-.553a1 1 0 0 0 .753-.544L12 6.24z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24FavouriteIdle;
