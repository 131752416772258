// eslint-disable-next-line import/no-cycle
import { ORDER_STATUS_LIST } from 'containers/OrderListPage/constants';
import { CATALOG_SPECIAL_CATEGORY_LIST } from 'containers/CatalogPage/constants';
import { COUPON_STATUS_LIST } from 'containers/CouponPage/constants';

const routes = {
  home: '/',
  coupons: '/coupons',
  myCouponPage: '/account/my-coupons',
  myCoupons: status =>
    status
      ? `/account/my-coupons/${status}`
      : `/account/my-coupons/:status(all|${COUPON_STATUS_LIST.join('|')})`,
  flashsale: '/flashsale',
  sharingList: '/account/sharingList',
  share: '/account/share',
  logIn: '/login',
  SMLogin: '/social',
  SmCustomer: '/fccustomer',
  SmInvoice: '/fcinvoice',
  forgotPassword: '/forgot-password',
  registration: id => (id ? `/registration/${id}` : '/registration/:id'),
  registerPartner: id => (id ? `/partner-register/${id}` : '/partner-register/:id'),
  catalogEmpty: '/catalog/all',
  catalog: id => (id ? `/catalog/${id}` : '/catalog/:id'),
  catalogSpecial: type =>
    type ? `/catalog/${type}` : `/catalog/:key(${CATALOG_SPECIAL_CATEGORY_LIST.join('|')})`,
  search: query => (query ? `/search/${query}` : '/search/:query'),
  product: urlKey => (urlKey ? `/product/${urlKey}` : '/product/:key'),
  checkout: id => (id ? `/checkout/${id}` : '/checkout'),
  pay: '/pay',
  success: id => (id ? `/checkout/success/${id}` : '/checkout/success/:id'),
  offlineSuccess: id => (id ? `/checkout/offlineSuccess/${id}` : '/checkout/offlineSuccess/:id'),
  dashboard: '/account/dashboard',
  ordersAll: '/account/orders',
  orders: status =>
    status
      ? `/account/orders/${status}`
      : `/account/orders/:status(all|${ORDER_STATUS_LIST.join('|')})`,
  orderDetail: id => (id ? `/account/order/${id}` : '/account/order/:id'),
  orderInvoice: orderId =>
    orderId ? `/account/order/invoice/${orderId}` : '/account/order/invoice/:orderId',
  invoiceRecords: '/account/invoice/records',
  invoiceApplication: orderId =>
    orderId ? `/account/invoice/application/${orderId}` : '/account/invoice/application/:orderId',
  applyInvoiceSuccess: orderId =>
    orderId
      ? `/account/invoice/application/success/${orderId}`
      : '/account/invoice/application/success/:orderId',
  invoiceDetail: id => (id ? `/account/invoice/${id}` : '/account/invoice/:id'),
  addressBook: '/account/address-book',
  fapiao: '/account/fapiao',
  wishlist: '/account/wishlist',
  userProfile: '/account/user-profile',
  refundApplication: '/refund/application',
  refundDelivery: '/refund/delivery',
  refundDetail: '/refund/detail',
  blog: '/blog',
  // FCRegister: '/fc-register',
  FCMaterial: email => (email ? `/fc-register/material/${email}` : '/fc-register/material/:id'),
  membership: '/membership',
  blogDetailPage: id => (id ? `/blog/${id}` : '/blog/:id'),
  cms: id => (id ? `/cms/${id}` : '/cms/:id'),
  shoppingCart: '/shopping-cart',
  idRegister: '/id-register',
  joinUs: '/join-us',
  privacy: '/privacy',
  privacyPolicy: '/privacy/policy',
  exportAccount: '/privacy/export',
  destroyAccount: '/privacy/estroy',
  rma: '/rma',
  icons: '/icons',
  notFound: '',
};

export const protectedRoutes = [
  routes.checkout,
  routes.dashboard,
  routes.ordersAll,
  routes.orders,
  routes.orderDetail,
  routes.profile,
  routes.notifications,
  routes.addressBook,
  routes.myCouponPage,
  routes.refundApplication,
  routes.refundDetail,
  routes.share,
  routes.sharingList,
  routes.brandEmbassador,
  routes.statistics,
  routes.success,
  routes.upgrade,
  routes.refundDelivery,
  routes.orderInvoice,
];

export default routes;
