import PropTypes from 'prop-types';

function MedalIcon(props) {
  const fill = props.fill || '#A7A9AC';
  const secondaryFill = props.secondaryFill || '#CD0039';
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>crown</title>
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard---FC-wCredit" transform="translate(-30.000000, -104.000000)">
          <g id="Group" transform="translate(16.000000, 88.000000)">
            <g id="crown" transform="translate(14.000000, 16.000000)">
              <path
                d="M35.5728287,3.94741407 C35.2955531,3.80856629 34.9618181,3.84409232 34.720639,4.03812951 L26.730088,10.5153673 L18.6151127,0.280804374 C18.4613828,0.102604967 18.236635,0 18.0000336,0 C17.7634322,0 17.5386844,0.102604967 17.3849545,0.280804374 L9.26997914,10.5153673 L1.27942823,4.03812951 C1.0387504,3.84213849 0.703664587,3.80626352 0.426286435,3.94679042 C0.148908283,4.08731732 -0.0179200695,4.3774749 0.00153481859,4.68554315 L1.43437183,28.086251 C1.5367881,29.7222669 2.90469246,30.9978247 4.55906773,31 L31.4409995,31 C33.0953747,30.9978247 34.4632791,29.7222669 34.5656954,28.086251 L35.9985324,4.68554315 C36.0175548,4.37748068 35.8503578,4.08757721 35.5728287,3.94741407 Z"
                id="Path"
                fill={fill}
              />
              <polygon
                id="Path"
                fill={secondaryFill}
                points="1.09546573 25 34.9045343 25 35 23 1 23"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

MedalIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MedalIcon;
