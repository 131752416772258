import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import {
  changeGuideDialogOpen,
  changeSpecialDialogOpen,
  changeSpecialVendors as changeStoreSpecialVendors,
  selectGuideDialogOpen,
  selectSpecialDialogOpen,
  selectStringSpecialVendors,
} from 'containers/CatalogPage/slices';
import { useGetSpecialProducts } from 'containers/ProductPage/queries';
import { toggle } from 'utils/helpers';
import useGetVendorCode from 'utils/Hooks/useGetVendorCode';
import { CART_QUERY_KEY, useBatchAddToCart } from 'containers/CartProvider/queries';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import catalogMessages from 'containers/CatalogPage/messages';
import useDeepCompareMemo from 'utils/Hooks/updateEffect/useDeepCompareMemo';

const useSpecial = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const guideOpen = useSelector(selectGuideDialogOpen);
  const specialOpen = useSelector(selectSpecialDialogOpen);
  const storeSpecialVendors = useSelector(selectStringSpecialVendors);
  const vendorCode = useGetVendorCode();
  const notify = useNotificationSnackbar();
  const { mutateAsync: batchAddToCart, isLoading } = useBatchAddToCart();
  const { data: specialProducts = [] } = useGetSpecialProducts(storeSpecialVendors);

  const [selected, setSelected] = useState([]);

  const checked = useDeepCompareMemo(() => selected.map(item => item.id), [selected]);

  const toggleGuide = flag => dispatch(changeGuideDialogOpen(flag));

  const toggleSpecial = flag => dispatch(changeSpecialDialogOpen(flag));

  const changeSpecial = product => {
    const values = selected.map(item =>
      item.id === product.id
        ? {
            ...item,
            qty: product.qty,
          }
        : item,
    );

    setSelected(values);
  };

  const selectedSpecial = product => {
    setSelected(toggle(selected, product, 'id'));
  };

  const changeSpecialVendors = (product = {}, key = '') => {
    const specialVendors = key ? get(product, key, []) : product;
    const { related_products: relatedProducts } =
      specialVendors.find(vendor => vendor.vendor_code === vendorCode) ?? {};

    dispatch(changeStoreSpecialVendors(relatedProducts ? JSON.parse(relatedProducts) : []));
  };

  const addToCart = async (products = selected) => {
    try {
      const cartItems = products.map(item => ({
        sku: item.sku,
        qty: item.qty,
      }));

      await batchAddToCart({
        cartItems,
        vendorCode,
        isKeep: 1,
        is_standard_sets: 1,
      });

      await queryClient.refetchQueries([CART_QUERY_KEY.GET]);

      notify(intl.formatMessage(catalogMessages.addToCartSuccess), 'success');
    } catch (err) {
      notify(intl.formatMessage(catalogMessages.addToCartFailed), 'warn');
    }
  };

  return {
    selected,
    checked,
    guideOpen,
    specialOpen,
    specialProducts,
    loading: isLoading,
    toggleGuide,
    toggleSpecial,
    addToCart,
    changeSpecial,
    selectedSpecial,
    changeSpecialVendors,
  };
};

export default useSpecial;
