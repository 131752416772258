import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import { getAuthorization } from 'utils/Auth';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import routes from 'utils/routes';
import { toggleMPLoginVisible } from 'containers/App/slices';
import AnimationShowElement from 'components/Animation/AnimationShowElement';

const blackList = [
  routes.orders(),
  routes.orderDetail(),
  routes.addressBook,
  routes.ordersAll,
  routes.fapiao,
  routes.orderInvoice(),
  routes.wishlist,
  routes.userProfile,
  routes.myCouponPage,
  routes.membership,
];

const RequireAuthentication = WrappedComponent => {
  const Child = ({ quoteID, ...other }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const black = useMemo(
      () => blackList.find(item => history?.location?.pathname.includes(item)),
      [],
    );

    const auth = getAuthorization();
    if (typeof auth.token !== 'undefined' && auth.type === 'user') {
      return isUndefined(black) ? (
        <AnimationShowElement initialTransform>
          <WrappedComponent {...other} />
        </AnimationShowElement>
      ) : (
        <WrappedComponent {...other} />
      );
    }

    if (determineEnvironment()) {
      dispatch(toggleMPLoginVisible(true));
      return <Redirect to={routes.home} />;
    }

    return <Redirect to={routes.logIn} />;
  };

  Child.propTypes = {
    quoteID: PropTypes.number,
    toggleMPLoginVisible: PropTypes.func,
  };

  return Child;
};

RequireAuthentication.propTypes = {
  WrappedComponent: PropTypes.element.isRequired,
};

export default RequireAuthentication;
