/**
 *
 * MainNavigation
 *
 */

import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Hidden from '@material-ui/core/Hidden';
import MiniCart from 'containers/CartProvider';
import routes from 'utils/routes';
import {
  toggleMiniCart,
  selectShowMiniCart,
  selectRouterLocation,
  toggleMPLoginVisible,
} from 'containers/App/slices';
import { getAuthorization } from 'utils/Auth';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { HomeIcon, CatalogIcon, BlogIcon, AccountIcon } from 'components/Icons';
import appMessages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    top: 'auto',
    bottom: 0,
    padding: 0,
    boxShadow: 'none',
  },
  toolbar: {
    padding: 0,
    paddingBottom: `calc(${theme.mixins.toolbar.safeAreaInsetBottom})`,
    background: '#fff',
    borderTop: `2px solid ${theme.palette.background.peach}`,
  },
  nav: {
    width: '100%',
    height: theme.mixins.toolbar.appBarHeight,
    '& > button': {
      width: '20%',
      boxSizing: 'border-box',
    },
  },
  icon: {
    marginBottom: 0,
  },
  button: {
    marginTop: -2,
    padding: theme.spacing(1.25, 1, 0.5),
    minWidth: 0,
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    '&.Mui-selected': {
      paddingTop: theme.spacing(1.25),
    },
  },
}));

const navigationLocations = [
  { url: routes.home, icon: HomeIcon, message: appMessages.home, dataTestId: 'nav-home' },
  {
    url: routes.catalog('all'),
    icon: CatalogIcon,
    message: appMessages.category,
    dataTestId: 'nav-catalog',
  },
  {
    url: routes.blog,
    icon: BlogIcon,
    message: appMessages.sharing,
    dataTestId: 'nav-blog',
  },
  {
    component: MiniCart,
    message: appMessages.cart,
    dataTestId: 'nav-cart',
  },
  {
    url: routes.dashboard,
    icon: AccountIcon,
    message: appMessages.account,
    dataTestId: 'nav-account',
  },
];

function MainNavigation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useSelector(selectRouterLocation);
  const showMiniCart = useSelector(selectShowMiniCart);
  const history = useHistory();

  const [value, setValue] = useState(0);

  const locationIndex = useCallback(() => {
    if (location.pathname.includes('catalog')) {
      return 1;
    }

    const accountArray = ['/login', '/login/otp', '/registration', '/account/dashboard'];
    if (accountArray.includes(location.pathname)) {
      return 4;
    }

    const index = navigationLocations.findIndex(l => location.pathname === l.url);

    return index === -1 ? 999 : index;
  }, [location.pathname]);

  useEffect(() => {
    setValue(() => locationIndex());
  }, [location]);

  useEffect(() => {
    if (showMiniCart) {
      setValue(999);
    } else {
      setValue(() => locationIndex());
    }
  }, [showMiniCart]);

  const handleNavigation = (event, index) => {
    const auth = getAuthorization();

    if (index === 4 && determineEnvironment() && auth.type === 'guest') {
      // const param = quoteID ? `?cartID=${quoteID}` : '';
      // eslint-disable-next-line no-undef
      // wx.miniProgram.navigateTo({
      //   url: `/pages/login/login${param}`,
      // });
      // dispatch(push(navigationLocations[index].url));
      dispatch(toggleMPLoginVisible(true));
      // history.push(routes.dashboard);
    } else {
      history.push(navigationLocations[index].url);
      dispatch(toggleMiniCart(false));
    }
  };

  return (
    <Hidden mdUp>
      <AppBar position="fixed" color="default" component="nav" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <BottomNavigation
            value={value}
            onChange={handleNavigation}
            showLabels
            className={classes.nav}
          >
            {navigationLocations.map((l, index) => {
              const Component = l.component || BottomNavigationAction;
              return (
                <Component
                  className={classes.button}
                  key={index.toString()}
                  label={<FormattedMessage {...l.message} />}
                  icon={
                    <SvgIcon
                      component={l.icon}
                      color={value === index ? 'primary' : 'inherit'}
                      className={classes.icon}
                      fontSize="large"
                    />
                  }
                  data-test-id={l.dataTestId}
                />
              );
            })}
          </BottomNavigation>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

export default memo(MainNavigation);
