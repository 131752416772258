import { createSlice } from '@reduxjs/toolkit';

export const catalogState = createSlice({
  name: 'catalog',
  initialState: {
    cartBallLocation: {},
    specialDialogOpen: false,
    guideDialogOpen: false,
    specialVendors: [],
    specialProductVendors: [],
  },
  reducers: {
    changeCartBallLocation: (state, action) => {
      state.cartBallLocation = action.payload;
    },
    changeSpecialDialogOpen: (state, action) => {
      state.specialDialogOpen = action.payload;
    },
    changeGuideDialogOpen: (state, action) => {
      state.guideDialogOpen = action.payload;
    },
    changeSpecialVendors: (state, action) => {
      state.specialVendors = action.payload;
    },
    changeSpecialProductVendors: (state, action) => {
      state.specialProductVendors = action.payload;
    },
  },
});

export const {
  changeCartBallLocation,
  changeSpecialDialogOpen,
  changeGuideDialogOpen,
  changeSpecialVendors,
  changeSpecialProductVendors,
} = catalogState.actions;

export const getCartBallLocation = state => state.catalog.cartBallLocation;
export const selectSpecialDialogOpen = state => state.catalog.specialDialogOpen;
export const selectGuideDialogOpen = state => state.catalog.guideDialogOpen;
export const selectSpecialVendors = state => state.catalog.specialVendors;
export const selectStringSpecialVendors = state => state.catalog.specialVendors.join();
export const selectSpecialProductVendors = state => state.catalog.specialProductVendors;
export const selectStringSpecialProductVendors = state =>
  state.catalog.specialProductVendors.join();

export default catalogState.reducer;
