import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGetCustomer } from 'containers/UserProfile/queries';
import { getAuthorization } from 'utils/Auth';

export const useAccess = () => {
  const { data: customer, isLoading: loading } = useGetCustomer();
  const isDCAndFC = [3, 4].includes(customer?.group_id);
  const auth = getAuthorization();
  const authorization = useCallback(
    rules => rules.includes(customer?.group_id || auth?.type),
    [customer?.group_id, auth?.type],
  );
  return {
    loading,
    showPv: isDCAndFC,
    canUsePoints: isDCAndFC,
    authorization,
  };
};

const Access = props => {
  const { accessible, fallback, children } = props;
  return <>{accessible ? children : fallback}</>;
};
Access.propTypes = {
  accessible: PropTypes.bool.isRequired,
  fallback: PropTypes.node,
  children: PropTypes.node,
};
export default Access;
