/**
 *
 * ConfirmRemoval
 *
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import appMessages from 'containers/App/messages';
import Buttons from 'components/Buttons/Buttons';
import FormatMessage from 'components/FormatMessage';
import messages from 'containers/CartProvider/messages';
import { FitLineBrandColorRed } from '../../muiTheme';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
    },
    '& .MuiPaper-root': {
      width: ({ width }) => width,
    },
  },
  assistant: {
    padding: 0,
    minHeight: 'auto',
  },
}));

function ConfirmDialog(props) {
  const {
    open,
    onClose,
    onAssistant,
    onConfirm,
    loading,
    title,
    renderContent,
    confirmBtnMsg,
    assistantBtnMsg,
    width,
    children,
    ...other
  } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      open={open}
      classes={{ container: classes.dialogContainer }}
      onClose={onClose}
      {...other}
    >
      <>
        <Box style={{ textAlign: 'center', padding: '2rem 0 0' }}>
          <Typography variant="h1">
            {title && title !== '' ? title : <FormattedMessage {...messages.deleteItem} />}
          </Typography>
        </Box>
        <Box px={2} py={1} textAlign="center" color="divider">
          {children}
        </Box>
        <Box p={2} pb={onAssistant ? 3 : 4} className="dialog-buttons">
          {onConfirm && (
            <Box>
              <Buttons
                messageId={confirmBtnMsg}
                onClick={onConfirm}
                loading={loading}
                disabled={loading}
                variant="contained"
                color="primary"
              />
            </Box>
          )}
          {onAssistant && (
            <Box pt={3}>
              <Buttons
                style={{ width: '100%', color: FitLineBrandColorRed }}
                disabled={loading}
                messageId={assistantBtnMsg}
                onClick={onAssistant}
                className={classes.assistant}
              />
            </Box>
          )}
        </Box>
      </>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  loading: false,
  confirmBtnMsg: <FormatMessage message={appMessages.confirm} variant="body2" bold={700} />,
  assistantBtnMsg: <FormatMessage message={appMessages.cancel} variant="body2" bold={700} />,
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onAssistant: PropTypes.any,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  renderContent: PropTypes.func,
  confirmBtnMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  assistantBtnMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

export default memo(ConfirmDialog);
