/* eslint-disable */
function Icon16Review(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/16/16-review" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Combined-Shape" d="M13.833 2C14.478 2 15 2.488 15 3.09v7.092c0 .602-.522 1.09-1.167 1.09h-3.208L8 14l-2.625-2.727H2.167c-.645 0-1.167-.489-1.167-1.091V3.09C1 2.488 1.522 2 2.167 2zM8 3.5l-.75 2.286H5l1.804 1.395L6.062 9.5 8 8.07 9.938 9.5l-.742-2.32L11 5.787H8.75L8 3.5z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon16Review;
