export const getImageSize = (image, size = 220) => {
  const dpr = window.devicePixelRatio;
  const aspectRatio = image.width / image.height;
  let w;
  let h;
  if (aspectRatio > 1) {
    w = Math.min(image.width / dpr, size);
    h = w / aspectRatio;
  } else {
    h = Math.min(image.height / dpr, size);
    w = h * aspectRatio;
  }

  return { w, h };
};

export const createImage = (url, size, isTimestamp) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      if (size) {
        const { w, h } = getImageSize(image);
        return resolve({ image, w, h });
      }
      resolve({ image });
      return null;
    };
    image.onerror = err => {
      reject(err);
    };
    image.src = isTimestamp ? `${url}?time=${new Date().valueOf()}` : url;
  });
