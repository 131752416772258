import { motion, AnimatePresence } from 'framer-motion';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

const customVariants = {
  container: {
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
        staggerChildren: 0.1,
        when: 'beforeChildren',
      },
    },
  },
  item: {
    hidden: { x: 50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  },
};

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
    transition: 'all .3s',
    '& li': {
      listStyle: 'none',
    },
  },
});

const VariantList = forwardRef(({ list, children, className, variants = customVariants }, ref) => {
  const classes = useStyles();

  if (isEmpty(list)) {
    return null;
  }

  return (
    <motion.ul
      variants={variants.container}
      initial="hidden"
      whileInView="visible"
      exit="hidden"
      viewport={{ once: true }}
      className={clsx(className, classes.root)}
      ref={ref}
    >
      <AnimatePresence exitBeforeEnter>
        {list?.map((item, i) => (
          <motion.li custom={i} key={item.id} variants={variants.item}>
            {children(item)}
          </motion.li>
        ))}
      </AnimatePresence>
    </motion.ul>
  );
});

VariantList.propTypes = {
  list: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  variants: PropTypes.object,
};

export default VariantList;
