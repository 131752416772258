/* eslint-disable */
function Icon16SignOut(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/16/16-sign-out" fill={fill}>
            <path id="Combined-Shape" d="M9.1 1c.54 0 .9.35.9.875V4.5H8.2V2.75H4.06l2.97 2.888c.18.175.27.35.27.612v7.875c0 .35-.18.7-.54.787C6.67 15 6.49 15 6.4 15a.828.828 0 0 1-.63-.262l-4.5-4.375c-.18-.175-.27-.35-.27-.613V1.875C1 1.35 1.36 1 1.9 1zM2.8 3.975V9.4l2.7 2.625V6.6L2.8 3.975zM11.5 4l3.238 3.364c.35.363.35.909 0 1.272L11.5 12l-1.225-1.273 1.75-1.818H8V7.091h4.025l-1.75-1.818L11.5 4z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon16SignOut;
