/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import notificationReducer from 'utils/Notifications/reducer';
import authorizationReducer from 'containers/AuthorizationProvider/slices';
import cartReducer from 'containers/CartProvider/slices';
import checkoutReducer from 'containers/CheckoutPage/slices';
import appReducer from 'containers/App/slices';
import catalogReducer from 'containers/CatalogPage/slices';
import homeReducer from 'containers/HomePage/slices';
import storeReducer from 'containers/StoreProvider/slices';
import oldIdReducer from 'containers/OldIDRegister/slices';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return {
    app: appReducer,
    router: connectRouter(history),
    notifications: notificationReducer,
    authorization: authorizationReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    catalog: catalogReducer,
    home: homeReducer,
    store: storeReducer,
    oldId: oldIdReducer,
    ...injectedReducers,
  };
}
