/**
 *
 * Asynchronously loads the component for AddressBook
 *
 */

import loadable from 'utils/loadable';

export const OrderInvoice = loadable(() => import('./OrderInvoice'));
export const InvoiceApplication = loadable(() => import('./InvoiceApplication'));
export const InvoiceRecords = loadable(() => import('./InvoiceRecords'));
export const InvoiceApplicationSuccess = loadable(() => import('./ApplicationSuccess'));
export const InvoiceDetail = loadable(() => import('./InvoiceDetail'));

export default loadable(() => import('./index'));
