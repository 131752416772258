import PropTypes from 'prop-types';

function EmptyAddressIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="Address-Book---Empty" transform="translate(-137.000000, -220.000000)">
          <g id="Group" transform="translate(137.000000, 220.000000)">
            <path
              d="M37,17 L37,87.339379 L36.9128541,87.3100356 L2.70359,95.9346192 C2.52929824,95.9785575 2.35287353,96 2.17647059,96 C1.69612353,96 1.22424294,95.8392584 0.837440588,95.5349161 C0.308188235,95.1191038 0,94.4814926 0,93.8052763 L0,93.8052763 L0,27.9635665 C0,26.9562322 0.680125294,26.0785403 1.64935118,25.8342236 L1.64935118,25.8342236 L36.4728806,17.055329 C36.6454529,17.0113906 36.8230529,17 37,17 L37,17 Z M97.29641,17.0665642 C97.9446718,16.9004698 98.6333288,17.0494236 99.1625594,17.4662837 C99.6918118,17.8820902 100,18.5196925 100,19.1958994 L100,19.1958994 L100,85.0366954 C100,86.0440157 99.3198094,86.9216955 98.3506488,87.1660307 L98.3506488,87.1660307 L63.5271194,95.9448035 C63.3548082,95.9887412 63.1770776,96 63,96 L63,96 L63,25.661707 L63.0871459,25.6910281 Z"
              id="Combined-Shape"
              fill="#F8F9F9"
            />
            <path
              d="M37.685555,17.1024849 C37.463125,17.0275912 37.2318117,17 37,17 L37,87.3484692 L62.314445,95.8976688 C62.535835,95.9726722 62.7675167,96 63,96 L63,25.6516844 L37.685555,17.1024849 Z"
              id="Path"
              fill="#CECECE"
            />
            <path
              d="M50,0 C63.2547855,0 74,10.779787 74,24.0774149 C74,38.4422195 55.8269164,56.9778234 51.1084291,61.5483515 C50.4867418,62.1505495 49.5132582,62.1505495 48.8915709,61.5483515 C44.1730836,56.9778234 26,38.4422195 26,24.0774149 C26,10.779787 36.7452145,0 50,0 Z M50.5,16 C45.8055796,16 42,19.8055796 42,24.5 C42,29.1944204 45.8055796,33 50.5,33 C55.1944204,33 59,29.1944204 59,24.5 C59,19.8055796 55.1944204,16 50.5,16 Z"
              id="Combined-Shape"
              fill="#A7A9AC"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

EmptyAddressIcon.defaultProps = {
  width: 100,
  height: 96,
  fill: 'currentColor',
};

EmptyAddressIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmptyAddressIcon;
