import PropTypes from 'prop-types';

function PendingPaymentIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="Order-Detail-Page---Unpaid" transform="translate(-48.000000, -80.000000)">
          <g id="Group-27" transform="translate(0.000000, 64.000000)">
            <g id="Group-23" transform="translate(48.000437, 16.000000)">
              <path
                d="M39.0735631,31.565 L19.0775631,30.003 C18.9095631,29.991 18.7455631,30.019 18.5935631,30.086 L0.593563062,38.086 C0.123563062,38.295 -0.111436938,38.827 0.0515630622,39.316 L0.930563062,41.955 C1.73756306,44.374 3.88956306,46.143 6.41456306,46.462 C10.9645631,47.037 19.4295631,48 24.9995631,48 C29.0495631,48 32.8135631,46.452 35.5975631,43.641 C38.4745631,40.736 40.0365631,36.798 39.9955631,32.553 C39.9905631,32.035 39.5905631,31.606 39.0735631,31.565 Z"
                id="Path"
                fill="#F3C7A8"
                fillRule="nonzero"
                transform="translate(19.998175, 39.000148) scale(-1, 1) translate(-19.998175, -39.000148) "
              />
              <g id="Group-22" transform="translate(13.999563, 0.000000)">
                <path
                  d="M27,0 L5,0 C2.23994685,0.00330611633 0.00330611633,2.23994685 0,5 L0,41 C0.00330611633,43.7600532 2.23994685,45.9966939 5,46 L27,46 C29.7600532,45.9966939 31.9966939,43.7600532 32,41 L32,5 C31.9966939,2.23994685 29.7600532,0.00330611633 27,0 Z M30,38 C30,38.5522847 29.5522847,39 29,39 L3,39 C2.44771525,39 2,38.5522847 2,38 L2,6 C2,5.44771525 2.44771525,5 3,5 L29,5 C29.5522847,5 30,5.44771525 30,6 L30,38 Z"
                  id="Shape"
                  fill="#444444"
                  fillRule="nonzero"
                />
                <path
                  d="M30,6 L30,38 C30,38.5522847 29.5522847,39 29,39 L3,39 C2.44771525,39 2,38.5522847 2,38 L2,6 C2,5.44771525 2.44771525,5 3,5 L29,5 C29.5522847,5 30,5.44771525 30,6 Z"
                  id="Path"
                  fill="#82ADB2"
                  fillRule="nonzero"
                />
                <polygon
                  id="¥"
                  fill="#FFFFFF"
                  points="19.921875 34 19.921875 32.03125 17.375 31.59375 17.375 28.90625 23.359375 28.90625 23.359375 27 17.375 27 17.375 24.390625 23.359375 24.390625 23.359375 22.46875 18.484375 22.46875 23.6875 13.546875 25.859375 13.234375 25.859375 11.25 18.96875 11.25 18.96875 13.234375 20.3125 13.46875 15.890625 21.609375 11.46875 13.46875 12.828125 13.234375 12.828125 11.25 5.921875 11.25 5.921875 13.234375 8.09375 13.5625 13.296875 22.46875 8.4375 22.46875 8.4375 24.390625 14.296875 24.390625 14.296875 27 8.4375 27 8.4375 28.90625 14.296875 28.90625 14.296875 31.59375 11.75 32.03125 11.75 34"
                />
              </g>
              <g id="Group-21" transform="translate(0.000743, 18.000000)" fillRule="nonzero">
                <path
                  d="M29.9960439,2.05 C29.6640439,2.028 29.3330439,2 28.9960439,2 L14.9960439,2 C14.4440439,2 13.9960439,2.448 13.9960439,3 C13.9960439,6.309 16.6870439,9 19.9960439,9 L22.9960439,9 C23.3780439,12.45 26.7390439,15.967 29.9960439,17.365 L29.9960439,2.05 Z"
                  id="Path"
                  fill="#000000"
                  opacity="0.1"
                  transform="translate(21.996044, 9.682500) scale(-1, 1) translate(-21.996044, -9.682500) "
                />
                <path
                  d="M15,0 L1,0 C0.448,0 0,0.448 0,1 C0,4.309 2.691,7 6,7 L10.056,7 C10.547,11.436 14.21,16 19,16 C19.015,16 19.029,16 19.044,15.999 L29.04,15.561 C29.578,15.538 30.001,15.091 29.9960439,14.552 C29.918,6.528 23.191,0 15,0 Z"
                  id="Path"
                  fill="#F3C7A8"
                  transform="translate(14.998022, 8.000000) scale(-1, 1) translate(-14.998022, -8.000000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PendingPaymentIcon.defaultProps = {
  width: 46,
  height: 48,
  fill: 'currentColor',
};

PendingPaymentIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PendingPaymentIcon;
