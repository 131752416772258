import { useRef, useState } from 'react';
import useDeepCompareMemo from 'utils/Hooks/updateEffect/useDeepCompareMemo';
import useDeepCompareEffect from 'utils/Hooks/updateEffect/useDeepCompareEffect';
import { timeSleep } from 'utils/helpers';

const useSideSwiper = ({ sideCount, sideSize: propSideSize }) => {
  const [swiperRect, setSwiperRect] = useState({});
  const [containerRect, setContainerRect] = useState({});
  const [sideRect, setSideRect] = useState({});
  const [step, setStep] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [showTrigger, setShowTrigger] = useState(false);

  const swiperRef = useRef(null);
  const containerRef = useRef(null);
  const sideRef = useRef(null);

  useDeepCompareEffect(() => {
    if (sideCount * sideRect.width >= containerRect.width) {
      setShowTrigger(true);
    }
  }, [sideCount, sideRect, containerRect]);

  const { containerSize, sideSize } = useDeepCompareMemo(
    () => ({
      swiperSize: swiperRect.width ?? 0,
      containerSize: containerRect.width ?? 0,
      sideSize: propSideSize ?? sideRect.width ?? 0,
    }),
    [swiperRect, containerRect, sideRect, propSideSize],
  );

  useDeepCompareEffect(() => {
    if (sideCount) {
      setSwiperRect(() => swiperRef.current?.getBoundingClientRect() ?? {});
      setContainerRect(() => containerRef.current?.getBoundingClientRect() ?? {});
      timeSleep().then(() => setSideRect(() => sideRef.current?.getBoundingClientRect() ?? {}));
    }
  }, [sideCount, swiperRef, containerRef, sideRef]);

  const scroll = space => {
    const swiperTarget = swiperRef.current;

    if (!swiperTarget) return;

    swiperTarget.style.transform = `translateX(${space}px)`;
  };

  const onPrev = event => {
    event.stopPropagation();
    let count = step;
    // eslint-disable-next-line no-plusplus
    count--;
    const space = count * sideSize;

    setStep(count);
    setNextDisabled(false);
    setPrevDisabled(count <= 0);

    scroll(-space);
  };

  const onNext = event => {
    event.stopPropagation();
    let count = step;
    // eslint-disable-next-line no-plusplus
    count++;
    const space = count * sideSize;

    const maxOffsetX = sideCount * sideSize - (space + containerSize) < 0;
    setNextDisabled(maxOffsetX);
    setPrevDisabled(false);
    setStep(count);

    scroll(-space);
  };

  return {
    sideRef,
    swiperRef,
    sideRect,
    swiperRect,
    containerRect,
    containerRef,
    prevDisabled,
    nextDisabled,
    showTrigger,
    onPrev,
    onNext,
  };
};

export default useSideSwiper;
