/**
 *
 * SharingShoppingDialog
 *
 */

import PropTypes from 'prop-types';
import SharingShoppingContent from './SharingShoppingContent';
import SharingDialog from './SharingDialog';

function SharingShoppingDialog({ open, onClose, dialogTitle, ...other }) {
  return (
    <SharingDialog open={open} onClose={onClose} dialogTitle={dialogTitle}>
      <SharingShoppingContent {...other} />
    </SharingDialog>
  );
}

SharingShoppingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SharingShoppingDialog;
