/* eslint-disable */
function Icon24Location(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width}  viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>2.Icons/24/24-location</title>
        <g id="2.Icons/24/24-location" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M12,2 C8.08888889,2 4,4.95652174 4,9.82608696 C4,14.4347826 11.1111111,21.4782609 11.3777778,21.7391304 C11.5555556,21.9130435 11.7333333,22 12,22 C12.2666667,22 12.4444444,21.9130435 12.6222222,21.7391304 C12.8888889,21.4782609 20,14.4347826 20,9.82608696 C20,4.95652174 15.9111111,2 12,2 Z M12,12 C10.5833333,12 9.5,10.9166667 9.5,9.5 C9.5,8.08333333 10.5833333,7 12,7 C13.4166667,7 14.5,8.08333333 14.5,9.5 C14.5,10.9166667 13.4166667,12 12,12 Z" id="Shape" fill={fill} ></path>
        </g>
    </svg>
  );
}

export default Icon24Location;
