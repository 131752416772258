import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteBuyNow, getBuyNow, setBuyNow } from 'utils/Checkout';
import { getAuthorization } from 'utils/Auth';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import routes from 'utils/routes';
import { toggleMPLoginVisible } from 'containers/App/slices';
import {
  useClearCheckoutQuote,
  getCheckoutQuote,
  useGetCheckoutQuote,
  useAddToCheckoutQuote,
} from 'containers/CheckoutPage/queries';

const useBuyNow = () => {
  const dispatch = useDispatch();
  const { mutateAsync: clearCheckoutQuote } = useClearCheckoutQuote();
  const { mutateAsync: addToCheckoutQuote } = useAddToCheckoutQuote();
  const { mutateAsync: getCheckoutQuoteAsync } = useGetCheckoutQuote();
  const history = useHistory();

  const processBuyNow = async payload => {
    const auth = getAuthorization();

    if (auth.type !== 'user') {
      setBuyNow(payload.data);

      if (determineEnvironment()) {
        dispatch(toggleMPLoginVisible(true));
      } else {
        history.push(routes.logIn);
      }
    } else {
      await clearCheckoutQuote();

      // The data structures are different between guest and user
      payload.cartItem = payload.cartItem || payload.data.cartItem;
      const quote = await getCheckoutQuoteAsync();
      payload.cartItem.quote_id = quote.id;

      await addToCheckoutQuote(payload);
      await getCheckoutQuote();

      history.push(routes.checkout());
    }
  };

  const buyNow = async (payload, callback) => {
    try {
      if (payload) {
        await processBuyNow(payload);
      } else if (getBuyNow()) {
        await processBuyNow(getBuyNow());
        deleteBuyNow();
      }
    } catch (err) {
      if (callback) callback(err);
    }
  };
  return buyNow;
};

export default useBuyNow;
