/* eslint-disable import/no-import-module-exports */
/* eslint-disable no-console */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser/esm';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import { LastLocationProvider } from 'react-router-last-location';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MuiThemeProvider } from '@material-ui/core/styles';
import vhCheck from 'vh-check';
import axiosErrorInterceptor from 'utils/Auth/axiosErrorIntercepter';
import history from 'utils/history';
// Import root app
import App from 'containers/App';
// Sentry error reporting boundary
import ErrorBoundary from 'utils/Sentry/ErrorBoundary';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
// Import FFClanPro Typeface
import './fonts/index.css';
// Load the favicon
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!file-loader?name=[name].[ext]!public/favicon.ico';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!file-loader?name=[name].[ext]!public/cu6bWfqhj4.txt';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!file-loader?name=[name].[ext]!public/f17gLgxqj8.txt';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!file-loader?name=[name].[ext]!public/MP_verify_9gKhIqDvRv37xGLZ.txt';
import { actions as notificationActions } from 'utils/Notifications/actions';
import initWechatLoginToken from 'utils/WeChat/initWechatLoginToken';
import store from './store';
// Import i18n messages
import { translationMessages } from './i18n';
// Import Material UI custom theme
import theme from './muiTheme';
import reportWebVitals from './reportWebVitals';

// Add support for reliable CSS vh sizes
vhCheck();

// Holds Helmet state specific to each request
const helmetContext = {};

(() => {
  const { hash } = window.location;
  const [type, vendorCode] = hash.split('-');
  if (type === '#setVendorCode' && vendorCode) {
    localStorage.setItem('vendorCode', vendorCode);
  }
})();

// Create redux store with history

export const queryClient = new QueryClient();
window.queryClient = queryClient;
initWechatLoginToken();

axiosErrorInterceptor(store, queryClient);
const MOUNT_NODE = document.getElementById('app');

// Initialize Sentry error reporting
if (process.env.PROJECT_ENV === 'staging' || process.env.PROJECT_ENV === 'production') {
  Sentry.init({
    dsn: `https://${process.env.SENTRY_DSN_TOKEN}@${process.env.SENTRY_DSN_URL}/${process.env.SENTRY_DSN_PROJECT}`,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        // hint.xhr is a whole XHR object that you can use to modify breadcrumb
        if (hint.xhr.response) {
          breadcrumb.data.response = hint.xhr.response?.slice?.(0, 200);
        }
        return { ...breadcrumb };
      }
      return breadcrumb;
    },
    environment: `${process.env.PROJECT_ENV} - mobile`,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    debug: true,
  });
}

if (window.location.hash.includes('openid=')) {
  const openid = window.location.hash.split('openid=')[1];
  console.log('set openid', openid);
  if (openid) {
    localStorage.setItem('openid', openid);
    Sentry.configureScope(scope => {
      scope.setTag('openid', openid);
    });
    window.history.replaceState(null, null, window.location.pathname + window.location.search);
  }
}

const render = messages => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <HelmetProvider context={helmetContext}>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <LastLocationProvider>
                <MuiThemeProvider theme={theme}>
                  <ErrorBoundary queryClient={queryClient}>
                    <App />
                  </ErrorBoundary>
                </MuiThemeProvider>
              </LastLocationProvider>
            </ConnectedRouter>
          </LanguageProvider>
        </Provider>
      </HelmetProvider>
      {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>,
    MOUNT_NODE,
  );
};

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise(resolve => {
//     resolve(import('intl'));
//   })
//     .then(() =>
//       Promise.all([import('intl/locale-data/jsonp/en'), import('intl/locale-data/jsonp/zh')]),
//     )
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     });
// } else {
render(translationMessages);
// }

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          console.log('SW registered: ', registration);
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the updated precached content has been fetched,
                  // but the previous service worker will still serve the older
                  // content until all client tabs are closed.
                  console.log(
                    'New content is available and will be used when all ' +
                      'tabs for this page are closed. See https://cra.link/PWA.',
                  );

                  // Execute callback
                  store.dispatch(
                    notificationActions.enqueueSnackbar({
                      message: '检测到新版本，更新中...',
                      options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                        maxSnack: 1,
                      },
                    }),
                  );
                  setTimeout(() => window.location.reload());
                } else {
                  // At this point, everything has been precached.
                  // It's the perfect time to display a
                  // "Content is cached for offline use." message.
                  console.log('Content is cached for offline use.');
                  // Execute callback
                }
              }
            };
          };
        })
        .catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}
reportWebVitals(console.log);

// check version update
(function () {
  try {
    const currentScriptSrc = document.currentScript.src;
    const { pathname } = new URL(currentScriptSrc);

    const checkVersionUpdate = () => {
      fetch(`/?t=${+new Date()}`)
        .then(response => response.text())
        .then(html => {
          const regex = new RegExp(`src="(${pathname})"`);
          const match = html.match(regex);
          if (!match) {
            // 弹窗通知用户有更新
            console.log('检测到新版本，更新中src...');
            store.dispatch(
              notificationActions.enqueueSnackbar({
                message: '检测到新版本，更新中...',
                options: {
                  key: new Date().getTime() + Math.random(),
                  variant: 'success',
                  maxSnack: 1,
                },
              }),
            );
            setTimeout(() => window.location.reload(), 200);
          } else {
            console.log('Currently the latest version!');
          }
        });
    };

    const pageShowEventHandler = event => {
      if (event && event.persisted) {
        checkVersionUpdate();
      }
    };
    const visibilityStateChangeEventHandler = () => {
      if (document.visibilityState === 'visible') {
        checkVersionUpdate();
      }
    };
    window.addEventListener('pageshow', pageShowEventHandler);
    document.addEventListener('visibilitychange', visibilityStateChangeEventHandler);
  } catch (error) {
    console.log(error);
  }
})();

// 全局捕获 ChunkLoadError
window.addEventListener('error', e => {
  if (e.message.includes('ChunkLoadError')) {
    // 提示用户刷新页面
    console.log('加载失败，请刷新页面！');
    // window.location.reload();
  }
});

export default store;
