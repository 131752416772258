/**
 *
 * Asynchronously loads the component for Blog
 *
 */

import loadable from 'utils/loadable';
import LogoPageLoader from 'components/PageLoader/LogoPageLoader';

export const BlogDetailPage = loadable(() => import('./components/BlogDetailPage'));

export default loadable(() => import('./index'), { fallback: <LogoPageLoader /> });
