/* eslint-disable */
function Icon16AngleLeft(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M9.403 12.62L5.329 8.345a.5.5 0 0 1 0-.69L9.403 3.38a.5.5 0 0 1 .724 0l.544.571a.5.5 0 0 1 0 .69L7.8 7.655a.5.5 0 0 0 0 .69l2.872 3.014a.5.5 0 0 1 0 .69l-.544.571a.5.5 0 0 1-.724 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16AngleLeft;
