/*
 * Cart Messages
 *
 * This contains all the text for the Cart container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Cart';

export default defineMessages({
  removeNoSubBundleSuccess: {
    id: `${scope}.removeNoSubBundleSuccess`,
    defaultMessage: 'Deleted successfully, please add the set to the shopping cart again',
  },
  removeNoSubBundleNotice: {
    id: `${scope}.removeNoSubBundleNotice`,
    defaultMessage:
      'The bundle "{bundleNames}" does not have any sub-products. Do you want to delete the bundle without sub-products?',
  },
  removeNoSubBundleTitle: {
    id: `${scope}.removeNoSubBundleTitle`,
    defaultMessage: 'Remove this item?',
  },
  selectedRemove: {
    id: `${scope}.selectedRemove`,
    defaultMessage: 'Confirm to delete the {count} selected items?',
  },
  deleteProduct: {
    id: `${scope}.deleteProduct`,
    defaultMessage: 'Delete product',
  },
  confirmDelete: {
    id: `${scope}.confirmDelete`,
    defaultMessage: 'Confirm Delete',
  },
  management: {
    id: `${scope}.management`,
    defaultMessage: 'Management',
  },
  exitManagement: {
    id: `${scope}.exitManagement`,
    defaultMessage: 'Exit Management',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Delete selected（{count}）',
  },
  removeFailed: {
    id: `${scope}.removeFailed`,
    defaultMessage: 'failed to delete',
  },
  removeSuccessfully: {
    id: `${scope}.removeSuccessfully`,
    defaultMessage: 'Successfully deleted',
  },
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Cart',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  pleaseWaiting: {
    id: `${scope}.pleaseWaiting`,
    defaultMessage: 'Please wait a moment',
  },
  productHasBeenAddedToCart: {
    id: `${scope}.productHasBeenAddedToCart`,
    defaultMessage: '{name} has been added to your cart.',
  },
  checkOut: {
    id: `${scope}.checkOut`,
    defaultMessage: 'Check Out{quantity, plural, =0 {} one { ({quantity})} other { ({quantity})}}',
  },
  continueShopping: {
    id: `${scope}.continueShopping`,
    defaultMessage: 'Continue',
  },
  confirmRemovalOfItem: {
    id: `${scope}.confirmRemovalOfItem`,
    defaultMessage: 'Confirm removal of item',
  },
  deleteItem: {
    id: `${scope}.deleteItem`,
    defaultMessage: 'Delete Item',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  destructiveAction: {
    id: `${scope}.destructiveAction`,
    defaultMessage: 'Permanently delete this {cartName}? This action cannot be undone.',
  },
  cartSelectAll: {
    id: `${scope}.cartSelectAll`,
    defaultMessage: 'Select All',
  },
  buyOthers: {
    id: `${scope}.buyOthers`,
    defaultMessage: 'Buy Others',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue browsing',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit shopping cart',
  },
  shoppingListQRCode: {
    id: `${scope}.shoppingListQRCode`,
    defaultMessage: 'QR Code',
  },
  stock: {
    id: `${scope}.stock`,
    defaultMessages: 'stock: {total} items',
  },
  stockInsufficient: {
    id: `${scope}.stockInsufficient`,
    defaultMessages: 'Insufficient',
  },
  insufficient: {
    id: `${scope}.insufficient`,
    defaultMessage: 'Insufficient inventory of goods',
  },
  checkStore: {
    id: `${scope}.checkStore`,
    defaultMessage: 'Check store',
  },
  flagshipStoreCheckout: {
    id: `${scope}.flagshipStoreCheckout`,
    defaultMessages: 'Flagship store checkout',
  },
  insufficientNotice: {
    id: `${scope}.insufficientNotice`,
    defaultMessages:
      'Dear customer, there are goods in stock in this store. You can choose to continue checking out. The goods will be shipped from the flagship store when checking out, or you can switch stores to buy',
  },
  joinFavSuccess: {
    id: `${scope}.joinFavSuccess`,
    defaultMessage: 'Added to favorites',
  },
  successfullyDeleted: {
    id: `${scope}.successfullyDeleted`,
    defaultMessage: 'Successfully Deleted',
  },
});
