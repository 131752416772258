import { createSlice } from '@reduxjs/toolkit';
import store from 'store';
import { arrayNoRepeat, genNonDuplicateID } from 'utils/helpers';

export const homeState = createSlice({
  name: 'home',
  initialState: {
    searchHistory: null,
    searchShow: false,
  },
  reducers: {
    toggleSearch: (state, action) => {
      state.searchShow = action.payload;
    },
    setSearchHistory: (state, { payload } = {}) => {
      const history = store.get('home_search_history') || [];
      const historyArray = arrayNoRepeat(
        [{ id: genNonDuplicateID(16), tagName: payload.query }, ...history],
        'tagName',
      );

      store.set('home_search_history', historyArray);
      state.searchHistory = [...historyArray];
    },
  },
});

export const { toggleSearch, setSearchHistory } = homeState.actions;

export const selectSearchHistory = state =>
  state.home.searchHistory || store.get('home_search_history') || [];
export const selectSearchShow = state => state.home.searchShow;

export default homeState.reducer;
