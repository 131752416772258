import { useState, useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import usePostLogin from 'utils/Hooks/usePostLogin';
import useInitApplication from 'utils/Hooks/useInitApplication';
import { updateWechatParams } from 'containers/App/slices';

const useWechatToWebview = () => {
  const dispatch = useDispatch();
  const postLogin = usePostLogin();
  const initApp = useInitApplication();

  const [params, setParams] = useState({});

  useEffect(() => {
    function handleHashChange() {
      try {
        const values = qs.parse(window.location.hash.substring(1));
        if (!values) {
          return;
        }

        const { source, token, openid } = values;
        if (source !== 'weapp') {
          return;
        }
        setParams(values);
        dispatch(updateWechatParams(values));

        if (openid) {
          localStorage.setItem('openid', openid);
        }
        if (token) {
          postLogin(token).then(() => initApp());
        }
      } catch (err) {
        console.log(err);
      }
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return params;
};

export default useWechatToWebview;
