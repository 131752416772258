/* eslint-disable */
function Icon12AngleUp(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M8.618 8.071L6.345 5.905a.5.5 0 0 0-.69 0L3.382 8.071a.5.5 0 0 1-.69 0l-.312-.297a.5.5 0 0 1 0-.724l3.275-3.121a.5.5 0 0 1 .69 0l3.275 3.12a.5.5 0 0 1 0 .725l-.312.297a.5.5 0 0 1-.69 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12AngleUp;
