/* eslint-disable */
function Icon16Check(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M12.725 3.225L5.75 10.2 3.275 7.725c-.3-.3-.75-.3-1.05 0-.3.3-.3.75 0 1.05l3 3c.15.15.3.225.525.225a.68.68 0 0 0 .525-.225l7.5-7.5c.3-.3.3-.75 0-1.05-.3-.3-.75-.3-1.05 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Check;
