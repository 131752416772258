import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import FormatMessage from 'components/FormatMessage';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import cartMessages from '../messages';

function NoSubBundleDialog({ noSubBundles, loading, onClose, onRemove }) {
  const intl = useIntl();
  const notify = useNotificationSnackbar();

  const bundleNames = noSubBundles.map(i => i.name).join(', ');

  const handleRemove = async () => {
    const ids = noSubBundles.map(i => i.item_id);
    await onRemove?.(ids);
    onClose?.();
    notify(intl.formatMessage(cartMessages.removeNoSubBundleSuccess), 'success');
  };

  return (
    <ConfirmDialog
      open={!isEmpty(noSubBundles)}
      width={343}
      title={intl.formatMessage(cartMessages.removeNoSubBundleTitle)}
      onConfirm={handleRemove}
      onAssistant={onClose}
      onClose={onClose}
      confirmBtnMsg={intl.formatMessage(cartMessages.confirmDelete)}
      loading={loading}
    >
      <FormatMessage
        color="textSecondary"
        message={{ ...cartMessages.removeNoSubBundleNotice, values: { bundleNames } }}
      />
    </ConfirmDialog>
  );
}

NoSubBundleDialog.propTypes = {
  loading: PropTypes.bool,
  noSubBundles: PropTypes.array,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};

export default NoSubBundleDialog;
