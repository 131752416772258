/* eslint-disable */
function EmailIcon(props) {
  const fill = props.fill || '#CD0039';
  const width = props.width || '14px';
  const height = props.height || '12px';
  const viewBox = props.viewBox || '0 0 14 12'

  return (
    <svg width={width} height={height} viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>2.Icons/16/16-icons</title>
      <g id="---9.3-Fapiao-Related" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="9.3-1-Fapiao-Detail-Page---Opened" transform="translate(-433.000000, -248.000000)" fill={fill}>
          <g id="Group-4" transform="translate(328.000000, 244.000000)">
            <g id="3.Buttons/Icon-+-Text/Regular/Icon-Left-Copy-2" transform="translate(104.000000, 0.000000)">
              <g id="2.Icons/16/16-icons" transform="translate(0.000000, 2.000000)">
                <path d="M13.25,2.15 L2.75,2.15 C1.78350169,2.15 1,2.93350169 1,3.9 L1,12.0666667 C1,13.033165 1.78350169,13.8166667 2.75,13.8166667 L13.25,13.8166667 C14.2164983,13.8166667 15,13.033165 15,12.0666667 L15,3.9 C15,2.93350169 14.2164983,2.15 13.25,2.15 L13.25,2.15 Z M13.075,4.95 L8.40833333,9.03333333 C8.28127011,9.09882607 8.14248381,9.1384793 8,9.15 C7.85751619,9.1384793 7.71872989,9.09882607 7.59166667,9.03333333 L2.925,4.95 C2.68337542,4.74059203 2.6572587,4.37495791 2.86666667,4.13333333 C3.07607463,3.89170876 3.44170876,3.86559203 3.68333333,4.075 L7.94166667,7.80833333 L12.2,4.075 C12.3146218,3.97130699 12.4666076,3.91890201 12.6207799,3.92991432 C12.7749523,3.94092664 12.9179467,4.01440163 13.0166667,4.13333333 C13.1475573,4.22388462 13.2303031,4.36892482 13.2416428,4.52768028 C13.2529825,4.68643573 13.1916915,4.84176428 13.075,4.95 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EmailIcon;
