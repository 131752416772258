/*
 *
 * Checkout Page constants
 *
 */
import ShipmentImage from 'images/checkout/icon-shipment.svg';
import StorePickUpImage from 'images/checkout/icon-store-pick-up.svg';

// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_PAYMENT_METHODS = [
  'wechatpay',
  'free',
  // 'alipay',
  // 'checkmo',
  // 'purchaseorder ',
  // 'cashondelivery',
];

export const PICKUP_METHODS = [
  {
    code: 'express',
    label: '快递物流',
    method_code: 'flatrate',
    disabled: true,
    icon: ShipmentImage,
  },
  {
    code: 'pickup',
    label: '到店自提',
    method_code: 'customerPickUp',
    disabled: true,
    icon: StorePickUpImage,
  },
];

export const COMMISSION_MONTH_DEADLINE_DAY = process.env.PROJECT_ENV === 'production' ? 3 : 31;
export const PAYMENT_METHODS = {
  WECHAT: 'wechatpay',
  ALI: 'alipay',
  CHINA_PAY: 'chinapay',
  H_ALI: 'huifupayAlipay',
  H_WECHAT: 'huifupayWechat',
  H_UNION: 'huifupayUnion',
};
