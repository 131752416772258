/* eslint-disable */
function Icon16Times(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M13.743 2.257a.829.829 0 0 0-1.2 0L8 6.8 3.457 2.257a.829.829 0 0 0-1.2 0 .829.829 0 0 0 0 1.2L6.8 8l-4.543 4.543a.829.829 0 0 0 0 1.2.778.778 0 0 0 .6.257.778.778 0 0 0 .6-.257L8 9.2l4.543 4.543a.926.926 0 0 0 .6.257.926.926 0 0 0 .6-.257.829.829 0 0 0 0-1.2L9.2 8l4.543-4.543a.829.829 0 0 0 0-1.2z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Times;
