import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactGA from 'react-ga4';
import { selectShowMiniCart, toggleMiniCart } from 'containers/App/slices';

function RouterListener() {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const showMiniCart = useSelector(selectShowMiniCart);
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('md'));

  useEffect(() => {
    if (process.env.PROJECT_ENV === 'production') {
      ReactGA.initialize('G-7P04DX181V');
      ReactGA.gtag('config', 'G-7P04DX181V');
    } else {
      ReactGA.initialize('G-7P04DX181V');
      ReactGA.gtag('config', 'G-7P04DX181V');
    }
    ReactGA.ga('set', 'currencyCode', 'CNY'); // Set currency to RMB.
    ReactGA.gtag('js', new Date());
    // ReactGA.plugin.require('ec');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    window.scrollTo(0, 0);
    if (showMiniCart && matchLg) {
      dispatch(toggleMiniCart(false));
    }
  }, [location.pathname, location.search]);

  return null;
}

export default RouterListener;
