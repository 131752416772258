/*
 * Account Dashboard Messages
 *
 * This contains all the text for the DashboardPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MembershipProvider';

export default defineMessages({
  emptyDownLineList: {
    if: `${scope}.emptyDownLineList`,
    defaultMessage: 'No customer record yet',
  },
  profit: {
    id: `${scope}.profit`,
    defaultMessage: 'Profit',
  },
  myClient: {
    id: `${scope}.myClient`,
    defaultMessage: 'My Client',
  },
  revenueInvoice: {
    id: `${scope}.revenueInvoice`,
    defaultMessage: 'Invoice',
  },
  applyWithdrawal: {
    id: `${scope}.applyWithdrawal`,
    defaultMessage: 'Application for Cash withdrawal',
  },
  withdrawal: {
    id: `${scope}.withdrawal`,
    defaultMessage: 'Cash withdrawal',
  },
  extractRemark: {
    id: `${scope}.extractRemark`,
    defaultMessage:
      'At the end of each month, the income of the previous month can be withdrawn after the settlement is completed',
  },
  annualIncome: {
    id: `${scope}.annualIncome`,
    defaultMessage: 'Annual Income',
  },
  downloadStatistical: {
    id: `${scope}.downloadStatistical`,
    defaultMessage: 'Download Report',
  },
  contribution: {
    id: `${scope}.contribution`,
    defaultMessage: 'Contribution',
  },
  invoiceStatus: {
    id: `${scope}.invoiceStatus`,
    defaultMessage: 'FC Invoice Status',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'Current revenue amount',
  },
  remark: {
    id: `${scope}.remark`,
    defaultMessage:
      'The system will transfer the amount to the designated account within the third working day after receiving the invoice. If you do not receive it, please contact the customer service',
  },
  transferInfo: {
    id: `${scope}.transferInfo`,
    defaultMessage: 'Transfer information',
  },
  invoiceMailing: {
    id: `${scope}.invoiceMailing`,
    defaultMessage: 'Invoice mailing',
  },
  afterRemark: {
    id: `${scope}.afterRemark`,
    defaultMessage:
      'Please send the invoice to the non Dekai financial department as soon as possible after application',
  },
  confirmWithdrawal: {
    id: `${scope}.confirmWithdrawal`,
    defaultMessage: 'Confirm Withdrawal',
  },
  afterWithdrawal: {
    id: `${scope}.afterWithdrawal`,
    defaultMessage: 'After withdrawal',
  },
  okay: {
    id: `${scope}.okay`,
    defaultMessage: 'OK',
  },
  bankOfDeposit: {
    id: `${scope}.bankOfDeposit`,
    defaultMessage: 'Bank Of Deposit',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
  accountName: {
    id: `${scope}.accountName`,
    defaultMessage: 'User Name',
  },
  IdCard: {
    id: `${scope}.IdCard`,
    defaultMessage: 'ID Card',
  },
  finance: {
    id: `${scope}.finance`,
    defaultMessage: 'FEIDEKAI FINACE 18095026173',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Room 301, building a, No. 201, Renmin Avenue, Huangpu District, Shanghai',
  },
  openOffline: {
    id: `${scope}.openOffline`,
    defaultMessage: 'Expand the offline list',
  },
  stow: {
    id: `${scope}.stow`,
    defaultMessage: 'Stow offline list',
  },
  allIntegral: {
    id: `${scope}.allIntegral`,
    defaultMessage: 'All Integral',
  },
  invoiceStatusWaitNotice: {
    id: `${scope}.invoiceStatusWaitNotice`,
    defaultMessage: '*If the invoice has been sent, this information can be ignored',
  },
  applyFc: {
    id: `${scope}.applyFc`,
    defaultMessage: 'Apply to Franchise',
  },
  successfulySaved: {
    id: `${scope}.successfulySaved`,
    defaultMessage: 'Successfully saved',
  },
  noInfo: {
    id: `${scope}.noInfo`,
    defaultMessage: 'Information is empty',
  },
  withdrawalSucceed: {
    id: `${scope}.withdrawalSucceed`,
    defaultMessage:
      'The system will transfer the amount to the designated account within three working days after receiving the invoice. If you do not receive it, please contact the customer service',
  },
  closeView: {
    id: `${scope}.closeView`,
    defaultMessage: 'Close',
  },
  uploadFileError: {
    id: `${scope}.uploadFileError`,
    defaultMessage: 'Image upload failed',
  },
  applyToFc: {
    id: `${scope}.applyToFc`,
    defaultMessage: 'Apply To Fc',
  },
  dateFormat: {
    id: `${scope}.dateFormat`,
    defaultMessage: 'MMM YYYY',
  },
  invoiceStatusDone: {
    id: `${scope}.invoiceStatusDone`,
    defaultMessage: 'Invoice received',
  },
  invoiceStatusWait: {
    id: `${scope}.invoiceStatusWait`,
    defaultMessage: 'Please mail the invoice to Fidekai as soon as possible',
  },
  invoiceStatusDoneDate: {
    id: `${scope}.invoiceStatusDoneDate`,
    defaultMessage: 'Invoice acquisition date: {date}',
  },
  invoiceStatusFailed: {
    id: `${scope}.invoiceStatusFailed`,
    defaultMessage: 'Invoice failed',
  },
  contactCMS: {
    id: `${scope}.contactCMS`,
    defaultMessage: 'Contact Customer Service',
  },
  availablePoints: {
    id: `${scope}.availablePoints`,
    defaultMessage: 'Available Points',
  },
  lastUpdateWithParams: {
    id: `${scope}.lastUpdateWithParams`,
    defaultMessage: 'Last update：{date}',
  },
  incomeWithParams: {
    id: `${scope}.incomeWithParams`,
    defaultMessage: 'Income: {total} points',
  },
  expenseWithParams: {
    id: `${scope}.expenseWithParams`,
    defaultMessage: 'Expense: {total} points',
  },
  pointsReward: {
    id: `${scope}.pointsReward`,
    defaultMessage: 'Points Reward',
  },
  pointsExpense: {
    id: `${scope}.pointsExpense`,
    defaultMessage: 'Points Expense',
  },
  orderProductWithParams: {
    id: `${scope}.orderProductWithParams`,
    defaultMessage: 'Order Products({total})',
  },
  income: {
    id: `${scope}.income`,
    defaultMessage: 'Income',
  },
  expense: {
    id: `${scope}.expense`,
    defaultMessage: 'Expense',
  },
  incomePoints: {
    id: `${scope}.incomePoints`,
    defaultMessage: 'Points Income',
  },
  expensePoints: {
    id: `${scope}.expensePoints`,
    defaultMessage: 'Points Expense',
  },
  pointsStatistics: {
    id: `${scope}.pointsStatistics`,
    defaultMessage: 'Points',
  },
  myCustomers: {
    id: `${scope}.myCustomers`,
    defaultMessage: 'My Customers',
  },
  // 消费获取
  purchaseReward: {
    id: `${scope}.purchaseReward`,
    defaultMessage: 'Purchase Reward',
  },
  // 退货退换
  pointsReturnRefund: {
    id: `${scope}.pointsReturnRefund`,
    defaultMessage: 'RMA Refund',
  },
  // 消费支付
  purchaseExpense: {
    id: `${scope}.purchaseExpense`,
    defaultMessage: 'Purchase Expense',
  },
  // 人工添加
  manuallyAdded: {
    id: `${scope}.manuallyAdded`,
    defaultMessage: 'Manually Adjustment',
  },
  // 订单取消退还
  orderCanceledRefund: {
    id: `${scope}.orderCanceledRefund`,
    defaultMessage: 'Order Canceled Refund',
  },
  pointsRecords: {
    id: `${scope}.pointsRecords`,
    defaultMessage: 'Points Records',
  },
  pointsCategory: {
    id: `${scope}.pointsCategory`,
    defaultMessage: 'Points Category',
  },
  orderProducts: {
    id: `${scope}.orderProducts`,
    defaultMessage: 'Order Products',
  },
  feidekaiID: {
    id: `${scope}.feidekaiID`,
    defaultMessage: 'Feidekai ID',
  },
  pointsStatusReward: {
    id: `${scope}.pointsStatusReward`,
    defaultMessage: 'Purchase Reward',
  },
  pointsStatusExpense: {
    id: `${scope}.pointsStatusExpense`,
    defaultMessage: 'Purchase Expense',
  },
  pointsStatusRefund: {
    id: `${scope}.pointsStatusRefund`,
    defaultMessage: 'Purchase Refund',
  },
  orderProductTitleWithParams: {
    id: `${scope}.orderProductTitleWithParams`,
    defaultMessage: '{title} {total}items',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  emptyPoints: {
    id: `${scope}.emptyPoints`,
    defaultMessage: 'No points records',
  },
  emptyPointsNotice: {
    id: `${scope}.emptyPointsNotice`,
    defaultMessage:
      "Kind reminder: This month's reward points statistics will be updated on the 2nd of next month",
  },
  reportPDF: {
    id: `${scope}.reportPDF`,
    defaultMessage: 'PDF',
  },
  reportCSV: {
    id: `${scope}.reportCSV`,
    defaultMessage: 'CSV',
  },
  creditPointsAndWeChatPay: {
    id: `${scope}.creditPointsAndWeChatPay`,
    defaultMessage: 'WeChat Pay; Credit Points',
  },
  creditTypeWithParams: {
    id: `${scope}.creditTypeWithParams`,
    defaultMessage: 'Type: {type}',
  },
  creditType: {
    id: `${scope}.creditType`,
    defaultMessage: 'Credit Type',
  },
  points: {
    id: `${scope}.points`,
    defaultMessage: 'Points',
  },
});
