/*
 * CatalogPage Messages
 *
 * This contains all the text for the CatalogPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CatalogPage';

export default defineMessages({
  productCatalogue: {
    id: `${scope}.productCatalogue`,
    defaultMessage: 'Product Catalogue',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'Products',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  flashSale: {
    id: `${scope}.title`,
    defaultMessage: 'Flash Sales',
  },
  searchResults: {
    id: `${scope}.searchResults`,
    defaultMessage: 'Search Results',
  },
  searchResultsCount: {
    id: `${scope}.searchResultsCount`,
    defaultMessage: '{count} Items',
  },
  searchResultsCommodity: {
    id: `${scope}.searchResultsCommodity`,
    defaultMessage: 'Show search results for {commodity}',
  },
  searchAllCommodity: {
    id: `${scope}.searchAllCommodity`,
    defaultMessage: 'All Commodity',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No "{value}" found',
  },
  goodsTotal: {
    id: `${scope}.goodsTotal`,
    defaultMessage: 'A total of {total} items',
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search keywords',
  },
  sort: {
    id: `${scope}.sort`,
    defaultMessage: 'Sort',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Price',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New',
  },
  noFountNotice: {
    id: `${scope}.noFountNotice`,
    defaultMessage: 'You can try to search for other keywords',
  },
  noGoods: {
    id: `${scope}.noGoods`,
    defaultMessages: 'No Goods',
  },
  addToCartSuccess: {
    id: `${scope}.addToCartSuccess`,
    defaultMessage: 'Add to Cart Successfully',
  },
  addToCartFailed: {
    id: `${scope}.addToCartFailed`,
    defaultMessage: 'Joining the shopping cart failed',
  },
});
