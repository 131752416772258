/* eslint-disable */
function Icon16OpenLinkInBrowser(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M8 6c.125 0 .244.053.329.147l3.063 3.603a.46.46 0 0 1 .069.483.437.437 0 0 1-.398.264H8.875v4.053c0 .248-.196.45-.438.45h-.875a.444.444 0 0 1-.437-.45v-4.053H4.937a.437.437 0 0 1-.398-.265.46.46 0 0 1 .07-.482L7.67 6.147A.444.444 0 0 1 8 6zm5.25-5c.966 0 1.75.768 1.75 1.714v8.572c0 .946-.784 1.714-1.75 1.714h-2.625v-1.714h2.625v-6H2.75v6h2.625V13H2.75C1.784 13 1 12.232 1 11.286V2.714C1 1.768 1.784 1 2.75 1z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16OpenLinkInBrowser;
