/* eslint-disable */
function Icon24Notification(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-notification" fill={fill}>
            <path id="Combined-Shape" d="M14 19c-.274 1.19-1.084 2-2 2-.916 0-1.726-.81-2-2 .626.065 1.292.095 2 .095s1.374-.032 2-.095zM12 3c3.213 0 5.818 2.686 5.818 6v1.5a3.371 3.371 0 0 0 1.125 2.395A3.17 3.17 0 0 1 20 15c0 1.875-2.99 3-8 3s-8-1.125-8-3a3.17 3.17 0 0 1 1.057-2.105A3.371 3.371 0 0 0 6.182 10.5V9c0-3.314 2.605-6 5.818-6z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Notification;
