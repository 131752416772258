/* eslint-disable */
function Flash_2(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 43 60" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g>
		<g id="---5.-Product-Detail-Page" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="PDP---Flashsale---V2" fill="#F5CCD7" transform="translate(-281 -412)">
				<path id="Path" d="M285.3 472a1.429 1.429 0 0 1-1.282-2.068l13.296-26.503h-14.88c-.487 0-.94-.246-1.204-.653a1.425 1.425 0 0 1-.104-1.362l12.9-28.571a1.434 1.434 0 0 1 1.307-.843h18.634a1.426 1.426 0 0 1 1.282 2.068l-7.563 15.075h14.88c.571 0 1.087.337 1.314.857a1.426 1.426 0 0 1-.263 1.544l-37.266 40c-.281.3-.665.456-1.052.456z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default Flash_2;