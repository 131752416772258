/*
 * CouponPage Messages
 *
 * This contains all the text for the CouponPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CouponPage';

export default defineMessages({
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  expiring: {
    id: `${scope}.expiring`,
    defaultMessage: 'Expiring',
  },
  usedOrExpired: {
    id: `${scope}.usedOrExpired`,
    defaultMessage: 'Used or expired',
  },
  addSuccess: {
    id: `${scope}.addSuccess`,
    defaultMessage: 'Added Successfully',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Coupons',
  },
  expired: {
    id: `${scope}.expired`,
    defaultMessage: 'Expired',
  },
  usedCoupons: {
    id: `${scope}.usedCoupons`,
    defaultMessage: 'Used / Expired coupons',
  },
  viewUsedCoupons: {
    id: `${scope}.viewUsedCoupons`,
    defaultMessage: 'View Used / Expired Coupons',
  },
  goToCouponCenter: {
    id: `${scope}.goToCouponCenter`,
    defaultMessage: 'Go to the coupon center',
  },
  outOfStock: {
    id: `${scope}.outOfStock`,
    defaultMessage: 'Not on effective date',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'Coupon center, there are discounts when you place an order',
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Discount',
  },
  grab: {
    id: `${scope}.grab`,
    defaultMessage: 'Grab it now',
  },
  left: {
    id: `${scope}.left`,
    defaultMessage: 'Remaining',
  },
  toUse: {
    id: `${scope}.toUse`,
    defaultMessage: 'To use',
  },
  used: {
    id: `${scope}.used`,
    defaultMessage: 'Used',
  },
  selected: {
    id: `${scope}.selected`,
    defaultMessage: 'Selected',
  },
  choose: {
    id: `${scope}.choose`,
    defaultMessage: 'Choose to use',
  },
  robbed: {
    id: `${scope}.robbed`,
    defaultMessage: 'Robbed',
  },
  relatedCoupons: {
    id: `${scope}.relatedCoupons`,
    defaultMessage: 'Related coupons',
  },
  getCcoupons: {
    id: `${scope}.getCcoupons`,
    defaultMessage: 'Get some coupons',
  },
  moreCoupons: {
    id: `${scope}.moreCoupons`,
    defaultMessage: 'More coupons',
  },
  chooseCoupon: {
    id: `${scope}.chooseCoupon`,
    defaultMessage: 'Choose coupon',
  },
  notEnoughCoupons: {
    id: `${scope}.notEnoughCoupons`,
    defaultMessage: 'Not enough coupons?',
  },
  noThresholdCoupon: {
    id: `${scope}.noThresholdCoupon`,
    defaultMessage: 'No threshold coupon',
  },
  noThresholdVoucher: {
    id: `${scope}.noThresholdVoucher`,
    defaultMessage: 'No threshold voucher',
  },
  notSuperimposedWithOtherPromotions: {
    id: `${scope}.notSuperimposedWithOtherPromotions`,
    defaultMessage: 'Not superimposed',
  },
});
