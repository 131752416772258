/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import { useCallback } from 'react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useMPBridge from 'utils/Hooks/useMPBridge';
import { getAuthorization } from 'utils/Auth';
import usePostLogin from 'utils/Hooks/usePostLogin';
import useInitApplication from 'utils/Hooks/useInitApplication';
import useGetInviteId from 'containers/AuthorizationProvider/hooks/useGetInviteId';
import { toggleMPLoginVisible } from 'containers/App/slices';
import useGetVendor from './useGetVendor';

const useLogin = () => {
  const history = useHistory();
  const postLogin = usePostLogin();
  const initApp = useInitApplication();
  const dispatch = useDispatch();
  const auth = getAuthorization();
  const { sessionId } = useGetInviteId();
  const { extension_attributes: { register_status: IS_REGISTER_MODE } = {} } = useGetVendor();

  const [MPLogin] = useMPBridge(
    'login',
    async ({ token, redirectUrl, PHPSESSID, openid, route }) => {
      const phpSessionId = PHPSESSID || sessionId;
      if (openid) {
        localStorage.setItem('openid', openid);
      }
      if (!isNull(token) && !isUndefined(token) && token !== '') {
        await postLogin(token, route);

        initApp();
      } else if (redirectUrl) {
        if (phpSessionId) {
          // domain=api.feidekai.alpha.tmogroup.asia;
          document.cookie = `${phpSessionId}; Max-Age=86400; path=/; domain=.tmogroup.asia;`;
        }
        history.replace(redirectUrl);
      } else {
        initApp();
      }
      // history.goBack();
    },
    { path: '/pages/login/login' },
  );

  const navigateToLogin = useCallback(
    (options = {}) => {
      MPLogin({
        ...options,
        IS_REGISTER_MODE,
        params: { ...(auth.token ? { cartID: auth.token } : {}) },
        complete: () => {
          dispatch(toggleMPLoginVisible(false));
        },
      });
    },
    [MPLogin, IS_REGISTER_MODE, auth.token],
  );

  return [navigateToLogin];
};

export default useLogin;
