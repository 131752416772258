/* eslint-disable */
function Icon24Orders(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/24/24-orders" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Combined-Shape" d="M13.7 2v5.833a.84.84 0 0 0 .743.827l.107.007h5.95v12.5a.84.84 0 0 1-.743.827L19.65 22H4.35a.845.845 0 0 1-.843-.729l-.007-.104V2.833a.84.84 0 0 1 .743-.827L4.35 2h9.35zM9.417 16.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm5 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zM8.38 9H6.5v1.212h1.378l1.128 4.97.028.096c.091.245.32.389.599.389h5.012l.094-.009a.598.598 0 0 0 .47-.416l1.253-3.636.024-.073a.584.584 0 0 0-.588-.715H9.32l-.314-1.333-.027-.097c-.092-.244-.32-.388-.6-.388zm7.02-6.512L20.002 7H15.4V2.488z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon24Orders;
