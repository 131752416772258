import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';

/**
 * Direct selector to the Notifications state domain
 */

const selectNotifications = state => state.notifications || initialState;

/**
 * Default selector used by Notifications
 */

const makeSelectNotifications = () =>
  createSelector(selectNotifications, substate => substate || []);

export default makeSelectNotifications;
export { selectNotifications };
