import PropTypes from 'prop-types';

function MedalIcon(props) {
  const fill = props.fill || '#A7A9AC';
  const secondaryFill = props.secondaryFill || '#CD0039';
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 14</title>
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard---RC" transform="translate(-30.000000, -102.000000)">
          <g id="Group-15" transform="translate(16.000000, 88.000000)">
            <g id="Group-14" transform="translate(14.000000, 14.000000)">
              <path
                d="M33.7496005,29.1638591 L22.9090909,24.3333333 L22.9090909,16 L13.0909091,16 L13.0909091,24.3333333 L12.9886863,24.3853658 L2.25294627,29.1638591 C1.00869472,29.7177022 0,31.291392 0,32.6743469 L0,35.1642253 C0,35.6258037 0.368041991,36 0.819580091,36 L35.1804699,36 C35.6331066,36 36,35.6351115 36,35.1642253 L36,32.6743469 C36,31.2894083 34.9945013,29.7186177 33.7496005,29.1638591 Z"
                id="Path"
                fill={fill}
              />
              <path
                d="M18,21 C13.0373757,21 9,17.0143855 9,12.1153846 L9,8.88461538 C9,3.98561447 13.0373757,0 18,0 C22.9626243,0 27,3.98561447 27,8.88461538 L27,12.1153846 C27,17.0143855 22.9626243,21 18,21 Z"
                id="Path"
                fill={secondaryFill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

MedalIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MedalIcon;
