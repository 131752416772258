import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import { CART_QUERY_KEY, useBatchRemoveCart } from 'containers/CartProvider/queries';
import messages from 'containers/CartProvider/messages';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import {
  selectIsCartManagement,
  toggleIsCartManagement,
  updateSelectedItems,
} from 'containers/CartProvider/slices';

const useCartBatchRemove = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const notify = useNotificationSnackbar();
  const queryClient = useQueryClient();
  const isCartManagement = useSelector(selectIsCartManagement);
  const { quote, selectedItems } = useShoppingCart();
  const { mutateAsync: batchRemove } = useBatchRemoveCart();

  const [isRemove, setIsRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsRemove = flag => {
    setIsRemove(flag);
  };

  const toggleIsManagement = flag => {
    const management = flag ?? !isCartManagement;
    dispatch(toggleIsCartManagement(management));
    if (!management) {
      dispatch(updateSelectedItems([]));
    }
  };

  const cancel = () => toggleIsRemove(false);

  const confirm = async ids => {
    try {
      setIsLoading(true);
      await batchRemove({
        cartId: quote.id,
        itemIds: ids || selectedItems,
      });
      await queryClient.refetchQueries([CART_QUERY_KEY.GET]);
      cancel();
      toggleIsManagement(false);
      setIsLoading(false);
    } catch (err) {
      notify(err.message || intl.formatMessage(messages.removeFailed), 'warn');
      setIsLoading(false);
    }
  };

  return {
    isManagement: isCartManagement,
    isLoading,
    isRemove,
    confirm,
    cancel,
    toggleIsRemove,
    toggleIsManagement,
  };
};

export default useCartBatchRemove;
