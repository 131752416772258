import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getUrlParams } from 'utils/helpers';

const useGetInviteId = () => {
  const { id } = useParams();
  const { enrollerId, sessionId } = getUrlParams();

  const inviteId = /^\d+/.test(id) ? id : enrollerId;
  const isRegister = useMemo(() => inviteId !== 'update', [inviteId]);

  return { inviteId, isRegister, sessionId: sessionId ? `PHPSESSID=${sessionId}` : '' };
};

export default useGetInviteId;
