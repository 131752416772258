/**
 *
 * Asynchronously loads the component for SocialMarketerUpgrade
 *
 */

import loadable from 'utils/loadable';

export const FCMaterial = loadable(() => import('./components/FCSubmission'));
export default loadable(() => import('./index'));
