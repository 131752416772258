import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import SpecialProductsDialog from 'components/Product/components/SpecialProducts';
import MobileSpecialProductDrawer from 'components/Product/components/MobileSpecialProducts';
import useSpecial from 'components/Product/hooks/useSpecial';

function SpecialDialog({ spaceBottom, onClose, ...other }) {
  const { specialOpen, toggleSpecial } = useSpecial();

  const handleClose = () => onClose?.() || toggleSpecial(false);

  return (
    <>
      <Hidden smDown>
        <SpecialProductsDialog open={specialOpen} onClose={handleClose} {...other} />
      </Hidden>
      <Hidden mdUp>
        <MobileSpecialProductDrawer
          spaceBottom={spaceBottom}
          open={specialOpen}
          onClose={handleClose}
          {...other}
        />
      </Hidden>
    </>
  );
}

SpecialDialog.propTypes = {
  spaceBottom: PropTypes.number,
  onClose: PropTypes.func,
};

export default SpecialDialog;
