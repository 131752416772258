import { shallowEqual, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useGetCustomerPromotionIdentity } from 'containers/AuthorizationProvider/queries';
import { selectCartItemsSelected } from 'containers/CartProvider/slices';
import { useGetCartQuote } from 'containers/CartProvider/queries';
import { useGetCustomer } from 'containers/UserProfile/queries';
import useDeepCompareMemo from 'utils/Hooks/updateEffect/useDeepCompareMemo';

const usePurchasePermissions = (prod = {}, k = '') => {
  const selectedItems = useSelector(selectCartItemsSelected, shallowEqual);
  const { data: customer = {} } = useGetCustomer();
  const { data: cartData = {} } = useGetCartQuote();
  const { data: promotionIdentity = false } = useGetCustomerPromotionIdentity();
  const cartQuote = useDeepCompareMemo(() => get(cartData, 'items', []), [cartData]);

  // Check the special information of the product, and then determine whether the user has purchase permission
  // 查看产品的special信息，然后判断用户是否具有购买权限
  const purchasePermissions = (product = {}, key = '') => {
    const { eligibility } = key ? get(product, key, {}) : product;

    // Purchase is allowed when the product does not have eligibility status
    // 该商品不具有eligibility身份时允许购买
    if (!eligibility) {
      return true;
    }

    // When the product has an eligibility status and is not logged in,
    // the user and the RC status are not allowed to purchase
    // 商品具有eligibility身份时并且未登录的用户、RC身份时不允许购买
    if (eligibility && (isEmpty(customer) || [1, 2].includes(customer.group_id))) {
      return false;
    }

    // Has the right to purchase standard packages, and can purchase any package
    // 角色具有购买达标套装权限，可以购买任意套装
    return Boolean(promotionIdentity);
  };

  const isBuy = useDeepCompareMemo(() => purchasePermissions(prod, k), [prod, k]);

  const findStandardSets = useDeepCompareMemo(() => {
    const standardSets = cartQuote.filter(
      quote => get(quote, 'extension_attributes.is_standard_sets', 0) === 1,
    );

    const isCartSelected = standardSets.some(i => selectedItems.includes(i.item_id));

    return {
      standardSets,
      isCartSelected,
    };
  }, [cartQuote, selectedItems]);

  const cartCheckout = (product = {}, key = '') => {
    const { eligibility } = key ? get(product, key, {}) : product;
    const { standardSets, isCartSelected } = findStandardSets;

    if (promotionIdentity) {
      return true;
    }

    if (eligibility) {
      return isEmpty(standardSets) ? purchasePermissions(product, key) : isCartSelected;
    }

    return true;
  };

  const isCartCheckout = useDeepCompareMemo(
    () => cartCheckout(prod, k),
    [prod, k, findStandardSets],
  );

  return {
    isBuy,
    isCartCheckout,
    cartCheckout,
    findStandardSets,
    purchasePermissions,
  };
};

export default usePurchasePermissions;
