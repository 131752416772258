/* eslint-disable */
function Icon12QuestionCircle(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/12/12-question-circle" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Shape" d="M6 1C3.25 1 1 3.25 1 6s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm.05 8.3c-.45 0-.75-.3-.75-.75s.3-.75.75-.75.75.3.75.75-.3.75-.75.75zm.966-3.414c-.327.171-.327.228-.327.343V6.8H5.377v-.571c0-.743.525-1.086.918-1.315.328-.171.394-.228.394-.4 0-.343-.263-.571-.656-.571a.716.716 0 0 0-.59.286l-.328.514L4 4.17l.328-.514c.328-.514.983-.857 1.705-.857C7.148 2.8 8 3.543 8 4.514c0 .8-.59 1.143-.984 1.372z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon12QuestionCircle;
