/* eslint-disable */
function Icon16TimesCircle(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M8 1c3.85 0 7 3.15 7 7s-3.15 7-7 7-7-3.15-7-7 3.15-7 7-7zm1.838 3.938L8 6.774 6.162 4.937 4.938 6.162 6.774 8 4.937 9.838l1.225 1.225L8 9.224l1.838 1.838 1.225-1.226L9.224 8l1.838-1.838-1.226-1.224z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16TimesCircle;
