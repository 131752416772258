/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 56,
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      minWidth: 144,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: theme.palette.background.peach,
      },
    },
    '&$selected': {
      color: theme.palette.primary.main,
      background: 'none',
      '&:hover': {
        backgroundColor: theme.palette.background.peach,
      },
    },
  },
  selected: {},
}));

const DropdownItem = forwardRef(({ children, option, MenuItemProps, ...other }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem
      classes={{ root: classes.root, selected: classes.selected }}
      {...MenuItemProps(option)}
      {...other}
      ref={ref}
    >
      {children}
    </MenuItem>
  );
});

DropdownItem.defaultProps = {
  MenuItemProps: () => ({}),
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  option: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  MenuItemProps: PropTypes.func,
};

export default DropdownItem;
