import { useQueryClient } from 'react-query';
import {
  CART_QUERY_KEY,
  useBatchAddToCart,
  useCheckoutShoppingListMutation,
} from 'containers/CartProvider/queries';
import useCheckout from 'utils/Hooks/useCheckout';

const useCheckoutShoppingList = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: getShoppingList } = useCheckoutShoppingListMutation();
  const { mutateAsync: batchAddToCart } = useBatchAddToCart();
  const { checkout: proceedToCheckout } = useCheckout();

  const checkoutShoppingList = async ({ complete, qrcodeId, customerId } = {}) => {
    try {
      const [{ sku, vendor_code: vendorCode }] = await getShoppingList({ qrcodeId, customerId });
      batchAddToCart(
        {
          cartItems: sku,
          vendorCode,
        },
        {
          onSuccess: async res => {
            if (res) {
              await queryClient.invalidateQueries([CART_QUERY_KEY.GET]);
              const carts = queryClient.getQueryData([CART_QUERY_KEY.GET]);
              proceedToCheckout(carts.items);
            }
          },
        },
      );
      if (complete) complete();
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  return { checkoutShoppingList };
};

export default useCheckoutShoppingList;
