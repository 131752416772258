import PropTypes from 'prop-types';

function MedalIcon(props) {
  const fill = props.fill || 'currentColor';
  const secondaryFill = props.secondaryFill || fill;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="2 0 12 12">
      <g fill="none" fillRule="evenodd">
        <polygon fill={fill} points="6 5 3 3.571 3 0 9 0 9 3.571" />
        <polygon
          fill={secondaryFill}
          points="8.163 12 6 10.804 3.837 12 4.25 9.468 2.5 7.674 4.918 7.305 6 5 7.082 7.305 9.5 7.674 7.75 9.468"
        />
      </g>
    </svg>
  );
}

MedalIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MedalIcon;
