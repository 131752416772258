/* eslint-disable */
function Icon16AngleRight(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M5.873 12.62l-.544-.571a.5.5 0 0 1 0-.69L8.2 8.345a.5.5 0 0 0 0-.69L5.329 4.641a.5.5 0 0 1 0-.69l.544-.571a.5.5 0 0 1 .724 0l4.074 4.275a.5.5 0 0 1 0 .69L6.597 12.62a.5.5 0 0 1-.724 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16AngleRight;
