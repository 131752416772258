import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export const snackbarStyles = theme => ({
  root: {
    margin: theme.spacing(0.25, 0),
    '& #notistack-snackbar .MuiSvgIcon-root': {
      color: theme.palette.primary.darkPeach,
    },
  },
  contentRoot: {
    background: theme.palette.background.white,
    border: `1px solid ${theme.palette.primary.darkPeach}`,
    color: '#090909',
    boxShadow: '0 0 16px rgba(0,0,0,0.08)',
    borderRadius: 8,
    fontSize: '0.75rem',
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  message: {
    wordBreak: 'break-all',
    padding: theme.spacing(1.75, 0),
    '& > .MuiSvgIcon-root': {
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
  action: {
    padding: 0,
    margin: '-1px 0 -1px auto',
    '& > .MuiIconButton-root': {
      color: theme.palette.text.primary,
    },
  },
  success: {
    borderColor: theme.palette.success.main,
    '& #notistack-snackbar .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    },
  },
  error: {
    borderColor: theme.palette.warning.main,
    '& #notistack-snackbar .MuiSvgIcon-root': {
      color: theme.palette.warning.main,
    },
  },
  warning: {
    borderColor: theme.palette.warning.main,
    '& #notistack-snackbar .MuiSvgIcon-root': {
      color: theme.palette.warning.main,
    },
  },
  info: {
    borderColor: theme.palette.primary.darkPeach,
    '& #notistack-snackbar .MuiSvgIcon-root': {
      color: theme.palette.primary.darkPeach,
    },
  },
});

export const AppWrapper = styled(Box)(() => ({
  // maxWidth: 'calc(578px + 16px * 2)',
  margin: '0 auto',
  // display: 'flex',
  minHeight: '60vh',
  overflow: 'visible',
  flexDirection: 'column',
  backgroundColor: 'white',
}));
