/* eslint-disable */
function Icon24Link(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-link" fill={fill}>
            <path id="Combined-Shape" d="M4.555 11.607a5.523 5.523 0 0 1 5.31-1.422l-1.692 1.691a3.65 3.65 0 0 0-2.3 1.048l-.93.931a3.68 3.68 0 0 0 5.203 5.204l.932-.931a3.647 3.647 0 0 0 1.047-2.3l1.69-1.693a5.526 5.526 0 0 1-1.422 5.31l-.931.931a5.542 5.542 0 1 1-7.839-7.837zm11.181-3.343c.348.348.353.91.011 1.263l-7.209 7.209a.9.9 0 1 1-1.274-1.274l7.209-7.209a.901.901 0 0 1 1.263.01zm4.64-4.64a5.542 5.542 0 0 1 0 7.838l-.931.931a5.526 5.526 0 0 1-5.31 1.423l1.693-1.691a3.647 3.647 0 0 0 2.3-1.047l.931-.932a3.68 3.68 0 0 0-5.204-5.204l-.931.932a3.65 3.65 0 0 0-1.048 2.3l-1.691 1.69a5.523 5.523 0 0 1 1.422-5.31l.932-.93a5.542 5.542 0 0 1 7.838 0z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Link;
