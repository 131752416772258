/* eslint-disable */
function Icon16Upload(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/16/16-upload" fill={fill}>
            <path id="Shape" d="M12.375 6.815v-.084C12.375 4.36 10.45 2.5 8 2.5c-2.188 0-4.025 1.523-4.287 3.638C2.138 6.646 1 8 1 9.692 1 11.808 2.75 13.5 4.938 13.5H11.5c1.925 0 3.5-1.523 3.5-3.385 0-1.607-1.137-2.877-2.625-3.3zm-3.5 2.114V11.5h-1.75V8.929H4.5L8 5.5l3.5 3.429H8.875z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon16Upload;
