/*
 * StoreProvider Messages
 *
 * This contains all the text for the StoreProvider
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.StoreProvider';

export default defineMessages({
  selectStore: {
    id: `${scope}.selectStore`,
    defaultMessage: 'Select Store',
  },
  allCity: {
    id: `${scope}.allCity`,
    defaultMessage: 'All City',
  },
  reLocation: {
    id: `${scope}.reLocation`,
    defaultMessage: 'ReLocation',
  },
  outOfStockTitle: {
    id: `${scope}.outOfStockTitle`,
    defaultMessage: 'out of stock',
  },
  outOfStockDesc: {
    id: `${scope}.outOfStockDesc`,
    defaultMessage:
      'Dear customer, this product is out of stock in this store, you can choose to continue adding to the shopping cart, the product will be shipped from the flagship store when you buy it, or you can switch stores to buy.',
  },
  outOfStock: {
    id: `${scope}.outOfStock`,
    defaultMessage: 'out of stock',
  },
  someItemsOutOfStock: {
    id: `${scope}.someItemsOutOfStock`,
    defaultMessage: 'Some items out of stock',
  },
  inStock: {
    id: `${scope}.inStock`,
    defaultMessage: 'in stock',
  },
  noMoreStore: {
    id: `${scope}.noMoreStore`,
    defaultMessage: 'No more',
  },
});
