/* eslint-disable */
function Icon24Rma(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-rma" fill={fill}>
            <path id="Combined-Shape" d="M8.906 4l-.785 5.267-1.846-1.768c-1.88 2.203-2.268 5.247-.995 7.818 1.273 2.571 3.98 4.21 6.95 4.21.426 0 .77.33.77.736 0 .407-.344.737-.77.737-3.593.002-6.861-1.994-8.374-5.116-1.512-3.123-.993-6.802 1.33-9.427L3.408 4.753 8.907 4zm2.863-1c5.096.005 9.225 3.96 9.231 8.84a8.553 8.553 0 0 1-2.186 5.703l1.78 1.704-5.5.753.785-5.267 1.846 1.768c1.881-2.203 2.268-5.247.995-7.818-1.273-2.571-3.98-4.21-6.95-4.21-.426 0-.77-.33-.77-.736 0-.407.344-.737.77-.737zm-1.746 5l1.98 4.094L13.99 8h1.51l-2.47 4.678h1.406v.93h-1.74v.948h1.74v.942h-1.74V17h-1.392v-1.502H9.552v-.942h1.752v-.948H9.552v-.93h1.386L8.5 8h1.523z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Rma;
