import PropTypes from 'prop-types';

function OrderPendingIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>cart-remove</title>
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g
          id="Order-Detail-Page---Cancelled"
          transform="translate(-48.000000, -82.000000)"
          fillRule="nonzero"
        >
          <g id="Group-30" transform="translate(0.000000, 64.000000)">
            <g id="cart-remove" transform="translate(48.000000, 18.000000)">
              <path
                d="M40.9997997,33 L5.99979968,33 C5.6438039,33 5.3138039,32.81 5.1348039,32.502 C4.9558039,32.194 4.9548039,31.814 5.1318039,31.504 L9.1318039,24.504 C9.4038039,24.024 10.0158039,23.858 10.4958039,24.132 C10.9748039,24.406 11.1418039,25.017 10.8678039,25.496 L7.7238039,31 L40.9997997,31 C41.5528039,31 41.9997997,31.448 41.9997997,32 C41.9997997,32.552 41.5528039,33 40.9997997,33 Z"
                id="Path"
                fill="#B3B3B3"
              />
              <path
                d="M8.0008039,8 C7.6338039,8 7.2808039,7.798 7.1048039,7.447 L5.3238039,3.885 L0.803803897,2.98 C0.262803897,2.872 -0.0891961027,2.345 0.0198038973,1.804 C0.126803897,1.262 0.655803897,0.913 1.1958039,1.02 L6.1958039,2.02 C6.4988039,2.08 6.7568039,2.277 6.8948039,2.553 L8.8948039,6.553 C9.1418039,7.047 8.9418039,7.648 8.4478039,7.895 C8.3038039,7.966 8.1508039,8 8.0008039,8 Z"
                id="Path"
                fill="#B3B3B3"
              />
              <path
                d="M9.9998039,35 C7.7908039,35 5.9998039,36.791 5.9998039,39 C5.9998039,41.209 7.7908039,43 9.9998039,43 C12.2088039,43 13.9998039,41.209 13.9998039,39 C13.9998039,36.791 12.2088039,35 9.9998039,35 Z"
                id="Path"
                fill="#223E49"
              />
              <path
                d="M36.9998039,35 C34.7908039,35 32.9998039,36.791 32.9998039,39 C32.9998039,41.209 34.7908039,43 36.9998039,43 C39.2088039,43 40.9998039,41.209 40.9998039,39 C40.9998039,36.791 39.2088039,35 36.9998039,35 Z"
                id="Path"
                fill="#223E49"
              />
              <circle id="Oval" fill="#FFFFFF" cx="9.9998039" cy="39" r="2" />
              <circle id="Oval" fill="#FFFFFF" cx="36.9998039" cy="39" r="2" />
              <path
                d="M38.9998039,26 L9.9998039,26 C9.4898039,26 9.0618039,25.617 9.0058039,25.11 L7.0058039,7.11 C6.9728039,6.812 7.0748039,6.515 7.2848039,6.3 C7.4938039,6.086 7.7928039,5.978 8.0878039,6.003 L42.0878039,9.003 C42.3708039,9.028 42.6298039,9.172 42.8008039,9.4 C42.9708039,9.627 43.0358039,9.917 42.9808039,10.195 L39.9808039,25.195 C39.8868039,25.664 39.4768039,26 38.9998039,26 Z"
                id="Path"
                fill="#5A7A84"
              />
              <circle id="Oval" fill="#E86C60" cx="37.9998039" cy="8" r="8" />
              <path
                d="M40.9998039,9 L34.9998039,9 C34.4478039,9 33.9998039,8.552 33.9998039,8 L33.9998039,8 C33.9998039,7.448 34.4478039,7 34.9998039,7 L40.9998039,7 C41.5518039,7 41.9998039,7.448 41.9998039,8 L41.9998039,8 C41.9998039,8.552 41.5518039,9 40.9998039,9 Z"
                id="Path"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

OrderPendingIcon.defaultProps = {
  width: 46,
  height: 43,
  fill: 'currentColor',
};

OrderPendingIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default OrderPendingIcon;
