import axios from 'axios';
import store from 'store';

import { getAuthorization } from 'utils/Auth';

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    // Return only the data
    return response.data ?? response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
}

export const parseToken = () => {
  try {
    const { type, token } = getAuthorization();
    if (type !== 'user' || !token) {
      return {};
    }
    const [headerEncoded, payloadEncoded, signature] = token.split('.');
    const { exp, iat, ...other } = JSON.parse(atob(payloadEncoded));

    return {
      header: JSON.parse(atob(headerEncoded)),
      payload: {
        exp: exp * 1000,
        iat: iat * 1000,
        ...other,
      },
      signature,
      headerEncoded,
      payloadEncoded,
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} method    The method of the request, i.e. GET or POST
 * @param  {string} url       The URL we want to request
 * @param  {object} [data]    The options we want to pass to the request
 * @param {object} params
 * @param {object} config
 * @return {object}           The response data
 */
export default function request(method, url, data, params = {}, config = {}) {
  const auth = getAuthorization();
  const base = process.env.API_URL;
  const version = process.env.API_VERSION;
  const storeCode = store.get('store_code');
  const apiUrl = `${base}${storeCode || 'chn_zh'}/${version}${url}`;

  return axios({
    method,
    url: apiUrl,
    withCredentials: true,
    data,
    params,
    timeout: 60000,
    ...config,
    ...(auth.type === 'user' || auth.type === 'socialmarketer'
      ? {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      : { headers: { 'Content-Type': 'application/json' } }),
  }).then(checkStatus);
}
