/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import { useSelector, useDispatch } from 'react-redux';
import { useIsMutating, useIsFetching } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { CART_QUERY_KEY, useGetCartQuote } from 'containers/CartProvider/queries';
import {
  selectCartItemsSelected,
  selectCartItems,
  toggleCartItem,
  toggleLock1000 as toggleLock1000Action,
  lockCheckout as lockCheckoutAction,
  lock1000,
} from 'containers/CartProvider/slices';
import { calcSubTotal, calcPvTotal } from 'utils/helpers';
import { selectShowMiniCart } from 'containers/App/slices';
import usePurchasePermissions from 'utils/Hooks/usePurchasePermissions';
import useDeepCompareMemo from 'utils/Hooks/updateEffect/useDeepCompareMemo';

const useShoppingCart = isManagement => {
  const dispatch = useDispatch();
  const selectedItems = useSelector(selectCartItemsSelected);
  const lockCheckout = useSelector(lockCheckoutAction);
  const isLock1000 = useSelector(lock1000);
  const showMiniCart = useSelector(selectShowMiniCart);
  const { data: cartData = {} } = useGetCartQuote();
  const { purchasePermissions, findStandardSets } = usePurchasePermissions();
  const isMutatingUpdateCartItem = useIsMutating([CART_QUERY_KEY.UPDATE]);
  const isMutatingRemoveFromCart = useIsMutating([CART_QUERY_KEY.DELETE]);
  const isMutatingAddToCartQuote = useIsMutating([CART_QUERY_KEY.ADD]);
  const isFetching = useIsFetching([CART_QUERY_KEY.GET]);

  const isLoading = !!(
    isMutatingUpdateCartItem ||
    isMutatingRemoveFromCart ||
    isMutatingAddToCartQuote ||
    isFetching
  );

  const cartQuote = useDeepCompareMemo(() => get(cartData, 'items', []), [cartData]);

  const { cartQuantity, specialCartList, generalCartList, carts } = useDeepCompareMemo(() => {
    const specialList = [];
    const generalList = [];
    let list = [];
    let quantity = 0;

    if (isArray(cartQuote)) {
      quantity = cartQuote.length > 0 ? cartQuote.reduce((acc, curr) => acc + curr.qty, 0) : 0;

      cartQuote.forEach(item => {
        if (purchasePermissions(item, 'extension_attributes')) {
          generalList.push(item);
        } else {
          specialList.push(item);
        }
      });

      if (!isEmpty(specialList)) {
        const nextItem = { ...specialList[specialList.length - 1], divider: true };
        specialList[specialList.length - 1] = nextItem;
      }

      list = [...specialList, ...generalList];

      const nextSelectedItems = selectedItems.filter(selectedItem =>
        cartQuote.some(i => i.item_id === selectedItem),
      );
      dispatch(selectCartItems(nextSelectedItems));
    }

    return {
      cartQuantity: quantity,
      specialCartList: specialList,
      generalCartList: generalList,
      carts: list,
    };
  }, [cartQuote]);

  const { isAllChecked, subTotal, pvTotal, cartSelectedQuantity } = useDeepCompareMemo(() => {
    const { standardSets } = findStandardSets;
    const quote = isEmpty(standardSets) ? generalCartList : cartQuote;
    let selectedQuantity = 0;
    if (selectedItems.length) {
      const selectedCartItems = quote.filter(i => selectedItems.indexOf(i.item_id) !== -1);
      selectedQuantity = selectedCartItems.reduce((acc, curr) => acc + curr.qty, 0);
    }

    const getIsAllChecked = () => {
      if (isManagement) {
        return cartQuote.length === selectedItems.length;
      }

      return isEmpty(generalCartList) ? false : quote.length === selectedItems.length;
    };

    return {
      // isAllChecked: isEmpty(generalCartList)
      //   ? false
      //   : (isManagement ? cartQuote : quote).length === selectedItems.length,
      isAllChecked: getIsAllChecked(),
      subTotal: calcSubTotal(selectedItems, quote),
      pvTotal: calcPvTotal(selectedItems, quote),
      cartSelectedQuantity: selectedQuantity,
    };
  }, [cartQuote, selectedItems, findStandardSets, generalCartList, isManagement]);

  const toggleAllItems = checked => {
    const nextSelectedItems = [];
    const { standardSets } = findStandardSets;
    if (checked) {
      const quote = isEmpty(standardSets) ? generalCartList : cartQuote;
      (isManagement ? cartQuote : quote).forEach(i => {
        nextSelectedItems.push(i.item_id);
      });
    }

    dispatch(selectCartItems(nextSelectedItems));
  };

  const toggleLock1000 = payload => dispatch(toggleLock1000Action(payload));

  const toggleItem = payload => dispatch(toggleCartItem(payload));

  return {
    quote: cartData,
    cartItems: carts,
    selectedItems,
    toggleAllItems,
    toggleLock1000,
    toggleItem,
    isAllChecked,
    cartQuantity,
    cartSelectedQuantity,
    subTotal,
    pvTotal,
    lockCheckout,
    isLock1000,
    isLoading,
    showMiniCart,
    specialCartList,
    generalCartList,
    isEligibility: !isEmpty(specialCartList),
  };
};

export default useShoppingCart;
