import PropTypes from 'prop-types';

function PaperIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 96 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="Order-List---Empty" transform="translate(-139.000000, -220.000000)">
          <g id="Group" transform="translate(16.000000, 220.000000)">
            <g id="paper" transform="translate(123.000000, 0.000000)">
              <path
                d="M72.5714286,0 L10,0 C15.7586571,0 20.4285714,4.67269565 20.4285714,10.4347826 L20.4285714,85.5652174 C20.4285714,91.3273043 25.0984857,96 30.8571429,96 L83,96 L83,10.4347826 C83,4.67269565 78.3300857,0 72.5714286,0 Z"
                id="Path"
                fill="#E1E1E1"
              />
              <path
                d="M96,86 C96,91.522 91.3053226,96 85.516129,96 L85.516129,96 L31,96 C36.7891935,96 41.483871,91.522 41.483871,86 L41.483871,86 Z M49.8888889,69 C51.0542222,69 52,69.896 52,71 C52,72.104 51.0542222,73 49.8888889,73 L35.1111111,73 C33.9457778,73 33,72.104 33,71 C33,69.896 33.9457778,69 35.1111111,69 L49.8888889,69 Z M68.8888889,52 C70.0542222,52 71,52.896 71,54 C71,55.104 70.0542222,56 68.8888889,56 L35.1111111,56 C33.9457778,56 33,55.104 33,54 C33,52.896 33.9457778,52 35.1111111,52 L68.8888889,52 Z M68.8888889,36 C70.0542222,36 71,36.896 71,38 C71,39.104 70.0542222,40 68.8888889,40 L35.1111111,40 C33.9457778,40 33,39.104 33,38 C33,36.896 33.9457778,36 35.1111111,36 L68.8888889,36 Z M68.8888889,19 C70.0542222,19 71,19.896 71,21 C71,22.104 70.0542222,23 68.8888889,23 L35.1111111,23 C33.9457778,23 33,22.104 33,21 C33,19.896 33.9457778,19 35.1111111,19 L68.8888889,19 Z M10.5,0 C16.2981,0 21,4.478 21,10 L21,10 L0,10 C0,4.478 4.7019,0 10.5,0 Z"
                id="Combined-Shape"
                fill="#A7A9AC"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

PaperIcon.defaultProps = {
  width: 96,
  height: 96,
  fill: 'currentColor',
};

PaperIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PaperIcon;
