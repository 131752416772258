import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGetStoreList } from 'containers/StoreProvider/queries';
import useGetVendorCode from 'utils/Hooks/useGetVendorCode';

const useGetVendor = () => {
  const vendorCode = useGetVendorCode();
  const { data: quote } = useGetStoreList();

  return useMemo(() => {
    if (isEmpty(quote) || !vendorCode) {
      return {};
    }

    return quote.find(item => item.extension_attributes?.vendor_code === vendorCode) || {};
  }, [vendorCode, quote]);
};

export default useGetVendor;
