import { useQuery } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import { isSignedIn } from 'utils/Auth';
import useGetVendorCode from 'utils/Hooks/useGetVendorCode';

export const getQrCodeQuery = (id = '', vendorCode = undefined) =>
  request('GET', `/wechat_share/qrcode?${id}&vendorCode=${vendorCode}`);
export const getMyShareDownlineQuery = vendorCode =>
  request('GET', `/wechat_share/downline?vendorCode=${vendorCode}`);

export const useGetQrCode = id => {
  const vendorCode = useGetVendorCode();
  return useQuery(
    ['sharingProvider.qrCode', { id, vendorCode }],
    () =>
      getQrCodeQuery(id, vendorCode).then(response =>
        response && response.length > 200 ? response : null,
      ),
    { ...querySetting, enabled: !!(id && vendorCode) },
  );
};

export const useGetMyShareDownline = () => {
  const vendorCode = useGetVendorCode();
  return useQuery(
    'sharingProvider.myShareDownline',
    () => getMyShareDownlineQuery(vendorCode).then(response => response.items),
    {
      ...querySetting,
      enabled: isSignedIn() && !!vendorCode,
    },
  );
};
