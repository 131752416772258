/* eslint-disable */
function Icon24FavouriteOn(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g>
				<g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g id="2.Icons/24/24-favourite-on" fill={fill}>
						<path id="Path" d="M20.821 9.259l-5.605-.81-2.504-5.046a.825.825 0 0 0-1.42 0L8.784 8.45l-5.605.81a.784.784 0 1 0-.44 1.342l4.056 3.93-.957 5.55a.785.785 0 0 0 .315.769c.244.176.567.199.834.06L12 18.29l5.013 2.62c.267.139.59.116.834-.06a.785.785 0 0 0 .315-.77l-.957-5.55 4.056-3.93a.784.784 0 0 0-.438-1.341h-.002z"/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Icon24FavouriteOn;
