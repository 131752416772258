/* eslint-disable */
function Icon24About(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-about" fill={fill}>
            <path id="Shape" d="M20.182 3H3.818A.818.818 0 0 0 3 3.818v16.364c0 .452.366.818.818.818h16.364a.818.818 0 0 0 .818-.818V3.818A.818.818 0 0 0 20.182 3zM13.83 17.38c-.552.226-2.464 1.171-3.565.166a1.472 1.472 0 0 1-.492-1.14c.114-1.17.371-2.319.766-3.42.109-.37.175-.75.197-1.136 0-.605-.222-.767-.823-.767-.315.02-.624.095-.914.223l.167-.692A6.203 6.203 0 0 1 11.354 10c1.059 0 1.836.547 1.836 1.588-.004.402-.056.801-.155 1.189l-.61 2.232c-.125.452-.353 1.447 0 1.742A1.79 1.79 0 0 0 14 16.689l-.17.692zM13 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24About;
