/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * SharingDialogContent
 *
 */

import { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { wrapText, parseProductImage } from 'utils/helpers';
import logo from 'images/logo.png';
import swooshPng from 'images/share/swoosh.png';
import bottomSwooshPng from 'images/share/bottom-swoosh.png';
import { useGetQrCode } from 'containers/SharingProvider/queries';
import FormatMessage from 'components/FormatMessage';
import useShare from 'utils/Hooks/useShare';
import messages from '../messages';
import ProductShareCard from './ProductShareCard';
import BlogShareCard from './BlogShareCard';
import SharingBottom from './SharingBottom';
import { createImage } from '../utils';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      minHeight: 'calc(100vh - 64px)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2, 3.5),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  recommend: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  canvas: {
    position: 'fixed',
    top: '100%',
    left: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
  },
}));

function SharingDialogContent({ type, title, pv, subtitle, media, blogId, sku, sharingRemark }) {
  const classes = useStyles();
  const canvasRef = useRef();
  const cardRef = useRef();

  const { onShare, onDownload, onCopy } = useShare();
  const { data } = useGetQrCode(blogId ? `blog_id=${blogId}` : `sku=${sku}`);

  const drawProduct = async ({ canvas, ctx, width, height }) => {
    // card
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    // card bottom
    ctx.rect(0, 190, width, height);
    ctx.fillStyle = '#FFF3E0';
    ctx.fillRect(0, 190, width, height);
    ctx.restore();

    try {
      // card wave
      ctx.restore();
      const { image: swoosh } = await createImage(swooshPng);
      ctx.drawImage(swoosh, 0, 160, width, (86 * width) / 750);

      // product image
      ctx.restore();
      const image0 = document.getElementById('image0');
      const { w, h } = await createImage(image0.src, 188);
      ctx.drawImage(image0, (width - w) / 2, 8 + (188 - h) / 2, w, h);

      // logo
      ctx.restore();
      const { image: logoEl } = await createImage(logo);
      ctx.drawImage(logoEl, 16, 16, 42, 32);

      // title
      ctx.font = 'bold 16px Roboto';
      ctx.fillStyle = '#090909';
      const { y } = wrapText(canvas, ctx, title, 16, 236, width - 108, 24);

      // price
      ctx.textAlign = 'left';
      ctx.fillStyle = '#CD0039';
      ctx.font = 'bold 16px Roboto';
      ctx.fillText(`￥${subtitle}`, 16, y + 24);
      ctx.restore();

      // qrcode
      ctx.restore();
      const { image: qrcode } = await createImage(data);
      ctx.drawImage(qrcode, width - 84, 236, 68, 68);

      ctx.restore();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const drawBlog = async ({ canvas, ctx, width, height }) => {
    // card
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);
    ctx.restore();

    try {
      // blog image
      ctx.restore();
      const image1 = document.getElementById('image1');
      const { image } = await createImage(image1.src, false, true);
      ctx.drawImage(image, 0, 0, width, 200);

      // wave
      ctx.restore();
      const { image: swoosh } = await createImage(bottomSwooshPng);
      const swooshH = parseInt((86 * width) / 750, 10);
      ctx.drawImage(swoosh, 0, 200 - swooshH, width, swooshH);

      // title
      ctx.font = 'bold 16px Roboto';
      ctx.fillStyle = '#090909';
      const { y } = wrapText(canvas, ctx, title, 16, 216, width - 108, 24);

      // sub title
      ctx.textAlign = 'left';
      ctx.fillStyle = '#808080';
      ctx.font = 'normal 16px Roboto';

      wrapText(canvas, ctx, subtitle, 16, y + 32, width - 108, 24);

      // qrcode
      ctx.restore();
      const { image: qrcode } = await createImage(data);
      ctx.drawImage(qrcode, width - 84, height - 84, 68, 68);

      ctx.restore();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const drawCanvas = async canvas => {
    const ctx = canvas.getContext('2d');
    const dpr = window.devicePixelRatio;
    const width = cardRef.current.offsetWidth;
    const height = cardRef.current.offsetHeight;

    canvas.width = width * dpr;
    canvas.height = height * dpr;
    ctx.scale(dpr, dpr);
    if (type === 'product') {
      await drawProduct({ canvas, ctx, width, height });
    }
    if (type === 'blog') {
      await drawBlog({ canvas, ctx, width, height });
    }
  };

  const handleSelect = key => {
    if (key === 'share')
      onShare({
        title,
        text: title,
      });
    if (key === 'download') onDownload(canvasRef.current, drawCanvas);
    if (key === 'copy') onCopy();
  };

  return (
    <>
      <canvas ref={canvasRef} className={classes.canvas} />
      <Box className={classes.root}>
        <Box className={classes.content}>
          {type === 'product' && (
            <ProductShareCard
              title={title}
              price={subtitle}
              pv={pv}
              qrCode={data}
              picture={parseProductImage(media?.[0]?.file)}
              ref={cardRef}
            />
          )}
          {type === 'blog' && (
            <BlogShareCard
              title={title}
              subtitle={subtitle}
              qrCode={data}
              picture={media}
              ref={cardRef}
            />
          )}
          <Box className={classes.recommend}>
            <Box my={2} pb={{ xs: 5, md: 0 }} color={{ xs: 'text.secondary', md: 'text.primary' }}>
              <FormatMessage
                variant="body1"
                message={sharingRemark ?? messages.recommendToYourFriends}
              />
            </Box>
            <SharingBottom onSelect={handleSelect} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

SharingDialogContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pv: PropTypes.number,
  media: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  type: PropTypes.string,
  blogId: PropTypes.string,
  sku: PropTypes.string,
  sharingRemark: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SharingDialogContent;
