/* eslint-disable */
function Icon24Download(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-download" fill={fill}>
            <path id="Shape" d="M18.25 10.277v-.123C18.25 6.708 15.5 4 12 4 8.875 4 6.25 6.215 5.875 9.292 3.625 10.031 2 12 2 14.462 2 17.538 4.5 20 7.625 20H17c2.75 0 5-2.215 5-4.923 0-2.339-1.625-4.185-3.75-4.8zM12 18l-5-5.143h3.75V9h2.5v3.857H17L12 18z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Download;
