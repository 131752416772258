/* eslint-disable */
function Icon16AngleUp(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M11.359 10.671L8.345 7.8a.5.5 0 0 0-.69 0l-3.014 2.872a.5.5 0 0 1-.69 0l-.571-.544a.5.5 0 0 1 0-.724l4.275-4.074a.5.5 0 0 1 .69 0l4.275 4.074a.5.5 0 0 1 0 .724l-.571.544a.5.5 0 0 1-.69 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16AngleUp;
