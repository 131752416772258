/* eslint-disable */
function Icon24DC(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="Group" transform="translate(5.5 2)">
            <path id="Path" d="M6.5 14.762c-1.877 0-3.605-.661-5-1.762v7l5-2.619 5 2.619v-7c-1.395 1.1-3.123 1.762-5 1.762z" fill="#A7A9AC" />
            <path id="Combined-Shape" d="M6.5 0a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13zm0 2.438L5.245 5.112l-2.808.428L4.47 7.623l-.48 2.94L6.5 9.174l2.51 1.389-.48-2.94 2.033-2.082-2.808-.428L6.5 2.437z" fill="#CD0039" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24DC;
