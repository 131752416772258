/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import messages from './messages';

export const CARD_TABS = [
  {
    message: messages.DCInvitation,
  },
  {
    message: messages.RCInvitation,
  },
];

export const INVITE_DESKTOP_ACTIONS = [
  {
    key: 'copy',
    message: messages.copyLink,
  },
  {
    key: 'download',
    message: messages.savePicture,
  },
];

export const INVITE_MOBILE_ACTIONS = [
  {
    key: 'share',
    message: messages.shareWithFriends,
  },
  {
    key: 'download',
    message: messages.savePicture,
  },
  {
    key: 'copy',
    message: messages.copyAppId,
  },
];

export const SHARE_DESKTOP_ACTIONS = [
  {
    key: 'copy',
    message: messages.copyLink,
  },
  {
    key: 'download',
    message: messages.savePicture,
  },
];

export const SHARE_MOBILE_ACTIONS = [
  {
    key: 'share',
    message: messages.shareWithFriends,
  },
  {
    key: 'download',
    message: messages.savePicture,
  },
  {
    key: 'copy',
    message: messages.copyLink,
  },
];

export const SHARING_SHOPPING_LIST_ACTIONS = [
  { key: 'share', message: messages.shareWithFriends },
  { key: 'download', message: messages.savePicture },
  { key: 'copy', message: messages.copyLink },
];
