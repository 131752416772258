/* eslint-disable */
function Icon24Address(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-address" fill={fill}>
            <path id="Combined-Shape" d="M18.46 15.414l2.454 4.5a.697.697 0 0 1-.036.73.838.838 0 0 1-.696.356H3.818a.84.84 0 0 1-.696-.355.697.697 0 0 1-.036-.73l2.455-4.5c.202-.37.693-.52 1.098-.335.404.185.567.636.365 1.006L5.142 19.5h13.716l-1.862-3.414c-.202-.37-.039-.822.365-1.007.405-.185.896-.034 1.098.335zM12 3c2.95 0 6 2.203 6 5.89 0 3.386-4.908 8.338-5.467 8.893a.757.757 0 0 1-1.066 0C10.909 17.228 6 12.276 6 8.89 6 5.203 9.05 3 12 3zm0 4.417c-.829 0-1.5.66-1.5 1.473 0 .813.671 1.472 1.5 1.472s1.5-.659 1.5-1.472c0-.814-.671-1.473-1.5-1.473z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Address;
