import store from 'store';
import expirePlugin from 'store/plugins/expire';

store.addPlugin(expirePlugin);

const BUY_NOW = 'continue_checkout_product';
const PROCEED_TO_CHECKOUT = 'continue_checkout_cart';

const setBuyNow = payload => store.set(BUY_NOW, payload, new Date().getTime() + 180000);

const deleteBuyNow = () => store.remove(BUY_NOW);

const getBuyNow = () => store.get(BUY_NOW) || '';

const setProceedToCheckout = payload =>
  store.set(PROCEED_TO_CHECKOUT, payload, new Date().getTime() + 180000);

const deleteProceedToCheckout = () => store.remove(PROCEED_TO_CHECKOUT);

const getProceedToCheckout = () => store.get(PROCEED_TO_CHECKOUT) || '';

export {
  setBuyNow,
  deleteBuyNow,
  getBuyNow,
  setProceedToCheckout,
  deleteProceedToCheckout,
  getProceedToCheckout,
};
