/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/* eslint-disable react/prop-types */
function Icon16QrCode(props) {
  const fill = props.fill || 'currentColor';
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={fill}>
        <g id="2.Icons/16/16-qr-code" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            id="Combined-Shape"
            d="M6.286 2c.428 0 .714.286.714.714v3.572C7 6.714 6.714 7 6.286 7H2.714C2.286 7 2 6.714 2 6.286V2.714C2 2.286 2.286 2 2.714 2h3.572zM5.57 3.429H3.43V5.57H5.57V3.43zM5 4v1H4V4h1zm1.286 5c.428 0 .714.286.714.714v3.572c0 .428-.286.714-.714.714H2.714C2.286 14 2 13.714 2 13.286V9.714C2 9.286 2.286 9 2.714 9h3.572zm-.715 1.429H3.43v2.142H5.57V10.43zM5 11v1H4v-1h1zm8.286-9c.428 0 .714.286.714.714v3.572c0 .428-.286.714-.714.714H9.714C9.286 7 9 6.714 9 6.286V2.714C9 2.286 9.286 2 9.714 2h3.572zm-.715 1.429H10.43V5.57h2.142V3.43zM12 4v1h-1V4h1zm1.2 5c.48 0 .8.3.8.75V14H9v-1.5h3.4v-2H10V9h3.2zM12 11v1h-1v-1h1z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon16QrCode;
