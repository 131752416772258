/* eslint-disable */
function Download(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill={fill}>
				<g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g id="2.Icons/12/12-download" fill={fill} transform="translate(-1 -1)">
						<path id="Combined-Shape" d="M2.25 7.5v1.8h7.5V7.5H11v2.4c0 .36-.25.6-.625.6h-8.75C1.25 10.5 1 10.26 1 9.9V7.5h1.25zm4.375-6v5.017l2.5-2.334L10 5 6.437 8.325A.587.587 0 0 1 6 8.5a.587.587 0 0 1-.438-.175L2 5l.875-.817 2.5 2.334V1.5h1.25z"/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Download;
