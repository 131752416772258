/* eslint-disable */
function Icon12Down(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M4.273 2v1.636H2.636v5.728h5.728V7.727H10v2.455a.818.818 0 0 1-.818.818H1.818A.818.818 0 0 1 1 10.182V2.818C1 2.366 1.366 2 1.818 2h2.455zM9.76 1c.327 0 .64.13.872.362a1.233 1.233 0 0 1 .005 1.75L6.625 7.125 4 8l.875-2.625 4.013-4.013C9.12 1.13 9.433 1 9.761 1z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12Down;
