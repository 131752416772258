import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import isEmpty from 'lodash/isEmpty';
import FormatMessage from 'components/FormatMessage';
import appMessages from 'containers/App/messages';
import MobileSpecialProduct from 'components/Product/components/MobileSpecialProduct';
import Buttons from 'components/Buttons/Buttons';
import { CloseIcon } from 'components/Icons';
import FormatSvgIcon from 'components/FormatMessage/FormatSvgIcon';
import useSpecial from 'components/Product/hooks/useSpecial';
import productMessages from 'containers/ProductPage/messages';
import FadeInVariantList from 'components/Variant/FadeInVariantList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    height: 68,
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      height: 50,
      lineHeight: '50px',
    },
  },
  content: {
    marginTop: theme.spacing(3),
    overflowY: 'auto',
    flex: 1,
  },
  footer: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addToCart: {
    width: 311,
  },
  addToCartText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.white,
  },
  cancelBtn: {
    padding: 0,
    marginRight: theme.spacing(2),
    minHeight: 'auto',
    height: 'auto',
  },
  drawerRoot: {
    zIndex: '1050 !important',
  },
  drawer: {
    height: 515,
    overflow: 'auto',
    borderRadius: '12px 12px 0 0',
  },
  anchorBottom: {
    paddingBottom: props => theme.mixins.toolbar.compatibleHeight(props.spaceBottom),
  },
}));

function MobileSpecialProducts({ onClose }) {
  const classes = useStyles();
  const { selected, checked, specialProducts, loading, selectedSpecial, addToCart, changeSpecial } =
    useSpecial();

  const handleAddToCart = async () => {
    await addToCart();
    onClose?.();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className="title">
          <FormatMessage bold={700} message={productMessages.buyExclusiveBundles} />
          <Buttons size="small" onClick={onClose}>
            <FormatMessage message={appMessages.close} color="textPrimary" variant="h4" />
            <FormatSvgIcon
              icon={CloseIcon}
              color="primary"
              height={16}
              style={{ marginLeft: 8, lineHeight: '20px' }}
              viewBox="0 0 12 12"
              iconStyle={{ fontSize: 12 }}
            />
          </Buttons>
        </Box>
        <FormatMessage
          variant="h6"
          color="textSecondary"
          message={productMessages.buyExclusiveBundlesNotice}
        />
      </Box>
      <Box className={classes.content}>
        <FadeInVariantList list={specialProducts}>
          {product => (
            <MobileSpecialProduct
              product={product}
              onChange={changeSpecial}
              onSelected={selectedSpecial}
              checked={checked.includes(product.id)}
            />
          )}
        </FadeInVariantList>
      </Box>
      <Box className={classes.footer}>
        <Buttons variant="text" color="primary" className={classes.cancelBtn} onClick={onClose}>
          <FormatMessage message={productMessages.iSee} />
        </Buttons>
        <Buttons
          variant="contained"
          color="primary"
          disabled={isEmpty(selected) || loading}
          className={classes.addToCart}
          onClick={handleAddToCart}
          loading={loading}
        >
          <FormatMessage message={appMessages.addToCart} className={classes.addToCartText} />
        </Buttons>
      </Box>
    </Box>
  );
}

MobileSpecialProducts.propTypes = {
  onClose: PropTypes.func,
};

export { MobileSpecialProducts };

const MobileSpecialProductDrawer = forwardRef((props, ref) => {
  const { open, spaceBottom, onClose, classes: propsClasses, ...other } = props;
  const classes = useStyles({ spaceBottom, ...props });

  return (
    <Drawer
      anchor="bottom"
      open={open}
      transitionDuration={{ enter: 250, exit: 100 }}
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawer,
        paperAnchorBottom: classes.anchorBottom,
        ...propsClasses,
      }}
      ref={ref}
      {...other}
    >
      <MobileSpecialProducts onClose={onClose} />
    </Drawer>
  );
});

MobileSpecialProductDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  spaceBottom: PropTypes.number,
  classes: PropTypes.object,
};

export default MobileSpecialProductDrawer;
