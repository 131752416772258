import PropTypes from 'prop-types';

function ErrorIcon({ width, height, fill, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---12.-DCFC-Management" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="Group-7" transform="translate(-155.000000, -50.000000)">
          <g id="f-remove" transform="translate(155.000000, 50.000000)">
            <path
              d="M16,32 C7.17730783,32 0,24.8226922 0,16 C0,7.17730783 7.17730783,0 16,0 C24.8226922,0 32,7.17730783 32,16 C32,24.8226922 24.8226922,32 16,32 Z"
              id="Path"
              fill="#BE1800"
            />
            <polygon
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
              points="23.6521739 20.8695652 18.7826087 16 23.6521739 11.1304348 20.8695652 8.34782609 16 13.2173913 11.1304348 8.34782609 8.34782609 11.1304348 13.2173913 16 8.34782609 20.8695652 11.1304348 23.6521739 16 18.7826087 20.8695652 23.6521739"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

ErrorIcon.defaultProps = {
  width: 32,
  height: 32,
  fill: 'currentColor',
  viewBox: '0 0 32 32',
};

ErrorIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
};

export default ErrorIcon;
