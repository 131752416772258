/* eslint-disable */
function Icon16Camera(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M13.25 4.4h-1.647l-1.066-1.958A.887.887 0 0 0 9.75 2h-3.5a.887.887 0 0 0-.783.442L4.397 4.4H2.75C1.784 4.4 1 5.116 1 6v6.4c0 .884.784 1.6 1.75 1.6h10.5c.966 0 1.75-.716 1.75-1.6V6c0-.884-.784-1.6-1.75-1.6zM8 11.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Camera;
