/* eslint-disable */
function Contract(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const viewBox = props.viewBox || '0 0 18 18'

	return (
		<svg height={height} width={width} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---11.-Apply-for-FC" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="Apply-for-FC---Lobby---All-Empty" fill={fill} transform="translate(-35 -481)">
				<g id="Group-2-Copy" transform="translate(16 454)">
					<g id="2.Icons/24/24-account" transform="translate(16 24)">
						<path id="Combined-Shape" d="M17.857 3C18.488 3 19 3.504 19 4.125v6.75l-2.286-1.688V5.25H5.286v13.5h4.571V21H4.143A1.134 1.134 0 0 1 3 19.875V4.125C3 3.504 3.512 3 4.143 3zM16.5 12l4.5 3.375V21h-3.336v-3.375h-2.289V21H12v-5.625L16.5 12zM10 14v1H7v-1h3zm4-3v1H7v-1h7zm0-3v1H7V8h7z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</svg>
	);
}

export default Contract;
