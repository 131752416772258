/* eslint-disable */
function Icon24Share(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-share" fill={fill}>
            <path id="Path" d="M16.571 9.75C18.461 9.75 20 8.236 20 6.375 20 4.515 18.462 3 16.571 3c-1.89 0-3.428 1.514-3.428 3.375 0 .25.033.494.086.73L9.549 9.37a3.427 3.427 0 0 0-2.12-.744C5.539 8.625 4 10.139 4 12c0 1.86 1.538 3.375 3.429 3.375.804 0 1.534-.286 2.12-.744l3.68 2.264a3.35 3.35 0 0 0-.086.73c0 1.86 1.538 3.375 3.428 3.375S20 19.486 20 17.625c0-1.86-1.538-3.375-3.429-3.375-.804 0-1.534.286-2.12.744l-3.68-2.264a3.35 3.35 0 0 0 .086-.73c0-.25-.033-.494-.086-.73l3.68-2.264a3.427 3.427 0 0 0 2.12.744z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Share;
