/* eslint-disable */
function Icon24StoreFlagship(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="2.Icons/24/24-store-flagship" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M5,3 C5.552,3 6,3.336 6,3.75 L6,3.75 L6,20.25 C6,20.664 5.552,21 5,21 C4.448,21 4,20.664 4,20.25 L4,20.25 L4,3.75 C4,3.336 4.448,3 5,3 Z M21,4 L18.6666667,9.5 L21,15 L7.77777778,15 C7.34844444,15 7,14.648 7,14.2142857 L7,14.2142857 L7,4.78571429 C7,4.352 7.34844444,4 7.77777778,4 L7.77777778,4 L21,4 Z" id="Combined-Shape" fill={fill}></path>
    </g>
    </svg>
  );
}

export default Icon24StoreFlagship;