/**
 * @author zhengji.su
 * @description Variant
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import isUndefined from 'lodash/isUndefined';

function Variant({ focus: propFocus, ...other }) {
  const [focus, setFocus] = useState(false);

  useEffect(() => (isUndefined(propFocus) ? setFocus(true) : setFocus(propFocus)), [propFocus]);

  return <motion.div initial={false} animate={focus ? 'open' : 'closed'} {...other} />;
}

Variant.propTypes = {
  focus: PropTypes.bool,
};

export default Variant;
