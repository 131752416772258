import PropTypes from 'prop-types';

function LogoIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 61"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.151 32.6325C78.6371 32.6325 79.6278 33.745 79.6278 35.1047C79.6278 36.4644 78.5133 37.7005 77.151 37.7005C75.7888 37.7005 74.6743 36.588 74.6743 35.1047C74.6743 33.745 75.7888 32.6325 77.151 32.6325ZM77.151 32.2617C75.665 32.2617 74.3027 33.4978 74.3027 35.1047C74.3027 36.7116 75.665 37.9477 77.151 37.9477C78.7609 37.9477 79.9993 36.7116 79.9993 35.1047C79.9993 33.4978 78.7609 32.2617 77.151 32.2617ZM76.5318 33.8686H77.151C77.3987 33.8686 77.7702 33.8686 77.7702 34.3631C77.7702 34.8575 77.3987 34.8575 77.151 34.8575H76.5318V33.8686ZM76.1603 36.588H76.5318V35.2283H77.0272C77.5226 35.2283 77.6464 35.3519 77.6464 35.8464C77.6464 36.3408 77.7702 36.4644 77.7702 36.588H78.2656C78.1418 36.3408 78.1417 35.97 78.1417 35.5991C78.1417 35.2283 78.0179 35.1047 77.6464 34.9811C78.2656 34.8575 78.2656 34.3631 78.2656 34.2395C78.2656 33.4978 77.3987 33.4978 77.2749 33.4978H76.1603V36.588Z"
        fill="#B1B3B4"
      />
      <path
        d="M3.21981 49.3197C4.70588 45.3642 6.31579 41.4087 8.42105 37.7005C17.3375 35.1047 19.0712 37.0825 19.0712 34.7339C19.0712 34.6103 19.195 34.1159 18.3282 33.8686C17.0898 33.4978 13.9938 33.8686 10.1548 34.7339C11.8885 31.5201 13.87 28.3063 16.2229 25.4633C21.4241 24.3508 23.2817 23.98 24.1486 23.98C25.0155 23.98 25.0155 24.2272 26.5015 24.2272C27.1207 24.2272 27.6161 23.6092 27.6161 22.8675C27.6161 21.755 25.7585 21.5078 24.8916 21.5078C22.1672 21.5078 17.709 22.3731 15.1084 22.9911C14.1176 23.2383 11.8885 23.6092 11.8885 25.0925C11.8885 25.4633 12.1362 25.9577 12.6316 25.9577C15.2322 21.6314 6.93498 35.4755 4.21053 40.2963C3.34365 42.1504 0 49.3197 0 51.0502C0 51.7918 0.619195 52.7807 1.48607 52.7807C2.2291 52.7807 2.47678 52.1627 2.60062 51.5446L3.21981 49.3197Z"
        fill="#B1B3B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4805 46.971C15.4805 47.4655 15.852 48.0835 16.4712 48.0835C17.4619 48.0835 17.5857 46.971 17.8334 46.2294C19.9387 38.9365 23.6538 35.3518 23.6538 34.363C23.6538 33.8685 23.2823 33.4977 22.787 33.4977C21.3009 33.4977 15.4805 44.2516 15.4805 46.971ZM27.1213 24.9688C26.0068 24.9688 24.3969 27.0701 24.3969 28.1826C24.3969 28.8006 24.7684 29.295 25.3876 29.295C26.626 29.295 28.2359 27.1937 28.2359 26.0812C28.2359 25.3396 27.8644 24.9688 27.1213 24.9688Z"
        fill="#B1B3B4"
      />
      <path
        d="M26.6247 31.6436C26.0055 31.7672 25.0148 31.8908 25.0148 32.7561C25.0148 33.3741 25.8817 33.7449 26.377 33.7449C26.7486 33.7449 26.9962 33.6213 27.3678 33.6213C24.2718 39.4309 23.5287 42.0267 23.5287 44.6225C23.4049 46.7238 24.891 47.7127 26.0055 47.7127C28.2346 47.7127 30.0922 45.3641 31.2068 43.7572C31.5783 43.2628 33.0644 41.0378 33.0644 40.4198C33.0644 40.049 32.6928 39.8017 32.3213 39.8017C31.826 39.8017 31.5783 40.1726 31.3306 40.5434C30.4637 42.0267 28.3585 45.3641 26.7486 45.3641C26.0055 45.3641 25.7578 44.7461 25.7578 44.128C25.7578 41.1614 28.73 35.3518 30.3399 32.8797C32.0736 32.3852 33.8074 31.6436 35.5411 31.52C36.2842 31.3964 37.151 31.3964 37.151 30.5311C37.151 29.6659 36.5318 29.4186 35.7888 29.4186C34.6743 29.4186 33.312 29.7895 32.1975 30.1603C32.6928 29.1714 34.7981 26.9465 34.7981 25.9576C34.7981 25.4632 34.3027 24.9688 33.9312 24.9688C32.8167 24.9688 29.5969 30.1603 28.8538 31.2728L26.6247 31.6436Z"
        fill="#B1B3B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.2207 46.1058C43.2207 46.6002 43.5922 47.2183 44.2114 47.2183C45.2021 47.2183 45.326 46.1058 45.5736 45.3641C47.6789 38.0713 51.3941 34.4866 51.3941 33.4977C51.3941 33.0033 51.0226 32.6325 50.5272 32.6325C48.9173 32.6325 43.2207 43.3864 43.2207 46.1058ZM54.8616 24.1035C53.747 24.1035 52.1371 26.2049 52.1371 27.3173C52.1371 27.9354 52.5086 28.4298 53.1278 28.4298C54.3662 28.4298 55.9761 26.3285 55.9761 25.216C55.9761 24.4743 55.6046 24.1035 54.8616 24.1035Z"
        fill="#B1B3B4"
      />
      <path
        d="M55.4803 33.8689C54.118 33.8689 50.4029 41.6562 49.6598 43.2631C49.2883 43.8811 48.793 44.9936 48.793 45.7353C48.793 46.3533 49.1645 46.8477 49.7837 46.8477C50.7744 46.8477 51.2697 45.488 51.6413 44.87C53.0035 42.3978 54.6134 40.0493 56.9663 38.566L57.0902 38.6896C55.8518 40.5437 54.7372 43.0159 54.7372 45.3644C54.7372 46.7241 55.2326 49.4435 56.9663 49.4435C57.4617 49.4435 57.9571 48.9491 57.9571 48.4546C57.9571 47.8366 56.9663 47.095 56.9663 45.2408C57.214 39.6785 62.1676 36.0938 62.1676 34.6105C62.1676 33.9925 61.7961 33.498 61.1769 33.498C59.567 33.498 55.4803 37.4535 54.3657 38.8132L54.2419 38.6896C54.4896 37.7007 56.3471 35.723 56.3471 34.7341C56.471 34.2397 55.9756 33.8689 55.4803 33.8689Z"
        fill="#B1B3B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0241 40.5438C64.1479 39.6786 65.0148 38.3189 66.0055 37.2064C66.9962 35.9703 68.1108 34.9814 68.9776 34.9814C69.3492 34.9814 69.473 35.1051 69.473 35.4759C69.473 36.3411 68.4823 37.4536 67.9869 37.948C66.7485 39.1841 65.634 40.0494 64.0241 40.5438ZM69.9684 32.5093C67.3677 32.5093 61.1758 37.7008 61.1758 43.2632C61.1758 45.3645 62.6618 46.6006 64.6433 46.7242C68.3584 46.8478 74.055 41.4091 74.1789 39.8022C74.1789 39.4313 73.8074 39.1841 73.5597 39.1841C72.6928 39.1841 69.5968 44.3757 65.7578 44.3757C64.3956 44.3757 63.4049 43.634 63.6526 42.2743C68.1108 41.1619 71.8259 36.8356 71.8259 34.1162C71.7021 33.2509 70.8352 32.5093 69.9684 32.5093Z"
        fill="#B1B3B4"
      />
      <path
        d="M43.8385 35.352C48.5444 27.3174 65.758 5.68598 66.7487 2.10134C66.9964 0.988866 65.6341 0 64.5196 0C62.5382 0 60.3091 3.58464 59.1945 4.94433L49.2874 18.4176C37.1512 35.7228 31.8261 45.7351 28.854 54.7585C27.6156 58.4667 29.7208 60.8153 32.1976 60.3208C35.2936 59.8264 36.7797 59.0847 38.5134 58.3431C41.9809 56.8598 60.4329 55.2529 64.2719 55.2529C64.8911 55.2529 69.4732 56.489 69.4732 53.0279C69.4732 49.6905 52.5072 48.9489 34.6744 54.264C36.6558 47.5892 40.2472 41.4088 43.8385 35.352Z"
        fill="#CD0039"
      />
    </svg>
  );
}

LogoIcon.defaultProps = {
  width: 80,
  height: 61,
  fill: 'currentColor',
};

LogoIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LogoIcon;
