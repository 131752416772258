/**
 * Buttons component bit.dev
 *
 * @param {bool} loading loading (trigger disabled and loading progress icon)
 * @param {object|string} messageId button name (pass the string or <FormatedMessage ...>)
 * @param {string} color control the color of the Button
 * @param {...} other pass the necessary event (Link or onSubmit)
 *
 * @return {node} Returns Buttons, all styling managed by Material UI Theme
 */

import { useState, memo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    fontWeight: props => props.fontWeight,
    '&.MuiButton-textPrimary:hover': {
      backgroundColor: 'transparent',
    },
  },
  fitContent: {
    padding: 0,
    minHeight: 'initial',
    height: 'auto',
  },
});

function Buttons({
  variant,
  loading,
  messageId,
  icon,
  route,
  className,
  onClick,
  effect = true,
  disabled,
  children,
  fitContent,
  ...others
}) {
  const classes = useStyles(others);

  const [customClassName, setCustomClassName] = useState([]);
  const isIconButton = messageId?.props?.viewBox;

  const handleClick = useCallback(
    event => {
      if (onClick) onClick(event);
    },
    [onClick],
  );

  useEffect(() => {
    if (isIconButton && effect) setCustomClassName([...customClassName, 'iconButton']);
  }, [isIconButton]);

  return (
    <Button
      to={route}
      disabled={loading || disabled}
      component={route && Link}
      endIcon={
        icon ||
        (loading && <CircularProgress size={18} color="inherit" style={{ marginLeft: 8 }} />)
      }
      variant={variant}
      type={variant === 'contained' ? 'submit' : 'button'}
      fullWidth={variant === 'contained'}
      className={clsx([className], classes.root, {
        [classes.fitContent]: fitContent,
      })}
      onClick={event => handleClick(event)}
      disableRipple={!variant}
      {...others}
    >
      {messageId ?? children}
    </Button>
  );
}

Buttons.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.object,
  messageId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.any,
  onClick: PropTypes.func,
  effect: PropTypes.bool,
  disabled: PropTypes.bool,
  fontWeight: PropTypes.number,
  children: PropTypes.node,
  fitContent: PropTypes.bool,
};

export default memo(Buttons);
