/* eslint-disable */
function Icon24Fapiao(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-fapiao" fill={fill}>
            <path id="Shape" d="M19.2 3H4.8c-.442 0-.8.335-.8.75V21l3.2-2.25L9.6 21l2.4-2.25L14.4 21l2.4-2.25L20 21V3.75c0-.415-.358-.75-.8-.75zM13 15H7v-2h6v2zm0-3H7v-2h6v2zm0-3H7V7h6v2zm4 6h-2v-2h2v2zm0-3h-2v-2h2v2zm0-3h-2V7h2v2z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Fapiao;
