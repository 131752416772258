/**
 *
 * WholePageDialog
 * @param {bool} open (open dialog)
 * @param {func} setOpen (open and close dialog)
 * @param {string} title Title of the modal
 * @param {string} subtitle Title of the modal
 * @param {node} children - include content of the modal
 * @param {node} icon icon with FontAwesome for instance
 *
 * @return {node} Returns WholePageDialog, all styling managed by Material UI Theme and classes (root and paper)
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Buttons from 'components/Buttons/Buttons';

const Transition = forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} timeout={{ enter: 300, exit: 180 }} />
));

function WholePageDialog({
  children,
  open,
  title,
  setOpen,
  subtitle,
  icon,
  headRender,
  containerProps,
  ...other
}) {
  return (
    <Dialog
      fullScreen
      hideBackdrop
      open={open}
      onClose={setOpen}
      TransitionComponent={Transition}
      TransitionProps={{ role: 'presentation' }}
      aria-label={title && title}
      {...other}
    >
      <Container fixed {...containerProps}>
        {headRender ? (
          headRender({
            title,
            setOpen,
            subtitle,
            icon,
          })
        ) : (
          <Box display="flex" mt={4} ml={-3}>
            {setOpen && (
              <Box borderRight={1} color="divider" height="28px">
                <Buttons size="medium" icon={icon && icon} onClick={setOpen} />
              </Box>
            )}
            <Box ml={2}>
              {title && (
                <Typography variant="h2" id={title}>
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography variant="caption" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {children}
      </Container>
    </Dialog>
  );
}

WholePageDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  headRender: PropTypes.func,
  setOpen: PropTypes.func,
  subtitle: PropTypes.object,
  icon: PropTypes.node,
  containerProps: PropTypes.object,
};

export default WholePageDialog;
