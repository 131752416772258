import { Redirect } from 'react-router-dom';
import { getAuthorization } from 'utils/Auth';
import { AnimationShowElement } from 'components/Parallax';
import routes from '../routes';
import { getBuyNow, getProceedToCheckout } from '../Checkout';

export default WrappedComponent =>
  function (props) {
    const auth = getAuthorization();
    const buyNow = getBuyNow();
    const proceedToCheckout = getProceedToCheckout();
    if (
      typeof auth.token !== 'undefined' &&
      auth.type === 'user' &&
      buyNow === '' &&
      proceedToCheckout === ''
    ) {
      return <Redirect to={routes.dashboard} />;
    }

    return (
      <AnimationShowElement initialTransform>
        <WrappedComponent {...props} />
      </AnimationShowElement>
    );
  };
