/* eslint-disable */
function Icon16EyeSlash(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M14.738 1.262c.32.321.347.79.08 1.135l-.08.09-12.25 12.25a.794.794 0 0 1-.613.263.794.794 0 0 1-.613-.262.842.842 0 0 1-.08-1.135l.08-.09 2.188-2.188c-.875-.787-1.663-1.662-2.1-2.362-.438-.525-.438-1.313 0-1.925C2.4 5.462 4.85 2.75 8 2.75c1.034 0 1.996.29 2.885.736l.265.139 2.362-2.362a.846.846 0 0 1 1.226 0zM13.85 6c.334.417.669.75.836 1a1.622 1.622 0 0 1 0 1.917C13.684 10.417 11.345 13 8.337 13c-.418 0-.92-.083-1.337-.167zM8 5.375C6.513 5.375 5.375 6.513 5.375 8c0 .365.06.668.182.962l.08.175 3.5-3.5c-.35-.174-.7-.262-1.137-.262z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16EyeSlash;
