/* eslint-disable */
function Icon12Bell(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M8 9a2 2 0 1 1-4 0zM6 1c1.84 0 3.333 1.393 3.333 3.111v1.945c.058.62.585 1.113 1.25 1.166.23 0 .417.174.417.39 0 .214-.187.388-.417.388H1.417C1.187 8 1 7.826 1 7.611c0-.215.187-.389.417-.389.665-.053 1.192-.546 1.25-1.166V4.11C2.667 2.393 4.159 1 6 1z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12Bell;
