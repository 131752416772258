/* eslint-disable */
function Delete(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 53 56" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g>
		<g id="---Symbols-(Dont't-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="Product/Cart/Cart-Item---2-Line" fill={fill} transform="translate(-926 -69)">
				<g id="2.Icons/24/24-favourite-on-Copy" transform="translate(921 61)">
					<path id="Combined-Shape" d="M49.875 28.75v28.031c0 3.573-2.742 6.469-6.125 6.469h-24.5c-3.383 0-6.125-2.896-6.125-6.469V28.75h36.75zM26.25 37.375H21V51.75h5.25V37.375zm7.875 0h-5.25V51.75h5.25V37.375zm7.875 0h-5.25V51.75H42V37.375zm-1.75-28.75c1.208 0 2.188 1.073 2.188 2.396v7.187h13.124c1.209 0 2.188 1.073 2.188 2.396S56.77 23 55.562 23H7.438c-1.209 0-2.188-1.073-2.188-2.396s.98-2.396 2.188-2.396h13.125v-7.187c0-1.323.979-2.396 2.187-2.396zm-2.188 4.792H24.938v4.791h13.125v-4.791z"/>
				</g>
			</g>
		</g>
	</g>
</svg>
	);
}

export default Delete;
