/* eslint-disable */
function Icon16Weixin(props) {
  // const fill = props.fill || '#00C25F';
	const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/Brand/16-weixin" fill={fill}>
            <path id="Combined-Shape" d="M11.153 5.944c2.348 0 4.847 1.56 4.847 3.69 0 1.29-.561 2.586-1.741 3.407l.283 1.23a.13.13 0 0 1-.197.14l-1.249-.793a6.13 6.13 0 0 1-1.943.292c-3.321 0-4.73-2.395-4.73-3.983 0-1.585 1.409-3.983 4.73-3.983zM5.803 1.5c2.713 0 5.115 1.6 5.599 3.912a6.839 6.839 0 0 0-.248-.005c-3.632 0-5.266 2.643-5.266 4.52 0 .332.051.687.155 1.048-.145-.006-1.634-.078-2.336-.301l-1.587.899a.206.206 0 0 1-.301-.232l.353-1.338S0 8.593 0 6.162 2.516 1.5 5.803 1.5zm3.832 6.455a.637.637 0 1 0 0 1.274.637.637 0 0 0 0-1.274zm3.187 0a.637.637 0 1 0 0 1.274.637.637 0 0 0 0-1.274zM3.639 3.932a.721.721 0 1 0 0 1.443.721.721 0 0 0 0-1.443zm4.193 0a.721.721 0 1 0 0 1.443.721.721 0 0 0 0-1.443z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon16Weixin;
