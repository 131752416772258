import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { CheckboxCheckedIcon, CheckboxIcon, MedalIcon } from 'components/Icons';
import FormatMessage from 'components/FormatMessage';
import appMessages from 'containers/App/messages';
import { Price } from 'components/Price';
import Picture from 'components/Picture';
import cartMessages from 'containers/CartProvider/messages';
import useSpecialProduct from 'components/Product/hooks/useSpecialProduct';
import StepperButtons from 'components/Buttons/StepperButtons';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    '&.border': {
      borderBottom: `1px solid ${theme.palette.primary.peach}`,
    },
  },
  active: {
    '& .radio': {
      borderColor: theme.palette.primary.main,
      '& div': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  checkRoot: {
    marginLeft: -8,
    padding: 8,
  },
  checkDisabled: {
    '& svg #Rectangle': {
      fill: theme.palette.primary.disabled,
    },
  },
  info: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flex: 1,
    '& .image': {
      overflow: 'hidden',
      width: 64,
      height: 64,
      border: `1px solid ${theme.palette.primary.darkPeach}`,
      borderRadius: 4,
    },
    '& .info': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: 0,
      '& .MuiTypography-h6': {
        lineHeight: 1,
      },
    },
  },
  smallSize: {
    fontSize: 10,
  },
  handler: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  price: {
    fontSize: 0,
    lineHeight: 1,
  },
}));

function MobileSpecialProduct({ product, border = true, checked, onSelected, onChange, ...other }) {
  const classes = useStyles();
  const theme = useTheme();
  const { productImage, price, stock, originalPrice, pv } = useSpecialProduct(product);

  const [value, setValue] = useState(() => ({ ...product, qty: 1 }));

  const handleChange = val => {
    setValue(state => ({ ...state, qty: val }));
    if (checked) {
      onChange({ ...value, qty: val });
    }
  };

  const handleSelected = () => {
    onSelected(value);
  };

  return (
    <Box
      className={clsx(classes.root, {
        border,
        [classes.active]: checked,
      })}
      {...other}
    >
      <Checkbox
        checked={checked}
        onChange={handleSelected}
        classes={{
          root: classes.checkRoot,
          disabled: classes.checkDisabled,
        }}
        icon={<CheckboxIcon />}
        checkedIcon={<CheckboxCheckedIcon />}
      />
      <div className={classes.info}>
        <div className="image">
          <Picture
            src={productImage}
            alt={product.name}
            title=""
            op="fit"
            params={{
              width: 64,
              height: 64,
            }}
          />
        </div>
        <div className="info">
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <div>
              <Typography variant="h6">{product.name}</Typography>
              <Box display="flex" mt={0.5}>
                <Box display="flex" alignItems="center" mr={1} color={theme.palette.primary.main}>
                  <MedalIcon
                    fill={theme.palette.primary.silver}
                    secondaryFill={theme.palette.primary.main}
                    width={13}
                    height={13}
                  />
                  <FormatMessage
                    className={classes.smallSize}
                    variant="caption"
                    color="primary"
                    message={{ ...appMessages.pvc, values: { total: pv } }}
                  />
                </Box>
                <FormatMessage
                  variant="caption"
                  color="textSecondary"
                  message={{ ...cartMessages.stock, values: { total: stock } }}
                />
              </Box>
            </div>
            <div className={classes.handler}>
              <Price
                originalPrice={originalPrice}
                price={price}
                color="primary"
                size="0.75rem"
                secondarySize="0.625rem"
                className={classes.price}
              />
              <StepperButtons onChange={handleChange} minusDisabled={value.qty <= 1} />
            </div>
          </Box>
        </div>
      </div>
    </Box>
  );
}

MobileSpecialProduct.propTypes = {
  product: PropTypes.object,
  border: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  onSelected: PropTypes.func,
};

export default MobileSpecialProduct;
