import { FormattedMessage } from 'react-intl';
import appMessages from 'containers/App/messages';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';

export function copyText(value) {
  const textarea = document.createElement('textarea');
  textarea.readOnly = 'readonly';
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  textarea.style.opacity = '0';
  textarea.value = value;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('Copy');
  document.body.removeChild(textarea);
}

const useCopyMeToClipBoard = () => {
  const notify = useNotificationSnackbar();

  return async copyMe => {
    try {
      (await navigator.clipboard?.writeText?.(copyMe)) || copyText(copyMe);
      notify(<FormattedMessage {...appMessages.copySuccess} />, 'success');
    } catch (err) {
      notify(<FormattedMessage {...appMessages.copyFailed} />, 'warn');
    }
  };
};

export default useCopyMeToClipBoard;
