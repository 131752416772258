/**
 * @author zhengji.su
 * @description VariantContent
 */

import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { contentVariants } from 'components/Variant/variants';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

function VariantContent({ className, ...other }) {
  const classes = useStyles();

  return (
    <motion.div variants={contentVariants} className={clsx(className, classes.root)} {...other} />
  );
}

VariantContent.propTypes = {
  className: PropTypes.string,
};

export default VariantContent;
