import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

export const oldIdState = createSlice({
  name: 'oldId',
  initialState: {
    step: 0,
    jumpStep: null,
    options: {},
  },
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setJumpStep: (state, action) => {
      state.jumpStep = action.payload;
    },
    setOptions: (state, action) => {
      state.options = isEmpty(action.payload) ? {} : { ...state.options, ...action.payload };
    },
  },
});

export const { setStep, setOptions, setJumpStep } = oldIdState.actions;

export const selectOptions = state => state.oldId;

export default oldIdState.reducer;
