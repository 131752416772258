import PropTypes from 'prop-types';

function InTransitIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>delivery</title>
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g
          id="Order-Detail-Page---In-Transit"
          transform="translate(-48.000000, -87.000000)"
          fillRule="nonzero"
        >
          <g id="Group-29" transform="translate(0.000000, 64.000000)">
            <g id="delivery" transform="translate(48.000000, 23.000000)">
              <path
                d="M45.447,18.105 L41.858,16.311 L39.97,8.757 C39.859,8.312 39.459,8 39,8 L29,8 C28.448,8 28,8.448 28,9 L28,25 L0,25 L0,26 C0,27.105 0.895,28 2,28 L45,28 C45.552,28 46,27.552 46,27 L46,19 C46,18.621 45.786,18.275 45.447,18.105 Z"
                id="Path"
                fill="#43A6DD"
              />
              <polygon id="Path" fill="#E6E6E6" points="39.969 17 38.219 10 32 10 32 17" />
              <path
                d="M0,2 L0,25 L30,25 L30,2 C30,0.895 29.105,0 28,0 L2,0 C0.895,0 0,0.895 0,2 Z"
                id="Path"
                fill="#EFD358"
              />
              <path
                d="M36,35 C32.691,35 30,32.309 30,29 C30,25.691 32.691,23 36,23 C39.309,23 42,25.691 42,29 C42,32.309 39.309,35 36,35 Z"
                id="Path"
                fill="#444444"
              />
              <path
                d="M36,32 C34.346,32 33,30.654 33,29 C33,27.346 34.346,26 36,26 C37.654,26 39,27.346 39,29 C39,30.654 37.654,32 36,32 Z"
                id="Path"
                fill="#E6E6E6"
              />
              <path
                d="M9,35 C5.691,35 3,32.309 3,29 C3,25.691 5.691,23 9,23 C12.309,23 15,25.691 15,29 C15,32.309 12.309,35 9,35 Z"
                id="Path"
                fill="#444444"
              />
              <path
                d="M9,32 C7.346,32 6,30.654 6,29 C6,27.346 7.346,26 9,26 C10.654,26 12,27.346 12,29 C12,30.654 10.654,32 9,32 Z"
                id="Path"
                fill="#E6E6E6"
              />
              <path
                d="M46,26 L45,26 C44.448,26 44,25.552 44,25 L44,23 C44,22.448 44.448,22 45,22 L46,22 L46,26 Z"
                id="Path"
                fill="#EFD358"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

InTransitIcon.defaultProps = {
  width: 46,
  height: 35,
  fill: 'currentColor',
};

InTransitIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InTransitIcon;
