/* eslint-disable */
function Icon16Processing(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="2.Icons/16/16-processing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M14,1 L14,2.75 C13.9992244,2.92297416 13.9485429,3.09188137 13.8542857,3.235625 L13.8542857,3.235625 L10.7428571,8 L13.856,12.764375 C13.9496514,12.9083131 13.9997322,13.0772056 14,13.25 L14,13.25 L14,15 L2,15 L2,13.25 C2.00077562,13.0770258 2.0514571,12.9081186 2.14571429,12.764375 L2.14571429,12.764375 L5.25714286,8 L2.144,3.235625 C2.05034864,3.09168692 2.00026779,2.92279444 2,2.75 L2,2.75 L2,1 L14,1 Z M12.05,13.1428571 L3.95,13.1428571 C3.70147186,13.1428571 3.5,13.3347351 3.5,13.5714286 C3.5,13.808122 3.70147186,14 3.95,14 L3.95,14 L12.05,14 C12.2985281,14 12.5,13.808122 12.5,13.5714286 C12.5,13.3347351 12.2985281,13.1428571 12.05,13.1428571 L12.05,13.1428571 Z M12.05,2 L3.95,2 C3.70147186,2 3.5,2.19187796 3.5,2.42857143 C3.5,2.66526489 3.70147186,2.85714286 3.95,2.85714286 L3.95,2.85714286 L12.05,2.85714286 C12.2985281,2.85714286 12.5,2.66526489 12.5,2.42857143 C12.5,2.19187796 12.2985281,2 12.05,2 L12.05,2 Z" id="Combined-Shape" fill={fill}></path>
    </g>
    </svg>
  );
}

export default Icon16Processing;
