/**
 * Locale customer's first and last name if both only contain Chinese characters
 * @param {string} firstname    Customer's first name
 * @param {string} lastname     Customer's last name
 *
 * @returns {string}            Returns the localised customer name
 */
export default function (firstname, lastname) {
  const fn = firstname || '';
  const ln = lastname || '';

  // eslint-disable-next-line prefer-regex-literals
  const pattern1 = new RegExp('[\u4E00-\u9FA5]+'); // Chinese
  const pattern2 = /[0-9]+/; // Number
  const pattern3 = /[A-Za-z]+/; // English

  let name = ln + fn;

  if (pattern3.test(name) && !pattern1.test(name) && !pattern2.test(name)) {
    name = `${ln} ${fn}`;
  }

  if (pattern1.test(name) && !pattern2.test(name) && !pattern3.test(name)) {
    name = `${ln}${fn}`;
  }

  // if (ln === fn) {
  //   name = ln;
  // }

  return name;
}
