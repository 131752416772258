import { memo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import appMessages from 'containers/App/messages';
import { MedalIcon } from 'components/Icons';
import Access, { useAccess } from 'components/Access/Access';
import FormatMessage from 'components/FormatMessage';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  pv: {
    marginLeft: theme.spacing(0.5),
  },
}));

const ProductPv = props => {
  const theme = useTheme();
  const classes = useStyles(props);
  const { classes: propsClasses, className, point, ...other } = props;
  const access = useAccess();
  if (classes) return null;
  if (point === undefined || point === null || point <= 0) return null;
  return (
    <Access accessible={access.showPv}>
      <Box className={clsx(classes.root, className)} {...other}>
        <MedalIcon
          fill={`${theme.palette.primary.silver}`}
          secondaryFill={`${theme.palette.primary.main}`}
          width={12}
          height={12}
        />
        {/* <Typography variant="h5" color="primary" component="span" className={classes.pv}> */}
        {/*  {point} */}
        {/*  <FormattedMessage {...appMessages.pvc} /> */}
        {/* </Typography> */}
        <FormatMessage
          variant="h5"
          color="primary"
          component="span"
          className={classes.pv}
          message={{ ...appMessages.pvc, values: { total: point } }}
        />
      </Box>
    </Access>
  );
};

ProductPv.propTypes = {
  point: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  classes: PropTypes.object,
};
export default memo(ProductPv);
