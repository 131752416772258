import PropTypes from 'prop-types';

function FCIcon(props) {
  const { width, height, fill, viewBox, fillFirst, fillLast } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>2.Icons/24/24-FC</title>
      <g id="---Icons-(Dont'-Check)" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="Group" transform="translate(3.000000, 2.000000)">
          <path
            d="M17.1818182,0 C17.6336875,0 18,0.377023369 18,0.842105263 L18,0.842105263 L18,5.05263158 C18,6.91295916 16.5347501,8.42105263 14.7272727,8.42105263 L14.7272727,8.42105263 L14.6618182,8.42105263 C14.2970924,11.0029434 12.3267234,13.0309255 9.81818182,13.4063158 L9.81818182,13.4063158 L9.81818182,16 L8.18181818,16 L8.18181818,13.4063158 C5.6732766,13.0309255 3.70290762,11.0029434 3.33818182,8.42105263 L3.33818182,8.42105263 L3.27272727,8.42105263 C1.46524991,8.42105263 0,6.91295916 0,5.05263158 L0,5.05263158 L0,0.842105263 C0,0.377023369 0.366312477,0 0.818181818,0 L0.818181818,0 Z M9,1.68421053 L7.73580446,4.45754386 L4.90909091,4.90105263 L6.95497781,7.05871345 L6.47160892,10.1052632 L9,8.66619883 L11.5283911,10.1052632 L11.0450222,7.05871345 L13.0909091,4.90105263 L10.2641955,4.45754386 L9,1.68421053 Z M3.27272727,1.68421053 L1.63636364,1.68421053 L1.63636364,5.05263158 C1.63636364,5.98279537 2.36898859,6.73684211 3.27272727,6.73684211 L3.27272727,6.73684211 L3.27272727,1.68421053 Z M16.3636364,1.68421053 L14.7272727,1.68421053 L14.7272727,6.73684211 C15.6310114,6.73684211 16.3636364,5.98279537 16.3636364,5.05263158 L16.3636364,5.05263158 L16.3636364,1.68421053 Z"
            id="Combined-Shape"
            fill={fillFirst}
          />
          <path
            d="M10.7142857,17 L7.28571429,17 C3,17 3,20 3,20 L15,20 C15,20 15,17 10.7142857,17 Z"
            id="Path"
            fill={fillLast}
          />
        </g>
      </g>
    </svg>
  );
}

FCIcon.defaultProps = {
  width: 46,
  height: 35,
  fill: 'currentColor',
  viewBox: '0 0 24 24',
  fillLast: '#A7A9AC',
};

FCIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
  fillFirst: PropTypes.string,
  fillLast: PropTypes.string,
};

export default FCIcon;
