/* eslint-disable */
function Icon16List(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/16/16-list" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Combined-Shape" d="M14.125 1c.525 0 .875.35.875.875v12.25c0 .525-.35.875-.875.875H1.875C1.35 15 1 14.65 1 14.125V1.875C1 1.35 1.35 1 1.875 1zm-.875 1.75H2.75v10.5h10.5V2.75zM11.5 9v2h-7V9h7zm0-4v2h-7V5h7z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon16List;
