/*
 *
 * CatalogPage constants
 *
 */

export const CATALOG_SPECIAL_CATEGORY_LIST = ['promotions', 'bundles'];

export const CATEGORY_ID_DEFAULT = 2;

export const SPECIAL_CATEGORY_IDS = {
  promotions: 7,
  bundles: 8,
};
