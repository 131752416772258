import PropTypes from 'prop-types';

function WomanIcon({ width, height, ...other }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g id="---1.-Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Home-1---Webview-MP-Page" transform="translate(-245.000000, -1747.000000)">
          <g id="Group-4-Copy" transform="translate(0.000000, 1735.000000)">
            <g id="woman-24" transform="translate(245.000000, 12.000000)">
              <path
                d="M4,21 L4,6.65853659 C4,2.98097546 6.90999985,0 10.5,0 C14.0900001,0 17,2.98097546 17,6.65853659 L17,21 L4,21 Z"
                id="Path"
                fill="#444444"
              />
              <rect id="Rectangle" fill="#EAC3A2" x="7" y="12" width="6" height="8" />
              <path
                d="M18.3414993,19.6709996 L13,17 C12.1560002,17.625 11.1230001,18 10,18 C8.87699985,18 7.84399985,17.625 7.0089998,17 L1.65849995,19.6709996 C0.64199995,20.1790009 0,21.2180004 0,22.3540001 L0,23.497202 C0,23.774889 0.2246094,24 0.5043874,24 L19.4956098,24 C19.7741794,24 20,23.7780133 20,23.4972019 L20,22.354 C20,21.2180004 19.3579998,20.1790009 18.3414993,19.6709996 Z"
                id="Path"
                fill="#8680D8"
              />
              <path
                d="M5,8.14897821 L5,9.20298157 C5,12.399247 7.4675007,15 10.5,15 C13.5324993,15 16,12.399247 16,9.20298157 L16,8.14897821 C16,7.27581507 15.3284264,6.56797319 14.5,6.56797319 C12.7585354,6.56797319 11.2481146,5.52533236 10.5,4 C9.7518854,5.52533236 8.2414646,6.56797319 6.5,6.56797319 C5.67157505,6.56797319 5,7.27581507 5,8.14897821 Z"
                id="Path"
                fill="#F1D9C5"
              />
              <path
                d="M6,17.5845497 L6.92005479,19.7067032 C7.0410615,20.0019443 7.41455252,20.0947039 7.65999853,19.8904657 L10,17.9436657 C8.93854473,17.9436657 7.96212592,17.589791 7.17288528,17 L6,17.5845497 Z"
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
              <path
                d="M12.834786,17 C12.0372717,17.5897893 11.0611703,17.9436629 10,17.9436629 L12.3393386,19.8904861 C12.5847121,20.0946949 12.9580927,20.0019355 13.0790636,19.7067241 L14,17.5818698 L12.834786,17 Z"
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

WomanIcon.defaultProps = {
  width: 20,
  height: 24,
};

WomanIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WomanIcon;
