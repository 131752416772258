/* eslint-disable */
function Decline(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="2.Icons/16/16-important-notice" fill={fill} transform="translate(-1 -1)">
				<path id="Shape" d="M14.125 1H1.875C1.35 1 1 1.35 1 1.875v12.25c0 .525.35.875.875.875h12.25c.525 0 .875-.35.875-.875V1.875C15 1.35 14.65 1 14.125 1zM8 11.5c-.525 0-.875-.35-.875-.875s.35-.875.875-.875.875.35.875.875-.35.875-.875.875zm.875-2.625h-1.75V4.5h1.75v4.375z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default Decline;