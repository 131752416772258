/* eslint-disable */
function Icon24Business(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="2.Icons/24/24-business" fill={fill}>
				<path id="Combined-Shape" d="M13.667 13c.46 0 .833.326.833.727v6.546c0 .401-.373.727-.833.727h-3.334c-.46 0-.833-.326-.833-.727v-6.546c0-.401.373-.727.833-.727zm-6.5 3c.46 0 .833.32.833.714v3.572c0 .394-.373.714-.833.714H3.833c-.46 0-.833-.32-.833-.714v-3.572c0-.394.373-.714.833-.714zm13-6c.46 0 .833.329.833.733v9.534c0 .404-.373.733-.833.733h-3.334c-.46 0-.833-.329-.833-.733v-9.534c0-.404.373-.733.833-.733zm-1.915-7c.413 0 .748.335.748.747V8.23h-1.497V5.55l-4.71 4.703a.748.748 0 0 1-1.058 0l-2.464-2.46L6.058 11 5 9.943l3.742-3.735a.748.748 0 0 1 1.058 0l2.465 2.46 4.18-4.174h-2.684V3z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default Icon24Business;