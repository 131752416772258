/**
 *
 * SharingBottom
 *
 */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ShareActionSheet from './ShareActionSheet';
import { SHARE_DESKTOP_ACTIONS, SHARE_MOBILE_ACTIONS } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 2, 1),
    },
  },
}));
function SharingBottom({ onSelect }) {
  const classes = useStyles();
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('md'));

  const handleSelect = key => {
    onSelect(key);
  };

  const list = useMemo(() => (matchLg ? SHARE_DESKTOP_ACTIONS : SHARE_MOBILE_ACTIONS), [matchLg]);

  return <ShareActionSheet className={classes.root} list={list} onSelect={handleSelect} />;
}

SharingBottom.propTypes = {
  onSelect: PropTypes.func,
};

export default SharingBottom;
