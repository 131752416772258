/* eslint-disable */
function LocationIcon(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || 16;
	const height = props.height || 16;

  return (
    <svg height={height} width={width} viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
				<path id="Shape" d="M8 1C5.262 1 2.4 3.07 2.4 6.478c0 3.226 4.978 8.157 5.164 8.34A.57.57 0 0 0 8 15a.57.57 0 0 0 .436-.183c.186-.182 5.164-5.113 5.164-8.339C13.6 3.07 10.738 1 8 1zm0 7c-.992 0-1.75-.758-1.75-1.75S7.008 4.5 8 4.5s1.75.758 1.75 1.75S8.992 8 8 8z" />
      </g>
    </svg>
  );
}

export default LocationIcon;
