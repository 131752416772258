import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { getUrlParams } from 'utils/helpers';
import { getAuthorization, setAuthorization } from 'utils/Auth';

const initWechatLoginToken = () => {
  if (!determineEnvironment()) {
    return {};
  }
  const { token, source, openid } = getUrlParams();

  if (openid) {
    localStorage.setItem('openid', openid);
  }

  if (source === 'weapp' && token) {
    setAuthorization(token);
  }

  return { ...getAuthorization(), source };
};

export default initWechatLoginToken;
