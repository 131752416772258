import PropTypes from 'prop-types';

function MedalIcon(props) {
  const fill = props.fill || '#A7A9AC';
  const secondaryFill = props.secondaryFill || '#CD0039';
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 37</title>
      <g id="---10.-My-Account" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard" transform="translate(-34.000000, -104.000000)">
          <g id="Group-38" transform="translate(16.000000, 92.000000)">
            <g id="Group-37" transform="translate(18.000000, 12.000000)">
              <path
                fill={fill}
                d="M19.4444444,8.00000885 C24.1695473,8.00000885 28,11.8329168 28,16.5610374 C28,21.3009124 25.8884299,25.5200955 22.6046591,28.22627 L25.6666667,28.2258159 C26.9553311,28.2258159 28,29.271152 28,30.5606394 L28,35.2217255 C28,35.6515546 27.651777,36 27.2222222,36 L0.777777778,36 C0.348222972,36 0,35.6515546 0,35.2217255 L0,30.5606394 C0,29.271152 1.04466892,28.2258159 2.33333333,28.2258159 L5.39534093,28.22627 C2.11157006,25.5200955 0,21.3009124 0,16.5610374 C0,13.2469301 1.9117258,10.2305778 4.90755338,8.81747608 C7.90338097,7.40437432 11.4451651,7.8484008 14,9.95737817 C15.5311462,8.68953122 17.4570378,7.99714396 19.4444444,8.00000885 Z"
                id="Combined-Shape"
              />
              <path
                fill={secondaryFill}
                d="M25.6666667,27.8990697 C26.9553311,27.8990697 28,28.9403201 28,30.2247674 L28,31 L0,31 L0,30.2247674 C0,28.9403201 1.04466892,27.8990697 2.33333333,27.8990697 L25.6666667,27.8990697 Z M12.6777778,8.81051816 C13.1456447,9.06651246 13.5883327,9.36577658 14,9.70436131 C12.1383824,11.2317166 11.0103939,13.4715929 10.8939747,15.8720772 C10.7775556,18.2725615 11.6835314,20.6104369 13.3886667,22.3096429 C13.6834228,22.6138278 13.6792074,23.0973449 13.3791922,23.3963783 C13.079177,23.6954117 12.5940725,23.6996133 12.2888889,23.4058217 C10.3278209,21.4548067 9.25851241,18.7855043 9.33207204,16.0247539 C9.40563168,13.2640034 10.6155786,10.6550345 12.6777778,8.81051816 Z M14,0 C14.4295548,0 14.7777778,0.347083444 14.7777778,0.77523257 L14.7777778,0.77523257 L14.7777778,3.10093028 L17.1111111,3.10093028 C17.5406659,3.10093028 17.8888889,3.44801372 17.8888889,3.87616285 C17.8888889,4.30431197 17.5406659,4.65139542 17.1111111,4.65139542 L17.1111111,4.65139542 L14.7777778,4.65139542 L14.7777778,6.97709313 C14.7777778,7.40524225 14.4295548,7.7523257 14,7.7523257 C13.5704452,7.7523257 13.2222222,7.40524225 13.2222222,6.97709313 L13.2222222,6.97709313 L13.2222222,4.65139542 L10.8888889,4.65139542 C10.4593341,4.65139542 10.1111111,4.30431197 10.1111111,3.87616285 C10.1111111,3.44801372 10.4593341,3.10093028 10.8888889,3.10093028 L10.8888889,3.10093028 L13.2222222,3.10093028 L13.2222222,0.77523257 C13.2222222,0.347083444 13.5704452,0 14,0 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

MedalIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MedalIcon;
