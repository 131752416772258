/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * ShareActionSheet
 *
 */

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import clsx from 'clsx';
import Buttons from 'components/Buttons/Buttons';
import { DownloadCloudIcon, LinkIcon } from 'components/Icons';
import linkWechat from 'images/link-wechat.png';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: 'calc(16px + env(safe-area-inset-bottom))',
    width: '100%',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      boxShadow: '0 0 16px rgba(0,0,0,0.08)',
      backgroundColor: theme.palette.background.white,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 2, 1),
    },
  },
  buttonWrapper: {
    flex: 1,
    fontSize: '0.625rem',
    [theme.breakpoints.up('md')]: {
      flex: 0,
      fontSize: '0.875rem',
      color: theme.palette.primary.main,
      marginRight: theme.spacing(8),
    },
  },
  button: {
    width: 48,
    borderRadius: '50%',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      width: 64,
      height: 64,
      marginBottom: theme.spacing(1),
    },
  },
}));

const SHARE_ICONS = {
  share: <img style={{ width: 24, height: 24 }} src={linkWechat} alt="link-wechat" />,
  download: <SvgIcon component={DownloadCloudIcon} size="large" style={{ fontSize: '1.5rem' }} />,
  copy: <SvgIcon component={LinkIcon} size="large" style={{ fontSize: '1.5rem' }} />,
};

function ShareActionSheet({ className, list = [], onSelect }) {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      {list.map(item => (
        <Box key={item.key} className={classes.buttonWrapper}>
          <Buttons
            variant="contained"
            color="primary"
            onClick={() => onSelect(item.key)}
            messageId={SHARE_ICONS[item.key] ?? item.icon}
            className={classes.button}
          />
          <Typography variant="h5" component="p">
            <FormattedMessage {...item.message} />
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

ShareActionSheet.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  onSelect: PropTypes.func,
};

export default ShareActionSheet;
