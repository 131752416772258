/* eslint-disable */
function Icon24AddToCart(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-add-to-cart" fill={fill}>
            <path id="Combined-Shape" d="M8 17a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm10 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM5.961 3c.37 0 .685.284.734.663l.408 3.16h7.741l.005.197c.1 2.02 1.716 3.627 3.696 3.627.517 0 1.008-.11 1.455-.308l-1.486 5.116a.745.745 0 0 1-.709.545H7.442a.749.749 0 0 1-.734-.664L5.313 4.53H3V3zm13.372 1v1.667H21v1.666h-1.667V9h-1.666V7.333H16V5.667h1.667V4h1.666z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24AddToCart;
