import { createSlice } from '@reduxjs/toolkit';
import { toggle } from 'utils/helpers';

const initialState = {
  selectedItems: [],
  lock: 0,
  lock1000: false,
  removeItem: {},
  isDeleteDialogShow: false,
  isCartManagement: false,
};

export const cartState = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: () => initialState,
    toggleLockCheckout: (state, action) => {
      if (action.payload.lock) {
        state.lock += 1;
      } else {
        state.lock -= 1;
      }
    },
    toggleLock1000: (state, action) => {
      state.lock1000 = action.payload.lock1000;
    },
    toggleCartItem: (state, action) => {
      state.selectedItems = toggle(state.selectedItems, action.payload.itemId);
    },
    toggleIsCartManagement: (state, action) => {
      state.isCartManagement = action.payload;
    },
    selectCartItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    toggleDeleteDialogShow: (state, action) => {
      state.isDeleteDialogShow = action.payload;
    },
    changeRemoveItem: (state, action) => {
      state.removeItem = action.payload;
    },
    updateSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
  },
});

export const {
  resetCart,
  toggleLockCheckout,
  toggleCartItem,
  selectCartItems,
  toggleLock1000,
  changeRemoveItem,
  toggleDeleteDialogShow,
  toggleIsCartManagement,
  updateSelectedItems,
} = cartState.actions;

export const selectCartItemsSelected = state => state.cart.selectedItems;
export const lockCheckout = state => state.cart.lock;
export const lock1000 = state => state.cart.lock1000;
export const selectDeleteDialogShow = state => state.cart.isDeleteDialogShow;
export const selectRemoveItem = state => state.cart.removeItem;
export const selectIsCartManagement = state => state.cart.isCartManagement;

export default cartState.reducer;
