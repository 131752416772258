/* eslint-disable */
function Icon24Profile(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/24/24-profile" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Combined-Shape" d="M8.667 6v3.368h6.666V6h5.834c.46 0 .833.377.833.842v14.316a.838.838 0 0 1-.833.842H2.833A.838.838 0 0 1 2 21.158V6.842C2 6.377 2.373 6 2.833 6h5.834zM9.5 16.105H7.833c-1.38 0-2.5 1.131-2.5 2.527H12c0-1.396-1.12-2.527-2.5-2.527zm9.167 0h-5v1.684h5v-1.684zm-10-4.21c-.921 0-1.667.753-1.667 1.684 0 .93.746 1.684 1.667 1.684.92 0 1.666-.754 1.666-1.684 0-.93-.745-1.684-1.666-1.684zm10 .842h-5v1.684h5v-1.684zM12 2c1.105 0 2 .767 2 1.714V8h-4V3.714c0-.902.812-1.64 1.844-1.709L12 2z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon24Profile;
