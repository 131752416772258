import { useQuery, useMutation } from 'react-query';
import { FormattedMessage } from 'react-intl';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import request from 'utils/request';
import { isSignedIn } from 'utils/Auth';
import { querySetting } from 'utils/requestGql';
import couponMessages from './messages';

const assignCustomerCoupon = ruleId => request('PUT', `/coupon/${ruleId}`);
const getAvailableList = () => request('GET', '/coupons');
const getMyCoupons = () => request('GET', '/coupons/me');
const getMineCoupons = () => request('GET', '/carts/mine/coupon_wallet');
const setMineCoupons = couponId => request('PUT', `/carts/mine/coupon_wallet/${couponId}`);
const removeMineCoupons = () => request('DELETE', '/carts/mine/coupon_wallet');

export const COUPON_QUERY_KEY = {
  GET_ALL: 'couponPage.availableCoupons',
  GET_MY_LIST: 'couponPage.myCoupons',
  CHECKOUT: 'couponPage.checkout',
};

export const useAvailableCoupons = () =>
  useQuery(
    [COUPON_QUERY_KEY.GET_ALL],
    () =>
      getAvailableList().then(res =>
        res
          .filter(item => item.extension_attributes.available_coupon)
          .sort(
            (a, b) =>
              b.extension_attributes.available_coupon / b.extension_attributes.total_coupon -
              a.extension_attributes.available_coupon / a.extension_attributes.total_coupon,
          ),
      ),
    querySetting,
  );

export const useMyCoupons = () =>
  useQuery([COUPON_QUERY_KEY.GET_MY_LIST], () => getMyCoupons(), {
    ...querySetting,
    enabled: isSignedIn(),
  });

export const useGetCoupon = () => {
  const notificationSnackbar = useNotificationSnackbar();
  return useMutation(id => assignCustomerCoupon(id), {
    onSuccess: () => {
      notificationSnackbar(<FormattedMessage {...couponMessages.addSuccess} />, 'success');
    },
  });
};

export const useGetMineCoupons = () =>
  useQuery([COUPON_QUERY_KEY.CHECKOUT], () => getMineCoupons(), {
    ...querySetting,
    enabled: isSignedIn(),
  });

export const useSetMineCoupons = () => useMutation(id => setMineCoupons(id));

export const useRemoveMineCoupons = () => useMutation(() => removeMineCoupons());
