import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  changePassword,
  register,
  sendOTP,
  logOut,
  logInByOTP,
  logInByUsername,
  getCustomerPromotionIdentity,
} from 'containers/AuthorizationProvider/api';
import { querySetting } from 'utils/requestGql';
import { resetCart } from 'containers/CartProvider/slices';
import { resetCheckout } from 'containers/CheckoutPage/slices';
import { deleteAuthorization, getAuthorization } from 'utils/Auth';
import routes, { protectedRoutes } from 'utils/routes';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { CART_QUERY_KEY } from 'containers/CartProvider/queries';

export const AUTH_QUERY_KEY = {
  SEND_OTP: 'authorization.sendOTP',
  GET_CUSTOMER_PROMOTION_IDENTITY: 'authorization.customerPromotionIdentity',
  RETRIEVE_PASSWORD: 'authorization.retrievePassword',
  REGISTER: 'authorization.register',
  LOGOUT: 'authorization.logout',
  LOGIN: 'authorization.login',
};

/**
 * Get mobile phone verification code 获取手机验证码
 * @param month 月份 *202101
 *
 * @apiGroup Authorization
 * */
export const useGetPhoneVerification = (payload, options) =>
  useQuery([AUTH_QUERY_KEY.SEND_OTP, payload], () => sendOTP(payload).then(res => res), {
    ...querySetting,
    enabled: false,
    cacheTime: 0,
    ...options,
  });

/**
 * Retrieve password 找回密码
 * @param payload
 *
 * @apiGroup Authorization
 * */
export const useRetrievePasswordMutation = () =>
  useMutation(payload => changePassword(payload), {
    mutationKey: AUTH_QUERY_KEY.RETRIEVE_PASSWORD,
  });

/**
 * Register 创建账号
 * @param {object} config
 *
 * @apiGroup Authorization
 * */
export const useRegisterMutation = (config = {}) =>
  useMutation(payload => register(payload), { ...config, mutationKey: AUTH_QUERY_KEY.REGISTER });

export const useLogoutRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(() => logOut(), {
    mutationKey: AUTH_QUERY_KEY.LOGOUT,
    onSuccess: () => {
      deleteAuthorization();
      dispatch(resetCart());
      dispatch(resetCheckout());

      if (determineEnvironment()) {
        wx.miniProgram.postMessage({ data: 'logout' });
      }

      const currentRoute = history?.location?.pathname.match(/^((?!\/\d+).)*/);
      if (currentRoute && protectedRoutes.includes(currentRoute[0])) {
        history.push(routes.home);
      }

      queryClient.refetchQueries([CART_QUERY_KEY.GET]);
    },
  });
};

export const useLogin = () =>
  useMutation(
    ({ isOTP, body }) => {
      const processor = isOTP ? logInByOTP : logInByUsername;
      return processor(body);
    },
    {
      mutationKey: AUTH_QUERY_KEY.LOGIN,
    },
  );

export const useGetCustomerPromotionIdentity = (enabled = true) => {
  const { type } = getAuthorization();

  return useQuery(
    [AUTH_QUERY_KEY.GET_CUSTOMER_PROMOTION_IDENTITY],
    () => getCustomerPromotionIdentity().then(({ count }) => count > 0),
    {
      ...querySetting,
      enabled: type === 'user' && enabled,
    },
  );
};
