import PropTypes from 'prop-types';

function RadioIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="*Design-System" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <rect id="Rectangle" fill="#CD0039" x="0" y="0" width="20" height="20" rx="10" />
        <rect id="Rectangle-Copy" fill="#FFFFFF" x="1" y="1" width="18" height="18" rx="9" />
        <rect id="Rectangle-Copy-2" fill="#CD0039" x="4" y="4" width="12" height="12" rx="6" />
      </g>
    </svg>
  );
}

RadioIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: 'currentColor',
};

RadioIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioIcon;
