/**
 *
 * SharingDialog
 *
 */

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Hidden from '@material-ui/core/Hidden';
import { TimesIcon, AngleLeftIcon16 } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import CenteredDialog from 'components/Dialogs/CenteredDialog';
import WholePageDialog from 'components/Dialogs/WholePageDialog';
import FormatMessage from 'components/FormatMessage';
import NavigationBar from 'components/NavigationBar';
import appMessages from 'containers/App/messages';
import SharingDialogContent from './SharingDialogContent';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  closeButton: {
    marginLeft: 'auto',
    minWidth: 'auto',
    marginRight: theme.spacing(-0.5),
  },
  backItem: {
    minHeight: 40,
    minWidth: 40,
    borderRadius: '50%',
  },
}));

function SharingDialog({ open, onClose, dialogTitle, children, ...other }) {
  const classes = useStyles();

  return (
    <>
      <Hidden mdUp>
        <WholePageDialog open={open} setOpen={onClose}>
          <Box position="absolute" left={0} top={0} width={1}>
            <NavigationBar
              position="sticky"
              renderBackItem={() => (
                <Buttons
                  messageId={
                    <>
                      <SvgIcon component={AngleLeftIcon16} viewBox="5 0 16 16" color="inherit" />
                      <FormatMessage message={appMessages.back} />
                    </>
                  }
                  color="textSecondary"
                  className={classes.backItem}
                  onClick={onClose}
                />
              )}
            />
            {children || <SharingDialogContent {...other} />}
          </Box>
        </WholePageDialog>
      </Hidden>
      <Hidden smDown>
        <CenteredDialog open={open}>
          <Box py={5} position="relative">
            <Box display="flex" alignItems="center" mb={4} px={5}>
              <FormatMessage variant="h2" message={dialogTitle ?? messages.shareProduct} />
              <Buttons
                size="small"
                messageId={
                  <SvgIcon
                    component={TimesIcon}
                    fontSize="large"
                    style={{ fontSize: '1.5rem' }}
                    viewBox="0 0 16 16"
                  />
                }
                className={classes.closeButton}
                onClick={onClose}
              />
            </Box>
            <Box width={1} mb={2} px={8.5}>
              {children || <SharingDialogContent {...other} />}
            </Box>
          </Box>
        </CenteredDialog>
      </Hidden>
    </>
  );
}

SharingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
};

export default SharingDialog;
