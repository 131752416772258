import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import isNumber from 'lodash/isNumber';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import useUpdateEffect from 'utils/Hooks/updateEffect/useUpdateEffect';
import { PlusIcon, MinusIcon } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    padding: 0,
    height: 32,
    maxWidth: 160,
    width: 134,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.primary.darkPeach}`,
    '&.disabled': {
      borderColor: theme.palette.primary.disabled,
    },
  },
  button: {
    display: 'inline-flex',
    padding: 0,
    width: 35,
    height: '100%',
    minHeight: 'auto',
    minWidth: 'auto',
    color: theme.palette.text.primary,
    '&[disabled]': {
      color: theme.palette.text.disabled,
    },
  },
  input: {
    width: 40,
    height: '100%',
    textAlign: 'center',
    border: 'none',
    outline: 'none',
    '&[disabled]': {
      backgroundColor: 'transparent',
    },
  },
}));

function StepperButtons({
  className,
  minusDisabled,
  plusDisabled,
  maximum,
  initialValue,
  onChange,
  disabled,
  showConfirm,
  isInput = true,
  ...other
}) {
  const classes = useStyles();

  const [value, setValue] = useState(initialValue);

  useUpdateEffect(() => {
    if (!showConfirm) {
      setValue(initialValue);
    }
  }, [showConfirm]);

  const onSubmit = (val, type) => {
    const newValue = val > maximum ? maximum : val;
    if (newValue >= 1) {
      setValue(newValue);
    }

    onChange?.(newValue, type);
  };

  const handleChange = event => {
    const val = event.target.value;
    setValue(val);
  };

  const handleBlur = event => {
    const val = event?.target?.value;
    const newValue = isEmpty(val) ? initialValue : Number(value);

    onSubmit(newValue, 'blur');
  };

  const increment = () => {
    const count = isNumber(Number(value)) ? Number(value) + 1 : 1;
    const newValue = Math.ceil(count);

    onSubmit(newValue, 'increment');
  };

  const decrement = () => {
    const newValue = value >= 1 ? value - 1 : 1;

    onSubmit(newValue, 'decrement');
  };

  return (
    <Box
      className={clsx(className, classes.root, {
        disabled,
      })}
      {...other}
    >
      <Buttons
        onClick={decrement}
        messageId={<SvgIcon component={MinusIcon} viewBox="0 0 16 16" />}
        disabled={minusDisabled || disabled}
        className={clsx(classes.button, 'minus')}
        effect={false}
      />
      {isInput ? (
        <input
          type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          className={classes.input}
          disabled={disabled}
        />
      ) : (
        <Typography variant="body2">{value}</Typography>
      )}
      <Buttons
        onClick={increment}
        messageId={<SvgIcon component={PlusIcon} viewBox="0 0 16 16" />}
        className={clsx(classes.button, 'plus')}
        effect={false}
        disabled={plusDisabled || disabled}
      />
    </Box>
  );
}

StepperButtons.defaultProps = {
  maximum: 99,
  initialValue: 1,
  disabled: false,
};

StepperButtons.propTypes = {
  className: PropTypes.string,
  maximum: PropTypes.number,
  minusDisabled: PropTypes.bool,
  plusDisabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showConfirm: PropTypes.bool,
  isInput: PropTypes.bool,
};

export default StepperButtons;
