/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useQueryClient } from 'react-query';
import { convertCartItemId, filterNoSubBundle, hasDuplicatesInArray } from 'utils/helpers';
import { getAuthorization } from 'utils/Auth';
import { selectCartItemsSelected } from 'containers/CartProvider/slices';
import { setProceedToCheckout } from 'utils/Checkout';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { toggleMPLoginVisible, toggleMiniCart } from 'containers/App/slices';
import routes from 'utils/routes';
import { useMoveToCheckoutQuote } from 'containers/CheckoutPage/queries';
import { CART_QUERY_KEY } from 'containers/CartProvider/queries';
import appMessages from 'containers/App/messages';
import useGetWechatParams from 'utils/Hooks/useGetWechatParams';
import useNotificationSnackbar from './useNotificationSnackbar';

const useCheckout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const itemIds = useSelector(selectCartItemsSelected);
  const queryClient = useQueryClient();
  const { mutateAsync: moveToCheckoutQuote, isLoading, ...other } = useMoveToCheckoutQuote();
  const { token } = useGetWechatParams();
  const notify = useNotificationSnackbar();

  const [isHasDuplicates, setIsHasDuplicates] = useState(false);
  const [noSubBundles, setNoSubBundles] = useState([]);

  const resetIsHasDuplicates = () => setIsHasDuplicates(false);

  const checkout = async (cartItems = []) => {
    const cartQuote = queryClient.getQueryData([CART_QUERY_KEY.GET]);
    const ids = isEmpty(cartItems) ? itemIds : cartItems.map(i => i.item_id);
    const filterBuldes = filterNoSubBundle(cartQuote?.items.filter(i => ids.includes(i.item_id)));

    if (!isEmpty(filterBuldes)) {
      setNoSubBundles(filterBuldes);
      return;
    }

    const auth = getAuthorization();

    if (isEmpty(itemIds) && isEmpty(cartItems)) {
      notify(intl.formatMessage(appMessages.emptyCheckoutNotice), 'warn');
      return;
    }

    if (auth.type !== 'user') {
      let items = [];
      if (!isEmpty(cartItems)) {
        items = cartItems;
      } else if (!isEmpty(itemIds)) {
        cartQuote?.items?.forEach(i => {
          if (itemIds.includes(i.item_id)) {
            items.push({ item_id: i.item_id, qty: i.qty, sku: i.sku });
          }
        });
      }

      if (hasDuplicatesInArray(items, 'item_id') || hasDuplicatesInArray(items, 'sku')) {
        if (!isEmpty(cartItems)) {
          notify(intl.formatMessage(appMessages.duplicateCheckout), 'warn');
        }
        setIsHasDuplicates(true);
        return;
      }

      setProceedToCheckout(items);
      if (determineEnvironment()) {
        dispatch(toggleMPLoginVisible(true));
      } else {
        history.push(routes.logIn);
        dispatch(toggleMiniCart(false));
      }
    } else {
      // check item_id if not in cart  change item_id;
      if (!isEmpty(cartItems)) {
        // for guest user  change cart item_id
        const items = [];
        cartQuote?.items?.forEach(i => {
          cartItems.forEach(i2 => {
            if (i.sku === i2.sku) {
              items.push({ item_id: i.item_id, qty: i2.qty });
            }
          });
        });

        if (hasDuplicatesInArray(items, 'item_id') || hasDuplicatesInArray(items, 'sku')) {
          notify(intl.formatMessage(appMessages.duplicateCheckout), 'warn');
          setIsHasDuplicates(true);
          return;
        }

        await moveToCheckoutQuote({ itemIds: convertCartItemId(items) });
      } else if (!isEmpty(itemIds)) {
        if (hasDuplicatesInArray(itemIds)) {
          setIsHasDuplicates(true);
          return;
        }

        await moveToCheckoutQuote({ itemIds });
      }
      dispatch(toggleMiniCart(false));
      history.push(routes.checkout());
    }
  };

  const resetNoSubBundles = () => setNoSubBundles([]);

  return {
    checkout,
    noSubBundles,
    isLoading: isLoading || token,
    isHasDuplicates,
    resetIsHasDuplicates,
    resetNoSubBundles,
    ...other,
  };
};

export default useCheckout;
