/* eslint-disable */
function IconCall(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="2.Icons/16/16-phone" stroke="none" stroke-width="1" fill={fill} fill-rule="evenodd">
        <path d="M13.3139683,9.80013766 L10.9612711,8.75617188 C10.4827943,8.5445055 9.92180679,8.68044514 9.59330186,9.08766101 L9.59330186,9.08766101 L8.88306781,9.97638188 C7.73210088,9.24417463 6.75589872,8.26823856 6.02338204,7.11747559 L6.9113621,6.40799884 C7.3200581,6.07983525 7.45673928,5.51772429 7.24435462,5.03854373 L6.19962809,2.68587085 C5.97042455,2.1698074 5.40149094,1.89533863 4.85490829,2.03714211 L2.86595296,2.55387517 C2.29801794,2.7022374 1.9307442,3.25185366 2.01097217,3.83333323 C2.76749983,9.09724828 6.90321885,13.2326192 12.167244,13.9887504 C12.219916,13.9962005 12.2730443,14 12.3262405,14 C12.8528605,13.9996817 13.3129461,13.6440666 13.4459653,13.1345284 L13.9619537,11.1448436 C14.1058546,10.5982679 13.8311343,10.0281657 13.3139683,9.80013766 Z" id="Path" fill="#CD0039"></path>
      </g>
    </svg>
  );
}

export default IconCall;


