/* eslint-disable */
function ArrowDown(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---Icons-(Dont't-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="2.Icons/12/12-arrow-down" fill={fill} transform="translate(-1 -1)">
				<path id="Path" d="M5.396 10.755c.078.078.17.14.272.18a.822.822 0 0 0 .637 0 .825.825 0 0 0 .271-.18L9.91 7.422a.833.833 0 1 0-1.18-1.178L6.82 8.155V1.833a.833.833 0 0 0-1.667 0v6.322l-1.91-1.91a.833.833 0 0 0-1.18 1.177l3.333 3.333z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default ArrowDown;