/* eslint-disable */
function Icon24InTransit(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-inTransit" fill={fill}>
            <path id="Combined-Shape" d="M16.923 6c.388 0 .727.26.82.632l.752 2.96 3.037 1.495c.287.142.468.43.468.746v5a.84.84 0 0 1-.846.834h-2.582A3.778 3.778 0 0 1 14.808 21a3.778 3.778 0 0 1-3.765-3.333S11 17.29 11 16.833V6zm-2.115 9.167c-1.166 0-2.116.934-2.116 2.083 0 1.15.95 2.083 2.116 2.083 1.166 0 2.115-.934 2.115-2.083 0-1.15-.95-2.083-2.115-2.083zM7.444 3C8.304 3 9 3.746 9 4.667V18H2V3zm8.924 4.667h-3.676v2.5h4.231l-.555-2.5z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24InTransit;
