/* eslint-disable */
function Icon12Aim(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="2.Icons/12/12-aim" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M6.41666667,1 L6.41666667,1.85458333 C8.38208333,2.05041667 9.94958333,3.61791667 10.1454167,5.58333333 L10.1454167,5.58333333 L11,5.58333333 L11,6.41666667 L10.1454167,6.41666667 C9.94958333,8.38208333 8.38208333,9.94916667 6.41666667,10.1454167 L6.41666667,10.1454167 L6.41666667,11 L5.58333333,11 L5.58333333,10.1454167 C3.61791667,9.94958333 2.05083333,8.38208333 1.85458333,6.41666667 L1.85458333,6.41666667 L1,6.41666667 L1,5.58333333 L1.85458333,5.58333333 C2.05041667,3.61791667 3.61791667,2.05041667 5.58333333,1.85458333 L5.58333333,1.85458333 L5.58333333,1 L6.41666667,1 Z M6.41666667,2.69541667 L6.41666667,3.5 L5.58333333,3.5 L5.58333333,2.69541667 C4.07833333,2.88458333 2.88458333,4.07833333 2.69541667,5.58333333 L2.69541667,5.58333333 L3.5,5.58333333 L3.5,6.41666667 L2.69541667,6.41666667 C2.88458333,7.92166667 4.07833333,9.11541667 5.58333333,9.30458333 L5.58333333,9.30458333 L5.58333333,8.5 L6.41666667,8.5 L6.41666667,9.30458333 C7.92166667,9.11541667 9.11541667,7.92166667 9.30458333,6.41666667 L9.30458333,6.41666667 L8.5,6.41666667 L8.5,5.58333333 L9.30458333,5.58333333 C9.11541667,4.07833333 7.92166667,2.88416667 6.41666667,2.69541667 L6.41666667,2.69541667 Z M6,5 C6.55228475,5 7,5.44771525 7,6 C7,6.55228475 6.55228475,7 6,7 C5.44771525,7 5,6.55228475 5,6 C5,5.44771525 5.44771525,5 6,5 Z" id="Combined-Shape" fill={fill} />
      </g>
    </svg>
  );
}

export default Icon12Aim;
