/* eslint-disable */
function Icon12Copy(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M10.5 5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h4zM8.111 1c.491 0 .889.358.889.8v2H5.889c-.491 0-.889.358-.889.8V9H1.889C1.398 9 1 8.642 1 8.2V1.8c0-.442.398-.8.889-.8z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12Copy;
