import { getAuthorization } from 'utils/Auth/index';

export default function toHuiPayParameters(orderId, vendorCode, paymentMethod) {
  return new Promise((resolve, reject) => {
    if (vendorCode && orderId && paymentMethod) {
      const { token } = getAuthorization();
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `/pages/payment/payment?orderID=${orderId}&vendorCode=${vendorCode}&paymentMethod=${paymentMethod}&token=${token}`,
      });

      resolve(true);
    }

    reject(new Error('支付参数获取错误'));
  });
}
