/* eslint-disable */
function Icon16Language(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="2.Icons/16/16-language" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Shape" d="M8 1C4.15 1 1 4.15 1 8s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7zm5 6h-1.407A20.749 20.749 0 0 0 11 3c1.037.878 1.778 2.341 2 4zm-5 6c-.6 0-1.8-1.52-2-4h4c-.2 2.48-1.4 4-2 4zM6 7c.2-2.48 1.3-4 2-4s1.8 1.52 2 4H6zM5 3c-.357 1.17-.571 2.537-.643 4H3c.214-1.659.929-3.122 2-4zM3 9h1.407c.074 1.463.297 2.83.593 4-1.037-.878-1.778-2.341-2-4zm8 4c.37-1.17.519-2.537.593-4H13c-.148 1.659-.889 3.122-2 4z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon16Language;
