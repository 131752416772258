import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import FormatSvgIcon from 'components/FormatMessage/FormatSvgIcon';
import { AddToCart, CloseIcon } from 'components/Icons';
import FormatMessage from 'components/FormatMessage';
import appMessages from 'containers/App/messages';
import SpecialProduct from 'components/Product/components/SpecialProduct';
import CenteredDialog from 'components/Dialogs/CenteredDialog';
import productMessages from 'containers/ProductPage/messages';
import useSpecial from 'components/Product/hooks/useSpecial';
import Buttons from 'components/Buttons/Buttons';
import SideSwiper from 'components/Swiper/SideSwiper';
import { getAuthorization } from 'utils/Auth';
import useToLogin from 'utils/Hooks/useToLogin';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 7.5),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3.25),
  },
  closeButton: {
    padding: 0,
    minWidth: 'auto',
    minHeight: 'auto',
    width: 24,
    height: 24,
  },
  content: {
    margin: theme.spacing(5, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addToCart: {
    width: 311,
  },
  addToCartText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.white,
  },
  cancelBtn: {
    marginTop: theme.spacing(3),
    padding: 0,
    minHeight: 'auto',
    height: 'auto',
  },
  centerDialog: {
    '& .MuiPaper-root': {
      width: 1168,
    },
  },
  banner: {
    minHeight: 405,
    '&.flex': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function SpecialProducts({ onClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const toLogin = useToLogin();
  const { checked, specialProducts, loading, changeSpecial, addToCart, selectedSpecial } =
    useSpecial();

  const handleAddToCart = async () => {
    const auth = getAuthorization();
    if (!auth.type || auth.type === 'guest') {
      toLogin();
      return;
    }
    await addToCart();
    onClose?.();
  };

  return (
    <Box className={classes.root}>
      <div className={classes.header}>
        <FormatMessage message={productMessages.buyExclusiveBundles} variant="h2" />
        <Buttons onClick={onClose} className={classes.closeButton}>
          <FormatSvgIcon icon={CloseIcon} viewBox="0 0 12 12" color="textPrimary" />
        </Buttons>
      </div>
      <FormatMessage message={productMessages.buyExclusiveBundlesNotice} />
      <Box className={classes.content}>
        <SideSwiper
          data={specialProducts}
          triggerScroll
          mx={-0.75}
          classes={{
            banner: clsx(classes.banner, {
              flex: specialProducts.length <= 4,
            }),
          }}
        >
          {product => (
            <Box px={0.5} height="100%">
              <SpecialProduct
                product={product}
                checked={checked.includes(product.id)}
                onChange={changeSpecial}
                onSelected={selectedSpecial}
                height="100%"
              />
            </Box>
          )}
        </SideSwiper>
      </Box>
      <Box className={classes.footer}>
        <Buttons
          variant="contained"
          color="primary"
          disabled={isEmpty(checked) || loading}
          loading={loading}
          className={classes.addToCart}
          onClick={handleAddToCart}
        >
          <FormatSvgIcon
            icon={AddToCart}
            iconStyle={{ color: theme.palette.primary.white }}
            height={16}
            viewBox="0 0 24 24"
          />
          <FormatMessage
            message={appMessages.addToCart}
            className={classes.addToCartText}
            bold={700}
          />
        </Buttons>
        <Button variant="text" color="primary" className={classes.cancelBtn} onClick={onClose}>
          <FormatMessage message={productMessages.iSee} bold={700} />
        </Button>
      </Box>
    </Box>
  );
}

SpecialProducts.propTypes = {
  onClose: PropTypes.func,
};

function SpecialProductsDialog(props) {
  const { open, onClose } = props;
  const classes = useStyles(props);

  return (
    <CenteredDialog open={open} maxWidth="lg" className={classes.centerDialog} onClose={onClose}>
      <SpecialProducts onClose={onClose} />
    </CenteredDialog>
  );
}

SpecialProductsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SpecialProductsDialog;
