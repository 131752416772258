/* eslint-disable */
function Icon24Close(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
     <g id="2.Icons/24/24-close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M20.6625,3.3375 C20.2125,2.8875 19.5375,2.8875 19.0875,3.3375 L12,10.425 L4.9125,3.3375 C4.4625,2.8875 3.7875,2.8875 3.3375,3.3375 C2.8875,3.7875 2.8875,4.4625 3.3375,4.9125 L10.425,12 L3.3375,19.0875 C2.8875,19.5375 2.8875,20.2125 3.3375,20.6625 C3.5625,20.8875 3.7875,21 4.125,21 C4.4625,21 4.6875,20.8875 4.9125,20.6625 L12,13.575 L19.0875,20.6625 C19.3125,20.8875 19.65,21 19.875,21 C20.1,21 20.4375,20.8875 20.6625,20.6625 C21.1125,20.2125 21.1125,19.5375 20.6625,19.0875 L13.575,12 L20.6625,4.9125 C21.1125,4.4625 21.1125,3.7875 20.6625,3.3375 Z" id="Path" fill={fill}></path>
    </g>
    </svg>
  );
}

export default Icon24Close;
