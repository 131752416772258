/* eslint-disable */
function Icon24Gift(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-gift" fill={fill}>
            <path id="Combined-Shape" d="M19 11v1.22L10.742 21H7l9.406-10H19zm-4 0L5.482 21A.767.767 0 0 1 5 20.28v-2.773L11.193 11H15zm4 3v6.185c0 .45-.365.815-.815.815H12l7-7zM9 11l-4 4v-4h4zm6-8c1.24 0 2.25 1.047 2.25 2.333 0 .274-.054.534-.138.778h3.138c.415 0 .75.348.75.778v2.333c0 .43-.335.778-.75.778H3.75A.764.764 0 0 1 3 9.222V6.89c0-.43.335-.778.75-.778h3.138a2.382 2.382 0 0 1-.138-.778C6.75 4.047 7.76 3 9 3c1.488 0 2.432 1 3 1.96C12.568 4 13.512 3 15 3zm0 1.556c-.948 0-1.56.843-1.907 1.555H15c.414 0 .75-.349.75-.778 0-.428-.336-.777-.75-.777zm-6 0c-.414 0-.75.349-.75.777 0 .43.336.778.75.778h1.907C10.56 5.4 9.948 4.556 9 4.556z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Gift;
