/* eslint-disable */
function Icon16Truck(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M11.308 3.5c.282 0 .528.19.597.463l.546 2.171 2.209 1.097c.208.104.34.315.34.547v3.666a.613.613 0 0 1-.615.612h-1.878a2.756 2.756 0 0 1-5.476 0S7 11.78 7 11.444V3.5zm-1.539 6.722c-.848 0-1.538.685-1.538 1.528s.69 1.528 1.538 1.528c.848 0 1.539-.685 1.539-1.528s-.69-1.528-1.539-1.528zM4.89 1.5C5.503 1.5 6 2.047 6 2.722V12.5H1v-11zm6.015 3.222H8.231v1.834h3.077l-.404-1.834z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Truck;
