/* eslint-disable */
function Icon16AngleDown(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M7.655 10.671L3.38 6.597a.5.5 0 0 1 0-.724l.571-.544a.5.5 0 0 1 .69 0L7.655 8.2a.5.5 0 0 0 .69 0l3.014-2.872a.5.5 0 0 1 .69 0l.571.544a.5.5 0 0 1 0 .724l-4.275 4.074a.5.5 0 0 1-.69 0z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16AngleDown;
