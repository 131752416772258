/* eslint-disable */
function Icon24Unpaid(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="2.Icons/24/24-unpaid" fill={fill}>
            <path id="Combined-Shape" d="M18.708 10c.253 0 .459.205.459.458v.917h-.917v-.458h-6.875a.46.46 0 0 0 0 .916h9.167c.253 0 .458.206.458.459v8.25a.459.459 0 0 1-.458.458h-8.709A1.833 1.833 0 0 1 10 19.167v-7.792c0-.76.616-1.375 1.375-1.375zM18 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM9 3c2.636 0 5 1.763 5.751 4.286l.073.246c-.531.034-1.034.098-1.508.188A4.529 4.529 0 0 0 9 4.5 4.505 4.505 0 0 0 4.5 9c0 2.482 2.018 4.5 4.5 4.5V15c-3.308 0-6-2.692-6-6s2.692-6 6-6zm.75 3v3.75H6v-1.5h2.25V6h1.5z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Unpaid;
