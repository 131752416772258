import determineEnvironment from 'utils/WeChat/determineEnvironment';

const usePosition = () => {
  const isWechat = determineEnvironment();
  const position = ({ onSuccess, onError }) => {
    if (isWechat) {
      wx?.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success(res) {
          console.log(res);
          onSuccess(res);
        },
        fail: err => onError(err),
      });
    } else {
      navigator.geolocation.getCurrentPosition(pos => onSuccess(pos.coords), onError, {
        timeout: 15000,
      });
    }
  };
  return { position };
};

export default usePosition;
