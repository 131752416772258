import { useDispatch } from 'react-redux';
import { actions as notificationActions } from 'utils/Notifications/actions';

const useNotificationSnackbar = options => {
  const dispatch = useDispatch();

  const notificationSnackbar = (message, type) => {
    dispatch(
      notificationActions.enqueueSnackbar({
        message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: type,
          maxSnack: 1,
          ...options,
        },
      }),
    );
  };
  return notificationSnackbar;
};

export default useNotificationSnackbar;
