/* eslint-disable */

function Pending(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g fill={fill}>
		<g id="---Icons-(Dont'-Check)" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
			<g id="2.Icons/16/16-processing" fill={fill} transform="translate(-2 -1)">
				<path id="Combined-Shape" d="M14 1v1.75a.893.893 0 0 1-.146.486L10.743 8l3.113 4.764a.893.893 0 0 1 .144.486V15H2v-1.75c0-.173.051-.342.146-.486L5.257 8 2.144 3.236A.893.893 0 0 1 2 2.75V1h12zm-1.95 12.143h-8.1a.44.44 0 0 0-.45.428.44.44 0 0 0 .45.429h8.1a.44.44 0 0 0 .45-.429.44.44 0 0 0-.45-.428zM12.05 2h-8.1a.44.44 0 0 0-.45.429.44.44 0 0 0 .45.428h8.1a.44.44 0 0 0 .45-.428.44.44 0 0 0-.45-.429z"/>
			</g>
		</g>
	</g>
</svg>
	);
}

export default Pending;