import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from 'store';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { toggleMPLoginVisible } from 'containers/App/slices';
import routes from 'utils/routes';
import { isSignedIn } from 'utils/Auth';

const useToLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return () => {
    if (isSignedIn()) {
      return;
    }
    store.set('last_url', history.location.pathname);
    if (determineEnvironment()) {
      dispatch(toggleMPLoginVisible(true));
    } else {
      history.push(routes.logIn);
    }
  };
};

export default useToLogin;
