/**
 *
 * PageLoader
 *
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIcon } from 'components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    padding: '60px',
    textAlign: 'center',
    backgroundColor: 'rgb(255 243 224 / 40%)',
    animation: '$fadeIn 0.40s ease',
    overflow: 'auto',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    display: 'block',
    width: 30,
    height: 30,
    marginBottom: theme.spacing(2),
    animation: '$spinAround 0.60s linear infinite',
  },
  '@keyframes spinAround': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

function PageLoader(props) {
  const { loading } = props;
  const classes = useStyles(props);

  if (!loading) return null;

  return (
    <Box m="auto" className={classes.root}>
      <Box mx="auto" className={classes.loader}>
        <LoadingIcon />
      </Box>
    </Box>
  );
}

PageLoader.defaultProps = {
  loading: false,
};

PageLoader.propTypes = {
  loading: PropTypes.bool,
};

export default memo(PageLoader);
