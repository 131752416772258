/**
 * @author zhengji.su
 * @description Carts
 */

import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import isEmpty from 'lodash/isEmpty';
import PageLoader from 'components/PageLoader';
import CartHeader from 'containers/CartProvider/components/CartHeader';
import MiniCartListItem from 'containers/CartProvider/components/MiniCartListItem';
import { CartEmptyIcon, CheckboxCheckedIcon, CheckboxIcon } from 'components/Icons';
import FormatMessage from 'components/FormatMessage';
import messages from 'containers/CartProvider/messages';
import appMessages from 'containers/App/messages';
import { formatPrice } from 'utils/helpers';
import Buttons from 'components/Buttons/Buttons';
import routes from 'utils/routes';
import EmptyState from 'components/EmptyState/Loadable';
import Access, { useAccess } from 'components/Access';
import Total from 'containers/CartProvider/components/Total';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import useCheckout from 'utils/Hooks/useCheckout';
import { toggleMiniCart } from 'containers/App/slices';
import ConfirmDiaLog from 'components/Dialogs/ConfirmDialog';
import useOperateCartItem from 'containers/CartProvider/hooks/useOperateCartItem';
import SpecialDialog from 'components/Product/components/SpecialDialog';
import FadeInVariantList from 'components/Variant/FadeInVariantList';
import productMessages from 'containers/ProductPage/messages';
import useCartBatchRemove from 'containers/CartProvider/hooks/useCartBatchRemove';
import DuplicateDialog from './DuplicateDialog';
import NoSubBundleDialog from './NoSubBundleDialog';

const useStyles = makeStyles(theme => ({
  carts: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
    },
  },
  items: {
    overflowY: 'scroll',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      padding: 0,
      height: 'calc(100% - 236px)',
    },
  },
  handle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.5rem 1rem 1rem',
    height: 112,
    zIndex: 100,
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
      bottom: '0px !important',
      height: 184,
      borderTop: `2px solid ${theme.palette.primary.peach}`,
      borderRadius: '0 0 8px 8px',
    },
  },
  checkRoot: {
    marginLeft: -8,
    padding: 8,
  },
  checkDisabled: {
    '& svg #Rectangle': {
      fill: theme.palette.primary.disabled,
    },
  },
  checkAllText: {
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 400,
    verticalAlign: 'text-top',
  },
  total: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    flex: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      '& .MuiTypography-root': {
        fontSize: 16,
      },
    },
  },
  btnRoot: {
    '&.MuiButton-textSizeLarge': {
      padding: '0 !important',
    },
  },
  btn: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: 0,
      textAlign: 'center',
    },
  },
  submit: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 700,
    '&.MuiButton-outlined.Mui-disabled': {
      borderColor: theme.palette.text.disabled,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      '&.MuiButton-outlined': {
        width: '100%',
      },
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
  },
  editBtn: {
    padding: theme.spacing(3, 0),
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  emptyTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: '34px',
    },
  },
  dialogTitle: {
    fontSize: '2rem',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  specialDialog: {
    zIndex: '1099 !important',
  },
  divider: {
    marginTop: '-1px',
    height: 4,
    backgroundColor: theme.palette.primary.peach,
  },
  eligibilityTitle: {
    fontSize: 0,
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  removeContent: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  batchRemove: {
    marginLeft: theme.spacing(2),
    minWidth: 'initial',
  },
}));

function Carts() {
  const classes = useStyles();
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { authorization, loading: accessLoading } = useAccess();
  const dispatch = useDispatch();
  const {
    checkout: proceedToCheckout,
    isLoading: checkoutLoading,
    isHasDuplicates,
    resetIsHasDuplicates,
    noSubBundles,
    resetNoSubBundles,
  } = useCheckout();
  const history = useHistory();
  const intl = useIntl();
  const {
    isManagement,
    isRemove,
    isLoading: batchRemoveLoading,
    confirm,
    cancel,
    toggleIsRemove,
    toggleIsManagement,
  } = useCartBatchRemove();
  const {
    cartItems,
    subTotal,
    pvTotal,
    isAllChecked,
    selectedItems,
    lockCheckout,
    toggleAllItems,
    showMiniCart,
    isLoading,
    isEligibility,
    generalCartList,
  } = useShoppingCart(isManagement);
  const {
    deleteDialogShow,
    loading: operateLoading,
    wishlistItems,
    removeItem,
    removeCartItem,
    toggleCartItem,
    toggleConfirm,
    joinFavorites,
  } = useOperateCartItem();

  const [loading, setLoading] = useState(false);

  const isFav = useMemo(() => {
    const id = removeItem?.extension_attributes?.product_id;
    return wishlistItems?.some(item => item.product_id === id);
  }, [removeItem, wishlistItems]);

  const handleDrawerClose = () => {
    dispatch(toggleMiniCart(false));
    if (isManagement) {
      toggleIsManagement(false);
    }
  };

  const handleSelectAll = () => toggleAllItems(!isAllChecked);

  const closeConfirm = () => {
    toggleConfirm(false);
  };

  const handleRedirect = () => {
    history.push(routes.catalogEmpty);
    dispatch(toggleMiniCart(false));
  };

  const handleShowCheckout = async () => proceedToCheckout();

  const handleToggleItem = () => toggleCartItem(removeItem);

  const handleRemoval = async () => {
    await removeCartItem({
      complete: () => handleToggleItem(),
    });
  };

  const handleJoinFavorites = () => joinFavorites(removeItem);

  if (isEmpty(cartItems)) {
    return (
      <Box height="100%" mb={10.5}>
        <CartHeader />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: 'calc(100% - 50px)' }}
        >
          <EmptyState
            body={
              <FormatMessage
                className={classes.emptyTitle}
                component="span"
                message={appMessages.tapToStartShopping}
                variant="h1"
                color="textPrimary"
              />
            }
            icon={<CartEmptyIcon />}
            buttonStyles={{ height: '3rem', fontSize: '0.875rem' }}
            action={{
              label: <FormattedMessage {...appMessages.startShopping} />,
              event: handleRedirect,
            }}
            fullWidth
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box minHeight={1} className={classes.carts}>
      <PageLoader loading={checkoutLoading || loading} />
      <CartHeader isManagement={isManagement} onManagement={() => toggleIsManagement()} />
      {isEligibility && (
        <div className={classes.eligibilityTitle}>
          <FormatMessage
            variant="h5"
            color="textSecondary"
            message={productMessages.specialControllerCartNotice}
          />
        </div>
      )}
      <FadeInVariantList
        focus={showMiniCart}
        className={classes.items}
        list={cartItems}
        rowKey="item_id"
      >
        {item => (
          <>
            <MiniCartListItem
              cartItem={item}
              proceedingToCheckout={bool => setLoading(bool)}
              disabled={!isManagement}
              small
            />
            {item.divider && <Divider className={classes.divider} />}
          </>
        )}
      </FadeInVariantList>
      <Box className={classes.handle} width={1} bgcolor="primary.white">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={{ lg: 2 }}
          lineHeight={1}
        >
          <div>
            <Checkbox
              checked={isAllChecked}
              value="selectAll"
              onChange={handleSelectAll}
              icon={<CheckboxIcon />}
              classes={{
                root: classes.checkRoot,
                disabled: classes.checkDisabled,
              }}
              checkedIcon={<CheckboxCheckedIcon />}
              disabled={isManagement ? false : isEmpty(generalCartList)}
            />
            <FormatMessage
              message={{
                ...messages.cartSelectAll,
                values: { select: matchLg ? '全选' : '选择全部' },
              }}
              className={classes.checkAllText}
              variant="subtitle2"
              component="span"
              color="textPrimary"
            />
          </div>
          {accessLoading ? (
            <Skeleton height={25} width="50%" />
          ) : (
            <div className={classes.total}>
              <Access accessible={false && authorization([3, 4])}>
                <Total total={pvTotal} title={appMessages.PVColon} style={{ marginLeft: '10px' }} />
              </Access>
              <Total
                total={formatPrice(subTotal, '¥', 2)}
                title={appMessages.totalColon}
                style={{ marginLeft: '10px' }}
                variant="h6"
              />
            </div>
          )}
        </Box>
        <div className={classes.btn}>
          <Hidden mdUp>
            <Buttons
              route={routes.catalogEmpty}
              color="primary"
              messageId={
                <FormatMessage
                  message={messages.continueShopping}
                  variant="inherit"
                  component="span"
                  color="primary"
                />
              }
              onClick={handleDrawerClose}
              classes={{ root: classes.btnRoot }}
            />
            <Buttons
              color="primary"
              className={classes.batchRemove}
              onClick={() => toggleIsManagement()}
            >
              <FormatMessage
                message={isManagement ? messages.exitManagement : messages.management}
                variant="body2"
                component="span"
                color="primary"
                bold={700}
              />
            </Buttons>
          </Hidden>
          <Buttons
            data-test-id="checkoutBtn"
            color="primary"
            style={matchLg ? {} : { marginLeft: 16, flex: 1 }}
            className={classes.submit}
            {...(isManagement
              ? {
                  onClick: () => toggleIsRemove(true),
                  messageId: intl.formatMessage(messages.remove, { count: selectedItems.length }),
                  disabled: isEmpty(selectedItems),
                  variant: 'outlined',
                }
              : {
                  onClick: handleShowCheckout,
                  loading: loading || isLoading || checkoutLoading,
                  disabled:
                    selectedItems.length < 1 || loading || lockCheckout > 0 || checkoutLoading,
                  messageId:
                    lockCheckout > 0
                      ? intl.formatMessage(appMessages.processing)
                      : intl.formatMessage(messages.checkOut, { quantity: selectedItems.length }),
                  variant: 'contained',
                })}
          />
          <Hidden smDown>
            <Buttons
              route={routes.shoppingCart}
              color="primary"
              messageId={
                <FormatMessage
                  message={messages.edit}
                  variant="h3"
                  component="span"
                  color="primary"
                />
              }
              onClick={handleDrawerClose}
              className={classes.editBtn}
            />
          </Hidden>
        </div>
      </Box>
      <ConfirmDiaLog
        width={343}
        open={deleteDialogShow}
        title={
          <FormatMessage
            message={appMessages.confirmRemovalOfItem}
            className={classes.dialogTitle}
          />
        }
        onClose={closeConfirm}
        onConfirm={handleRemoval}
        loading={operateLoading}
        maxWidth={matchLg ? 'xs' : 'sm'}
        onAssistant={isFav ? null : handleJoinFavorites}
        assistantBtnMsg={
          <FormatMessage message={appMessages.moveToWishlist} variant="h3" bold={700} />
        }
      >
        <FormatMessage
          color="textSecondary"
          message={{ ...messages.destructiveAction, values: { cartName: removeItem.name } }}
          className={classes.removeContent}
        />
      </ConfirmDiaLog>
      <ConfirmDiaLog
        open={isRemove}
        width={343}
        title={intl.formatMessage(messages.deleteProduct)}
        confirmBtnMsg={intl.formatMessage(messages.confirmDelete)}
        onConfirm={() => confirm()}
        onAssistant={cancel}
        onClose={cancel}
        loading={batchRemoveLoading || isLoading}
      >
        <FormatMessage
          color="textSecondary"
          message={{ ...messages.selectedRemove, values: { count: selectedItems.length } }}
          className={classes.removeContent}
        />
      </ConfirmDiaLog>
      <SpecialDialog classes={{ root: classes.specialDialog }} />
      <DuplicateDialog open={isHasDuplicates} onClose={resetIsHasDuplicates} />
      <NoSubBundleDialog
        noSubBundles={noSubBundles}
        loading={batchRemoveLoading}
        onRemove={confirm}
        onClose={resetNoSubBundles}
      />
    </Box>
  );
}

export default Carts;
