/* eslint-disable react/prop-types */
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { createStructuredSelector } from 'reselect';
import { actions } from './actions';
import makeSelectNotifications from './selectors';

class Notifier extends Component {
  displayed = [];

  // eslint-disable-next-line react/sort-comp
  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = id => {
    this.displayed = this.displayed.filter(key => id !== key);
  };

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        this.props.closeSnackbar(key);
        return;
      }

      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) return;

      // Display snackbar using notistack
      this.props.enqueueSnackbar(message, {
        key,
        ...options,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        onClose: (event, reason, keyClosed) => {
          if (options.onClose) {
            options.onClose(event, reason, keyClosed);
          }
        },
        onExited: (event, keyExited) => {
          this.props.removeSnackbar(keyExited);
          this.removeDisplayed(keyExited);
        },
      });

      // Keep track of snackbars that we've displayed
      this.storeDisplayed(key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = createStructuredSelector({
  notifications: makeSelectNotifications(),
});

function mapDispatchToProps(dispatch) {
  return {
    removeSnackbar: key => dispatch(actions.removeSnackbar(key)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSnackbar, withConnect)(Notifier);
