import PropTypes from 'prop-types';

function LoadingIcon(props) {
  const fill = props.fill || 'currentColor';
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 16 16">
      <path
        d="M8 16c-4.41 0-8-3.59-8-8s3.59-8 8-8c2.135 0 4.143.83 5.654 2.34.285.284.285.745 0 1.03-.283.283-.743.283-1.027 0C11.39 2.134 9.747 1.454 8 1.454 4.39 1.455 1.455 4.39 1.455 8S4.39 14.545 8 14.545c1.748 0 3.392-.68 4.628-1.916.284-.286.745-.286 1.03 0s.283.743 0 1.027C12.145 15.167 10.137 16 8 16z"
        fill={fill}
      />
    </svg>
  );
}

LoadingIcon.defaultProps = {
  fill: '#CD0039',
  width: 30,
  height: 30,
};

LoadingIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoadingIcon;
