import useCopyMeToClipBoard from 'utils/Hooks/useCopyMeToClipBoard';

const useShare = () => {
  const copyToClipBoard = useCopyMeToClipBoard();

  const onShare = ({ title, ...other }) => {
    if (navigator.share) {
      navigator
        .share({
          title,
          url: window.location.href,
          ...other,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      // fallback
      copyToClipBoard(window.location.href);
    }
  };

  const onDownload = async (canvas, callback) => {
    await callback(canvas);
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'FitLine.png';
    link.href = url;
    link.click();
  };

  const onCopy = txt => copyToClipBoard(txt || window.location.href);

  return { onDownload, onShare, onCopy };
};

export default useShare;
