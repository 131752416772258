import PropTypes from 'prop-types';

function CheckoutCompleteIcon3({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="--WorkBench-(Don't-Check)--"
        stroke="none"
        strokeWidth="1"
        fill={fill}
        fillRule="evenodd"
      >
        <g
          id="Checkout---Completed-(Different-Graphic)"
          transform="translate(-133.000000, -124.000000)"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(133.000000, 124.000000)">
            <path
              d="M87.4166667,79.9005693 C85.6220509,77.828463 80.2039844,75.3419355 80.2039844,75.3419355 L76.7820513,77.6212524 L77.1385141,82.1798861 L86.0497926,82.3870968 L87.4166667,79.9005693 Z"
              id="Path"
              fill="#EAD8C5"
            />
            <path
              d="M13.6722187,59 C13.4393226,59 13.2099478,58.940445 13.0038348,58.8269083 L8.99640654,56.6220555 L4.98897824,58.8269083 C4.50483926,59.0926256 3.91865837,59.0478196 3.47650833,58.7112993 C3.03435829,58.374779 2.81277255,57.804794 2.90477056,57.2406183 L3.66945917,52.5750595 L0.426719486,49.2640177 C0.0398293886,48.8634126 -0.097853534,48.2684052 0.0710147589,47.7268122 C0.239883052,47.1852192 0.686351129,46.7898934 1.22446795,46.7054855 L5.70335839,46.0222068 L7.71569685,41.7720332 C7.98386393,41.2937623 8.47391475,41 9.00359346,41 C9.53327217,41 10.023323,41.2937623 10.2914901,41.7720332 L12.2966416,46.0222068 L16.7755321,46.7054855 C17.3136489,46.7898934 17.7601169,47.1852192 17.9289852,47.7268122 C18.0978535,48.2684052 17.9601706,48.8634126 17.5732805,49.2640177 L14.3305408,52.5750595 L15.0952294,57.2406183 C15.1670247,57.6790078 15.0499433,58.1281968 14.7754106,58.4676207 C14.5008779,58.8070446 14.0970142,59.0019362 13.6722187,59 Z"
              id="Path"
              fill="#20ACDC"
            />
            <path
              d="M73.6820519,18 C73.4489883,18 73.2194486,17.9404992 73.0131873,17.8270384 L69.0028768,15.6236576 L64.9925664,17.8270384 C64.5080792,18.0925783 63.9214767,18.0478022 63.4790087,17.7115065 C63.0365406,17.3752109 62.8147955,16.8056065 62.9068597,16.2418074 L63.6720983,11.5793636 L60.4270264,8.2705324 C60.039858,7.87019472 59.9020761,7.27558455 60.0710658,6.73435315 C60.2400556,6.19312175 60.6868448,5.79805992 61.2253486,5.71370833 L65.7074603,5.0308859 L67.7083003,0.771517804 C67.9766602,0.293566214 68.4670635,0 68.9971232,0 C69.5271828,0 70.0175861,0.293566214 70.285946,0.771517804 L72.2925397,5.01885379 L76.7746514,5.70167622 C77.3131552,5.7860278 77.7599444,6.18108964 77.9289342,6.72232104 C78.0979239,7.26355244 77.960142,7.85816261 77.5729736,8.25850029 L74.3279017,11.5673314 L75.0931403,16.2297753 C75.1684094,16.6673831 75.0546384,17.1173873 74.7823813,17.4589365 C74.5101242,17.8004858 74.1072862,17.9985728 73.6820519,18 Z"
              id="Path"
              fill="#20ACDC"
            />
            <path
              d="M44.8,13.8 C42.8117749,13.8 41.2,12.1882251 41.2,10.2 C41.2,9.5372583 40.6627417,9 40,9 C39.3372583,9 38.8,9.5372583 38.8,10.2 C38.8,12.1882251 37.1882251,13.8 35.2,13.8 C34.5372583,13.8 34,14.3372583 34,15 C34,15.6627417 34.5372583,16.2 35.2,16.2 C37.1882251,16.2 38.8,17.8117749 38.8,19.8 C38.8,20.4627417 39.3372583,21 40,21 C40.6627417,21 41.2,20.4627417 41.2,19.8 C41.2,17.8117749 42.8117749,16.2 44.8,16.2 C45.4627417,16.2 46,15.6627417 46,15 C46,14.3372583 45.4627417,13.8 44.8,13.8 Z"
              id="Path"
              fill="#A7CFDC"
            />
            <path
              d="M108.8,55.8 C106.811775,55.8 105.2,54.1882251 105.2,52.2 C105.2,51.5372583 104.662742,51 104,51 C103.337258,51 102.8,51.5372583 102.8,52.2 C102.8,54.1882251 101.188225,55.8 99.2,55.8 C98.5372583,55.8 98,56.3372583 98,57 C98,57.6627417 98.5372583,58.2 99.2,58.2 C101.188225,58.2 102.8,59.8117749 102.8,61.8 C102.8,62.4627417 103.337258,63 104,63 C104.662742,63 105.2,62.4627417 105.2,61.8 C105.2,59.8117749 106.811775,58.2 108.8,58.2 C109.462742,58.2 110,57.6627417 110,57 C110,56.3372583 109.462742,55.8 108.8,55.8 Z"
              id="Path-Copy"
              fill="#A7CFDC"
            />
            <g id="gift" transform="translate(31.000000, 22.000000)">
              <path
                d="M50.4736842,58 L7.52631579,58 C6.13106947,58 5,56.8657887 5,55.4666667 L5,22.5333333 C5,21.1342113 6.13106947,20 7.52631579,20 L50.4736842,20 C51.8689305,20 53,21.1342113 53,22.5333333 L53,55.4666667 C53,56.8657887 51.8689305,58 50.4736842,58 Z"
                id="Path"
                fill="#203C5C"
              />
              <path
                d="M53,32.260533 L53,35.8264235 L30.7861811,58 L27.2138189,58 L53,32.260533 Z M45.79112,20.54331 C46.2845347,20.0507891 47.0838863,20.0507891 47.5773011,20.54331 C48.0707158,21.0358309 48.0707158,21.8337343 47.5773011,22.3262552 L47.5773011,22.3262552 L11.8388126,58 L8.26645053,58 Z M30.7861811,20.173913 L5,45.91338 L5,42.3474896 L27.2138189,20.173913 L30.7861811,20.173913 Z M39.1052632,0 C42.5875874,0 45.4210526,2.82833217 45.4210526,6.30434783 C45.4210526,9.78036348 42.5875874,12.6086957 39.1052632,12.6086957 L39.1052632,12.6086957 L18.8947368,12.6086957 C15.4124126,12.6086957 12.5789474,9.78036348 12.5789474,6.30434783 C12.5789474,2.82833217 15.4124126,0 18.8947368,0 C24.4049347,0 27.4834274,4.47583478 29,7.73274913 C30.5165726,4.47583478 33.5950653,0 39.1052632,0 Z M18.8947368,2.52173913 C16.8050947,2.52173913 15.1052632,4.2184913 15.1052632,6.30434783 C15.1052632,8.39020435 16.8050947,10.0869565 18.8947368,10.0869565 L18.8947368,10.0869565 L27.2508168,10.0869565 C26.2442442,7.47594783 23.7006611,2.52173913 18.8947368,2.52173913 Z M39.1052632,2.52173913 C34.2993389,2.52173913 31.7557558,7.47594783 30.7491832,10.0869565 L30.7491832,10.0869565 L39.1052632,10.0869565 C41.1949053,10.0869565 42.8947368,8.39020435 42.8947368,6.30434783 C42.8947368,4.2184913 41.1949053,2.52173913 39.1052632,2.52173913 Z"
                id="Combined-Shape"
                fill="#EFD358"
              />
              <path
                d="M55.4782609,23 L2.52173913,23 C1.12902043,23 0,21.835941 0,20.4 L0,12.6 C0,11.164059 1.12902043,10 2.52173913,10 L55.4782609,10 C56.8709796,10 58,11.164059 58,12.6 L58,20.4 C58,21.835941 56.8709796,23 55.4782609,23 Z"
                id="Path"
                fill="#A3BFE0"
              />
            </g>
            <path
              d="M87.429562,79.7858592 L42.4784468,79.7858592 C40.581455,79.7858592 39.0436409,78.2614282 39.0436409,76.3809454 L39.0436409,76.3809454 C39.0436409,74.5004626 40.581455,72.9760316 42.4784468,72.9760316 L57.6437844,72.9760316 C60.4333619,72.9760316 62.9337632,70.9054865 63.0799657,68.1439823 C63.2359059,65.1984765 60.8732575,62.7612903 57.9350731,62.7612903 L15,62.7612903 L15,87.4111636 L43.2499385,99.0856255 C46.3769858,100.247654 49.8366424,100.231328 53.0193507,99.4347653 L90.9615385,85.9506088 C90.1631007,83.3108983 88.9440366,81.2901671 87.429562,79.7858592 Z"
              id="Path"
              fill="#EAD8C5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

CheckoutCompleteIcon3.defaultProps = {
  width: 110,
  height: 100,
  fill: 'currentColor',
};

CheckoutCompleteIcon3.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CheckoutCompleteIcon3;
