import AnimationShowElement from 'components/Animation/AnimationShowElement';

const AnimateComponent = WrappedComponent => {
  const Child = props => (
    <AnimationShowElement initialTransform>
      <WrappedComponent {...props} />
    </AnimationShowElement>
  );

  return Child;
};

export default AnimateComponent;
