/* eslint-disable */
function Icon16Star(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M14.25 5.634l-3.777-.575-1.685-3.584A.904.904 0 0 0 8 1a.904.904 0 0 0-.787.475L5.527 5.06l-3.776.574a.887.887 0 0 0-.708.623.947.947 0 0 0 .221.94l2.732 2.791-.644 3.94a.937.937 0 0 0 .348.897c.269.205.626.232.92.07L8 13.033l3.378 1.86a.842.842 0 0 0 .921-.07.937.937 0 0 0 .348-.896l-.644-3.94 2.733-2.79a.947.947 0 0 0 .221-.938.887.887 0 0 0-.705-.624h-.002z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Star;
