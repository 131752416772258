import PropTypes from 'prop-types';

function DoneIcon({ width, height, fill, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---12.-DCFC-Management" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="FC-Invoice" transform="translate(-171.000000, -376.000000)" fillRule="nonzero">
          <g id="c-check" transform="translate(171.000000, 376.000000)">
            <path
              d="M16,32 C7.17730783,32 0,24.8223513 0,16 C0,7.1776487 7.17730783,0 16,0 C24.8226922,0 32,7.1776487 32,16 C32,24.8223513 24.8226922,32 16,32 Z"
              id="Path"
              fill="#72C472"
            />
            <polygon
              id="Path"
              fill="#FFFFFF"
              points="13.2173913 23.5329461 5.68478609 16 7.65217391 14.0322713 13.2173913 19.5974887 24.3478261 8.46705391 26.3152139 10.4347826"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

DoneIcon.defaultProps = {
  width: 32,
  height: 32,
  fill: 'currentColor',
  viewBox: '0 0 32 32',
};

DoneIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
};

export default DoneIcon;
