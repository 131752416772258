/**
 *
 * Asynchronously loads the component for Checkout
 *
 */

import loadable from 'utils/loadable';

export const PaymentPage = loadable(() => import('./components/PaymentDesktop'));

export default loadable(() => import('./index'));
