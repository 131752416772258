/* eslint-disable */
function Icon16CheckCircle(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M8 1c3.85 0 7 3.15 7 7s-3.15 7-7 7-7-3.15-7-7 3.15-7 7-7zm2.625 4.025l-3.5 3.5-1.75-1.75L4.15 8l2.975 2.975L11.85 6.25l-1.225-1.225z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16CheckCircle;
