/* eslint-disable */
/**
 * Create the store with dynamic reducers
 */

import { compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';

import createReducer from './reducers';

export default function configStore(initialState = {}, history) {
  let composeEnhancers = compose;

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false });
  }

  // Create the store with one middlewares
  // 1. routerMiddleware: Syncs the location/URL path to the state
  let middlewares = [];
  if (process.env.NODE_ENV !== 'production') {
    middlewares = [...getDefaultMiddleware(), routerMiddleware(history)];
  } else {
    middlewares = [...getDefaultMiddleware(), routerMiddleware(history)];
  }

  const store = configureStore({
    reducer: createReducer(),
    preloadedState: initialState,
    middleware: middlewares,
  });

  return store;
}
