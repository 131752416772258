import PropTypes from 'prop-types';

function RadioIcon({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="*Design-System" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="4-6-Checkbox-and-Single-Select">
          <rect fill="#FFFFFF" x="0" y="0" />
          <g id="3.Buttons/SingleSelection/Off">
            <rect id="Rectangle" fill="#F6D7A4" x="0" y="0" width="20" height="20" rx="10" />
            <rect id="Rectangle-Copy" fill="#FFFFFF" x="1" y="1" width="18" height="18" rx="9" />
          </g>
        </g>
      </g>
    </svg>
  );
}

RadioIcon.defaultProps = {
  width: 20,
  height: 20,
};

RadioIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioIcon;
