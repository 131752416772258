import PropTypes from 'prop-types';

function MedalTwoIcon({ width, height, fill, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>2.Icons/12/12-medal2</title>
      <g id="2.Icons/12/12-medal2" stroke="none" strokeWidth="1" fill="#CD0039" fillRule="evenodd">
        <path
          d="M6,9 C4.874,9 3.837,8.621 3,7.9905 L3,12 L6,10.5 L9,12 L9,7.9905 C8.163,8.621 7.126,9 6,9 Z"
          id="Path-Copy"
          fill="#A7A9AC"
        />
        <path
          d="M6,0 C8.209139,0 10,1.790861 10,4 C10,6.209139 8.209139,8 6,8 C3.790861,8 2,6.209139 2,4 C2,1.790861 3.790861,0 6,0 Z M6,1.5 L5.22743606,3.14666667 L3.5,3.41 L4.75026421,4.69111111 L4.45487212,6.5 L6,5.64555556 L7.54512788,6.5 L7.24973579,4.69111111 L8.5,3.41 L6.77256394,3.14666667 L6,1.5 Z"
          id="Combined-Shape-Copy"
          fill={fill}
        />
      </g>
    </svg>
  );
}

MedalTwoIcon.defaultProps = {
  width: 12,
  height: 12,
  fill: 'currentColor',
  viewBox: '0 0 14 14',
};

MedalTwoIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
};

export default MedalTwoIcon;
