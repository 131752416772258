/* eslint-disable */
function Icon16Minus(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <rect height="2" width="12" fill={fill} fillRule="evenodd" rx="1" x="2" y="7" />
      </g>
    </svg>
  );
}

export default Icon16Minus;
