import { forwardRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useIntl, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Popover from '@material-ui/core/Popover';
import isEmpty from 'lodash/isEmpty';
import { AimIcon, CloseIcon24, AngleLeftIcon16 } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import FormatMessage from 'components/FormatMessage';
import FormatSvgIcon from 'components/FormatMessage/FormatSvgIcon';
import WholePageDialog from 'components/Dialogs/WholePageDialog';
import NavigationBar from 'components/NavigationBar';
import useStore from 'containers/StoreProvider/hooks/useStore';
import PageLoader from 'components/PageLoader';
import useGetSourceCode from 'utils/Hooks/useGetSourceCode';
import { useGetStoreList } from 'containers/StoreProvider/queries';
import StoreList from './components/StoreList';
import SelectPicker from './components/SelectPicker';
import messages from './messages';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    width: 420,
    maxHeight: ({ position = {} }) =>
      position?.height ? position.height : `calc(100vh - ${position.y}px - 16px)`,
    // minHeight: 'auto',
    // boxSizing: 'border-box',
    // boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)',
    // borderRadius: 8,
    // background: theme.palette.background.white,
  },
  content: {
    overflowY: 'scroll',
    maxHeight: ({ position = {} }) =>
      position?.height ? position.height - 44 : `calc(100vh - ${position.y}px - 60px)`,
  },
  backdrop: {
    backgroundColor: 'transparent',
  },
  storeContainer: {
    // width: 1408,
    // position: 'absolute',
    // top: 10,
  },
  locationButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  closeButton: {
    marginLeft: theme.spacing(-1.5),
  },
}));

const StoreProvider = ({ onClose }) => {
  const {
    storeList,
    cityList = [],
    getNearbyStores,
    updateSource,
    // redirectStore,
    toggleStoreVisible,
    storeVisible,
    isLoading,
    changeCity,
    filterValues,
    popoverProps,
    storeSelected,
  } = useStore();
  const { data: allStoreList } = useGetStoreList();
  const classes = useStyles(popoverProps);
  const intl = useIntl();

  const cartSourceCode = useGetSourceCode();

  const handleClose = () => {
    toggleStoreVisible(false);
    onClose?.();
  };

  // const handleSelection = s => {
  //   // updateSource(s);
  //   redirectStore(s);
  //   handleClose();
  // };

  const handleLocation = () => {
    getNearbyStores();
  };

  const handleChangeCity = val => {
    changeCity({ city: val });
  };

  const cityListOptions = useMemo(() => ['全国', ...cityList], [cityList]);

  const { position, ...otherPopoverProps } = popoverProps;

  const sortedStoreList = useMemo(
    () => [...storeList]?.sort(s => (s?.source_code === storeSelected?.source_code ? -1 : 1)),
    [storeSelected, storeList],
  );

  useEffect(() => {
    if (!isEmpty(allStoreList) && cartSourceCode) {
      const { host } = window.location;
      const currentStore = allStoreList?.find(store =>
        store?.extension_attributes?.subdomain?.includes?.(host),
      );
      if (origin && currentStore) {
        updateSource(currentStore);
      }
    }
  }, [allStoreList, cartSourceCode]);

  const headRender = ({ setOpen }) => (
    <NavigationBar
      border
      title={<FormattedMessage {...messages.selectStore} />}
      onClick={setOpen}
      rightItem={
        <Buttons
          startIcon={
            <SvgIcon component={AimIcon} viewBox="0 0 12 12" fontSize="small" color="primary" />
          }
          messageId={
            <Typography variant="h4">
              <FormattedMessage {...messages.reLocation} />
            </Typography>
          }
          onClick={handleLocation}
        />
      }
    />
  );
  return (
    <>
      <PageLoader loading={isLoading} />
      <Hidden mdUp>
        <WholePageDialog
          title={<FormattedMessage {...messages.selectStore} />}
          open={storeVisible}
          setOpen={handleClose}
          icon={<SvgIcon component={AngleLeftIcon16} viewBox="0 0 16 16" color="primary" />}
          headRender={headRender}
          containerProps={{ style: { padding: 0 } }}
          TransitionComponent={Transition}
        >
          <Box minHeight={300} overflow="scroll" pb="64px" maxHeight="calc(100vh - 48px)">
            <StoreList list={sortedStoreList} />
          </Box>
        </WholePageDialog>
      </Hidden>
      <Hidden smDown>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          elevation={1}
          {...otherPopoverProps}
          open={storeVisible}
          onClose={handleClose}
        >
          <Box className={classes.storeContainer}>
            <Box className={classes.container}>
              <Box display="flex" alignItems="center" pt={1.5} px={3}>
                <IconButton color="primary" onClick={handleClose} className={classes.closeButton}>
                  <SvgIcon
                    component={CloseIcon24}
                    viewBox="0 0 24 24"
                    style={{ fontSize: '1rem' }}
                    color="primary"
                  />
                </IconButton>
                <Buttons
                  size="medium"
                  startIcon={
                    <FormatSvgIcon
                      icon={AimIcon}
                      viewBox="0 0 16 16"
                      variant="caption"
                      color="primary"
                    />
                  }
                  messageId={<FormatMessage variant="caption" message={messages.reLocation} />}
                  onClick={handleLocation}
                  className={classes.locationButton}
                />
                <SelectPicker
                  value={filterValues.city || ''}
                  renderValue={selected => (
                    <Typography variant="body2">
                      <b>
                        {!selected || selected === '全国'
                          ? intl.formatMessage(messages.allCity)
                          : selected}
                      </b>
                    </Typography>
                  )}
                  placeholder={intl.formatMessage(messages.allCity)}
                  onChange={handleChangeCity}
                  options={cityListOptions}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                />
              </Box>
              <Box className={classes.content}>
                <StoreList list={sortedStoreList} />
              </Box>
            </Box>
          </Box>
        </Popover>
      </Hidden>
    </>
  );
};

StoreProvider.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default StoreProvider;
