import PropTypes from 'prop-types';

function MedalIcon24({ width, height, ...other }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g id="---1.-Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Home-1---Webview-MP-Page"
          transform="translate(-149.000000, -1747.000000)"
          fillRule="nonzero"
        >
          <g id="Group-4-Copy" transform="translate(0.000000, 1735.000000)">
            <g id="medal" transform="translate(149.000000, 12.000000)">
              <path
                d="M12,9.36650227 L7.49672377,0.299549594 C7.40491846,0.115020472 7.21672549,-0.001117904 7.0113407,-2.27373675e-13 L0.539566473,-2.27373675e-13 C0.352630335,-2.27373675e-13 0.178980894,0.0970651577 0.0806467102,0.256741193 C-0.0176874737,0.416417228 -0.0266805046,0.615849355 0.0568799787,0.783799292 L6.13495461,13 L12,9.36650227 Z"
                id="Path"
                fill="#ED7064"
              />
              <path
                d="M12,9.36650228 L16.502263,0.299549625 C16.5940476,0.115020503 16.7821983,-0.00111787272 16.9875368,-2.27373675e-13 L23.4578549,-2.27373675e-13 C23.6452283,-0.0010221671 23.8196992,0.0957441751 23.9186622,0.255584497 C24.0176252,0.415424818 24.0268887,0.615417566 23.9431288,0.783799322 L17.8637258,13 L12,9.36650228 Z"
                id="Path"
                fill="#ED7064"
              />
              <path
                d="M12,8 C7.581722,8 4,11.581722 4,16 C4,20.418278 7.581722,24 12,24 C16.418278,24 20,20.418278 20,16 C19.9950035,11.5837933 16.4162067,8.00499654 12,8 Z"
                id="Path"
                fill="#FFD764"
              />
              <polygon
                id="Path"
                fill="#D3A133"
                points="12 12 13.3906783 14.6333745 16.5 15.0556242 14.2497355 17.1055624 14.7808276 20 12 18.6333745 9.21917245 20 9.75026449 17.1055624 7.5 15.0556242 10.6093217 14.6333745"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

MedalIcon24.defaultProps = {
  width: 24,
  height: 24,
};

MedalIcon24.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MedalIcon24;
