import axios from 'axios';
import { push } from 'connected-react-router/immutable';
import { sprintf } from 'utils/helpers';
import { deleteAuthorization } from 'utils/Auth';
import routes from 'utils/routes';
import { resetCart } from 'containers/CartProvider/slices';
import { resetCheckout } from 'containers/CheckoutPage/slices';
import { CART_QUERY_KEY } from 'containers/CartProvider/queries';
import { parseToken } from 'utils/request';
import { actions as notificationActions } from 'utils/Notifications/actions';
import { sentryCaptureRequest } from 'utils/Sentry';
import { COUPON_QUERY_KEY } from 'containers/CouponPage/queries';
import reduxStore from '../../store';
// import determineEnvironment from 'utils/WeChat/determineEnvironment';

const showErrorMessage = message => {
  reduxStore.dispatch(
    notificationActions.enqueueSnackbar({
      message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'default',
      },
    }),
  );
};

export default ({ dispatch }, queryClient) => {
  const init = () => {
    // Specific to the user's token being expired
    deleteAuthorization();
    dispatch(resetCart());
    dispatch(resetCheckout());
    dispatch(push(routes.home));
    // if (determineEnvironment()) {
    //   dispatch(push(routes.home));
    // } else {
    //   dispatch(push(routes.logIn));
    // }
    queryClient.refetchQueries([CART_QUERY_KEY.GET]);
    queryClient.removeQueries(COUPON_QUERY_KEY.CHECKOUT);
    queryClient.removeQueries(COUPON_QUERY_KEY.GET_MY_LIST);
    queryClient.removeQueries('membershipPage.upgradeSMData');
    queryClient.removeQueries(['orderListPage.orders']);
    queryClient.removeQueries('loyalty.pointsPayout');
    queryClient.removeQueries('userProvider.customer');
    queryClient.removeQueries('sharingProvider.myShareDownline');
    queryClient.removeQueries('wishlistPage.allWishlist');
    queryClient.removeQueries('fapiaoPage.fapiaoList');
  };

  axios.interceptors.request.use(
    config => {
      // const { headers } = response
      // const authorization = headers.Authorization.split('Bearer ')
      // const token = authorization[authorization.length - 1]
      const { payload = {} } = parseToken();
      if (payload.exp && Date.now() > payload.exp) {
        init();
        console.log(config?.url);
        // eslint-disable-next-line no-new
        new axios.Cancel(`登录凭证已过期，请重新登录！`);
        throw new Error(`登录凭证已过期，请重新登录！`);
      }
      return config;
    },
    error => Promise.reject(error),
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      console.log(error);

      sentryCaptureRequest(error);

      if (typeof error.response === 'undefined') {
        const err = new Error(error?.message || '服务器正忙，请稍后重试！', { cause: error });
        // showErrorMessage(err.message);
        return Promise.reject(err);
      }

      const { response: { config = {}, status, data = {} } = {} } = error;
      const isNoLogin = [
        "The current customer isn't authorized.",
        "The consumer isn't authorized to access %resources.",
      ].includes(data?.message);

      if (status === 401 && isNoLogin && !config.url.endsWith('/logout')) {
        init();
        return Promise.reject(new Error('登录凭证已过期，请重新登录！', { cause: error }));
      }

      const message = data?.message
        ? sprintf(data.message, data.parameters)
        : `服务器正忙，请稍后重试`;

      if (status >= 400 && !isNoLogin && !config.url.includes('loyalty/use-points')) {
        showErrorMessage(message);
      }

      return Promise.reject(new Error(message, { cause: error }));
    },
  );
};
