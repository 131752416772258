import isEqual from 'lodash/isEqual';
import { useMemo, useRef } from 'react';

const depsEqual = (aDeps, bDeps) => isEqual(aDeps, bDeps);

const useDeepCompareMemo = (callback, deps) => {
  const ref = useRef();
  const signalRef = useRef(0);

  if (!depsEqual(deps, ref.current)) {
    ref.current = deps;
    signalRef.current += 1;
  }

  return useMemo(() => callback(), [signalRef.current]);
};

export default useDeepCompareMemo;
