/* eslint-disable */
function Icon16InfoCircle(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M8 2c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6zm0 1.5A4.513 4.513 0 0 0 3.5 8c0 2.475 2.025 4.5 4.5 4.5s4.5-2.025 4.5-4.5S10.475 3.5 8 3.5zm1 4v4H7v-4h2zm-1-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16InfoCircle;
