import PropTypes from 'prop-types';

function CartEmptyIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 98 96"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="---6.Shopping-cart" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="Shopping-cart---Empty" transform="translate(-138.000000, -219.000000)">
          <g id="cart" transform="translate(138.000000, 219.000000)">
            <path
              d="M22.4890325,51.9999968 C23.322633,51.9897528 24.0963803,52.437632 24.510729,53.1704221 C24.9250777,53.9032123 24.9149854,54.8058836 24.4843593,55.5289798 L17.2620423,68.3460188 L93.7028254,68.3460188 C94.9715199,68.3460188 96,69.387846 96,70.6730049 C96,71.9581638 94.9715199,72.9999968 93.7028254,72.9999968 L13.3017132,72.9999968 C12.4802016,73.001635 11.7203558,72.5587635 11.3088974,71.8384904 C10.897439,71.1182172 10.8970074,70.230195 11.3077656,69.5095119 L20.4964642,53.2206097 C20.8928327,52.4776837 21.655432,52.0105967 22.4890325,51.9999968 Z M2.79910017,0.0466189595 L14.4581604,2.33219857 C15.1650614,2.46924829 15.7676057,2.91894626 16.0904288,3.5504125 L20.7540529,12.6927309 C21.1162839,13.4032235 21.0762225,14.2470998 20.6482439,14.9215515 C20.2202653,15.5960032 19.4657429,16.0043121 18.655422,16 C17.7674083,16.0047159 16.953726,15.5146661 16.5567912,14.7360391 L12.415493,6.59480454 L1.86637535,4.52635499 C0.604307609,4.27389684 -0.210002884,3.066416 0.0475619623,1.82937105 C0.305126808,0.592326107 1.53703243,-0.205839193 2.79910017,0.0466189595 Z"
              id="Combined-Shape"
              fill="#A7A9AC"
            />
            <path
              d="M23,78 C18.0294373,78 14,82.0294373 14,87 C14,91.9705627 18.0294373,96 23,96 C27.9705627,96 32,91.9705627 32,87 C32,82.0294373 27.9705627,78 23,78 Z"
              id="Path"
              fill="#A7A9AC"
            />
            <path
              d="M85,78 C80.0294373,78 76,82.0294373 76,87 C76,91.9705627 80.0294373,96 85,96 C89.9705627,96 94,91.9705627 94,87 C94,82.0294373 89.9705627,78 85,78 Z"
              id="Path"
              fill="#A7A9AC"
            />
            <circle id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="22.5" cy="86.5" r="4.5" />
            <circle id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="84.5" cy="86.5" r="4.5" />
            <path
              d="M88.8188856,57 L22.827837,57 C21.6678936,57 20.6935307,56.1184634 20.5659369,54.9534021 L16.0148301,13.5615348 C15.9369177,12.8749296 16.1687187,12.1893214 16.6460883,11.6944346 C17.1234578,11.1995478 17.7957696,10.9478662 18.4769789,11.0090363 L95.9238491,11 C96.5681179,11.0572758 97.1578319,11.3888885 97.5456082,11.9119606 C97.9333845,12.4350326 98.082584,13.1001415 97.9559183,13.7410614 L91.0512035,55.1419651 C90.8423141,56.220948 89.9068514,57 88.8188856,57 Z"
              id="Path"
              fill="#A7A9AC"
            />
            <path
              d="M84.0504985,53 L26.9119076,53 C25.9075664,53 25.0639107,52.2526103 24.9534332,51.2648409 L21.0128407,16.171736 C20.94538,15.5896142 21.1460857,15.0083377 21.5594179,14.5887598 C21.97275,14.1691818 22.5548737,13.9557996 23.1447012,14.0076612 L90.2023572,14 C90.7601997,14.0485599 91.2708057,14.3297098 91.6065632,14.7731839 C91.9423207,15.2166581 92.0715056,15.7805547 91.9618317,16.3239434 L85.9833591,51.4247095 C85.8024915,52.3394994 84.9925177,53 84.0504985,53 Z"
              id="Path-Copy"
              fill="#CECECE"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

CartEmptyIcon.defaultProps = {
  width: 98,
  height: 96,
  fill: 'currentColor',
};

CartEmptyIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CartEmptyIcon;
