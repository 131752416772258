import PropTypes from 'prop-types';

function CheckboxCheckedIcon({ width, height, fill }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="*Design-System" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g id="4-6-Checkbox-and-Single-Select" transform="translate(-298.000000, -431.000000)">
          <rect fill="none" x="0" y="0" width="595" height="842" />
          <g id="3.Buttons/Checkbox/On" transform="translate(298.000000, 431.000000)">
            <rect id="Rectangle" fill="#CD0039" x="0" y="0" width="20" height="20" rx="4" />
            <g id="2.Icons/16/16-check" transform="translate(2.000000, 2.000000)" fill="#FFFFFF">
              <path
                d="M12.725,3.225 L5.75,10.2 L3.275,7.725 C2.975,7.425 2.525,7.425 2.225,7.725 C1.925,8.025 1.925,8.475 2.225,8.775 L5.225,11.775 C5.375,11.925 5.525,12 5.75,12 C5.975,12 6.125,11.925 6.275,11.775 L13.775,4.275 C14.075,3.975 14.075,3.525 13.775,3.225 C13.475,2.925 13.025,2.925 12.725,3.225 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

CheckboxCheckedIcon.defaultProps = {
  width: 20,
  height: 20,
  fill: 'currentColor',
};

CheckboxCheckedIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CheckboxCheckedIcon;
