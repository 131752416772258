import PropTypes from 'prop-types';

function GermanyIcon({ width, height, ...other }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g id="---1.-Home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Home-1---Webview-MP-Page"
          transform="translate(-53.000000, -1747.000000)"
          fillRule="nonzero"
        >
          <g id="Group-4-Copy" transform="translate(0.000000, 1735.000000)">
            <g id="germany" transform="translate(53.000000, 12.000000)">
              <path
                d="M24,8 L0,8 L0,1.33333333 C0,0.596666667 0.4475,0 1,0 L23,0 C23.5525,0 24,0.596666667 24,1.33333333 L24,8 Z"
                id="Path"
                fill="#000000"
              />
              <rect id="Rectangle" fill="#EE0000" x="0" y="8" width="24" height="8" />
              <path
                d="M24,22.6666667 C24,23.4033333 23.5525,24 23,24 L1,24 C0.4475,24 0,23.4033333 0,22.6666667 L0,16 L24,16 L24,22.6666667 Z"
                id="Path"
                fill="#FDCF00"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

GermanyIcon.defaultProps = {
  width: 24,
  height: 24,
};

GermanyIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default GermanyIcon;
