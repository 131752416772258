/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * SharingShoppingContent
 *
 */

import { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { wrapText } from 'utils/helpers';
import swooshPng from 'images/share/swoosh.png';
import { useGetQrCode } from 'containers/SharingProvider/queries';
import { useGetCustomer } from 'containers/UserProfile/queries';
import parseCustomerName from 'utils/User/parseCustomerName';
import PageLoader from 'components/PageLoader';
import useShare from 'utils/Hooks/useShare';
import messages from '../messages';
import { createImage } from '../utils';
import ShareActionSheet from './ShareActionSheet';
import SharingShoppingCard from './SharingShoppingCard';
import { SHARING_SHOPPING_LIST_ACTIONS } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      minHeight: 'calc(100vh - 64px)',
      paddingBottom: 'calc(102px + env(safe-area-inset-bottom))',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
  },
  canvas: {
    position: 'fixed',
    top: '100%',
    left: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
  },
}));

function SharingShoppingContent({ qrcodeId, subtitle, customerId }) {
  const intl = useIntl();
  const classes = useStyles();
  const canvasRef = useRef();
  const cardRef = useRef();

  const { onShare, onDownload, onCopy } = useShare();

  const { data: customer } = useGetCustomer();
  const { data, isLoading } = useGetQrCode(`type=st&value=${qrcodeId}-${customerId}`);

  const fullName = parseCustomerName(
    customer?.firstname?.substr(0, 3),
    customer?.lastname?.substr(0, 3),
  );

  const drawCanvas = async canvas => {
    const ctx = canvas.getContext('2d');

    const dpr = window.devicePixelRatio;
    const width = cardRef.current.offsetWidth;
    const height = cardRef.current.offsetHeight;
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    ctx.scale(dpr, dpr);

    // card
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    // card bottom
    ctx.rect(0, 254, width, height);
    ctx.fillStyle = '#FFF3E0';
    ctx.fillRect(0, 254, width, height);
    ctx.restore();

    try {
      // card wave
      ctx.restore();
      const { image: swoosh } = await createImage(swooshPng);
      ctx.drawImage(swoosh, 0, 216, width, (86 * width) / 750 + 1);

      // qrcode
      ctx.restore();
      const { image: qrcode } = await createImage(data);
      ctx.drawImage(qrcode, (width - 200) / 2, 16, 200, 200);

      // title
      ctx.font = 'bold 16px Roboto';
      ctx.fillStyle = '#090909';

      const { y } = wrapText(
        canvas,
        ctx,
        `${intl.formatMessage(messages.somebodySharedAList, { name: fullName })}`,
        16,
        276,
        width - 32,
        24,
      );

      // sub title
      ctx.textAlign = 'left';
      ctx.fillStyle = '#808080';
      ctx.font = 'normal 16px Roboto';

      wrapText(canvas, ctx, subtitle, 16, y + 32, width - 32, 24);

      ctx.restore();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const handleSelect = key => {
    if (key === 'share')
      onShare({
        title: `${intl.formatMessage(messages.somebodySharedAList, { name: fullName })}`,
      });
    if (key === 'download') onDownload(canvasRef.current, drawCanvas);
    if (key === 'copy') onCopy();
  };

  return (
    <>
      <PageLoader loading={isLoading} />
      <canvas ref={canvasRef} className={classes.canvas} />
      <Box className={classes.root}>
        <Box className={classes.content}>
          <SharingShoppingCard
            qrCode={data}
            fullName={fullName}
            subtitle={subtitle}
            ref={cardRef}
          />
          <Box className={classes.inviteDesc}>
            <Box mt={2}>
              <Typography variant="body1" color="textSecondary">
                <FormattedMessage {...messages.sharedListDesc} />
              </Typography>
            </Box>

            <Hidden mdUp>
              <ShareActionSheet list={SHARING_SHOPPING_LIST_ACTIONS} onSelect={handleSelect} />
            </Hidden>
          </Box>
        </Box>
      </Box>
    </>
  );
}

SharingShoppingContent.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  qrcodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SharingShoppingContent;
