import { useMutation, useQuery } from 'react-query';
import get from 'lodash/get';
import GET_CUSTOMER from 'containers/UserProfile/gql/queryGetCustomer';
import requestGql, { querySetting } from 'utils/requestGql';
import { isSignedIn } from 'utils/Auth';
import { changePassword, updateProfile, updateSM, userUpgradeDC } from 'containers/UserProfile/api';

export const USER_QUERY_KEY = {
  GET: 'userProvider.customer',
};

export const getCustomerQuery = () => requestGql(GET_CUSTOMER);

export const useGetCustomer = (option = {}) =>
  useQuery(
    USER_QUERY_KEY.GET,
    () =>
      getCustomerQuery().then(response => {
        const customer = get(response, 'customer', {});
        const shippingAddresses = customer.addresses.filter(address => address.type === 1);
        const billingAddress = customer.addresses.filter(address => address.type === 2);
        customer.addresses = shippingAddresses;
        customer.billingAddress = billingAddress[0] || {};
        return customer;
      }),
    {
      ...querySetting,
      enabled: isSignedIn(),
      ...option,
    },
  );

/**
 * Update personal information 更新个人信息
 * @apiGroup User
 * */
export const useUpdateProfileMutation = () => useMutation(payload => updateProfile(payload));

/**
 * Change Password 修改密码
 * @apiGroup User
 * */
export const useChangePasswordMutation = () =>
  useMutation(({ oldPassword, newPassword }) => changePassword({ oldPassword, newPassword }));

/**
 * @apiGroup User
 * */
export const useUpdateSMMutation = () => useMutation(payload => updateSM(payload));

/**
 * Update DC 升级DC
 * @apiGroup User
 * */
export const useUpgradeDCMutation = () => useMutation(() => userUpgradeDC());
