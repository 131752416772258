import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import get from 'lodash/get';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

export const getInventorySources = payload =>
  request('GET', `/vendor/inventory/sources${qs.stringify(payload, { addQueryPrefix: true })}`);
export const getInventorySourceItem = sourceCode =>
  request('GET', `/inventory/sources/${sourceCode}`);
export const getCustomerSource = () => request('GET', '/tmo-vendor/customer/source');
export const setCustomerSource = payload => request('POST', '/tmo-vendor/customer/source', payload);
export const getVendorQuery = vendorCode => request('GET', `/tmo-vendor/vendor/${vendorCode}`);

export const getSourceCartQuantity = payload =>
  request(
    'GET',
    `/inventory/source-items/quantity/cart${qs.stringify(payload, { addQueryPrefix: true })}`,
    payload,
  );
export const getSourceCityList = () => request('GET', '/vendor/inventory/sources/city');

export const useGetCustomerSourceQuery = () =>
  useQuery(['customer.source'], () => getCustomerSource(), querySetting);
export const useSourceQuery = sourceCode => {
  const data = {
    'searchCriteria[filterGroups][1][filters][0][conditionType]': 'eq',
    'searchCriteria[filterGroups][1][filters][0][field]': 'source_code',
    'searchCriteria[filterGroups][1][filters][0][value]': sourceCode,
  };
  return useQuery(
    ['source', sourceCode],
    () => getInventorySources(data).then(response => get(response, 'items', [])),
    { ...querySetting, enabled: !!sourceCode },
  );
};

export const useGetInventorySourceItemQuery = sourceCode =>
  useQuery(['sourceItem', sourceCode], () => getInventorySourceItem(sourceCode), {
    ...querySetting,
    enabled: !!sourceCode,
  });

export const useGetVendorQuery = vendorCode =>
  useQuery(
    vendorCode && ['customer.vendor', vendorCode],
    () => getVendorQuery(vendorCode),
    querySetting,
  );

export const useGetStoreList = () =>
  useQuery(
    ['source.stores'],
    () => getInventorySources().then(response => get(response, 'items', [])),
    querySetting,
  );

export const useGetSourceCityListQuery = () =>
  useQuery(
    ['source.cityList'],
    () => getSourceCityList().then(([cityList]) => cityList),
    querySetting,
  );
export const useGetStores = () =>
  useMutation(payload => getInventorySources(payload).then(response => get(response, 'items', [])));
export const useSetCustomerSource = () => useMutation(payload => setCustomerSource(payload));
export const useGetSourceCartQuantity = () =>
  useMutation(payload =>
    getSourceCartQuantity(payload).then(response => get(response, 'items', [])),
  );
