/* eslint-disable */
function Icon24Weibo(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg height={height} width={width} viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
			<g fill={fill}>
				<g id="---6.-Log-in-&amp;-Reg" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
					<g id="5-2-Log-in-via-password" fill={fill} transform="translate(-867 -732)">
						<g id="Group-5" transform="translate(548 730)">
							<path id="Combined-Shape" d="M3.578 8.32c3.024-3.022 6.549-4.399 7.874-3.073.585.585.642 1.597.266 2.805-.196.61.571.272.571.273 2.443-1.023 4.575-1.083 5.354.03.416.594.376 1.426-.007 2.39-.177.444.055.513.393.614 1.377.427 2.909 1.46 2.909 3.28 0 3.01-4.342 6.802-10.869 6.802C5.09 21.441 0 19.028 0 15.06c0-2.075 1.315-4.475 3.578-6.739zm13.231 6.263c-.258-2.607-3.688-4.403-7.661-4.01-3.972.392-6.985 2.825-6.727 5.433.258 2.608 3.688 4.404 7.661 4.012 3.973-.392 6.985-2.826 6.727-5.435zm-11.179.113c.821-1.666 2.958-2.608 4.849-2.116 1.957.505 2.955 2.351 2.155 4.144-.811 1.834-3.143 2.812-5.122 2.174-1.91-.617-2.718-2.503-1.882-4.202zm2.961.631c-.615-.258-1.41.008-1.79.603-.384.597-.204 1.31.407 1.588.62.283 1.443.014 1.827-.599.378-.619.179-1.327-.444-1.592zm1.516-.629c-.236-.094-.531.02-.67.252-.134.234-.06.5.177.597.241.1.548-.015.687-.253.133-.239.047-.508-.194-.596zM16.316 2.14a6.353 6.353 0 0 1 6.047 1.957 6.35 6.35 0 0 1 1.329 6.215.919.919 0 1 1-1.749-.564 4.52 4.52 0 0 0-.944-4.42 4.522 4.522 0 0 0-4.3-1.39.918.918 0 0 1-.384-1.798zm.681 3.19a3.093 3.093 0 0 1 3.592 3.978.792.792 0 0 1-1.505-.486 1.516 1.516 0 0 0-.316-1.48 1.518 1.518 0 0 0-1.441-.465.791.791 0 0 1-.33-1.548z" transform="translate(319)"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Icon24Weibo;
