import { makeStyles } from '@material-ui/core/styles';
import FitLineLogo from 'images/logo.svg';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
  },
});

function LogoPageLoader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={FitLineLogo} alt="fitLine-logo" />
    </div>
  );
}

export default LogoPageLoader;
