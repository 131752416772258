import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Variant } from 'components/Variant/index';
import VariantContent from 'components/Variant/VariantContent';
import { stiffnessVariants } from 'components/Variant/variants';

const contentVariants = {
  open: {
    clipPath: 'none',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: 'none',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5,
    },
  },
};

function FadeInVariantList({ list = [], focus, className, rowKey = 'id', children }) {
  if (isEmpty(list)) {
    return null;
  }

  return (
    <Variant focus={focus} className={className}>
      <VariantContent variants={contentVariants}>
        {list.map((item, index) => (
          <VariantContent key={item[rowKey]} variants={stiffnessVariants}>
            {children?.(item, index)}
          </VariantContent>
        ))}
      </VariantContent>
    </Variant>
  );
}

FadeInVariantList.propTypes = {
  list: PropTypes.array,
  focus: PropTypes.bool,
  className: PropTypes.string,
  rowKey: PropTypes.string,
  children: PropTypes.func,
};

export default FadeInVariantList;
