/* eslint-disable */
function Icon16StepDone(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M7.5 2c.743 0 1.486.093 2.229.371.464.186.742.743.557 1.208-.186.464-.743.742-1.207.557-.558-.186-1.022-.279-1.579-.279-2.6 0-4.643 2.043-4.643 4.643 0 2.6 2.043 4.643 4.643 4.643 2.6 0 4.643-2.043 4.643-4.643 0-.557.371-.929.928-.929.558 0 .929.372.929.929A6.46 6.46 0 0 1 7.5 15 6.46 6.46 0 0 1 1 8.5 6.46 6.46 0 0 1 7.5 2zm5.955.267a.893.893 0 0 1 1.272 0 .846.846 0 0 1 0 1.244L8.364 9.733a.834.834 0 0 1-.637.267.834.834 0 0 1-.636-.267L5.273 7.956a.846.846 0 0 1 0-1.245.893.893 0 0 1 1.272 0l1.182 1.156z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16StepDone;
