/* eslint-disable */
function Icon16Clock(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M8 1a7.021 7.021 0 0 1 7 7 7 7 0 1 1-7-7zm0 1.75a5.25 5.25 0 1 0 0 10.5A5.266 5.266 0 0 0 13.25 8c0-2.9-2.35-5.25-5.25-5.25zM7.91 4a.91.91 0 0 1 .908.91v2.272h2.273a.91.91 0 0 1 0 1.818H7.909A.91.91 0 0 1 7 8.09V4.91A.91.91 0 0 1 7.91 4z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Clock;
