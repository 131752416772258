/* eslint-disable */
function Icon16Edit(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M6.25 1c.525 0 .875.35.875.875s-.35.875-.875.875h-3.5v10.5h10.5v-3.5c0-.525.35-.875.875-.875s.875.35.875.875v4.375c0 .525-.35.875-.875.875H1.875C1.35 15 1 14.65 1 14.125V1.875C1 1.35 1.35 1 1.875 1zm5.78.27a.87.87 0 0 1 1.26 0l1.44 1.44c.36.36.36.9 0 1.26L8.7 10H6V7.3z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon16Edit;
