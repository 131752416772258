/* eslint-disable */
function Icon24Weixin(props) {
  const fill = props.fill || '#00C25F';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <g id="2.Icons/Brand/24-weixin" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path id="Combined-Shape" d="M16.73 8.666c3.522 0 7.27 2.34 7.27 5.535 0 1.936-.842 3.879-2.611 5.112l.425 1.845a.196.196 0 0 1-.296.209l-1.873-1.189c-.843.28-1.813.438-2.915.438-4.981 0-7.094-3.593-7.094-5.975 0-2.378 2.113-5.975 7.094-5.975zM8.704 2c4.07 0 7.673 2.4 8.397 5.869a9.233 9.233 0 0 0-.372-.008c-5.448 0-7.899 3.965-7.899 6.78 0 .498.077 1.031.232 1.572-.217-.01-2.451-.117-3.503-.452l-2.38 1.349a.309.309 0 0 1-.451-.348l.53-2.007S0 12.642 0 8.994 3.774 2 8.704 2zm5.748 9.682a.956.956 0 1 0 0 1.912.956.956 0 0 0 0-1.912zm4.78 0a.956.956 0 1 0 0 1.912.956.956 0 0 0 0-1.912zM5.459 5.647a1.081 1.081 0 1 0-.001 2.163 1.081 1.081 0 0 0 .001-2.163zm6.289 0a1.081 1.081 0 1 0-.001 2.163 1.081 1.081 0 0 0 .001-2.163z" fill={fill} />
        </g>
      </g>
    </svg>
  );
}

export default Icon24Weixin;
