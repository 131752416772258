/* eslint-disable */
function Icon24Bank(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';
	const viewBox = props.viewBox || '0 0 24 24'

  return (
    <svg height={height} width={width} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <g id="---11.-Apply-for-FC" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="Apply-for-FC---Lobby---All-Empty" fill={fill} transform="translate(-35 -321)">
            <g id="2.Icons/24/24-account-Copy" transform="translate(32 318)">
              <path id="Combined-Shape" d="M6.375 12v6.75h2.25V12h2.25v6.75h2.25V12h2.25v6.75h2.25V12h2.25v6.75H21V21H3v-2.25h1.125V12h2.25zm5.175-8.914a1.22 1.22 0 0 1 .9 0l7.875 3.44c.45.116.675.574.675 1.033v2.294c0 .688-.45 1.147-1.125 1.147H4.125C3.45 11 3 10.541 3 9.853V7.559c0-.459.225-.917.675-1.032zM12 6.412c-.675 0-1.125.459-1.125 1.147s.45 1.147 1.125 1.147 1.125-.459 1.125-1.147-.45-1.147-1.125-1.147z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon24Bank;
