import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import { formatDistance, parseMediaSourcesImage } from 'utils/helpers';
import { StoreFlagshipIcon, StoreStarIcon } from 'components/Icons';
import { transitionTime } from 'muiTheme';
import useStore from 'containers/StoreProvider/hooks/useStore';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  shopLogo: {
    width: 24,
    height: 24,
    [theme.breakpoints.up('md')]: {
      width: 49,
      height: 49,
    },
  },
  outOfStock: {
    color: theme.palette.error.main,
  },
  someItemsOutOfStock: {
    color: theme.palette.primary.pending,
  },
  inStock: {
    color: theme.palette.success.main,
  },
  flex: {
    display: 'flex',
  },
  right: {
    marginLeft: 'auto',
    display: 'flex',
  },
  items: {
    padding: theme.spacing(3),
    // cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.peachSupport,
      transition: `all ${transitionTime}s`,
    },
  },
  button: {
    padding: 0,
    minWidth: 'auto',
    minHeight: 'auto',
  },
  title: {
    fontWeight: 550,
    marginRight: theme.spacing(1),
  },
}));

function StoreItem({ item, storeNotice, ...other }) {
  const classes = useStyles();
  const { getStockStatus } = useStore();

  if (isEmpty(item)) {
    return null;
  }

  const isInviter = item?.extension_attributes?.customer;
  const isFlagStore = item?.extension_attributes?.flagship;
  const distance = item?.extension_attributes?.distance;
  const stocks = item?.extension_attributes?.skus
    ? JSON.parse(item?.extension_attributes?.skus)
    : undefined;
  const showStock = stocks !== undefined;
  const stockStatus = showStock ? getStockStatus(stocks) : undefined;
  const stockNumber = !isEmpty(stocks) && stocks.length === 1 ? stocks[0].quantity : undefined;

  return (
    <Box {...other}>
      <Hidden mdUp>
        <Box className={classes.root}>
          <Box mb={0.5}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Avatar
                  alt={item.name}
                  src={parseMediaSourcesImage(item?.extension_attributes?.logo, 'source')}
                  className={classes.shopLogo}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">{item.name}</Typography>
              </Grid>
              {isInviter && (
                <Grid item className={classes.flex}>
                  <SvgIcon
                    component={StoreStarIcon}
                    viewBox="0 0 24 24"
                    fontSize="large"
                    color="primary"
                  />
                </Grid>
              )}
              {isFlagStore && (
                <Grid item className={classes.flex}>
                  <SvgIcon component={StoreFlagshipIcon} viewBox="0 0 24 24" fontSize="large" />
                </Grid>
              )}
              {/* {selected && (
                <Grid item className={classes.right}>
                  <SvgIcon component={CheckIcon} viewBox="0 0 16 16" color="primary" />
                </Grid>
              )} */}
            </Grid>
          </Box>
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            {showStock ? (
              <>
                <Grid item>
                  <Typography
                    variant="body2"
                    className={clsx({
                      [classes.outOfStock]: stockStatus === 'outOfStock',
                      [classes.someItemsOutOfStock]: stockStatus === 'someItemsOutOfStock',
                      [classes.inStock]: stockStatus === 'inStock',
                    })}
                  >
                    <FormattedMessage {...messages[stockStatus]} />
                  </Typography>
                </Grid>
                {stockStatus === 'inStock' && stockNumber && (
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      库存：
                      {stockNumber}
                    </Typography>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {storeNotice || item.street}
                </Typography>
              </Grid>
            )}

            <Grid item className={classes.right}>
              <Typography variant="body2" color="textSecondary">
                {formatDistance(distance)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box width={1} display="flex" className={classes.items}>
          <Grid item xs={2}>
            <Grid item>
              <Avatar
                alt={item.name}
                src={parseMediaSourcesImage(item?.extension_attributes?.logo, 'source')}
                className={classes.shopLogo}
              />
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="body1" className={classes.title}>
                  {item.name}
                </Typography>
              </Grid>
              {isInviter && (
                <Grid item className={classes.flex}>
                  <SvgIcon
                    component={StoreStarIcon}
                    viewBox="0 0 24 24"
                    fontSize="large"
                    color="primary"
                  />
                </Grid>
              )}
              {isFlagStore && (
                <Grid item className={classes.flex}>
                  <SvgIcon component={StoreFlagshipIcon} viewBox="0 0 24 24" fontSize="large" />
                </Grid>
              )}
              {/* {selected && (
                <Grid item className={classes.right}>
                  <SvgIcon component={CheckIcon} viewBox="0 0 16 16" color="primary" />
                </Grid>
              )} */}
            </Grid>
            <Grid container alignItems="center" spacing={1}>
              {showStock ? (
                <>
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={clsx({
                        [classes.outOfStock]: stockStatus === 'outOfStock',
                        [classes.someItemsOutOfStock]: stockStatus === 'someItemsOutOfStock',
                        [classes.inStock]: stockStatus === 'inStock',
                      })}
                    >
                      <FormattedMessage {...messages[stockStatus]} />
                    </Typography>
                  </Grid>
                  {stockStatus === 'inStock' && stockNumber && (
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        库存：
                        {stockNumber}
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    {item.street}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="body1">{formatDistance(distance)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
    </Box>
  );
}

StoreItem.propTypes = {
  item: PropTypes.object.isRequired,
  // selectedItem: PropTypes.object,
  storeNotice: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default StoreItem;
